import React, { useState, useEffect, useRef } from 'react';
import parseISO from 'date-fns/parseISO';
import compareDates from 'date-fns/compareDesc';

import {
  addUserTodoItem, getUserTodoItems, removeUserTodoItem, updateUserTodoItem
} from '../../../api';
import { todoItemStatusValues } from '../../../types';
import TodoItem from './TodoItem';
import styles from './todoItems.css';
import { eventActions, eventCategories, trackEvent } from '../../../services/analytics';
import { READ_ONLY_DB } from '../../../env';
import useTranslation from '../../../hooks/useTranslation';

const TodoItems = () => {
  const { t } = useTranslation();
  const itemsRef = useRef([]);
  const [todoItems, setTodoItems] = useState([]);
  const [message, setMessage] = useState(null);
  const [newItem, setNewItem] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const withTimestamp = (item) => ({ ...item, createdDate: parseISO(item?.createdDate) });
  const compareTodoItems = (lhs, rhs) => compareDates(rhs.createdDate, lhs.createdDate);

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      try {
        const response = await getUserTodoItems();
        if (!isCancelled && Array.isArray(response)) {
          const items = response.filter(Boolean).map(withTimestamp).sort(compareTodoItems);
          setTodoItems(items);
        }
      } catch {
        setTodoItems([]);
      }
    })();
    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, todoItems.length);
  }, [todoItems]);

  const onSelectItem = READ_ONLY_DB ? () => {} : (id) => {
    setSelectedItemId(id);
  };

  const onUpdateItem = READ_ONLY_DB ? () => {} : async (item, pressedEnter) => {
    const response = await updateUserTodoItem(item.id, item);
    const updatedIndex = todoItems.findIndex((i) => i.id === item.id);
    setTodoItems([
      ...todoItems.slice(0, updatedIndex),
      withTimestamp(response),
      ...todoItems.slice(updatedIndex + 1)
    ]);
    setSelectedItemId(null);

    if (pressedEnter) {
      itemsRef.current[updatedIndex].focus();
    }
    trackEvent(eventCategories.myStudies.TODO, eventActions.UPDATE);
  };

  const onEditNewItem = READ_ONLY_DB ? () => {} : () => {
    setNewItem({
      id: null,
      content: '',
      status: todoItemStatusValues.OPEN
    });
    setSelectedItemId(null);
  };

  const onAddNewItem = READ_ONLY_DB ? () => {} : async (item, pressedEnter) => {
    const addedItem = await addUserTodoItem(item);
    setNewItem(null);
    setTodoItems([withTimestamp(addedItem), ...todoItems].sort(compareTodoItems));

    if (pressedEnter) {
      itemsRef.current[itemsRef.current.length - 1].focus();
    }
    trackEvent(eventCategories.myStudies.TODO, eventActions.ADD);
  };

  const onRemoveItem = READ_ONLY_DB ? () => {} : async (id) => {
    await removeUserTodoItem(id);
    setTodoItems(todoItems.filter((item) => item.id !== id));
    trackEvent(eventCategories.myStudies.TODO, eventActions.REMOVE);
    setMessage(t('todo.itemRemoved'));
    setTimeout(() => setMessage(null), 1000);
  };

  const onMarkItemDone = READ_ONLY_DB ? () => {} : async (id) => {
    const toUpdate = todoItems.find((item) => item.id === id);
    if (!toUpdate) {
      return;
    }

    const response = await updateUserTodoItem(id, {
      ...toUpdate,
      status: todoItemStatusValues.DONE
    });
    const updatedIndex = todoItems.findIndex((item) => item.id === id);
    setTodoItems([...todoItems.slice(0, updatedIndex), withTimestamp(response), ...todoItems.slice(updatedIndex + 1)]);
    trackEvent(eventCategories.myStudies.TODO, eventActions.MARK_DONE);
  };

  return (
    <div className={styles.container}>
      <h2>{t('todo.title')}</h2>
      <div className={styles.itemsContainer}>
        {todoItems.length === 0 && newItem === null
          ? (
            <div className={`${styles.noItems} noItems`}>
              <div className={`${styles.noItemsIcon} icon--alert`} aria-hidden="true" />
              <div className={styles.noItemsText}>
                {t('todo.noItems')}
              </div>
            </div>
          )
          : (
            <ul>
              {todoItems.map((item, i) => (
                <TodoItem
                  item={item}
                  key={item.id}
                  buttonRef={(el) => { itemsRef.current[i] = el; }}
                  isSelected={selectedItemId === item.id}
                  onMarkItemDone={onMarkItemDone}
                  onSelectItem={onSelectItem}
                  onSaveItem={onUpdateItem}
                  onCancel={() => onSelectItem(null)}
                  onRemoveItem={onRemoveItem}
                  disabled={READ_ONLY_DB}
                />
              ))}
              {newItem && (
                <TodoItem
                  item={newItem}
                  isSelected
                  onSaveItem={onAddNewItem}
                  onCancel={() => setNewItem(null)}
                  onRemoveItem={() => setNewItem(null)}
                  disabled={READ_ONLY_DB}
                />
              )}
            </ul>
          )}
      </div>

      <div className={styles.addButtonContainer}>
        <button
          type="button"
          className="button button--outline"
          onClick={onEditNewItem}
          disabled={newItem !== null || READ_ONLY_DB}
        >
          <span className="icon--plus" aria-hidden="true" />
          { ' ' }
          {t('todo.addItem')}
        </button>
      </div>

      <div className="sr-only" aria-live="polite">
        {message}
      </div>
    </div>
  );
};

export default TodoItems;
