import { hot } from 'react-hot-loader/root';
import React from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import FrontPageURLSelector from './FrontPageURLSelector';
import InitializeLang from '../InitializeLang';
import Header from '../Header';
import Footer from '../Footer';
import FrontPage from '../FrontPage';
import Login from '../Login';
import { NewsPage, DegreeProgrammeNewsPage } from '../NewsPage';
import CourseSearch from '../CourseSearch';
import DegreeProgramStudies from '../DegreeProgrammeStudies';
import CoursePage from '../CoursePage';
import MyStudies from '../MyStudies';
import MultiSearch from '../MultiSearch';
import StudyModule from '../StudyModule';
import RootContextProvider from '../../context/RootContextProvider';
import LangContextProvider from '../../context/LangContextProvider';
import DeviceSizeContextProvider from '../../context/DeviceSizeContextProvider';
import PrivateRoute from '../PrivateRoute';
import RootContext from '../../context/RootContext';

import {
  routes,
  searchPathTranslations,
  searchByDpPathTranslations,
  frontPagePathTranslations,
  newsPathTranslations,
  myStudiesPathTranslations,
  multiSearchPathTranslations,
  degreeStructurePathTranslations,
  degreeProgramPathTranslations,
  curPathTranslations,
  oldSearchPathTranslations,
  cuPathTranslations,
  smPathTranslations
} from '../../constants';
import InitializeAnalyticsWrapper from '../InitializeAnalyticsWrapper';
import ErrorBoundary from '../ErrorBoundary';
import DegreeStructurePage from '../DegreeStructurePage';
import PersistingUserSelectionsContextProvider from '../../context/PersistingUserSelectionsContextProvider';

const translatedPath = (translations) => `:path(${Object.values(translations).join('|')})`;
const Main = () => {
  const searchPathMatch = `/${translatedPath(searchPathTranslations)}`;
  const oldSearchPathMatch = `/${translatedPath(oldSearchPathTranslations)}`;
  const dpBrowserPathMatch = `/${translatedPath(searchByDpPathTranslations)}`;
  const courseUnitPathMatch = `/${translatedPath(cuPathTranslations)}/:courseUnitId`;
  // Course page URL contains the course name in the end, and it
  // may contain slashes and question marks, as we want to have the URL human readable.
  // So, do not add parsed elements into the URL after the name.
  const courseUnitRealisationPathMatch = `/${translatedPath(curPathTranslations)}/:courseUnitRealisationId`;
  const frontpagePathMatch = `/${translatedPath(frontPagePathTranslations)}`;
  const degreeProgrammeNewspagePathMatch = `/${translatedPath(newsPathTranslations)}/f/:id`;
  const newspagePathMatch = `/${translatedPath(newsPathTranslations)}/:id`;
  const myStudiesPathMatch = `/${translatedPath(myStudiesPathTranslations)}`;
  const multiSearchPathMatch = `/${translatedPath(multiSearchPathTranslations)}`;
  const studyModulePathMatch = `/${translatedPath(smPathTranslations)}/:studyModuleId`;
  const degreeProgramPathMatch = `*/${translatedPath(degreeProgramPathTranslations)}/:degreeProgramId`;
  const degreeStructurePathMatch = `/${translatedPath(degreeStructurePathTranslations)}`;

  return (
    <LangContextProvider>
      {/* ErrorBoundary must be inside LangContextProvider to enable localized error messages in boundary */}
      <ErrorBoundary>
        <PersistingUserSelectionsContextProvider>
          <BrowserRouter>
            <InitializeLang>
              <FrontPageURLSelector />
              <RootContextProvider>
                <DeviceSizeContextProvider>
                  <InitializeAnalyticsWrapper>
                    <Header />
                    <RootContext.Consumer>
                      {({ obarConfig, lang }) => (
                        <main id={obarConfig?.contentId}>
                          <Switch>
                            <Route exact path={frontpagePathMatch} component={FrontPage} />
                            <Route path={degreeProgrammeNewspagePathMatch} component={DegreeProgrammeNewsPage} />
                            <Route path={newspagePathMatch} component={NewsPage} />
                            <Route path={routes.LOGIN} component={Login} />
                            <Route exact path={searchPathMatch} component={CourseSearch} />
                            <Route path={courseUnitPathMatch} component={CoursePage} />
                            <Route path={courseUnitRealisationPathMatch} component={CoursePage} />
                            <PrivateRoute path={myStudiesPathMatch} component={MyStudies} />
                            <Route path={multiSearchPathMatch} component={MultiSearch} />
                            <Route path={studyModulePathMatch} component={StudyModule} />
                            <Route path={degreeProgramPathMatch} component={DegreeStructurePage} />
                            <Route path={degreeStructurePathMatch} component={DegreeStructurePage} />
                            <Route path={dpBrowserPathMatch} component={DegreeProgramStudies} />
                            <Route path={oldSearchPathMatch}>
                              <Redirect to={routes.search(lang)} />
                            </Route>
                            <Route path="/demokurssi">
                              <Redirect to={`/${curPathTranslations.fi}/hy-opt-cur-2324-6278cf26-5d71-4a65-b179-3fd5d112aa40`} />
                            </Route>
                            <Route path="/demokurs">
                              <Redirect to={`/${curPathTranslations.sv}/hy-opt-cur-2324-6278cf26-5d71-4a65-b179-3fd5d112aa40`} />
                            </Route>
                            <Route path="/demo-course">
                              <Redirect to={`/${curPathTranslations.en}/hy-opt-cur-2324-6278cf26-5d71-4a65-b179-3fd5d112aa40`} />
                            </Route>
                            <Route path="*">
                              <Redirect to={routes.FRONT_PAGE} />
                            </Route>
                          </Switch>
                        </main>
                      )}
                    </RootContext.Consumer>
                    <Footer />
                  </InitializeAnalyticsWrapper>
                </DeviceSizeContextProvider>
              </RootContextProvider>
            </InitializeLang>
          </BrowserRouter>
        </PersistingUserSelectionsContextProvider>
      </ErrorBoundary>
    </LangContextProvider>
  );
};

export default hot(Main);
