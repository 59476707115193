import React from 'react';
import { string, arrayOf } from 'prop-types';
import Organisation from '../Organisation';
import { getOrganisationId, getOrganisationName } from '../util';
import styles from './coordinatorOrganisations.css';
import { courseUnitRealisationOrganisationType } from '../../../types';
import { getTranslation } from '../../../i18n';
import useTranslation from '../../../hooks/useTranslation';

const sortOrganisations = (lang) => (org1, org2) => {
  const name1 = getTranslation(getOrganisationName(org1), lang).toUpperCase();
  const name2 = getTranslation(getOrganisationName(org2), lang).toUpperCase();
  if (name1 < name2) return -1;
  if (name1 > name2) return 1;
  return 0;
};

// FIXME: partnerLocation should probably be organisation specific
const CoordinatorOrganisations = ({
  organisations,
  partnerLocation
}) => {
  const { lang } = useTranslation();
  return (
    <div className={styles.coordinatorsContainer}>
      {organisations
        .filter((org) => org.organisation || org.educationalInstitution)
        .sort(sortOrganisations(lang)).map((org) => (
          <div key={getOrganisationId(org)} className={styles.coursePartnerContainer}>
            <span className={styles.coursePartner}>
              <Organisation
                key={getOrganisationId(org)}
                name={getOrganisationName(org)}
                partnerLocation={partnerLocation}
              />
            </span>
          </div>
        ))}
    </div>
  );
};

CoordinatorOrganisations.propTypes = {
  organisations: arrayOf(courseUnitRealisationOrganisationType),
  partnerLocation: string
};

export default CoordinatorOrganisations;
