import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { parseISO } from 'date-fns';
import { uniqueId } from 'lodash';
import classNames from 'classnames';
import urlRegex from 'url-regex-safe';

import Section from '../../../Section';
import styles from './messages.css';
import { cmsMessagesType, cmsMessageType } from '../../../../types';
import { formatDateTime } from '../../../../utils';
import Link from '../../../Link';
import useTranslation from '../../../../hooks/useTranslation';

const isExternal = (url) => !url.startsWith(`${window.location.protocol}//${window.location.host}`);

const asLink = (url) => {
  if (isExternal(url)) {
    return (<Link key={uniqueId('a')} href={url} external>{url}</Link>);
  }
  return (<a href={url} key={uniqueId('a')}>{url}</a>);
};

const replaceUrlsWithLinks = (text, nodes) => {
  const textParts = text.split(urlRegex());
  if (textParts.length > 1) {
    const urls = text.match(urlRegex()).reverse();
    textParts.forEach((textPart) => {
      nodes.push(textPart);
      if (urls.length) {
        nodes.push(asLink(urls.pop()));
      }
    });
  } else {
    nodes.push(text);
  }
};

const MessageContent = ({ text }) =>
  text.split(/(\n\r|\r\n|\r|\n)/)
    .reduce((result, current, i) => {
      if (i % 2) {
        result.push(<br key={uniqueId('br')} />);
      } else {
        replaceUrlsWithLinks(current, result);
      }
      return result;
    }, []);

MessageContent.propTypes = {
  text: string.isRequired
};

const MessageRow = ({ message, lang, t }) => {
  const [expanded, setExpanded] = useState(false);
  const contentId = uniqueId('message');

  return (
    <>
      <tr className={classNames(styles.messageInfo, { [styles.expanded]: expanded })}>
        <td>{formatDateTime(message.created, lang)}</td>
        <td>{message.topic}</td>
        <td>
          <button type="button" className="button button--link" onClick={() => setExpanded(!expanded)} aria-expanded={expanded} aria-controls={contentId}>
            {t(expanded ? 'messages.hide' : 'messages.show')}
          </button>
        </td>
      </tr>
      <tr id={contentId} className={styles.messageContent} aria-expanded={expanded}>
        {expanded && (
          <td role="region" colSpan={3}>
            <MessageContent text={message.content} />
          </td>
        )}
      </tr>
    </>
  );
};

MessageRow.propTypes = {
  message: cmsMessageType.isRequired,
  lang: string,
  t: func
};

const Messages = ({ messages }) => {
  const { t, lang } = useTranslation();

  if (!messages?.length) {
    return null;
  }

  return (
    <Section className={styles.section} level={2} label={t('messages.label')} noUpperCaseLabel>
      <table>
        <thead>
          <tr className={styles.head}>
            <th>{t('messages.sentAt')}</th>
            <th>{t('messages.topic')}</th>
            <th>{t('messages.content')}</th>
          </tr>
        </thead>
        <tbody>
          {messages
            .sort((msg1, msg2) => parseISO(msg2.created) - parseISO(msg1.created))
            .map((msg) => (<MessageRow key={msg.created} message={msg} lang={lang} t={t} />))}
        </tbody>
      </table>
    </Section>
  );
};

Messages.propTypes = {
  messages: cmsMessagesType
};

export default Messages;
