import { parseFeed } from './rssParser';

const TIKE_BULLETIN_FEED_URL = 'https://od.helsinki.fi/katko/katkot.rss';

const getDisruptionBulletins = async () => {
  const disruptionBulletinFeed = await parseFeed(TIKE_BULLETIN_FEED_URL);
  return (disruptionBulletinFeed && disruptionBulletinFeed.items) || undefined;
};

export default getDisruptionBulletins;
