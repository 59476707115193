import React from 'react';
import {
  bool, number, node, oneOf
} from 'prop-types';
import classNames from 'classnames';

import { cmsMaterialsType, cmsMaterialTypes, courseUserAffiliationTypes } from '../../../../types';
import MaterialFile from './MaterialFile';
import MaterialTitle from './MaterialTitle';
import Collapse from '../../../Collapse';
import MaterialLink from './MaterialLink';
import styles from './cmsMaterials.css';
import useTranslation from '../../../../hooks/useTranslation';

const RestrictedContainer = ({ children, hasCourseAffiliation }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.restrictedContainer}>
      {!hasCourseAffiliation && <span className={classNames(styles.permissionIcon, 'icon--permission-private')} />}
      <div className={styles.restrictedContent}>
        {children}
        <div className={styles.restrictedDescription}>
          {t('courseMaterials.restricted')}
        </div>
      </div>
    </div>
  );
};

RestrictedContainer.propTypes = {
  children: node.isRequired,
  hasCourseAffiliation: bool
};

RestrictedContainer.defaultProps = {
  hasCourseAffiliation: false
};

const CmsMaterials = ({
  materials, embedMedia, collapsible, useRestricted, affiliation, showByDefault
}) => {
  const { t } = useTranslation();

  if (!materials || !materials.length) {
    return null;
  }

  const hasCourseAffiliation = [
    courseUserAffiliationTypes.STUDENT,
    courseUserAffiliationTypes.TEACHER,
    courseUserAffiliationTypes.ADMINISTRATOR
  ].includes(affiliation);

  const materialComponents = materials.map((material) => {
    let mat;
    switch (material.type) {
      case cmsMaterialTypes.TITLE:
        return <MaterialTitle key={material.id} material={material} />;
      case cmsMaterialTypes.FILE:
        mat = <MaterialFile key={material.id} material={material} embedMedia={embedMedia} />;
        break;
      case cmsMaterialTypes.LINK:
        mat = <MaterialLink key={material.id} material={material} />;
        break;
      default:
        return null;
    }

    if (useRestricted && material.restricted) {
      return (
        <RestrictedContainer key={material.id} hasCourseAffiliation={hasCourseAffiliation}>
          {mat}
        </RestrictedContainer>
      );
    }

    return mat;
  }).filter((elem) => elem);

  let alwaysShowCount = showByDefault;
  if (materialComponents.length > showByDefault && materialComponents[showByDefault - 1].type === cmsMaterialTypes.TITLE) {
    alwaysShowCount += 1;
  }

  return collapsible && materialComponents.length > showByDefault ? (
    <Collapse
      alwaysShow={alwaysShowCount}
      expandLabel={t('courseMaterials.expandLabel')}
      collapseLabel={t('courseMaterials.collapseLabel')}
      upperCaseLabel
      collapsed
      collapseArrow={false}
      className={styles.materialsCollapse}
    >
      {materialComponents}
    </Collapse>
  ) : (
    <div className={styles.materialsNoncollapsible}>
      {materialComponents}
    </div>
  );
};

CmsMaterials.propTypes = {
  materials: cmsMaterialsType,
  embedMedia: bool,
  collapsible: bool,
  useRestricted: bool,
  affiliation: oneOf(Object.values(courseUserAffiliationTypes)),
  showByDefault: number
};

CmsMaterials.defaultProps = {
  embedMedia: false,
  collapsible: false,
  useRestricted: false,
  showByDefault: 3
};

export default CmsMaterials;
