import { useEffect, useState } from 'react';

const useIsSlowLoad = (isLoading, { thresholdMs }) => {
  const [isSlowLoad, setIsSlowLoad] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (isLoading) {
      timeoutId = setTimeout(() => {
        setIsSlowLoad(true);
      }, thresholdMs);
    } else {
      setIsSlowLoad(false);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isLoading, thresholdMs]);

  return isSlowLoad;
};

export default useIsSlowLoad;
