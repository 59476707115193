import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { getDegreeProgrammeNews, getGuideDegreeProgrammes } from '../../api';
import NewsContainer from '../NewsContainer';
import { formatLocalizedOption, guideAllNewsLinkForLanguage } from '../../utils';
import DegreeProgrammeSelector from '../DegreeProgrammeSelector';
import useDegreePrograms from '../../hooks/useDegreePrograms';
import useTranslation from '../../hooks/useTranslation';
import usePersistingDegreeProgramme from '../../hooks/usePersistingDegreeProgramme';

const DEFAULT_SELECTED = null;
const DEFAULT_NEWS = undefined;
const DEFAULT_OPTIONS = [];

const initOptions = async (
  lang,
  setDegreeProgrammeOptions,
  setSelectedDegreeProgramme,
  userDegreePrograms,
  persistingDegreeProgramme
) => {
  const dpsWithNews = await getGuideDegreeProgrammes(lang);
  // modify data to be compatible with DegreeProgrammeSelector
  const dpOptions = Array.isArray(dpsWithNews) ? dpsWithNews.map((dp) => ({
    ...formatLocalizedOption(dp, lang),
    value: dp.code,
    type: dp.programme_type
  })) : DEFAULT_OPTIONS;

  setDegreeProgrammeOptions(dpOptions);

  if (persistingDegreeProgramme?.code) {
    setSelectedDegreeProgramme(persistingDegreeProgramme.code);
  } else if (userDegreePrograms) {
    const primary = userDegreePrograms.find((dp) => dp.isPrimary);
    setSelectedDegreeProgramme(dpOptions.find((dpOpt) => primary?.code === dpOpt.code)?.value);
  }
};

const DegreeProgrammeNews = ({ trackedEventCategory }) => {
  const { lang } = useTranslation();
  const { persistingDegreeProgramme, setPerstistingDegreeProgramme } = usePersistingDegreeProgramme();
  const userDegreePrograms = useDegreePrograms();

  const [degreeProgrammeOptions, setDegreeProgrammeOptions] = useState(DEFAULT_OPTIONS);
  const [selectedDegreeProgramme, setSelectedDegreeProgramme] = useState(DEFAULT_SELECTED);
  const [initialized, setInitialized] = useState(degreeProgrammeOptions.length > 0);
  const [news, setNews] = useState(DEFAULT_NEWS);

  // for DegreeProgrammeNews that uses DegreeProgrammeSelector, initialize
  useEffect(() => {
    if (!initialized) {
      try {
        initOptions(lang, setDegreeProgrammeOptions, setSelectedDegreeProgramme, userDegreePrograms, persistingDegreeProgramme);
      } finally {
        setInitialized(true);
      }
    }
  }, [userDegreePrograms, initialized, lang, persistingDegreeProgramme]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const items = await getDegreeProgrammeNews(selectedDegreeProgramme, lang);
        setNews(items.map((i) => ({ ...i, renderDegreeProgrammeLink: true })));
      } catch (e) {
        setNews(null);
      }
    };

    if (selectedDegreeProgramme) {
      fetchNews();
    }
  }, [selectedDegreeProgramme, lang]);

  return (
    <NewsContainer
      header="degreeProgrammeNews.header.main"
      controls={
        (
          <DegreeProgrammeSelector
            options={degreeProgrammeOptions}
            onSelect={(value) => {
              setPerstistingDegreeProgramme('code', value);
              setSelectedDegreeProgramme(value);
            }}
            selectedItem={selectedDegreeProgramme}
            ownMultiple
          />
        )
      }
      newsItems={news}
      itemTypeLabel="degreeProgrammeNews.itemTypeLabel"
      showItemIngress={false}
      showAllLink={guideAllNewsLinkForLanguage(lang)}
      showAllLabel="degreeProgrammeNews.showAllLabel"
      trackedEventCategory={trackedEventCategory}
    />
  );
};

DegreeProgrammeNews.propTypes = {
  trackedEventCategory: string
};

export default DegreeProgrammeNews;
