import React, { createRef, useRef, useState } from 'react';
import {
  arrayOf, node, shape, func, number, string
} from 'prop-types';
import { findIndex } from 'lodash';
import Tab from './Tab';
import styles from './tabGroup.css';

export const tabGroupKeyboardNavigation = (e, tabRefs, activateTab) => {
  const maxIndex = tabRefs.current.length - 1;
  const tabIndex = findIndex(tabRefs.current, (tab) => tab.current === document.activeElement);
  let newTabIndex = -1;
  switch (e.key) {
    case 'End':
      newTabIndex = maxIndex;
      break;
    case 'Home':
      newTabIndex = 0;
      break;
    case 'ArrowLeft':
      newTabIndex = tabIndex > 0 ? tabIndex - 1 : maxIndex;
      break;
    case 'ArrowRight':
      newTabIndex = tabIndex < maxIndex ? tabIndex + 1 : 0;
      break;
    default:
      break;
  }
  if (newTabIndex >= 0) {
    e.preventDefault();
    activateTab(newTabIndex);
    tabRefs.current[newTabIndex]?.current?.focus();
  }
};

const TabGroup = ({
  tabs, active, onTabSelect, controls, label
}) => {
  const [selected, setSelected] = useState(active);
  const tabRefs = useRef(tabs ? tabs.map(() => createRef()) : []);

  const onTabClicked = (index) => {
    if (onTabSelect) {
      onTabSelect(index);
    }

    setSelected(index);
  };

  const handleKeyDown = (e) => tabGroupKeyboardNavigation(e, tabRefs, onTabClicked);

  if (!tabs || !tabs.length) {
    return null;
  }

  return (
    <div className="horizontal-tabs clearfix">
      <div className={styles.tabsBorder} />
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
      <div role="tablist" aria-label={label} className={`horizontal-tabs-list ${styles.tabsContainer}`} onKeyDown={handleKeyDown}>
        {tabs.map((tab, idx) => (
          <Tab
            /* eslint-disable-next-line react/no-array-index-key */
            key={tab.label + idx}
            index={idx}
            label={tab.label}
            onTabClicked={onTabClicked}
            ref={tabRefs.current[idx]}
            first={idx === 0}
            last={idx === tabs.length - 1}
            selected={idx === selected}
            controls={controls}
          />
        ))}
      </div>
      {tabs[selected].content}
    </div>
  );
};

TabGroup.propTypes = {
  tabs: arrayOf(shape({
    label: node.isRequired,
    content: node
  })).isRequired,
  active: number,
  onTabSelect: func,
  controls: string.isRequired,
  label: string.isRequired
};

TabGroup.defaultProps = {
  active: 0,
  onTabSelect: undefined
};

export default TabGroup;
