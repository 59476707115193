import React, { useEffect, useRef, useState } from 'react';
import {
  bool, func, node, string
} from 'prop-types';
import classNames from 'classnames';
import useBodyScrollLock from '../../hooks/useBodyScrollLock';
import styles from './modalDialog.css';
import useTranslation from '../../hooks/useTranslation';

const ModalDialog = ({
  children,
  id,
  onConfirm,
  onCancel,
  confirmLabel,
  cancelLabel,
  buttonsClassName,
  reverseButtons,
  modalDialogTitle,
  submitButton,
  isEnrolment
}) => {
  const { t } = useTranslation();
  useBodyScrollLock();
  const closeButtonRef = useRef(null);
  const confirmButtonRef = useRef(null);

  const [windowHeight, setWindowHeight] = useState(0);

  const resizeWindow = () => {
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  });

  const preventTabLeavingModal = (e) => {
    if (e.key === 'Tab' && !e.shiftKey) {
      e.preventDefault();
      closeButtonRef.current.focus();
    }
  };

  const preventShiftTabLeavingModal = (e) => {
    if (e.key === 'Tab' && e.shiftKey) {
      e.preventDefault();
      confirmButtonRef.current.focus();
    }
  };

  const buttonContainerClasses = classNames(styles.buttonContainer, {
    [styles.reverse]: reverseButtons
  });
  const cancelButtonClasses = classNames('button', 'button--outline', styles.button, {
    [buttonsClassName]: buttonsClassName
  });
  const confirmButtonClasses = classNames('button', 'button--action', styles.button, {
    [buttonsClassName]: buttonsClassName
  });

  const confirmButton = () => (
    <button
      key="ok-button"
      className={confirmButtonClasses}
      type={submitButton ? 'submit' : 'button'}
      onClick={submitButton ? undefined : onConfirm}
      ref={reverseButtons ? undefined : confirmButtonRef}
      onKeyDown={reverseButtons ? undefined : preventTabLeavingModal}
    >
      {confirmLabel || t('ok')}
    </button>
  );

  const buttons = [(
    <button
      key="cancel-button"
      type="button"
      className={cancelButtonClasses}
      onClick={onCancel}
      ref={reverseButtons ? confirmButtonRef : undefined}
      onKeyDown={(reverseButtons || !onConfirm) ? preventTabLeavingModal : undefined}
    >
      {cancelLabel || t('cancel')}
    </button>
  ), onConfirm ? confirmButton() : null];

  return (
    <div className={styles.overlay}>
      <div
        id={id}
        className={styles.dialog}
        style={{ maxHeight: windowHeight }}
        role="dialog"
        aria-label={modalDialogTitle}
        aria-modal="true"
        tabIndex={-1}
      >
        <form onSubmit={submitButton ? onConfirm : undefined} className={styles.form}>
          <button
            id="modal-close-button"
            autoFocus // eslint-disable-line jsx-a11y/no-autofocus
            className={styles.closeButton}
            type="button"
            onClick={onCancel}
            aria-label={
              isEnrolment
                ? t('assistedEnrolment.closeEnrolment')
                : cancelLabel
            }
            onKeyDown={preventShiftTabLeavingModal}
            ref={closeButtonRef}
          >
            <span className="icon icon--remove" />
          </button>

          <div>
            <h2>
              {modalDialogTitle}
            </h2>
            {children}
          </div>
          <div className={buttonContainerClasses}>
            {reverseButtons ? buttons.reverse() : buttons}
          </div>
        </form>
      </div>
    </div>
  );
};

ModalDialog.propTypes = {
  children: node.isRequired,
  id: string,
  confirmLabel: node,
  cancelLabel: node,
  onConfirm: func,
  onCancel: func.isRequired,
  buttonsClassName: string,
  reverseButtons: bool,
  modalDialogTitle: string,
  submitButton: bool,
  isEnrolment: bool
};

export default ModalDialog;
