import React from 'react';
import { number, node, func } from 'prop-types';
import { IS_PROD } from '../../env';
import styles from './errorInfo.css';
import useTranslation from '../../hooks/useTranslation';

const Sentry = require('@sentry/browser');

class ErrorBoundary extends React.Component {
  static formatErrorInfo(info) {
    if (info) {
      return JSON.stringify(info).replace(/\\n/g, '\n');
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      showErrorInfo: !IS_PROD,
      errorInfo: null
    };
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    const { showErrorInfo } = this.state;
    Sentry.captureException(error);
    if (showErrorInfo) {
      // eslint-disable-next-line no-console
      console.error(error, info);
      this.setState({
        errorInfo: ErrorBoundary.formatErrorInfo(info)
      });
    }
  }

  render() {
    const { children, headerLevel, t } = this.props;
    const { hasError, showErrorInfo, errorInfo } = this.state;

    const Header = `h${headerLevel}`;

    if (hasError) {
      return (
        <>
          <Header>{t('errorBoundary.genericError')}</Header>
          {showErrorInfo ? (
            <pre className={styles.errorInfo}>{errorInfo}</pre>
          ) : null}
        </>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: node,
  headerLevel: number,
  t: func
};

ErrorBoundary.defaultProps = {
  headerLevel: 1
};

const ErrorBoundaryWrapper = ({ children, headerLevel }) => {
  const { t } = useTranslation();

  return (
    <ErrorBoundary headerLevel={headerLevel} t={t}>
      {children}
    </ErrorBoundary>
  );
};

ErrorBoundaryWrapper.propTypes = {
  children: node,
  headerLevel: number
};

export default ErrorBoundaryWrapper;
