import React from 'react';

import Section from '../../../Section';
import { courseCmsDataType } from '../../../../types';
import useTranslation from '../../../../hooks/useTranslation';

const CourseCompletion = ({ cmsData }) => {
  const { t } = useTranslation();
  if (!cmsData || !cmsData.courseCompletion) {
    return null;
  }

  return (
    <Section level={2} label={t('courseUnitRealisation.courseCompletion.title')} html>
      {cmsData.courseCompletion}
    </Section>
  );
};

CourseCompletion.propTypes = {
  cmsData: courseCmsDataType
};

export default CourseCompletion;
