import React, { useState } from 'react';
import classNames from 'classnames';
import {
  node, string, object, oneOfType, bool
} from 'prop-types';
import { uniqueId } from 'lodash';

import useHideOnClickOutside from '../../hooks/useHideOnClickOutside';
import styles from './tooltipButton.css';
import useTranslation from '../../hooks/useTranslation';

const TooltipButton = ({
  label, children, className, ariaHidden
}) => {
  const { t } = useTranslation();
  const [rightSideTooltip, setRightSideTooltip] = useState(true);
  const [maxWidth, setMaxWidth] = useState(null);
  const { ref, isComponentVisible, setIsComponentVisible } = useHideOnClickOutside(false);

  const handleClick = (event) => {
    setRightSideTooltip(event.nativeEvent.clientX > window.innerWidth / 2);
    setMaxWidth(`${(window.innerWidth / 3) * 2}px`);
    setIsComponentVisible(!isComponentVisible);
  };

  const tooltipId = uniqueId('tooltip');

  return (
    <span className={classNames(styles.container, className)} aria-hidden={ariaHidden}>
      <button type="button" className={styles.button} onClick={handleClick} aria-haspopup="true" aria-expanded={isComponentVisible} aria-controls={tooltipId}>{label}</button>
      <div
        ref={ref}
        id={tooltipId}
        className={classNames(styles.tooltip, {
          [styles.right]: rightSideTooltip,
          [styles.left]: !rightSideTooltip,
          hidden: !isComponentVisible
        })}
        style={{ maxWidth }}
      >
        <div className={styles.closeButtonContainer}>
          <button type="button" className={classNames(styles.closeButton, 'icon--remove')} onClick={handleClick} aria-label={t('close')} />
        </div>
        {children}
      </div>
    </span>
  );
};

TooltipButton.propTypes = {
  label: oneOfType([string, object]).isRequired,
  className: string,
  children: node.isRequired,
  ariaHidden: bool
};

export default TooltipButton;
