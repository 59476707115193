import React from 'react';
import EnrolmentPeriod from './EnrolmentPeriod';
import ExternalEnrolment from './ExternalEnrolment';
import styles from './enrolment.css';
import { courseUnitRealisationType, courseUserAffiliationTypes } from '../../../../../types';
import { isMOOC } from '../../../../../utils';
import SisuEnrolment from './SisuEnrolment';
import OpenUniversityProductEnrolment from './OpenUniversityProductEnrolment';
import SubOpenUniversityProductEnrolment from './SubOpenUniversityProductEnrolment';
import CombinedOpenUniversityProductEnrolment from './CombinedOpenUniversityProductEnrolment';
import CombinedSubOpenUniversityProductEnrolment from './CombinedSubOpenUniversityProductEnrolment';
import Enrolled from './Enrolled';
import CombinedEnrolment from './CombinedEnrolment';
import { enrolmentModes, openUniversityProductTypes } from '../../../../../constants';
import AssistedEnrolment from './AssistedEnrolment';
import useTranslation from '../../../../../hooks/useTranslation';

const renderMOOCEnrolmentMessage = (t) => (
  <div id="enrolment">
    <div className={`${styles.moocMessage} mooc-message`}>
      <span>{t('enrolment.mooc')}</span>
    </div>
  </div>
);

const Enrolment = ({ courseUnitRealisation }) => {
  const { t } = useTranslation();
  const {
    cmsData, enrolmentPeriod, lateEnrolmentEnd, externalEnrolmentLink, tags, affiliation, enrolmentMode, openUniversityProductType
  } = courseUnitRealisation;
  const hideEnrolmentButton = cmsData?.hideEnrolmentButton;
  const isEnrolled = [courseUserAffiliationTypes.ENROLMENT_PENDING, courseUserAffiliationTypes.STUDENT].includes(affiliation);

  if (hideEnrolmentButton) {
    return isMOOC(tags) ? renderMOOCEnrolmentMessage(t) : null;
  }

  if (isEnrolled) {
    return (
      <div id="enrolment">
        <Enrolled courseUnitRealisation={courseUnitRealisation} />
      </div>
    );
  }

  const getEnrolment = () => {
    switch (openUniversityProductType) {
      case openUniversityProductTypes.MAIN:
        return (<OpenUniversityProductEnrolment courseUnitRealisation={courseUnitRealisation} />);
      case openUniversityProductTypes.COMBINED_MAIN:
        return (<CombinedOpenUniversityProductEnrolment courseUnitRealisation={courseUnitRealisation} />);
      case openUniversityProductTypes.SUB:
        return (<SubOpenUniversityProductEnrolment courseUnitRealisation={courseUnitRealisation} />);
      case openUniversityProductTypes.COMBINED_SUB:
        return (<CombinedSubOpenUniversityProductEnrolment courseUnitRealisation={courseUnitRealisation} />);
      // no default
    }
    switch (enrolmentMode) {
      case enrolmentModes.OPEN_UNIVERSITY:
        return (
          <span id="external-container" className={styles.externalLinkContainer}>
            <ExternalEnrolment externalEnrolmentLink={externalEnrolmentLink} enrolmentPeriod={enrolmentPeriod} />
          </span>
        );
      case enrolmentModes.COMBINED:
        return (<CombinedEnrolment courseUnitRealisation={courseUnitRealisation} />);
      case enrolmentModes.ASSISTED:
        return (<AssistedEnrolment courseUnitRealisation={courseUnitRealisation} />);
      case enrolmentModes.SISU:
      default:
        return (<SisuEnrolment courseUnitRealisation={courseUnitRealisation} />);
    }
  };

  return (
    <div id="enrolment">
      {getEnrolment()}
      <EnrolmentPeriod enrolmentPeriod={enrolmentPeriod} lateEnrolmentEnd={lateEnrolmentEnd} />
    </div>
  );
};

Enrolment.propTypes = {
  courseUnitRealisation: courseUnitRealisationType.isRequired
};

export default Enrolment;
