import React from 'react';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';

import { courseCmsDataType, courseContactPersonType } from '../../../../types';
import MoodleLink from './MoodleLink';
import ZoomLink from './ZoomLink';
import Section from '../../../Section';
import styles from './links.css';
import { eventCategories } from '../../../../services/analytics';
import CourseContactInfo from './CourseContactInfo';
import CourseFeedbackLink from './CourseFeedbackLink';
import Link from '../../../Link';
import useTranslation from '../../../../hooks/useTranslation';

const getLink = (link) => {
  const external = !link.uri.includes(window.location.host);

  return (
    <Link
      key={link.uri}
      href={link.uri}
      className={classNames(
        'button--action-before',
        styles.link,
        styles.linkButton
      )}
      tracked
      eventCategory={eventCategories.coursePage.LINK}
      trackingLabel="list of links"
      external={external}
      iconPosition="before"
    >
      {link.title}
    </Link>
  );
};

const renderLinks = (links, t) => links?.length && (
  <div className={styles.linksContainer}>
    <h2>{t('common.links')}</h2>
    {links.map(getLink)}
  </div>
);

const Links = ({ cmsData, courseFeedbackUrl, contactInfo }) => {
  const { t } = useTranslation();

  return (
    <Section className={styles.links}>
      <MoodleLink link={cmsData?.moodleLink} />
      <ZoomLink link={cmsData?.zoomLink} />
      {renderLinks(cmsData?.links, t)}
      <CourseFeedbackLink feedbackUrl={courseFeedbackUrl} />
      <CourseContactInfo contactInfo={contactInfo} />
    </Section>
  );
};

Links.propTypes = {
  cmsData: courseCmsDataType,
  courseFeedbackUrl: string,
  contactInfo: arrayOf(courseContactPersonType)
};

export default Links;
