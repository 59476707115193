import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

import styles from './icons.css';

const arrowProps = {
  iconClassName: string,
  className: string,
  height: string,
  width: string,
  'aria-hidden': oneOf(['true', 'false'])
};

const Arrow = ({
  iconClassName, height = '1em', width = '1em', className, ...props
}) => {
  const { 'aria-hidden': ariaHidden } = props;
  const containerClassNames = classNames('icon', className);

  return (
    <span className={containerClassNames}>
      <svg
        className={iconClassName}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 1000"
        height={height}
        width={width}
        aria-hidden={ariaHidden || true}
      >
        <path d="M993.6,482.3L518.2,6.4c-4.6-4.6-11-6.9-17.4-6.3c-6.4,0.6-12.3,4-15.9,9.4l-73.5,107.7
    c-5.9,8.6-4.8,20.2,2.6,27.6l265,265.3H21.7c-12,0-21.7,9.7-21.7,21.7v128.2c0,12,9.7,21.7,21.7,21.7H687L414,855.2
    c-7.4,7.4-8.5,19-2.6,27.6l73.5,107.7c3.6,5.3,9.5,8.8,15.9,9.4c0.7,0.1,1.3,0.1,2,0.1c5.7,0,11.3-2.3,15.4-6.4l475.4-475.9
    c4.8-4.8,6.9-11.4,6.2-17.7c0.1-0.8,0.1-1.5,0.1-2.3C1000,491.9,997.7,486.4,993.6,482.3z"
        />
      </svg>
    </span>
  );
};

export const ArrowOffsite = ({
  iconClassName, height, width, className, ...props
}) => (
  <Arrow
    className={className}
    iconClassName={classNames(styles.arrow, styles.offsite, iconClassName)}
    height={height}
    width={width}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

export const ArrowRight = ({
  iconClassName, height, width, className, ...props
}) => (
  <Arrow
    className={className}
    iconClassName={classNames(styles.arrow, styles.onsite, iconClassName)}
    height={height}
    width={width}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

Arrow.propTypes = arrowProps;

ArrowOffsite.propTypes = arrowProps;

ArrowRight.propTypes = arrowProps;

export default Arrow;
