import React from 'react';
import { func, oneOf } from 'prop-types';
import { range } from 'lodash';
import classNames from 'classnames';
import { formStateType, multiSearchResultListType } from '../../types';
import { eventActions, eventCategories, trackEvent } from '../../services/analytics';
import { ArrowRight } from '../Icon/Arrow';
import styles from './searchPagination.css';
import useTranslation from '../../hooks/useTranslation';

const getPageHref = (page) => {
  const url = new URL(window.location.href);
  url.searchParams.set('page', page);
  return url.pathname + url.search;
};

const ES_PAGINATION_LIMIT = 10000;

const SearchPagination = ({
  setPageIndex, searchOptions, results, searchType
}) => {
  const { t } = useTranslation();
  const { totalHits, pageSize = 30 } = results;
  const page = searchOptions.page || 0;
  const totalPages = totalHits > pageSize ? Math.ceil(totalHits / pageSize) : 1;
  const maxPageLimit = Math.floor(ES_PAGINATION_LIMIT / pageSize);

  const smallest = Math.max(page - 2, 1);
  const largest = Math.min(page + 2, totalPages - 1, maxPageLimit);

  const moreIndicator = <li className="pager__ellipsis">⋯</li>;

  const moreBefore = (page > 2) ? moreIndicator : '';
  const moreAfter = (largest < totalPages - 1) ? moreIndicator : '';

  const onButtonClick = (pageIndex) => (event) => {
    event.preventDefault();
    setPageIndex(pageIndex);
    const eventCategory = eventCategories[searchType].SEARCH_PAGINATION;
    trackEvent(eventCategory, eventActions.CLICK, pageIndex);
  };

  const createButton = (pageIndex) => (
    <li className="pager__page" key={`${pageIndex}-item`}>
      <a
        href={getPageHref(pageIndex)}
        className={`button ${page === pageIndex ? 'is-active' : ''}`}
        onClick={onButtonClick(pageIndex)}
        aria-label={`${t('search.pagination.goToPageAriaLabel')} ${pageIndex + 1}`}
        aria-current={pageIndex === page ? 'page' : null}
      >
        {pageIndex + 1}
      </a>
    </li>
  );

  const first = page === 0;
  const last = page + 1 >= Math.min(totalPages, maxPageLimit);
  const buttons = (smallest < largest) ? range(smallest, largest).map(createButton) : '';

  const prevPageButton = !first ? (
    <li className="pager__prev" key="first-item">
      <a
        href={getPageHref(page - 1)}
        className="button--action-before icon--arrow-left"
        onClick={onButtonClick(page - 1)}
        aria-label={t('search.pagination.goToPreviousPage')}
        rel="prev"
      >
        {t('search.pagination.previous')}
      </a>
    </li>
  ) : '';

  const nextPageButton = !last ? (
    <li className="pager__next" key="last-item">
      <a
        href={getPageHref(page + 1)}
        className={classNames('button--action', 'with-icon', styles.pagerButton)}
        onClick={onButtonClick(page + 1)}
        aria-label={t('search.pagination.goToNextPage')}
        rel="next"
      >
        <span>{t('search.pagination.next')}</span>
        <ArrowRight />
      </a>
    </li>
  ) : '';

  const lastPageButton = (totalPages > 1 && totalPages <= maxPageLimit) ? createButton(totalPages - 1) : '';
  const firstPageButton = createButton(0);

  return totalPages && totalPages > 1 ? (
    <nav aria-label="Search result pagination">
      <ul className="pager">
        {prevPageButton}
        {firstPageButton}
        {moreBefore}
        {buttons}
        {moreAfter}
        {lastPageButton}
        {nextPageButton}
      </ul>
    </nav>
  ) : null;
};

export const searchTypes = {
  COURSES: 'courses',
  DEGREE_PROGRAMMES: 'dpSearch',
  MULTI: 'multiSearch'
};

SearchPagination.propTypes = {
  setPageIndex: func.isRequired,
  searchOptions: formStateType.isRequired,
  results: multiSearchResultListType,
  searchType: oneOf(Object.values(searchTypes))
};

export default SearchPagination;
