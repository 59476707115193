import React from 'react';

import Event from '../Event';
import { formatTimeSpan } from '../../../../utils';

import { calendarEventType } from '../../../../types';
import useTranslation from '../../../../hooks/useTranslation';

const MonthEvent = ({ event }) => {
  const { lang } = useTranslation();

  return (
    <>
      <div className="rbc-event-label">{formatTimeSpan(event.start, event.end, lang)}</div>
      <Event event={event} hideOptionalInfo />
    </>
  );
};

MonthEvent.propTypes = {
  event: calendarEventType
};

export default MonthEvent;
