import React from 'react';
import { calendarEventSourceTypes, calendarEventType } from '../../../../../types';
import { eventCategories } from '../../../../../services/analytics';
import Link from '../../../../Link';

const EventTitle = ({ event }) => {
  const titleText = [event.title, (event.source === calendarEventSourceTypes.COURSE_PAGE) && event.courseTitle].filter(Boolean).join(', ');
  return event.courseUri ? (
    <Link
      tracked
      eventCategory={eventCategories.myStudies.CALENDAR}
      href={event.courseUri}
    >
      {titleText}
    </Link>
  ) : titleText;
};

EventTitle.propTypes = {
  event: calendarEventType
};

export default EventTitle;
