import React, { useEffect } from 'react';
import { string } from 'prop-types';
import styles from './degreeProgramStructure.css';
import useTranslation from '../../../hooks/useTranslation';

const DegreeProgramStructure = ({
  id, academicYear
}) => {
  const { t, lang } = useTranslation();

  useEffect(() => {
    window.eduviewer_var?.render();
  }, [academicYear]);
  return (
    <div className={`${styles.container}`}>
      <h2>{t('degreeProgram.degreeProgramStructure')}</h2>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <div className={styles.eduviewerContainer} id="eduviewer-root" module-code={id} lang={lang} academic-year={academicYear} selected-academic-year-only="true" hide-selections="true" hide-accordion="true" disable-global-style="true" internal-course-links="true" />
    </div>
  );
};

DegreeProgramStructure.propTypes = {
  id: string,
  academicYear: string
};

export default DegreeProgramStructure;
