import React from 'react';
import { getTranslation } from '../../../i18n';
import { studyModuleType } from '../../../types';
import { withPTag } from '../../../utils';
import Section from '../../Section';
import useTranslation from '../../../hooks/useTranslation';
/* eslint-disable react/no-danger */

const StudyModuleDescription = ({
  studyModule: {
    contentDescription, prerequisites, outcomes, additionalInfo, minorStudyRightApplicationInstructions
  } = {}
}) => {
  const { t, lang } = useTranslation();

  return (
    (contentDescription || prerequisites || outcomes || additionalInfo || minorStudyRightApplicationInstructions) ? (
      <>
        <h2>{t('studyModule.studyModuleInformation')}</h2>
        {contentDescription && (
        <Section>
          <h3>{t('studyModule.content')}</h3>
          <div className="rich-text" dangerouslySetInnerHTML={withPTag(getTranslation(contentDescription, lang))} />
        </Section>
        )}
        {prerequisites
            && (
              <Section>
                <h3>{t('studyModule.prerequisites')}</h3>
                <div className="rich-text" dangerouslySetInnerHTML={withPTag(getTranslation(prerequisites, lang))} />
              </Section>
            )}
        {outcomes
            && (
              <Section>
                <h3>{t('studyModule.outcomes')}</h3>
                <div className="rich-text" dangerouslySetInnerHTML={withPTag(getTranslation(outcomes, lang))} />
              </Section>
            )}
        {additionalInfo
            && (
              <Section>
                <h3>{t('studyModule.additionalInfo')}</h3>
                <div className="rich-text" dangerouslySetInnerHTML={withPTag(getTranslation(additionalInfo, lang))} />
              </Section>
            )}
        {minorStudyRightApplicationInstructions
            && (
              <Section>
                <h3>{t('studyModule.minorStudyRightApplicationInstruction')}</h3>
                <div className="rich-text" dangerouslySetInnerHTML={withPTag(getTranslation(minorStudyRightApplicationInstructions, lang))} />
              </Section>
            )}
      </>
    ) : null);
};

StudyModuleDescription.propTypes = {
  studyModule: studyModuleType
};

export default StudyModuleDescription;
