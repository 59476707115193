import React from 'react';
import { cmsMaterialLinkType } from '../../../../../types';

import styles from '../cmsMaterials.css';
import { eventCategories } from '../../../../../services/analytics';
import Link from '../../../../Link';

const MaterialLink = ({ material }) => (
  <div className={styles.materialContainer}>
    <div className={styles.infoContainer}>
      {material.url ? (
        <Link
          href={material.url}
          external
          tracked
          eventCategory={eventCategories.coursePage.LINK}
          trackingLabel="materials"
        >{material.title}
        </Link>
      ) : material.title}
      {material.instructions && <div>{material.instructions}</div>}
    </div>
  </div>
);

MaterialLink.propTypes = {
  material: cmsMaterialLinkType
};

export default MaterialLink;
