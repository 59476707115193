import React from 'react';
import {
  isAfter, isValid, isWithinInterval, parseISO
} from 'date-fns';
import { courseUnitRealisationType, courseUserAffiliationTypes } from '../../../../../../types';
import styles from './enrolled.css';
import Link from '../../../../../Link';
import useTranslation from '../../../../../../hooks/useTranslation';

const Enrolled = ({ courseUnitRealisation }) => {
  const { t } = useTranslation();
  const {
    affiliation, enrolmentPeriod, lateEnrolmentEnd, openUniversity, coTeachingRealisation, sisuUrl
  } = courseUnitRealisation;

  if (![courseUserAffiliationTypes.ENROLMENT_PENDING, courseUserAffiliationTypes.STUDENT].includes(affiliation)) {
    return null;
  }

  const enrolmentStartDate = parseISO(enrolmentPeriod?.startDateTime);
  const enrolmentEndDate = parseISO(enrolmentPeriod?.endDateTime);
  const lateEnrolmentEndDate = parseISO(lateEnrolmentEnd);
  const now = new Date();
  const enrolmentPeriodEnded = isAfter(now, lateEnrolmentEnd ? lateEnrolmentEndDate : enrolmentEndDate);
  const enrolmentActive = isValid(enrolmentStartDate) && isValid(enrolmentEndDate) && isWithinInterval(
    now,
    {
      start: enrolmentStartDate,
      end: isValid(lateEnrolmentEndDate) ? lateEnrolmentEndDate : enrolmentEndDate
    }
  );
  const showCancelEnrolment = enrolmentActive && !openUniversity && !coTeachingRealisation;

  let enrolmentStatusTranslationKey;
  let enrolmentStatusIcon;
  switch (affiliation) {
    case courseUserAffiliationTypes.ENROLMENT_PENDING:
      enrolmentStatusTranslationKey = 'sisuEnrolment.enrolled.notConfirmed';
      enrolmentStatusIcon = 'icon--info-stroke';
      break;
    case courseUserAffiliationTypes.STUDENT:
    default:
      enrolmentStatusTranslationKey = 'sisuEnrolment.enrolled.confirmed';
      enrolmentStatusIcon = 'icon--square-checked';
      break;
  }

  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <span className={enrolmentStatusIcon} />
      </div>
      <div>
        <div className={styles.enrolmentStatus}>
          {t(enrolmentStatusTranslationKey)}
        </div>
        {showCancelEnrolment && (
        <div className="cancelEnrolment">
          {/* url really needs to look like that to correctly route to study calendar */}
          <Link href={`${sisuUrl}/student/plan//calendar`} external>
            {t('sisuEnrolment.enrolled.cancelEnrolment')}
          </Link>
        </div>
        )}
        {enrolmentPeriodEnded && (
        <div>
          {t('sisuEnrolment.enrolled.enrolmentPeriodEnded')}
        </div>
        )}
      </div>
    </div>
  );
};

Enrolled.propTypes = {
  courseUnitRealisation: courseUnitRealisationType.isRequired
};

export default Enrolled;
