import React from 'react';
import {
  node, number, bool, func, string
} from 'prop-types';
import classNames from 'classnames';

import styles from './tab.css';

const Tab = React.forwardRef(({
  index, label, selected, first, last, onTabClicked, controls
}, ref) => {
  const tabClasses = classNames('button', 'horizontal-tab-button', `horizontal-tab-button-${index}`, styles.tab, {
    first,
    last,
    [styles.selected]: selected
  });

  return (
    <button role="tab" aria-controls={controls} aria-selected={selected} className={tabClasses} type="button" ref={ref} onClick={() => onTabClicked(index)} tabIndex={selected ? 0 : -1}>
      {label}
    </button>
  );
});

Tab.propTypes = {
  index: number.isRequired,
  label: node.isRequired,
  selected: bool,
  first: bool,
  last: bool,
  onTabClicked: func.isRequired,
  controls: string.isRequired
};

Tab.defaultProps = {
  selected: false
};

export default Tab;
