import React, { useContext } from 'react';
import { arrayOf, string } from 'prop-types';

import styles from './links.css';
import Link from '../../../Link';
import useTranslation from '../../../../hooks/useTranslation';
import EmailIcon from '../../../Icon/EmailIcon';
import { ICON_POSITIONS } from '../../../../constants';
import LangContext from '../../../../context/LangContext';
import { courseContactPersonType } from '../../../../types';

const ContactPersonImage = ({ firstName, lastName, picture }) => {
  const initials = (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
  return (
    <div className={styles.courseContactPersonImageContainer}>
      {picture ? <img src={picture} alt="" /> : <span>{initials}</span>}
    </div>
  );
};

ContactPersonImage.propTypes = courseContactPersonType;

const ContactPerson = ({
  title,
  firstName,
  lastName,
  emailAddress,
  picture
}) => {
  const { lang } = useContext(LangContext);

  const translatedTitle = title?.[lang];
  return (
    <div className={styles.courseContactPersonCard}>
      <div className={styles.courseContactPersonNameContainer}>
        <ContactPersonImage
          firstName={firstName}
          lastName={lastName}
          picture={picture}
        />
        <div>
          <h3>
            {firstName} {lastName}
          </h3>
          {translatedTitle && <span>{translatedTitle}</span>}
        </div>
      </div>
      <Link
        className={styles.courseContactPersonEmail}
        href={`mailto: ${emailAddress}`}
        external
        iconOverride={EmailIcon}
        iconPosition={ICON_POSITIONS.BEFORE}
      >
        {emailAddress}
      </Link>
    </div>
  );
};

ContactPerson.propTypes = courseContactPersonType;

const GenericContactLink = ({ translationKey }) => {
  const { t } = useTranslation();
  const translationKeyPrefix = `courseContactInfo.${translationKey}`;
  return (
    <Link href={t(`${translationKeyPrefix}.href`)} external>
      {t(`${translationKeyPrefix}.text`)}
    </Link>
  );
};

GenericContactLink.propTypes = {
  translationKey: string
};

const CourseContactInfo = ({ contactInfo }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.linksContainer}>
      <h2>{t('courseContactInfo.heading')}</h2>
      {contactInfo.map(
        ({
          firstName, lastName, emailAddress, picture, title
        }) => (
          <ContactPerson
            key={emailAddress}
            firstName={firstName}
            lastName={lastName}
            title={title}
            emailAddress={emailAddress}
            picture={picture}
          />
        )
      )}
      <div className={styles.courseContactLinksContainer}>
        <h3>{t('courseContactInfo.studentServiceHeading')}</h3>
        <GenericContactLink translationKey="studentServicePoints" />
        <GenericContactLink translationKey="openUniversityStudentAdvice" />
        <GenericContactLink translationKey="servicesForDoctoralEducation" />
      </div>
    </div>
  );
};

CourseContactInfo.propTypes = {
  contactInfo: arrayOf(courseContactPersonType)
};

export default CourseContactInfo;
