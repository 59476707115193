import React from 'react';
import { arrayOf } from 'prop-types';
import { routes, AVAILABLE_LANGUAGES } from '../../constants';
import { getEduviewerEmbedUrl } from '../../utils';
import { getTranslation } from '../../i18n';
import { dpSelectorOptionType, degreeProgramType } from '../../types';
import DegreeProgramSelector from './DegreeProgramSelector';
import DocumentHead from '../DocumentHead';
import DegreeProgram from '../DegreeProgram';
import useTranslation from '../../hooks/useTranslation';

const DegreeStructure = ({
  degreeProgram,
  degreeProgrammes
}) => {
  const { t, lang } = useTranslation();

  const pageTitle = degreeProgram ? [
    t('degreeProgram.degreeProgram'),
    degreeProgram.code,
    getTranslation(degreeProgram.name, lang)
  ].filter(Boolean).join(' ') : t('degreeProgram.title');

  // Pass true to getEduviewerEmbedUrl if you want to test local version of eduviewer
  const EDUVIEWER_SRC = getEduviewerEmbedUrl();
  const dpUrls = degreeProgram
    ? AVAILABLE_LANGUAGES.map((language) => [language, routes.dp(language, degreeProgram.id, degreeProgram.code)])
    : [];
  const scripts = degreeProgram ? [
    {
      src: EDUVIEWER_SRC,
      type: 'text/javascript',
      async: true,
      id: 'eduviewer-script',
      removeOnUnmount: true
    }
  ] : [];

  return (
    <div className="grid-container page-wide-content-padding">
      <DocumentHead urls={dpUrls} title={pageTitle} scripts={scripts} />
      <h1>
        {t('degreeProgram.title')}
      </h1>
      <DegreeProgramSelector
        degreeProgrammes={degreeProgrammes || []}
        lang={lang}
      />
      {degreeProgram
        && <DegreeProgram degreeProgram={degreeProgram} />}
    </div>
  );
};

DegreeStructure.propTypes = {
  degreeProgram: degreeProgramType,
  degreeProgrammes: arrayOf(dpSelectorOptionType)
};

export default DegreeStructure;
