import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { getCalendarFeed, upsertCalendarFeed } from '../../../../api';
import styles from './toolbar.css';
import useHideOnClickOutside from '../../../../hooks/useHideOnClickOutside';
import { eventActions, eventCategories, trackEvent } from '../../../../services/analytics';
import { READ_ONLY_DB } from '../../../../env';
import useTranslation from '../../../../hooks/useTranslation';

const instructionLinks = {
  OUTLOOK: {
    fi: 'https://helpdesk.it.helsinki.fi/ohjeet/yhteydenpito-ja-julkaiseminen/kalentereiden-synkronointi',
    en: 'https://helpdesk.it.helsinki.fi/en/instructions/collaboration-and-publication/synchronising-different-calendars',
    sv: 'https://helpdesk.it.helsinki.fi/sv/instruktioner/kontakter-och-publicering/kalendersynkronisering'
  },
  GOOGLE: {
    fi: 'https://support.google.com/calendar/answer/37100?hl=fi',
    en: 'https://support.google.com/calendar/answer/37100?hl=en',
    sv: 'https://support.google.com/calendar/answer/37100?hl=sv'
  }
};

const buildCalendarUrl = (feedId, lang = 'fi') => `${window.location.protocol}//${window.location.host}/api/calendars/${feedId}?lang=${lang}`;

const SubscribeToCalendar = () => {
  const { t, lang } = useTranslation();
  const { ref, isComponentVisible, setIsComponentVisible } = useHideOnClickOutside(false);
  const [calendarUrl, setCalendarUrl] = useState(null);
  const [copied, setCopied] = useState(false);

  const toggleVisibilityAndGetFeedUrl = async () => {
    if (!calendarUrl) {
      let response;
      try {
        response = await getCalendarFeed();
      } catch {
        if (!READ_ONLY_DB) {
          response = await upsertCalendarFeed();
        }
      }
      setCalendarUrl(buildCalendarUrl(response.feedId, lang));
      trackEvent(eventCategories.myStudies.CALENDAR, eventActions.ADD_TO_CALENDAR);
    }
    setIsComponentVisible(!isComponentVisible);
  };

  const onCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const updateCalendarUrl = READ_ONLY_DB ? () => {} : async () => {
    const response = await upsertCalendarFeed();
    setCalendarUrl(buildCalendarUrl(response.feedId, lang));
  };

  return (
    <div ref={ref} className={`${styles.subscribeContainer} ${styles.dropdownContainer}`}>
      <button type="button" className="button button--outline" onClick={() => toggleVisibilityAndGetFeedUrl()}>
        {t('calendar.actions.subscribe.title')}
      </button>
      {isComponentVisible && calendarUrl && (
      <div className={`${styles.subscribeContentContainer} ${styles.dropdownContentContainer}`}>
        <div className={styles.subscribeContent}>
          <p>
            {t('calendar.actions.subscribe.instructions')}
            <br />
            <span className={styles.calendarUrl}>{calendarUrl}</span>
          </p>
          <CopyToClipboard text={calendarUrl} onCopy={onCopy}>
            <button type="button" className="button button--outline">
              {t('calendar.actions.subscribe.copy')}
            </button>
          </CopyToClipboard>
          {copied && (
          <span className={styles.copied}>
            {t('calendar.actions.subscribe.copied')}
          </span>
          )}
          <p>
            {t('calendar.actions.subscribe.updateNotice')}
          </p>
          <button type="button" className="button button--outline" onClick={updateCalendarUrl} disabled={READ_ONLY_DB}>
            {t('calendar.actions.subscribe.update')}
          </button>
          <p>
            {t('calendar.actions.subscribe.addCalendarInstructions')}
            <br />
            <a href={instructionLinks.OUTLOOK[lang]} target="_blank" rel="noopener noreferrer">
              {t('calendar.actions.subscribe.addToOutlook')}
            </a>
            {' | '}
            <a href={instructionLinks.GOOGLE[lang]} target="_blank" rel="noopener noreferrer">
              {t('calendar.actions.subscribe.addToGoogle')}
            </a>
          </p>
        </div>
      </div>
      )}
    </div>
  );
};

export default SubscribeToCalendar;
