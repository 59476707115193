import React from 'react';
import {
  bool, string, node, oneOfType
} from 'prop-types';
import classNames from 'classnames';
import { routes } from '../../constants';
import { formatDate } from '../../utils';
import { bulletinItemType, newsItemType } from '../../types';
import styles from './newsItem.css';
import HyphenatedText from '../HyphenatedText';
import { ArrowOffsite, ArrowRight } from '../Icon/Arrow';
import IconBox from '../Icon/IconBox';
import Link from '../Link';
import useTranslation from '../../hooks/useTranslation';

const NewsLink = ({
  isExternal, link, children, trackedEventCategory, ...props
}) => (
  <Link
    href={link}
    external={isExternal}
    useRouter={!isExternal}
    tracked={Boolean(trackedEventCategory)}
    eventCategory={trackedEventCategory}
    iconPosition="none"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {children}
  </Link>
);

NewsLink.propTypes = {
  link: string.isRequired,
  isExternal: bool.isRequired,
  children: node.isRequired,
  trackedEventCategory: string
};

const getFieldId = (itemId, fieldName) => `news${itemId}${fieldName}`;

// remove non-breaking spaces
const normalizeText = (text) => text.replace(/\u00A0/g, ' ');

const NewsItem = ({
  item, typeLabel, externalLink, trackedEventCategory, showIngress
}) => {
  const { t, lang } = useTranslation();
  const id = item.id || item.guid;
  const imageId = getFieldId(id, 'Image');
  const dateId = getFieldId(id, 'Date');
  const titleId = getFieldId(id, 'Title');
  const summaryId = getFieldId(id, 'Summary');
  const labelledBy = titleId;
  const describedBy = [imageId, dateId, summaryId].join(' ');

  return (
    <NewsLink
      isExternal={externalLink}
      trackedEventCategory={trackedEventCategory}
      link={externalLink ? item.link : routes.news(lang, item.id, item.renderDegreeProgrammeLink, item.alias)}
      className={styles.newsItem}
      aria-labelledby={labelledBy}
      aria-describedby={describedBy}
    >
      <article className={styles.content}>
        {item.image && (
          <div className={styles.image}>
            <img id={imageId} src={item.image} alt={item.imageAltText} />
          </div>
        )}
        <div className={styles.textContent}>
          <div className={styles.date}>
            {t(typeLabel)} <span aria-hidden="true">|</span> <span id={dateId}>{formatDate(item.isoDate, lang)}</span>
          </div>
          <h3 id={titleId} className={classNames(styles.title, externalLink)}>
            <HyphenatedText text={item.title} lang={lang} />
          </h3>
          {(showIngress && (item.summary || item.content)) && (
            <p id={summaryId} lang={lang} className={styles.ingress}>
              {normalizeText(item.summary || item.content)}
            </p>
          )}
        </div>
        <IconBox isLinkBoxIcon icon={externalLink ? ArrowOffsite : ArrowRight} />
      </article>
    </NewsLink>
  );
};

NewsItem.propTypes = {
  item: oneOfType([newsItemType, bulletinItemType]).isRequired,
  typeLabel: string.isRequired,
  externalLink: bool,
  trackedEventCategory: string,
  showIngress: bool.isRequired
};

NewsItem.defaultProps = {
  externalLink: false
};

export default NewsItem;
