import React, { useRef, createRef, useEffect } from 'react';
import {
  arrayOf,
  func,
  string,
  bool
} from 'prop-types';
import { findIndex, uniqueId } from 'lodash';
import classNames from 'classnames';

import styles from './filter.css';
import { option } from '../../types';
import useHideOnClickOutside from '../../hooks/useHideOnClickOutside';
import useTranslation from '../../hooks/useTranslation';

const onFilterChange = (event, selectedItems, setSelectedItems, isMulti, isClearable) => {
  const { checked, value } = event.target;

  let newSelectedItems = [];

  if (isMulti) {
    if (checked && !selectedItems.includes(value)) {
      newSelectedItems = [...selectedItems, value];
    } else if (!checked && selectedItems.includes(value)) {
      newSelectedItems = selectedItems.filter((s) => s !== value);
    }
  } else if (checked) {
    newSelectedItems = [value];
  } else if (!isClearable) {
    newSelectedItems = [...selectedItems];
  }
  setSelectedItems(newSelectedItems);
};

const getItemLabels = (items, itemIds) => items.reduce((labels, item) => {
  if (itemIds.includes(item.value)) {
    labels.push(item.label);
  }
  return labels;
}, []);

// Exported for test purposes
export const OptionItem = React.forwardRef(({
  value, checked, handleChange, label, filterKey
}, ref) => (
  <div className={styles.columnButtonContainer}>
    <label htmlFor={`checkbox-${filterKey}-${value}`}>
      <input
        ref={ref}
        className={styles.invisibleCheckbox}
        id={`checkbox-${filterKey}-${value}`}
        type="checkbox"
        value={value}
        onChange={handleChange}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            ref.current.checked = !checked;
            handleChange(e);
          }
        }}
        checked={checked}
      />
      <span className={styles.columnButtonToggle}>{label}</span>
    </label>
  </div>
));

OptionItem.propTypes = {
  value: string.isRequired,
  checked: bool.isRequired,
  handleChange: func.isRequired,
  label: string.isRequired,
  filterKey: string.isRequired
};

const FilterWithPseudoSelection = ({
  filterKey,
  items,
  selectedItems,
  setSelectedItems,
  disabled,
  isMulti,
  isClearable,
  defaultTextLocalizationKey
}) => {
  const { t } = useTranslation();
  const { ref, isComponentVisible, setIsComponentVisible } = useHideOnClickOutside(false);

  const buttonRef = useRef();
  const optionRefs = useRef([]);

  useEffect(() => {
    optionRefs.current = items.map(() => createRef());
  }, [items]);

  const itemListId = `filterItemList${uniqueId()}`;

  const defaultText = defaultTextLocalizationKey
    ? t(defaultTextLocalizationKey)
    : t(`filters.${filterKey}.prompt`);

  let selectionText = '';
  if (selectedItems.length) {
    selectionText = getItemLabels(items, selectedItems).join(', ');
  }
  selectionText = selectionText || defaultText;

  const closeDropdownAndReturnFocus = () => {
    setIsComponentVisible(false);
    buttonRef.current.focus();
  };

  const handleChange = (e) => {
    onFilterChange(e, selectedItems, setSelectedItems, isMulti, isClearable);
    if (!isMulti) {
      closeDropdownAndReturnFocus();
    }
  };

  const getFirstSelectedOptionOrFirstOptionRef = () =>
    optionRefs.current?.find((r) => r?.current?.checked)?.current || optionRefs.current[0]?.current;

  const openDropdownAndFocusFirstSelectedOptionOrFirstOption = () => {
    setIsComponentVisible(true);
    setTimeout(() => {
      getFirstSelectedOptionOrFirstOptionRef()?.focus?.();
    }, 0);
  };

  const handleKeyDown = (e) => {
    if (['ArrowUp', 'ArrowDown'].includes(e.key) && !isComponentVisible) {
      e.preventDefault();
      openDropdownAndFocusFirstSelectedOptionOrFirstOption();
    } else if (isComponentVisible) {
      const currentFocusedRefIndex = findIndex(optionRefs.current, (r) => r?.current?.contains(document.activeElement));
      let toFocusRef = null;
      switch (e.key) {
        case 'End':
          toFocusRef = optionRefs.current[optionRefs.current.length - 1];
          break;
        case 'Home':
          // eslint-disable-next-line prefer-destructuring
          toFocusRef = optionRefs.current[0];
          break;
        case 'ArrowDown':
          toFocusRef = optionRefs.current[Math.min(currentFocusedRefIndex + 1, optionRefs.current.length - 1)];
          break;
        case 'ArrowUp':
          toFocusRef = optionRefs.current[Math.max(currentFocusedRefIndex - 1, 0)];
          break;
        case 'Escape':
          closeDropdownAndReturnFocus();
          break;
        default: break;
      }

      if (toFocusRef) {
        e.preventDefault();
        toFocusRef.current.focus();
      }
    }
  };

  const handleToggleButtonClick = () => {
    if (isComponentVisible) {
      setIsComponentVisible(false);
    } else {
      openDropdownAndFocusFirstSelectedOptionOrFirstOption();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={styles.filterWithPseudoSelection} ref={ref} onKeyDown={handleKeyDown}>
      <button
        ref={buttonRef}
        type="button"
        disabled={disabled}
        className={classNames(styles.toggleRowVisible, 'icon--caret-down', { [styles.rowOpen]: isComponentVisible })}
        onClick={handleToggleButtonClick}
        role="combobox"
        aria-haspopup="menu"
        aria-controls={itemListId}
        aria-expanded={isComponentVisible}
        aria-owns={itemListId}
      >
        {selectionText}
      </button>
      <div id={itemListId} className={classNames(styles.checkboxColumn, { hidden: !isComponentVisible })}>
        {items.map((i, idx) => {
          const { value, label } = i;
          return (
            <OptionItem
              ref={optionRefs.current[idx]}
              key={value}
              value={value}
              checked={selectedItems.includes(value)}
              handleChange={handleChange}
              label={label}
              filterKey={filterKey}
            />
          );
        })}
      </div>
    </div>
  );
};

FilterWithPseudoSelection.propTypes = {
  filterKey: string.isRequired,
  items: arrayOf(option).isRequired,
  selectedItems: arrayOf(string).isRequired,
  setSelectedItems: func.isRequired,
  disabled: bool.isRequired,
  isMulti: bool.isRequired,
  isClearable: bool.isRequired,
  defaultTextLocalizationKey: string
};

const Filter = ({
  filterKey,
  items,
  selectedItems,
  setSelectedItems,
  disabled,
  isClearable,
  isMulti,
  defaultTextLocalizationKey,
  className,
  legendToLeft
}) => {
  const { t } = useTranslation();

  return (
    <fieldset className={classNames(styles.filter, className)}>
      <div className={classNames(styles.flexContainer, { [styles.itemsInRow]: legendToLeft })}>
        <div className={styles.legendFlexItem}>
          <legend className={styles.filterTitle}>
            {t(`filters.${filterKey}.title`)}
          </legend>
        </div>

        <FilterWithPseudoSelection
          filterKey={filterKey}
          items={items}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          disabled={disabled}
          isMulti={isMulti}
          isClearable={isClearable}
          defaultTextLocalizationKey={defaultTextLocalizationKey}
        />
      </div>
    </fieldset>
  );
};

Filter.defaultProps = {
  disabled: false,
  isClearable: true,
  isMulti: true,
  legendToLeft: false
};

Filter.propTypes = {
  filterKey: string.isRequired,
  items: arrayOf(option).isRequired,
  selectedItems: arrayOf(string).isRequired,
  setSelectedItems: func.isRequired,
  disabled: bool,
  isClearable: bool,
  isMulti: bool,
  defaultTextLocalizationKey: string,
  className: string,
  legendToLeft: bool
};

export default Filter;
