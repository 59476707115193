import React, { useCallback, useContext, useReducer } from 'react';
import { parse, stringify } from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { AVAILABLE_LANGUAGES, routes } from '../../constants';

import DocumentHead from '../DocumentHead';
import Breadcrumb from '../Breadcrumb';
import SearchForm from './SearchForm';
import SearchResultLoader from './SearchResultLoader';
import searchOptionsReducer from './searchOptionsReducer';
import { multiSearchParams } from '../../api';
import { trackMultiSearch } from '../../services/analytics';
import { SITEIMPROVE_ENABLED } from '../../env';
import { getSiteimproveScriptSrc } from '../../utils';
import RootContext from '../../context/RootContext';
import ErrorBoundary from '../ErrorBoundary';
import useTranslation from '../../hooks/useTranslation';

const {
  TYPE, DEGREE_PROGRAMME, TARGET_GROUP, PAGE_INDEX, SEARCH_TEXT
} = multiSearchParams;

const MultiSearch = () => {
  const { t, lang } = useTranslation();
  const history = useHistory();
  const { config } = useContext(RootContext);

  const optionValues = parse(useLocation().search, { parseNumbers: true });

  const [formState, dispatch] = useReducer(
    searchOptionsReducer,
    {
      [TYPE]: 'all', [DEGREE_PROGRAMME]: undefined, [TARGET_GROUP]: undefined, [SEARCH_TEXT]: '', [PAGE_INDEX]: 0, ...optionValues
    }
  );

  // Child SearchForm component uses this to update search params, which then get passed on to SearchResultLoader.
  const paramsToLocation = useCallback((state) => {
    history.replace({
      search: stringify(state),
      state: { scrollY: 0 }
    });
    trackMultiSearch(state);
  }, [history]);

  // See https://jira.it.helsinki.fi/browse/OPISKELU-1511
  const scripts = (SITEIMPROVE_ENABLED)
    ? [{
      async: true,
      src: getSiteimproveScriptSrc(config.siteimproveAnalyticsScriptId),
      type: 'text/plain',
      cookieconsent: 'statistics'
    }]
    : null;

  return (
    <>
      <DocumentHead scripts={scripts} urls={AVAILABLE_LANGUAGES.map((l) => [l, routes.multiSearch(l)])} />
      <div className="grid-container">
        <Breadcrumb />
        <h1>{t('multiSearch.title')}</h1>
      </div>

      <SearchForm
        onSearch={(state) => {
          paramsToLocation(state);
        }}
        formState={formState}
        dispatch={dispatch}
      />
      <SearchResultLoader
        optionValues={optionValues}
        formState={formState}
        dispatch={dispatch}
        onOptionsChange={paramsToLocation}
        lang={lang}
      />
    </>
  );
};

// For tests
export const MultiSearchLocalized = MultiSearch;

// Export module wrapped in ErrorBoundary to handle errors in initialization also
export default () => (
  <ErrorBoundary>
    <MultiSearchLocalized />
  </ErrorBoundary>
);
