import React, {
  useReducer,
  useState,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { stringify, parse } from 'query-string';

import DegreeProgramSelector from '../../DegreeProgrammeSelector';
import { findFilterOptions, searchDegreeProgramCourses, coursesSearchParams } from '../../../api';
import DPSearchResults from '../DPSearchResults';
import Filter from '../../Filter';
import styles from './courseBrowser.css';
import { asArray, formatLocalizedOption, getStudyYearStartYear } from '../../../utils';

import searchOptionsReducer from '../../CourseSearch/SeachForm/searchOptionsReducer';
import { trackDegreeProgramSearch } from '../../../services/analytics';
import useDegreePrograms from '../../../hooks/useDegreePrograms';
import { getTranslation } from '../../../i18n';
import useTranslation from '../../../hooks/useTranslation';
import usePersistingDegreeProgramme from '../../../hooks/usePersistingDegreeProgramme';

const LANGUAGE_OPTIONS_TO_SHOW = [
  'urn:code:language:fi',
  'urn:code:language:sv',
  'urn:code:language:en'
];

const {
  COURSE_TYPE, TEACHING_LANGUAGE, PERIOD, STUDY_YEAR,
  STUDY_LEVEL, DEGREE_PROGRAMME, STUDY_TRACK, PAGE_INDEX,
  SORT
} = coursesSearchParams;

const OTHER_LANGUAGE_VALUE = 'otherLanguage';

let language = null;

const localizedOption = (o) => formatLocalizedOption(o, language);

const sortOptions = [{
  value: 'dateDesc',
  name: {
    fi: 'uusin ensin',
    sv: 'nyaste först',
    en: 'Newest'
  }
}, {
  value: 'dateAsc',
  name: {
    fi: 'vanhin ensin',
    sv: 'äldsta först',
    en: 'Oldest'
  }
}, {
  value: 'nameAsc',
  name: {
    fi: 'aakkosittain A-Ö',
    sv: 'i alfabetisk ordning A-Ö',
    en: 'Alphabetically A-Ö'
  }
}, {
  value: 'nameDesc',
  name: {
    fi: 'aakkosittain Ö-A',
    sv: 'i alfabetisk ordning Ö-A',
    en: 'Alphabetically Ö-A'
  }
}];
const DEFAULT_SORT_VALUE = 'dateDesc';

const getLanguageOptionsToShow = (availableOptions, translate) => {
  const languageOptionsToShow = availableOptions
    .filter((o) => LANGUAGE_OPTIONS_TO_SHOW.includes(o.value));

  if (languageOptionsToShow.length !== availableOptions.length) {
    languageOptionsToShow.push({
      label: translate('degreeProgramStudies.filters.otherLanguages'),
      value: OTHER_LANGUAGE_VALUE
    });
  }

  return languageOptionsToShow;
};

const selectedDegreeProgrammeName = (selectedDegreeProgramme, degreeProgrammes) => {
  const option = degreeProgrammes.find((o) => o.value === selectedDegreeProgramme);
  return option && option.label;
};

const fetchOptions = async (
  setDegreeProgrammeOptions,
  setPeriodOptions,
  setTypeOptions,
  setLanguageOptions,
  setCurrentStudyYear,
  setStudyLevelOptions,
  setStudyTrackOptions,
  setError
) => {
  try {
    const filterOptions = await findFilterOptions({
      types: [DEGREE_PROGRAMME, COURSE_TYPE, TEACHING_LANGUAGE, STUDY_YEAR, PERIOD, STUDY_LEVEL, STUDY_TRACK],
      studyYear: getStudyYearStartYear(new Date())
    });
    if (!filterOptions || !Object.keys(filterOptions).length) {
      // Most likely just caused by redirect on first coming to page, see https://jira.it.helsinki.fi/browse/OPISKELU-2190
      return;
    }
    const {
      degreeProgramme: degreeProgrammeOptions,
      period: periodOptions,
      type: typeOptions,
      teachingLanguageUrn: languageOptions,
      studyYear: studyYearOptions,
      studyLevel: studyLevelOptions,
      studyTrack: studyTrackOptions
    } = filterOptions;

    setCurrentStudyYear(studyYearOptions?.find((s) => s.isCurrent).value);
    const dpOptions = degreeProgrammeOptions?.map(localizedOption);
    if (dpOptions) {
      dpOptions.sort((a, b) => a.label.localeCompare(b.label));
      setDegreeProgrammeOptions(dpOptions);
    }

    setPeriodOptions(periodOptions?.map(localizedOption) ?? []);
    setTypeOptions(typeOptions?.map(localizedOption) ?? []);
    setLanguageOptions(languageOptions?.map(localizedOption) ?? []);
    setStudyLevelOptions(studyLevelOptions?.map(localizedOption) ?? []);

    if (studyTrackOptions) {
      Object.entries(studyTrackOptions).forEach(([dpGroupdId, studyTracks]) => {
        studyTrackOptions[dpGroupdId] = studyTracks
          .map((st) => ({ ...localizedOption(st), value: st.groupId }))
          .sort((a, b) => a.label.localeCompare(b.label));
      });
    }

    setStudyTrackOptions(studyTrackOptions);
  } catch (error) {
    setError(error);
  }
};

const getLanguagesToSearchBy = (selectedLanguages, allLanguages) =>
  selectedLanguages
    .flatMap((s) => {
      if (OTHER_LANGUAGE_VALUE === s) {
        return allLanguages
          .filter((a) => !LANGUAGE_OPTIONS_TO_SHOW.includes(a.value))
          .map((a) => a.value);
      }
      return [s];
    });

const fetchCourses = async (
  formState,
  allLanguages,
  currentStudyYear,
  setCourses,
  languageCode) => {
  if (formState[DEGREE_PROGRAMME]) {
    const result = await searchDegreeProgramCourses({
      ...formState,
      [TEACHING_LANGUAGE]: getLanguagesToSearchBy(asArray(formState[TEACHING_LANGUAGE]), allLanguages),
      [STUDY_YEAR]: currentStudyYear
    }, languageCode);
    setCourses(result);
  }
};

const CourseBrowser = () => {
  const { t, lang } = useTranslation();
  const degreePrograms = useDegreePrograms();
  const primaryDegreeProgramme = degreePrograms?.find((dp) => dp.isPrimary);
  const { state } = useLocation();
  const history = useHistory();
  const { persistingDegreeProgramme, setPerstistingDegreeProgramme } = usePersistingDegreeProgramme();

  const [degreeProgrammeOptions, setDegreeProgrammeOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [periodOptions, setPeriodOptions] = useState([]);
  const [studyLevelOptions, setStudyLevelOptions] = useState();
  const [studyTrackOptions, setStudyTrackOptions] = useState({});
  const [currentStudyYear, setCurrentStudyYear] = useState();
  const [courses, setCourses] = useState();
  const [scrollY, setScrollY] = useState(state?.scrollY);
  const [error, setError] = useState(null);

  const initialValues = {
    sort: DEFAULT_SORT_VALUE,
    ...parse(useLocation().search, { parseNumbers: true })
  };

  language = lang || null;
  const [formState, dispatch] = useReducer(searchOptionsReducer, initialValues);

  const degreeProgrammeName = selectedDegreeProgrammeName(formState.degreeProgramme, degreeProgrammeOptions);

  const changeValue = useCallback((type) => (newVal) => {
    dispatch({ type, value: newVal });
    setScrollY(undefined);
    trackDegreeProgramSearch(type, newVal, primaryDegreeProgramme, {
      degreeProgrammeOptions, typeOptions, languageOptions, periodOptions, studyLevelOptions, studyTrackOptions
    });
  }, [degreeProgrammeOptions, languageOptions, periodOptions, primaryDegreeProgramme, studyLevelOptions, studyTrackOptions, typeOptions]);

  useEffect(() => {
    fetchOptions(
      setDegreeProgrammeOptions,
      setPeriodOptions,
      setTypeOptions,
      setLanguageOptions,
      setCurrentStudyYear,
      setStudyLevelOptions,
      setStudyTrackOptions,
      setError
    );
  }, []);

  useEffect(() => {
    const paramsToLocation = () => {
      history.replace({
        search: stringify(formState),
        state: { scrollY }
      });
    };
    if (currentStudyYear && formState.degreeProgramme) {
      fetchCourses(
        formState,
        languageOptions,
        currentStudyYear,
        setCourses,
        language
      );
      paramsToLocation();
    }
  }, [
    formState,
    formState.degreeProgramme,
    formState.teachingLanguageUrn,
    formState.type,
    formState.period,
    formState.studyLevel,
    formState.studyTrack,
    formState.page,
    formState.sort,
    languageOptions,
    currentStudyYear,
    scrollY,
    history
  ]);

  useEffect(() => {
    if (persistingDegreeProgramme?.groupId
      && persistingDegreeProgramme?.groupId !== primaryDegreeProgramme?.groupId
      && !formState.degreeProgramme
      && degreeProgrammeOptions?.find(({ value }) => value === persistingDegreeProgramme.groupId)) {
      changeValue(DEGREE_PROGRAMME)(persistingDegreeProgramme.groupId);
    } else if (
      primaryDegreeProgramme
      && !formState.degreeProgramme
      && degreeProgrammeOptions?.find(({ value }) => value === primaryDegreeProgramme.groupId)
    ) {
      changeValue(DEGREE_PROGRAMME)(primaryDegreeProgramme.groupId);
    }
  }, [primaryDegreeProgramme, degreeProgrammeOptions, changeValue, formState.degreeProgramme, persistingDegreeProgramme]);

  const localizedSortOptions = useMemo(
    () => sortOptions.map(({ value, name }) => ({ value, label: getTranslation(name, lang) })),
    [lang]);

  if (error) {
    // Thrown to be caught by ErrorBoundary https://jira.it.helsinki.fi/browse/OPISKELU-2066
    throw Error(error);
  }

  return (
    <>
      <p className={styles.instructions}>
        {t('degreeProgramStudies.instructions')}
      </p>
      <div className={styles.filters}>
        <div className="grid-row">
          <DegreeProgramSelector
            options={degreeProgrammeOptions}
            selectedItem={formState.degreeProgramme}
            onSelect={(value) => {
              setPerstistingDegreeProgramme('groupId', value);
              changeValue(DEGREE_PROGRAMME)(value);
            }}
          />
          <Filter
            filterKey="studyTrackFilter"
            items={(formState.degreeProgramme && studyTrackOptions?.[formState.degreeProgramme])
              ? studyTrackOptions[formState.degreeProgramme]
              : []}
            selectedItems={asArray(formState.studyTrack)}
            setSelectedItems={changeValue(STUDY_TRACK)}
            disabled={!formState.degreeProgramme || !studyTrackOptions?.[formState.degreeProgramme]?.length}
            defaultTextLocalizationKey={formState.degreeProgramme && !studyTrackOptions?.[formState.degreeProgramme]?.length
              ? 'filters.studyTrackFilter.N/A'
              : undefined}
          />
        </div>
        <div className="grid-row">
          <Filter
            filterKey="periodFilter"
            items={periodOptions}
            selectedItems={asArray(formState.period)}
            setSelectedItems={changeValue(PERIOD)}
            disabled={!formState.degreeProgramme}
          />
          <Filter
            filterKey="typeFilter"
            items={typeOptions}
            selectedItems={asArray(formState.type)}
            setSelectedItems={changeValue(COURSE_TYPE)}
            disabled={!formState.degreeProgramme}
          />
          <Filter
            filterKey="languageFilter"
            items={getLanguageOptionsToShow(languageOptions, t)}
            selectedItems={asArray(formState.teachingLanguageUrn)}
            setSelectedItems={changeValue(TEACHING_LANGUAGE)}
            disabled={!formState.degreeProgramme}
          />
        </div>
      </div>
      {courses && (
        <DPSearchResults
          selectedStudyLevel={formState.studyLevel}
          setSelectedStudyLevel={changeValue(STUDY_LEVEL)}
          degreeProgramme={degreeProgrammeName}
          studyLevels={studyLevelOptions}
          setPageIndex={changeValue(PAGE_INDEX)}
          results={courses}
          searchOptions={initialValues}
          sortOptions={localizedSortOptions}
          setSort={changeValue(SORT)}
        />
      )}
    </>
  );
};

export default CourseBrowser;
