import React, {
  useEffect, useRef, useState
} from 'react';
import {
  shape, func, string, bool
} from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { uniqueId } from 'lodash';
import styles from './searchResults.css';
import useDegreePrograms from '../../../hooks/useDegreePrograms';
import { multiSearchResultListType } from '../../../types';
import { getGuideDegreeProgrammes, multiSearchParams } from '../../../api';
import { formatLocalizedOption } from '../../../utils';
import SearchPagination, { searchTypes } from '../../SearchPagination';
import { routes } from '../../../constants';
import MobileTypeFilters from './MobileTypeFilters';
import TypeFilters from './TypeFilters';
import ResultItem from './ResultItem';
import { typesWithExtraFilters, searchTypeFilters, searchTargetGroupFilters } from '../searchOptionsReducer';
import FormattedNumber from '../../FormattedNumber';
import IconBox from '../../Icon/IconBox';
import { ArrowOffsite, ArrowRight } from '../../Icon/Arrow';
import DegreeProgrammeSelector from '../../DegreeProgrammeSelector';
import Filter from '../../Filter';
import { isMobile, useDeviceSize } from '../../../context/DeviceSizeContextProvider';
import useIsSlowLoad from '../../../hooks/useIsSlowLoad';
import useTranslation from '../../../hooks/useTranslation';
import usePersistingDegreeProgramme from '../../../hooks/usePersistingDegreeProgramme';

const {
  TYPE, DEGREE_PROGRAMME, TARGET_GROUP, PAGE_INDEX
} = multiSearchParams;

const SearchResults = ({
  results, resultsLoading, formState = {}, dispatch, onOptionsChange, searchText, debouncedOptions
}) => {
  const { t, lang } = useTranslation();
  const userDegreePrograms = useDegreePrograms();
  const { persistingDegreeProgramme, setPerstistingDegreeProgramme } = usePersistingDegreeProgramme();
  const primaryDegreeProgram = userDegreePrograms?.filter((dp) => dp.isPrimary)?.[0];

  const [initialized, setInitialized] = useState(false);
  const [degreeProgramOptions, setDegreeProgramOptions] = useState([]);
  const isSlowLoad = useIsSlowLoad(resultsLoading, { thresholdMs: 400 });

  useEffect(() => {
    if (!initialized) {
      (async () => {
        const degreePrograms = await getGuideDegreeProgrammes(lang, false);
        const dpOptions = Array.isArray(degreePrograms) ? degreePrograms.map((dp) => ({
          ...formatLocalizedOption(dp, lang),
          value: dp.code,
          type: dp.programme_type
        })) : [];
        setDegreeProgramOptions(dpOptions);
        setInitialized(true);
      })();
    }
  }, [initialized, lang]);

  // extract form state values used in useEffect dependencies to avoid complex expression lint warning
  const {
    [TYPE]: watchedType,
    [DEGREE_PROGRAMME]: watchedDegreeProgram,
    [TARGET_GROUP]: watchedTargetGroup,
    [PAGE_INDEX]: watchedPageIndex
  } = formState;

  useEffect(() => {
    if (!watchedDegreeProgram
      && typesWithExtraFilters.includes(watchedType)
      && persistingDegreeProgramme?.code) {
      dispatch({
        type: DEGREE_PROGRAMME,
        value: persistingDegreeProgramme.code
      });
    } else if (!watchedDegreeProgram
        && typesWithExtraFilters.includes(watchedType)
        && primaryDegreeProgram
        && degreeProgramOptions.find((dpo) => dpo.code === primaryDegreeProgram.code)) {
      dispatch({ type: DEGREE_PROGRAMME, value: primaryDegreeProgram.code });
    }
    // don't include degree program in deps, as that would defeat the purpose of clearing
    // dp selection by setting it right back to default when selected dp goes undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedType, primaryDegreeProgram, persistingDegreeProgramme, dispatch]);

  useEffect(() => {
    onOptionsChange(formState);
    // search text changes should not trigger search from result filter effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedType, watchedTargetGroup, watchedDegreeProgram, watchedPageIndex, onOptionsChange]);

  const onChangeValue = (type) => (value) => {
    dispatch({ type, value });
  };

  const types = [searchTypeFilters.ALL, searchTypeFilters.ARTICLES, searchTypeFilters.NEWS, searchTypeFilters.COURSES].map((value) => ({
    label: t(`multiSearch.searchType.${value}`),
    value
  }));

  const targetGroups = Object.values(searchTargetGroupFilters).map((value) => ({
    label: t(`multiSearch.targetGroups.${value}`),
    value
  }));

  const resultListId = uniqueId('multiSearchResults');

  // keep track of tab and page changes
  const [loadedType, setLoadedType] = useState(null);
  const [loadedPage, setLoadedPage] = useState(null);
  const [isPageChange, setIsPageChange] = useState(false);
  const currentType = debouncedOptions?.[TYPE];
  const currentPage = debouncedOptions?.[PAGE_INDEX];
  useEffect(() => {
    if (!resultsLoading && currentType && typeof currentPage === 'number') {
      if (loadedType && currentType === loadedType) {
        setIsPageChange(true);
      } else {
        setIsPageChange(false);
      }
      setLoadedType(currentType);
      setLoadedPage(currentPage);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentType, currentPage, resultsLoading]);

  // move focus to heading on page change
  const headingRef = useRef();
  useEffect(() => {
    if (isPageChange && currentPage === loadedPage) {
      headingRef.current.focus();
    }
  }, [isPageChange, loadedPage, currentPage]);

  const otherSearchesStudiesSearch = (
    <div className={styles.otherSearches}>
      <h3 className={styles.searchTitle}>
        {t('multiSearch.specificSearch.title')}
      </h3>
      <Link className={styles.linkContainer} to={routes.search(lang)}>
        <IconBox icon={ArrowRight} className={styles.arrowContainer} />
        <span className={styles.textContainer}>
          <h4 className={styles.linkText}>
            {t('multiSearch.specificSearch.courses.title')}
          </h4>
          <span className={styles.linkText}>
            {t('multiSearch.specificSearch.courses.tagLine')}
          </span>
        </span>
      </Link>
    </div>
  );

  const articleFilters = typesWithExtraFilters.includes(formState[TYPE]) && (
    <div className={classNames('grid-row', styles.articleFilters)}>
      <DegreeProgrammeSelector
        options={degreeProgramOptions}
        selectedItem={formState[DEGREE_PROGRAMME]}
        onSelect={(code) => {
          onChangeValue(DEGREE_PROGRAMME)(code);
          setPerstistingDegreeProgramme('code', code);
        }}
        ownMultiple
        isClearable
      />
      <Filter
        setSelectedItems={(values) => onChangeValue(TARGET_GROUP)(values?.[0])}
        filterKey="multiSearchTargetGroupFilter"
        items={targetGroups}
        selectedItems={formState[TARGET_GROUP] ? [formState[TARGET_GROUP]] : []}
        isMulti={false}
        isClearable
      />
    </div>
  );

  const resultsList = (
    <>
      {articleFilters}
      {formState[TYPE] === searchTypeFilters.COURSES && otherSearchesStudiesSearch}
      <div id={resultListId} className={styles.results}>
        {results?.hits?.length ? (
          <>
            {results.hits.map((item) =>
              <ResultItem key={item.id} item={item} primaryDegreeProgram={primaryDegreeProgram} />)}
            <SearchPagination
              setPageIndex={onChangeValue(PAGE_INDEX)}
              results={results}
              searchOptions={formState}
              searchType={searchTypes.MULTI}
            />
          </>
        ) : t('search.noResults')}
      </div>
      {formState[TYPE] !== searchTypeFilters.COURSES && otherSearchesStudiesSearch}
    </>
  );

  const { deviceSize } = useDeviceSize();
  const panelPrefix = 'multiSearchTabPanel-';
  const tabPrefix = 'multiSearchTab-';

  const resultView = isMobile(deviceSize) ? (
    <>
      <div className={styles.filters}>
        <MobileTypeFilters types={types} selectedType={formState[TYPE]} setSelectedType={onChangeValue(TYPE)} resultListId={resultListId} />
      </div>
      <div className={styles.results}>
        {resultsList}
      </div>
    </>
  ) : (
    <>
      <div className={styles.filters}>
        <TypeFilters
          types={types}
          selectedType={formState[TYPE]}
          setSelectedType={onChangeValue(TYPE)}
          label={t('multiSearch.searchResults')}
          panelPrefix={panelPrefix}
          tabPrefix={tabPrefix}
          isLoading={resultsLoading}
          hasContent={!!results}
        />
      </div>
      {types.map(({ value }) => (
        <div
          key={panelPrefix + value}
          id={panelPrefix + value}
          className={`${styles.results}${value !== formState[TYPE] ? ' hidden' : ''}`}
          role="tabpanel"
          tabIndex="0"
          aria-labelledby={tabPrefix + value}
        >
          {value === formState[TYPE] && resultsList}
        </div>
      ))}
    </>
  );
  // iOS screen reader tends to split headings if they consist of separate strings
  const screenReaderHeading = typeof results?.totalHits === 'number' ? `${searchText || ''}, ${t('multiSearch.searchResults')} ${results.totalHits}` : '';

  const resultSlowLoadingHeading = isSlowLoad && t('multiSearch.loadingResults');
  const resultsLoaded = results && t('multiSearch.resultsLoaded');
  const loadingStatusMessage = resultsLoaded || resultSlowLoadingHeading || '';

  return (
    <div className={styles.container}>
      <div className="grid-container">
        <h2 className="sr-only" role="region" aria-live="assertive" tabIndex={-1} aria-atomic="true">
          <span className="sr-only">{loadingStatusMessage}</span>
        </h2>
        <h2 className={styles.title} role="region" aria-live="polite" tabIndex={-1} aria-atomic="true" ref={headingRef}>
          <span className="sr-only">{screenReaderHeading}</span>
          {typeof results?.totalHits === 'number'
            ? <span aria-hidden>{t('multiSearch.searchResults')} (<FormattedNumber value={results.totalHits} lang={lang} />)</span>
            : null}
        </h2>
        {resultView}
        <div className={styles.otherSearches}>
          <h3 className={styles.searchTitle}>{t('multiSearch.otherSearches.title')}</h3>
          {['programmes', 'contactInformation', 'helkaSearch'].map((l) => (
            <a key={`searchLink-${l}`} href={t(`multiSearch.otherSearches.${l}.url`)} className={styles.linkContainer}>
              <IconBox className={styles.arrowContainer} icon={ArrowOffsite} />
              <span className={styles.textContainer}>
                <h4 className={styles.linkText}>
                  {t(`multiSearch.otherSearches.${l}.title`)}
                </h4>
                <span className={styles.linkText}>
                  {t(`multiSearch.otherSearches.${l}.tagLine`)}
                </span>
              </span>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

SearchResults.propTypes = {
  results: multiSearchResultListType,
  resultsLoading: bool,
  formState: shape({}),
  debouncedOptions: shape({}),
  dispatch: func,
  onOptionsChange: func,
  searchText: string
};

export default SearchResults;
