import React from 'react';
import Section from '../../../Section';
import { courseUnitRealisationType } from '../../../../types';
import {
  formatCourseName,
  formatCredits
} from '../../../../utils';
import styles from './openUniversityProductMainEnrol.css';
import { openUniversityProductTypes, curPathTranslations } from '../../../../constants';
import Link from '../../../Link';
import useTranslation from '../../../../hooks/useTranslation';

const jointCourseImage = require('../../../../images/joint_course.png');

const OpenUniversityProductMainEnrol = ({ courseUnitRealisation = {} }) => {
  const { t, lang } = useTranslation();
  const { openUniversityProductType, openUniversityProductMainCourseUnitRealisations } = courseUnitRealisation;

  const isOpenUniversityProductSubCUR =
    openUniversityProductType === openUniversityProductTypes.SUB
    || openUniversityProductType === openUniversityProductTypes.COMBINED_SUB;

  if (
    !isOpenUniversityProductSubCUR
    || !openUniversityProductMainCourseUnitRealisations
    || openUniversityProductMainCourseUnitRealisations.length === 0
  ) {
    return null;
  }

  return (
    <Section>
      <div className={styles.openUniversityProductMain}>
        <img className={styles.jointCourseImgMobile} src={jointCourseImage} alt={t('courseUnit.courseUnitRealisations.jointCourseImageAlt', null, lang)} />
        <div className="rich-text">
          <p>{t('scheduleOpenUniversityProducts')}</p>
          {openUniversityProductMainCourseUnitRealisations.map((cur) => (
            <Link key={cur.id} href={`/${curPathTranslations[lang]}/${cur.id}`} iconPosition="after">
              {`${formatCourseName(cur.id, cur.name, cur.nameSpecifier, lang)} ${formatCredits(cur.assessmentItems?.[0]?.credits, lang)}`.trim()}
            </Link>
          ))}
        </div>
      </div>
    </Section>
  );
};

OpenUniversityProductMainEnrol.propTypes = {
  courseUnitRealisation: courseUnitRealisationType
};

export default OpenUniversityProductMainEnrol;
