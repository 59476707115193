import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { renderHtml } from '../CoursePage/util';
import styles from './Section.css';

const renderContent = (html, children) => {
  if (html) {
    // eslint-disable-next-line react/no-danger
    return <div className="rich-text" dangerouslySetInnerHTML={renderHtml(children)} />;
  }

  return children;
};

const Section = ({
  level, label, children, html, className, subHeading, noUpperCaseLabel
}) => {
  const Heading = `h${level}`;
  const SubHeading = `h${level + 1}`;

  if (children) {
    return (
      <section className={classNames(styles.section, className)}>
        {label && <Heading className={classNames(styles.heading, { [styles.noUpperCaseLabel]: noUpperCaseLabel })}>{label}</Heading>}
        {subHeading && <SubHeading className={styles.subHeading}>{subHeading}</SubHeading>}
        {renderContent(html, children)}
      </section>
    );
  }

  return null;
};

Section.propTypes = {
  label: PropTypes.string,
  level: PropTypes.number,
  children: PropTypes.node,
  html: PropTypes.bool,
  className: PropTypes.string,
  subHeading: PropTypes.string,
  noUpperCaseLabel: PropTypes.bool
};

Section.defaultProps = {
  level: 3,
  className: '',
  html: false,
  noUpperCaseLabel: false
};

export default Section;
