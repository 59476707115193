import React from 'react';

import Section from '../../Section';
import { getTranslation } from '../../../i18n';
import { courseUnitRealisationType } from '../../../types';
import useTranslation from '../../../hooks/useTranslation';

const StudyFormat = ({ courseUnitRealisation }) => {
  const { t, lang } = useTranslation();

  const content = getTranslation(courseUnitRealisation?.studyFormat, lang);

  if (!content) {
    return null;
  }

  return (
    <Section level={2} label={t('studyFormat')} html>
      {content}
    </Section>
  );
};

StudyFormat.propTypes = {
  courseUnitRealisation: courseUnitRealisationType
};

export default StudyFormat;
