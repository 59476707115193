import React from 'react';
import { cmsMaterialTitleType } from '../../../../../types';

const MaterialTitle = ({ material }) => (<h3>{material.title}</h3>);

MaterialTitle.propTypes = {
  material: cmsMaterialTitleType
};

export default MaterialTitle;
