import React from 'react';

import { courseUnitRealisationType } from '../../../../types';
import CmsMaterials from '../CmsMaterials';
import Section from '../../../Section';
import ParagraphedText from '../../ParagraphedText';
import styles from './courseMaterials.css';
import useTranslation from '../../../../hooks/useTranslation';

const CourseMaterials = ({ courseUnitRealisation = {} }) => {
  const { t } = useTranslation();
  const { cmsData, affiliation } = courseUnitRealisation;

  if (!cmsData || !cmsData.courseMaterials) {
    return null;
  }

  const { courseMaterials } = cmsData;

  return (
    <Section level={2} label={t('courseMaterials.title')}>
      <ParagraphedText className={styles.materialsInstructions} text={courseMaterials.info} />
      <CmsMaterials materials={courseMaterials.materials} embedMedia collapsible useRestricted affiliation={affiliation} />
    </Section>
  );
};

CourseMaterials.propTypes = {
  courseUnitRealisation: courseUnitRealisationType
};

export default CourseMaterials;
