import { useContext } from 'react';
import RootContext from '../context/RootContext';

const useUserPreferences = () => {
  const { userPreferences, handleUpdateUserPreference } = useContext(RootContext);
  return {
    getUserPreference: (key) => userPreferences?.[key],
    setUserPreference: handleUpdateUserPreference
  };
};

export default useUserPreferences;
