import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Restores scroll from query parameters when given parameter is set to true.
 *
 * @param hasResults {boolean} - specifices if scroll restoration can be fired
 */
const useScrollRestoration = (hasResults) => {
  const { state } = useLocation();
  const { scrollY } = state || { scrollY: 0 };

  useEffect(() => {
    if (hasResults) {
      window.scrollTo(0, scrollY);
    }
  }, [hasResults, scrollY]);
};

export default useScrollRestoration;
