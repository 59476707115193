import React from 'react';
import { bool, func, string } from 'prop-types';
import styles from './degreeProgrammeSelector.css';
import { DEGREE_PROGRAMME_GROUP_LABELS } from '../../constants';
import { getTranslation } from '../../i18n';
import { dpOptionGroupType, localizedTextType } from '../../types';
import HyphenatedText from '../HyphenatedText';
import useTranslation from '../../hooks/useTranslation';

export const getOptionGroupId = (label) => `${label}Group`;

const GroupHeading = ({ label, multipleOwn = false, t }) => (
  <span className={`${styles.groupHeading} heading-like size-4`}>
    {
      label === DEGREE_PROGRAMME_GROUP_LABELS.OWN && multipleOwn
        ? t('degreeProgramStudies.filters.degreeProgramFilter.types.ownMultiple')
        : t(`degreeProgramStudies.filters.degreeProgramFilter.types.${label}`)
    }
  </span>
);

GroupHeading.propTypes = {
  label: string.isRequired,
  multipleOwn: bool,
  t: func.isRequired
};

const Option = ({ onClick, names, lang }) => (
  <li className="degreeProgrammeOption">
    <button
      type="button"
      onClick={onClick}
      className={styles.degreeProgrammeSelectButton}
    >
      <HyphenatedText text={getTranslation(names, lang)} lang={lang} />
    </button>
  </li>
);

Option.propTypes = {
  onClick: func.isRequired,
  names: localizedTextType.isRequired,
  lang: string
};

const OptionGroup = ({
  optionGroup, nameFilter, setIsDPSelectorOpen, onSelect, ownMultiple
}) => {
  const { t, lang } = useTranslation();

  return (
    <div id={getOptionGroupId(optionGroup.label)}>
      <GroupHeading label={optionGroup.label} multipleOwn={ownMultiple} t={t} />
      <ul>
        {optionGroup.options.filter(nameFilter).map((o) => (
          <Option
            key={`${optionGroup.label}-${o.value}`}
            onClick={() => setIsDPSelectorOpen(false) || onSelect(o.value)}
            names={o.name}
            lang={lang}
          />
        ))}
      </ul>
    </div>
  );
};

OptionGroup.propTypes = {
  optionGroup: dpOptionGroupType.isRequired,
  nameFilter: func.isRequired,
  setIsDPSelectorOpen: func.isRequired,
  onSelect: func.isRequired,
  ownMultiple: bool
};

export default OptionGroup;
