import React, { useContext, useEffect, useState } from 'react';
import { node } from 'prop-types';

import RootContext from '../../context/RootContext';
import { initializeTracking } from '../../services/analytics';

const InitializeAnalyticsWrapper = ({ children }) => {
  const { config: { matomoTracking } } = useContext(RootContext);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    initializeTracking(matomoTracking);
    setInitialized(true);
  }, [matomoTracking]);

  return initialized ? (<>{children}</>) : null;
};

InitializeAnalyticsWrapper.propTypes = {
  children: node.isRequired
};

export default InitializeAnalyticsWrapper;
