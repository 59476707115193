import React, { useState } from 'react';
import classNames from 'classnames';

import {
  courseCmsNoDataReasonTypes,
  courseUnitRealisationType,
  courseUserAffiliationTypes
} from '../../../../../types';
import { AVAILABLE_LANGUAGES, FLOW_STATES } from '../../../../../constants';
import ModalDialog from '../../../../ModalDialog';

import styles from './editCoursePage.css';
import { eventActions, eventCategories, trackEvent } from '../../../../../services/analytics';
import Section from '../../../../Section';
import { isPublished } from '../../../../../utils';
import Link from '../../../../Link';
import { ArrowRight } from '../../../../Icon/Arrow';
import useTranslation from '../../../../../hooks/useTranslation';

const EditCoursePage = ({ courseUnitRealisation = {} }) => {
  const { t, lang: uiLanguage } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const teachingLanguage = courseUnitRealisation.teachingLanguage?.shortName?.fi;
  // note: the language can be safely set every time, if CMS data already exists it will automatically open the language that has been created
  // regardless fo the content of lang-parameter in the link
  const [editLanguage, setEditLanguage] = useState((teachingLanguage && AVAILABLE_LANGUAGES.includes(teachingLanguage))
    ? teachingLanguage
    : uiLanguage);

  const {
    id, canEditCourse, cmsBaseUrl, cmsData, affiliation, flowState
  } = courseUnitRealisation;

  const modalId = 'edit-course-page-dialog';

  if (!canEditCourse || flowState === FLOW_STATES.CANCELLED) {
    return null;
  }

  const isTeacher = [courseUserAffiliationTypes.TEACHER, courseUserAffiliationTypes.RESPONSIBLE_TEACHER].includes(affiliation);

  const useEditModal = cmsData?.noContent?.reason === courseCmsNoDataReasonTypes.NO_CMS_DATA;

  const onSelectEditLanguage = (event) => setEditLanguage(event.target.value);
  const getCmsEditUrl = (languageToEdit) => {
    let cmsEditUrl = `${cmsBaseUrl}/edit-course?courseRealisationId=${id}&returnTo=${encodeURIComponent(window.location.href)}`;
    if (languageToEdit != null) {
      cmsEditUrl += `&lang=${languageToEdit}`;
    }
    return cmsEditUrl;
  };
  const goToCMSEditor = (selectedLanguage) => {
    window.location.href = getCmsEditUrl(selectedLanguage);
    trackEvent(eventCategories.coursePage.INSTRUCTIONS, eventActions.CLICK, 'instructions');
  };

  const containerClasses = classNames(styles.container, {
    [`${styles.unpublished}`]: !isPublished(courseUnitRealisation)
  });

  return (
    <Section className={containerClasses}>
      <div className={styles.infoContainer}>
        <div className={styles.iconContainer}>
          <span className={`icon-2x icon--edit-event ${styles.icon}`} />
        </div>
        <div>
          <h2 className={styles.title}>{t('editCoursePage.title')}</h2>
          {t(`editCoursePage.description.${affiliation}`)}
          {!isPublished(courseUnitRealisation) && (
            <>
              {' '}
              {t(`editCoursePage.notPublished.${isTeacher ? 'TEACHER' : 'ADMINISTRATOR'}`)}
            </>
          )}
        </div>
      </div>
      <div className={styles.linkContainer}>
        {useEditModal ? (
          <button
            type="button"
            className={classNames('button--action', 'with-icon', styles.openModalButton)}
            onClick={() => setModalOpen(!modalOpen)}
            aria-haspopup="dialog"
            aria-controls={modalId}
            aria-expanded={modalOpen}
          >
            <span>{t('editCoursePage.editLink')}</span>
            <ArrowRight />
          </button>
        ) : (
          <Link
            href={getCmsEditUrl(editLanguage)}
            className="button--action"
            iconPosition="after"
            tracked
            eventCategory={eventCategories.coursePage.INSTRUCTIONS}
            trackingLabel="instructions"
          >
            {t('editCoursePage.editLink')}
          </Link>
        )}
      </div>
      {useEditModal && modalOpen && (
        <ModalDialog
          id={modalId}
          onConfirm={() => goToCMSEditor(editLanguage)}
          onCancel={() => setModalOpen(false)}
          confirmLabel={t('continue')}
          modalDialogTitle={t('editCoursePage.modal.title')}
        >
          <p>{t('editCoursePage.modal.instructions')}</p>
          <div className="form__fieldset">
            {AVAILABLE_LANGUAGES.map((langCode) => (
              <div key={langCode} className="form__item">
                <input type="radio" value={langCode} name="newCoursePageLanguage" id={`newCoursePage_${langCode}`} checked={editLanguage === langCode} onChange={onSelectEditLanguage} />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor={`newCoursePage_${langCode}`}>{t(`language.${langCode}`)}</label>
              </div>
            ))}
          </div>
        </ModalDialog>
      )}
    </Section>
  );
};

EditCoursePage.propTypes = {
  courseUnitRealisation: courseUnitRealisationType
};

export default EditCoursePage;
