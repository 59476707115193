import React, { useState } from 'react';
import {
  isWithinInterval, parseISO
} from 'date-fns';
import classNames from 'classnames';

import ModalDialog from '../../../../../ModalDialog';
import { courseUnitRealisationType } from '../../../../../../types';
import { getTranslation } from '../../../../../../i18n';
import { formatCredits } from '../../../../../../utils';

import styles from './combinedEnrolment.css';
import sisuEnrolmentStyles from '../SisuEnrolment/sisuEnrolment.css';
import { ArrowOffsite } from '../../../../../Icon/Arrow';
import ExternalEnrolment from '../ExternalEnrolment';
import { getEnrolmentEnd } from '../util';
import useTranslation from '../../../../../../hooks/useTranslation';

const CombinedEnrolment = ({ courseUnitRealisation }) => {
  const { t, lang } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  if (!courseUnitRealisation.enrolmentPeriod) {
    // If realisation does not have enrolment period, it is not possible to enroll to the realisation
    return null;
  }

  const {
    courseUnits, enrolmentPeriod, lateEnrolmentEnd, sisuUrl, externalEnrolmentLink
  } = courseUnitRealisation;

  const modalId = 'combined-enrolment-dialog';

  const startDateTime = parseISO(enrolmentPeriod.startDateTime);
  const endDateTime = parseISO(enrolmentPeriod.endDateTime);
  const lateEndDateTime = parseISO(lateEnrolmentEnd);
  const now = new Date();

  const confirmButtonClasses = classNames('button', 'button--action', styles.enrolmentButton, {
    [sisuEnrolmentStyles.dialogButton]: sisuEnrolmentStyles.dialogButton
  });

  if (!isWithinInterval(now, { start: startDateTime, end: lateEnrolmentEnd ? lateEndDateTime : endDateTime })) {
    return null;
  }

  const goToSisu = () => {
    window.open(`${sisuUrl}/student/plan`, '_blank');
    setModalOpen(false);
  };

  const goToAvoin = () => (
    <span className={classNames(styles.goToAvoin)}>
      <ExternalEnrolment externalEnrolmentLink={externalEnrolmentLink} enrolmentPeriod={enrolmentPeriod} lateEnrolmentEnd={lateEnrolmentEnd} />
    </span>
  );

  const getSisuConfirmLabel = () => (
    <span className={sisuEnrolmentStyles.goToSisu}>
      {t('sisuEnrolment.modal.goToSisu')}
      <span className="sr-only">, {t('openInNewWindow')}</span>
      <ArrowOffsite className={sisuEnrolmentStyles.offsiteArrow} aria-hidden="true" />
    </span>
  );

  const enrolmentEnd = getEnrolmentEnd(courseUnitRealisation, lang, t);

  const createCombinedEnrolmentModal = () => (
    <ModalDialog
      id={modalId}
      onConfirm={null}
      onCancel={() => setModalOpen(false)}
      confirmLabel={null}
      cancelLabel={t('sisuEnrolment.modal.return')}
      buttonsClassName={sisuEnrolmentStyles.dialogButton}
      reverseButtons
      modalDialogTitle={t('sisuEnrolment.modal.title')}
      isEnrolment
    >

      <div>
        {t('sisuEnrolment.modal.courseUnit')}
      </div>

      <ul className={sisuEnrolmentStyles.courseUnits}>
        {courseUnits.map((courseUnit) => (
          <li key={courseUnit.id} className={sisuEnrolmentStyles.courseUnit}>
            {courseUnit.code} {getTranslation(courseUnit.name, lang)}{courseUnit.credits ? `, ${formatCredits(courseUnit.credits, lang)}` : ''}
          </li>
        ))}
      </ul>

      <div className={styles.verticalContainer}>
        <span className={styles.verticalPart}>
          <h5 className={styles.bigger}>{t('sisuEnrolment.modal.student')}</h5>
          {enrolmentEnd}
          <div className={sisuEnrolmentStyles.enrolmentInfoItem}>
            <span className={sisuEnrolmentStyles.index}>1.</span>
            {t('sisuEnrolment.modal.studyPlan')}
          </div>

          <div className={sisuEnrolmentStyles.enrolmentInfoItem}>
            <span className={sisuEnrolmentStyles.index}>2.</span>
            {t('sisuEnrolment.modal.completion')}
          </div>
          <button key="ok-button" type="button" className={confirmButtonClasses} onClick={goToSisu}>
            {getSisuConfirmLabel()}
          </button>
        </span>
        <hr className={styles.vertical} />
        <span className={styles.verticalPart}>
          <h5 className={styles.bigger}>{t('sisuEnrolment.modal.openUniversityStudent')}</h5>
          {enrolmentEnd}
          <div className={sisuEnrolmentStyles.enrolmentInfoItem}>
            <span className={sisuEnrolmentStyles.index}>1.</span>
            {t('sisuEnrolment.modal.openUniversityEnrolment')}
          </div>
          {goToAvoin()}
        </span>
      </div>
    </ModalDialog>
  );

  return (
    <>
      <button
        type="button"
        className={classNames('button--action', sisuEnrolmentStyles.goToEnrolment)}
        onClick={() => setModalOpen(true)}
        aria-haspopup="dialog"
        aria-controls={modalId}
        aria-expanded={modalOpen}
      >
        {t('sisuEnrolment.goToEnrolment')}
      </button>
      {modalOpen ? (createCombinedEnrolmentModal()) : null}
    </>
  );
};

CombinedEnrolment.propTypes = {
  courseUnitRealisation: courseUnitRealisationType.isRequired
};

export default CombinedEnrolment;
