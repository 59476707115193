import React, { useRef, createRef } from 'react';
import { arrayOf, func, string } from 'prop-types';
import { getTranslationWithLocale } from '../../i18n';
import { eventActions, eventCategories, trackEvent } from '../../services/analytics';
import { dpOptionGroupType } from '../../types';
import styles from './degreeProgrammeSelector.css';
import { getOptionGroupId } from './OptionGroup';
import { tabGroupKeyboardNavigation } from '../TabGroup';
import useTranslation from '../../hooks/useTranslation';

const TypeFilters = ({
  typeFilter, setTypefilter, groupedProgrammes
}) => {
  const { t } = useTranslation();
  const tabRefs = useRef(groupedProgrammes.map(() => createRef()));

  const onTypeSelectClicked = (selectedType) => {
    const prevTypeFilter = typeFilter;
    setTypefilter(selectedType.label);

    if (prevTypeFilter !== selectedType.label) {
      trackEvent(
        eventCategories.dpSearch.SEARCH_FILTER,
        eventActions.TARGET_GROUP,
        getTranslationWithLocale(`degreeProgramStudies.filters.degreeProgramFilter.types.${selectedType.label}`, 'en')
      );
    }
  };

  const activateTab = (selectedTabIndex) => onTypeSelectClicked(groupedProgrammes[selectedTabIndex]);
  const handleKeyDown = (e) => tabGroupKeyboardNavigation(e, tabRefs, activateTab);

  return (
    <div className={styles.typeFilterContainer}>
      { /* eslint-disable-next-line jsx-a11y/interactive-supports-focus */ }
      <div
        role="tablist"
        className={styles.typeFilters}
        onKeyDown={handleKeyDown}
      >
        {groupedProgrammes.map((go, idx) => {
          const isSelected = typeFilter === go.label;
          return (
            <button
              className={`${styles.typeFilter} ${isSelected ? styles.selected : ''}`}
              key={go.label}
              type="button"
              role="tab"
              tabIndex={isSelected ? 0 : -1}
              aria-selected={isSelected}
              aria-controls={getOptionGroupId(go.label)}
              onClick={() => onTypeSelectClicked(go)}
              ref={tabRefs.current[idx]}
            >
              <span className={styles.typeFilterLabel}>{t(`degreeProgramStudies.filters.degreeProgramFilter.types.${go.label}`)}</span>
              <span> ({go.options.length})</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

TypeFilters.propTypes = {
  groupedProgrammes: arrayOf(dpOptionGroupType).isRequired,
  typeFilter: string,
  setTypefilter: func
};

export default TypeFilters;
