import React from 'react';
import { Link } from 'react-router-dom';
import styles from './breadcrumb.css';
import { breadcrumbType } from '../../types';
import { frontPagePathTranslations } from '../../constants';
import useTranslation from '../../hooks/useTranslation';

const HomeIcon = require('./home.svg');

const separator = '/';

const home = (lang) => (
  <Link
    to={'/'.concat(frontPagePathTranslations[lang])}
    className={styles.urls}
  >
    <img src={HomeIcon} alt={frontPagePathTranslations[lang]} />
  </Link>
);

const Breadcrumb = ({
  crumb
}) => {
  const { t, lang } = useTranslation();

  return (
    <nav className={styles.breadcrumb} aria-label={t('breadcrumb')}>
      <ol>
        <li>{home(lang)}</li>
        <li aria-hidden="true">{separator}</li>
        {crumb ? (
          <>
            <li>
              {crumb.useRouter ? (
                <Link
                  to={crumb.path[lang]}
                  className={styles.urls}
                >
                  {crumb.name[lang]}
                </Link>
              ) : (
                <a
                  href={crumb.path[lang]}
                  className={styles.urls}
                >
                  {crumb.name[lang]}
                </a>
              )}
            </li>
            <li aria-hidden="true">{separator}</li>
          </>
        ) : null}
      </ol>
    </nav>
  );
};

Breadcrumb.propTypes = {
  crumb: breadcrumbType
};

export default Breadcrumb;
