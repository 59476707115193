import React, { useState, useCallback } from 'react';
import {
  string, node, number, bool
} from 'prop-types';
import truncate from 'truncate-html';
import classNames from 'classnames';
import { renderHtml } from '../CoursePage/util';
import styles from './showMore.css';
import useTranslation from '../../hooks/useTranslation';

const getPlainTextTruncatedContent = (content, length) => {
  const [shortContent] = content.split('\n');
  return `${shortContent.substr(0, length).trim()}`;
};

const getExpanded = (content, isHtml) => (isHtml
  // eslint-disable-next-line react/no-danger
  ? <div dangerouslySetInnerHTML={renderHtml(content)} />
  : content);

const getTruncated = (content, length, isHtml) => (isHtml
  // eslint-disable-next-line react/no-danger
  ? <div dangerouslySetInnerHTML={renderHtml(truncate(content, length))} />
  : getPlainTextTruncatedContent(content, length));

const getPlainTextContentLength = (content, isHtml) => {
  if (isHtml) {
    return truncate(content, content.length, { stripTags: true }).length;
  }
  return content.length;
};

/**
 * Generates "Show more/less" element of given string.
 *
 * Content is truncated to length of length parameter (default 30) with HTML tags intact, when isHtml is true.
 * If content is not HTML and contains newline ("\n") before truncation point, it will be used as truncation
 * point instead even if overall length of content is shorter than length parameter.
 *
 * @param {Object} properties
 * @param {!string} properties.content - String to be modified
 * @param {number} [properties.length=30] - Where to truncate content
 * @param {boolean} [properties.isHtml=false] - Is content to be rendered as html
 * @param {node} [properties.preContent] - Any content to show before content to truncate, does not get counted towards truncating
 * @param {...any} [properties.props] - Optional properties to pass for wrapping <div>
 *
 * @returns {JSX.Element} - Processed JSX
 */
const ShowMore = ({
  content,
  length,
  isHtml,
  preContent,
  ...props
}) => {
  const { t } = useTranslation();
  const initiallyTruncatedBasedOnNewLine = !isHtml && content.includes('\n') && content.indexOf('\n') < length;
  const plainTextContentLength = getPlainTextContentLength(content, isHtml);
  const [isExpanded, setExpanded] = useState(plainTextContentLength <= length && !initiallyTruncatedBasedOnNewLine);
  const showShowMore = plainTextContentLength > length || initiallyTruncatedBasedOnNewLine;

  const onShowMoreClicked = useCallback(() => setExpanded(!isExpanded), [isExpanded]);

  const getShowMoreButton = () => {
    if (!showShowMore) {
      return null;
    }

    if (isHtml) {
      return (
        <button className={styles.showMoreButton} type="button" onClick={onShowMoreClicked}>
          {t(`search.results.${isExpanded ? 'lessContent' : 'moreContent'}`)}
        </button>
      );
    }

    if (!isExpanded) {
      return (
        <span className="nowrap">
          ...
          <button className={classNames(styles.showMoreButton, styles.keepTogether)} type="button" onClick={onShowMoreClicked}>
            {t('search.results.moreContent')}
          </button>
        </span>
      );
    }

    return (
      <button className={classNames(styles.showMoreButton, styles.keepTogether)} type="button" onClick={onShowMoreClicked}>
        {t('search.results.lessContent')}
      </button>
    );
  };

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <div {...props}>
      {preContent}
      {isExpanded ? (
        <>
          {getExpanded(content, isHtml)}{' '}
        </>
      ) : getTruncated(content, length, isHtml)}
      {getShowMoreButton()}
    </div>
  );
};

ShowMore.propTypes = {
  content: string.isRequired,
  preContent: node,
  length: number,
  isHtml: bool
};

ShowMore.defaultProps = {
  length: 30,
  isHtml: false
};

export default ShowMore;
