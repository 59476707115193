import React from 'react';
import {
  string, number, oneOfType, oneOf
} from 'prop-types';

import { formatNumber } from '../../utils';
import { AVAILABLE_LANGUAGES } from '../../constants';

const FormattedNumber = ({ value, lang }) => (
  <span aria-label={value}>
    {formatNumber(value, lang)}
  </span>
);

FormattedNumber.propTypes = {
  value: oneOfType([string, number]).isRequired,
  lang: oneOf(AVAILABLE_LANGUAGES).isRequired
};

export default FormattedNumber;
