import React, { useState } from 'react';
import {
  node, bool, string, number, oneOfType
} from 'prop-types';
import { uniqueId } from 'lodash';
import classNames from 'classnames';
import styles from './collapse.css';

const getLabel = (collapsed, toggleLabel, expandLabel, collapseLabel) => {
  if (toggleLabel) {
    return toggleLabel;
  }

  return collapsed ? expandLabel : collapseLabel;
};

const Collapse = ({
  children, alwaysShow, toggleLabel, expandLabel, collapseLabel, collapsed, collapseArrow, upperCaseLabel, className, collapseContainerClassName
}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const buttonClasses = classNames('button--outline', styles.collapseButton, {
    'button--action-before': collapseArrow,
    'icon--caret-down': isCollapsed && collapseArrow,
    'icon--caret-up': !isCollapsed && collapseArrow,
    [`${styles.upperCaseLabel}`]: upperCaseLabel
  });

  const buttonId = uniqueId('collapseButton');

  const containerClasses = classNames(styles.collapseContainer, {
    [`${styles.collapsed}`]: isCollapsed,
    [`${styles.expanded}`]: !isCollapsed
  }, collapseContainerClassName);

  const containerId = uniqueId('collapseContainer');

  if (alwaysShow && Array.isArray(children)) {
    return (
      <div className={className}>
        {children.slice(0, alwaysShow)}
        {children.length > alwaysShow && (
          <>
            <div className={containerClasses} aria-expanded={!isCollapsed}>
              {!isCollapsed && children.slice(alwaysShow)}
            </div>
            <button type="button" className={buttonClasses} onClick={() => setIsCollapsed(!isCollapsed)}>
              {getLabel(isCollapsed, toggleLabel, expandLabel, collapseLabel)}
            </button>
          </>
        )}
      </div>
    );
  }

  return (
    <div className={className}>
      <button
        aria-expanded={!isCollapsed}
        aria-controls={containerId}
        id={buttonId}
        type="button"
        className={buttonClasses}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {getLabel(isCollapsed, toggleLabel, expandLabel, collapseLabel)}
      </button>
      <div id={containerId} className={containerClasses} aria-expanded={!isCollapsed}>
        {!isCollapsed && <div role="region" aria-labelledby={buttonId}>{children}</div>}
      </div>
    </div>
  );
};

Collapse.propTypes = {
  children: node.isRequired,
  alwaysShow: number,
  toggleLabel: oneOfType([string, node]),
  expandLabel: string,
  collapseLabel: string,
  collapsed: bool,
  collapseArrow: bool,
  upperCaseLabel: bool,
  className: string,
  collapseContainerClassName: string
};

Collapse.defaultProps = {
  collapseArrow: true,
  className: '',
  collapseContainerClassName: ''
};

export default Collapse;
