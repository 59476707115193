import React from 'react';
import { arrayOf, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { hierarchyModule } from '../../../types';
import { getTranslation } from '../../../i18n';
import Section from '../../Section';
import styles from './degreeProgrammes.css';
import { degreeProgramPathTranslations, DOCUMENT_STATES } from '../../../constants';
import useTranslation from '../../../hooks/useTranslation';

const getDegreeProgrammeUrlPath = (dp, lang, cpId) => `/${degreeProgramPathTranslations[lang]}/${dp.id}${cpId ? `?cpId=${cpId}` : ''}`;

const DegreeProgrammes = ({
  degreeProgrammes, selectedYear
}) => {
  const { t, lang } = useTranslation();

  return (
    <Section className={styles.degreeProgrammesSection} level={2} label={t('studyModule.degreeProgrammes.title')}>
      {!!degreeProgrammes.length
          && (
            <p className={styles.degreeProgrammesDescription}>
              {t(`studyModule.degreeProgrammes.description${degreeProgrammes.length === 1 ? 'Single' : 'Multiple'}`)}
            </p>
          )}
      <div className={styles.linkList}>
        {degreeProgrammes.map((dp) => (dp.documentState === DOCUMENT_STATES.ACTIVE ? (
          <Link
            key={dp.id}
            className="old-button--action-before icon--arrow-left"
            to={getDegreeProgrammeUrlPath(dp, lang, selectedYear)}
          >
            {getTranslation(dp.name, lang)}
          </Link>
        ) : (
          <span className="old-button--action-before--disabled icon--arrow-left">
            {getTranslation(dp.name, lang)} ({t('notAvailable')})
          </span>
        )))}
      </div>
    </Section>
  );
};

DegreeProgrammes.propTypes = {
  degreeProgrammes: arrayOf(hierarchyModule).isRequired,
  selectedYear: string
};

export default DegreeProgrammes;
