import React from 'react';
import { node, number } from 'prop-types';
import styles from './emptyState.css';

const EmptyState = ({ colSpan, children }) => (
  <tr>
    <td colSpan={colSpan} className={styles.emptyState}>
      {children}
    </td>
  </tr>
);

EmptyState.propTypes = {
  colSpan: number,
  children: node
};

export default EmptyState;
