import React, { useState, useRef } from 'react';
import {
  isWithinInterval, parseISO
} from 'date-fns';
import classNames from 'classnames';

import ModalDialog from '../../../../../ModalDialog';
import { courseUnitRealisationType } from '../../../../../../types';
import { getTranslation } from '../../../../../../i18n';
import { formatCredits, sanitizeDangerousHtml } from '../../../../../../utils';

import styles from './combinedOpenUniversityProductEnrolment.css';
import sisuEnrolmentStyles from '../SisuEnrolment/sisuEnrolment.css';
import { ArrowOffsite } from '../../../../../Icon/Arrow';
import {
  getEnrolmentEnd, courseUnitsWithRelatedMainOpenUniversityProducts
} from '../util';
import useTranslation from '../../../../../../hooks/useTranslation';
import { cuPathTranslations } from '../../../../../../constants';
import Link from '../../../../../Link';

const CombinedOpenUniversityProductEnrolment = ({ courseUnitRealisation }) => {
  const { t, lang } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCourseUnitId, setSelectedCourseUnitId] = useState();
  const firstCourseUnitRef = useRef();

  if (!courseUnitRealisation.enrolmentPeriod) {
    // If realisation does not have enrolment period, it is not possible to enroll to the realisation
    return null;
  }

  const {
    courseUnits, enrolmentPeriod, lateEnrolmentEnd, sisuUrl, openUniversityProducts
  } = courseUnitRealisation;

  const modalId = 'combined-open-university-product-enrolment-dialog';

  const relevantCourseUnits = courseUnitsWithRelatedMainOpenUniversityProducts(courseUnits, openUniversityProducts);

  if (!relevantCourseUnits.length) {
    // If no course unit match any open university products, it is not possible to enrol to the open university product
    // Assumption is, that we don't want to offer this we've gotten here but are missing OUPs
    return null;
  }

  const isSingleCourseUnit = relevantCourseUnits.length === 1;

  const startDateTime = parseISO(enrolmentPeriod.startDateTime);
  const endDateTime = parseISO(enrolmentPeriod.endDateTime);
  const lateEndDateTime = parseISO(lateEnrolmentEnd);
  const now = new Date();

  if (!isWithinInterval(now, { start: startDateTime, end: lateEnrolmentEnd ? lateEndDateTime : endDateTime })) {
    return null;
  }

  const confirmButtonClasses = classNames('button', 'button--action', styles.enrolmentButton, {
    [sisuEnrolmentStyles.dialogButton]: sisuEnrolmentStyles.dialogButton
  });

  const goToSisu = () => {
    window.open(`${sisuUrl}/student/plan`, '_blank');
    setModalOpen(false);
  };

  const goToOpenUniversityProduct = () => {
    const courseUnitId = isSingleCourseUnit ? relevantCourseUnits[0].id : selectedCourseUnitId;
    const url = openUniversityProducts.find((oup) => oup.courseUnitId === courseUnitId)?.enrolmentLink;
    if (url) {
      window.open(url, '_blank');
      setModalOpen(false);
    } else {
      firstCourseUnitRef?.current.focus();
    }
  };

  const getSisuConfirmLabel = () => (
    <span className={sisuEnrolmentStyles.goToSisu}>
      {t('sisuEnrolment.modal.goToSisu')}
      <span className="sr-only">, {t('openInNewWindow')}</span>
      <ArrowOffsite className={sisuEnrolmentStyles.offsiteArrow} aria-hidden="true" />
    </span>
  );

  const enrolmentEnd = getEnrolmentEnd(courseUnitRealisation, lang, t);

  const getDegreeStudentModalContents = () => (
    <span className={styles.verticalPart}>
      <h5 className={styles.bigger}>{t('sisuEnrolment.modal.student')}</h5>
      <div className={sisuEnrolmentStyles.enrolmentInfoItem}>
        <span className={sisuEnrolmentStyles.index}>1.</span>
        {t('sisuEnrolment.modal.studyPlan')}
      </div>

      <div className={sisuEnrolmentStyles.enrolmentInfoItem}>
        <span className={sisuEnrolmentStyles.index}>2.</span>
        {t('sisuEnrolment.modal.completion')}
      </div>
      {enrolmentEnd}
      <button key="ok-button" type="button" className={confirmButtonClasses} onClick={goToSisu}>
        {getSisuConfirmLabel()}
      </button>
    </span>
  );

  const firstCourseUnit = relevantCourseUnits[0];

  const getSingleCourseUnitContents = () => (
    <>
      <div>{t('openUniversityProductEnrolment.modal.descriptionSingle')}</div>

      <div className={styles.courseUnit}>
        <p id={`enrolment-course-unit-${firstCourseUnit.id}`} className={styles.courseUnitDescriptionSingle}>
          {firstCourseUnit.code} {getTranslation(firstCourseUnit.name, lang)}{firstCourseUnit.credits ? `, ${formatCredits(firstCourseUnit.credits, lang)}` : ''}
        </p>
        <Link
          id={`enrolment-course-unit-link-${firstCourseUnit.id}`}
          aria-labelledby={`enrolment-course-unit-link-${firstCourseUnit.id} enrolment-course-unit-${firstCourseUnit.id}`}
          external
          href={`/${getTranslation(cuPathTranslations, lang)}/${firstCourseUnit.id}/${firstCourseUnit.code}`}
        >
          {t('openUniversityProductEnrolment.modal.courseUnitLink')}
        </Link>
      </div>

      <div className={styles.verticalContainer}>
        {getDegreeStudentModalContents()}
        <hr className={styles.vertical} />
        <span className={styles.verticalPart}>
          <h5 className={styles.bigger}>{t('sisuEnrolment.modal.openUniversityStudent')}</h5>
          <div className={styles.enrolmentInfoItem}>
            <span className={styles.index}>1.</span>
            {t('openUniversityProductEnrolment.modal.enrolBelow')}
          </div>
          {enrolmentEnd}
          <button type="button" className={confirmButtonClasses} onClick={goToOpenUniversityProduct}>
            {getSisuConfirmLabel()}
          </button>
        </span>
      </div>
    </>
  );

  const getMultipleCourseUnitsContents = () => (
    <>
      <div>
        {t('openUniversityProductEnrolment.modal.descriptionMany')}
      </div>
      <div className={styles.verticalContainer}>
        {getDegreeStudentModalContents()}
        <hr className={styles.vertical} />
        <span className={styles.verticalPart}>
          <h5 className={styles.bigger}>{t('sisuEnrolment.modal.openUniversityStudent')}</h5>
          {/* eslint-disable-next-line react/no-danger */}
          <p dangerouslySetInnerHTML={sanitizeDangerousHtml(t('openUniversityProductEnrolment.modal.descriptionBody'))} />

          <fieldset>
            <legend className={styles.enrolmentInfoItem}>
              <span className={styles.index}>1.</span>
              {t('openUniversityProductEnrolment.modal.selectCourseUnit')}
            </legend>
            <div
              className={styles.courseUnits}
            >
              {relevantCourseUnits
                .map((courseUnit, i) => (
                  <div key={courseUnit.id} className={styles.courseUnit}>
                    <input
                      id={`enrolment-course-unit-${courseUnit.id}`}
                      type="radio"
                      name="enrolment-course-unit-picker"
                      ref={i === 0 ? firstCourseUnitRef : undefined}
                      required
                      value={courseUnit.id}
                      onChange={() => setSelectedCourseUnitId(courseUnit.id)}
                      checked={selectedCourseUnitId === courseUnit.id}
                    />
                    <label htmlFor={`enrolment-course-unit-${courseUnit.id}`}>
                      <p className={styles.courseUnitDescriptionMulti}>
                        {courseUnit.code} {getTranslation(courseUnit.name, lang)}
                      </p>
                    </label>
                    <Link
                      className={styles.courseUnitInputLink}
                      id={`enrolment-course-unit-link-${courseUnit.id}`}
                      aria-labelledby={`enrolment-course-unit-link-${courseUnit.id} enrolment-course-unit-${courseUnit.id}`}
                      external
                      href={`/${getTranslation(cuPathTranslations, lang)}/${courseUnit.id}/${courseUnit.code}`}
                    >{t('openUniversityProductEnrolment.modal.courseUnitLink')}
                    </Link>
                  </div>
                ))}
            </div>
          </fieldset>

          <div className={styles.enrolmentInfoItem}>
            <span className={styles.index}>2.</span>
            {t('openUniversityProductEnrolment.modal.enrol')}
          </div>
          {getEnrolmentEnd(courseUnitRealisation, lang, t)}
          <button type="button" className={confirmButtonClasses} onClick={goToOpenUniversityProduct}>
            {getSisuConfirmLabel()}
          </button>
        </span>
      </div>
    </>
  );

  return (
    <>
      <button
        type="button"
        className={classNames('button--action', sisuEnrolmentStyles.goToEnrolment)}
        onClick={() => setModalOpen(true)}
        aria-haspopup="dialog"
        aria-controls={modalId}
        aria-expanded={modalOpen}
      >
        {t('openUniversityProductEnrolment.goToEnrolment')}
      </button>
      {modalOpen ? (
        <ModalDialog
          id={modalId}
          onConfirm={null}
          onCancel={() => setModalOpen(false)}
          confirmLabel={null}
          cancelLabel={t('openUniversityProductEnrolment.modal.return')}
          buttonsClassName={sisuEnrolmentStyles.dialogButton}
          reverseButtons
          modalDialogTitle={t('openUniversityProductEnrolment.modal.title')}
          isEnrolment
        >
          {isSingleCourseUnit ? getSingleCourseUnitContents() : getMultipleCourseUnitsContents()}
        </ModalDialog>
      ) : null}
    </>
  );
};

CombinedOpenUniversityProductEnrolment.propTypes = {
  courseUnitRealisation: courseUnitRealisationType.isRequired
};

export default CombinedOpenUniversityProductEnrolment;
