import React from 'react';
import Section from '../../../Section';
import { courseCmsDataType } from '../../../../types';

const OptionalInformation = ({ cmsData }) => {
  if (!cmsData || !cmsData.optionalInfo || !cmsData.optionalInfo.length) {
    return null;
  }

  return cmsData.optionalInfo.map((info) => (
    <Section key={`${info.title}_${info.content}`} level={2} label={info.title} html>
      {info.content}
    </Section>
  ));
};

OptionalInformation.propTypes = {
  cmsData: courseCmsDataType
};

export default OptionalInformation;
