import { useEffect, useState } from 'react';

export default function useApiFunction(fn, ...args) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSlowLoad, setIsSlowLoad] = useState(false);
  useEffect(() => {
    if (args.some((arg) => typeof arg === 'undefined')) return () => {};
    let mounted = true;
    const isSlowLoadTimeout = setTimeout(() => setIsSlowLoad(true), 200);
    (async () => {
      setLoading(true);
      try {
        const response = await fn(...args);
        if (mounted) {
          setData(response);
          setIsSlowLoad(false);
          clearTimeout(isSlowLoadTimeout);
        }
      } catch (e) {
        if (mounted) {
          setError(e);
        }
      } finally {
        if (mounted) {
          setLoading(false);
          setIsSlowLoad(false);
          clearTimeout(isSlowLoadTimeout);
        }
      }
    })();
    return () => {
      mounted = false;
      clearTimeout(isSlowLoadTimeout);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fn, ...args]);
  return {
    data, loading, error, isSlowLoad
  };
}
