import React from 'react';
import { courseResultsType } from '../../../../../types';
import styles from './gradeDistributions.css';
import { getTranslation } from '../../../../../i18n';
import useTranslation from '../../../../../hooks/useTranslation';

const getGradeBar = (count, total) => (
  <div className={styles.gradeBar}>
    <div className={styles.gradeBarFiller} style={{ width: `${(count / total) * 100}%` }} />
  </div>
);

const GradeDistributions = ({ courseResults = {} }) => {
  const { t, lang } = useTranslation();
  const {
    distribution, showBarForFailed, hidePassedSummary, showGradeDistribution
  } = courseResults;
  if (!showGradeDistribution) {
    return null;
  }
  const passedCount = distribution.reduce((count, item) => (item.passed ? count + item.count : count), 0);
  const failedCount = distribution.reduce((count, item) => (!item.passed ? count + item.count : count), 0);
  const totalInGraphCount = showBarForFailed ? passedCount + failedCount : passedCount;

  return (
    <div>
      {hidePassedSummary
        ? ''
        : (
          <div className={styles.passedTotal}>
            <span>{t('courseResults.passedCount')}:</span>
            {passedCount} {t('pcs')}
          </div>
        )}
      <div className={styles.grades}>
        {distribution.filter((item) => item.passed || showBarForFailed).map((item) => (
          <div key={item.localId} className={styles.grade}>
            <span className={styles.gradeTitle}>
              {getTranslation(item.title, lang)}
            </span>
            <span>{item.count} {t('pcs')}</span>
            {getGradeBar(item.count, totalInGraphCount)}
          </div>
        ))}
      </div>
      {!showBarForFailed && (
        <div className={styles.notPassed}>
          <span>{t('courseResults.failed')}:</span>
          {` ${failedCount} `}
          {t('pcs')}
        </div>
      )}
    </div>
  );
};

GradeDistributions.propTypes = {
  courseResults: courseResultsType
};

export default GradeDistributions;
