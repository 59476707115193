import React from 'react';

export const initialDegreeProgramme = {
  groupId: null,
  code: null,
  id: null
};

export const getInitialUserSelections = () => ({
  degreeProgramme: initialDegreeProgramme
});

const PersistingUserSelectionsContext = React.createContext(getInitialUserSelections());

export default PersistingUserSelectionsContext;
