import { useEffect, useState } from 'react';

const useFocusAfterContentChange = (content, isLoading, focusElRef) => {
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    if (content && !isLoading) {
      if (isInitialLoad) {
        setIsInitialLoad(false);
      } else {
        focusElRef?.current?.focus();
      }
    }
    // don't run hook on isInitialLoad change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, isLoading]);

  return setIsInitialLoad;
};

export default useFocusAfterContentChange;
