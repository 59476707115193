import React, {
  useState, useEffect, useCallback
} from 'react';
import {
  endOfToday, isAfter, isBefore, parseISO, startOfToday
} from 'date-fns';

import { isMobile, useDeviceSize } from '../../../context/DeviceSizeContextProvider';
import { getUserEnrollments } from '../../../api';
import {
  partitionArray, isBeforeOrEqual, isAfterOrEqual
} from '../../../utils';

import TabGroup from '../../TabGroup';
import EnrollmentsByType from './EnrollmentsByType';
import styles from './coursesExams.css';
import useTranslation from '../../../hooks/useTranslation';

const CoursesExams = () => {
  const { t, lang } = useTranslation();
  const [courses, setCourses] = useState([]);
  const [exams, setExams] = useState([]);
  const [selectedView, setSelectedView] = useState('current');

  const { deviceSize } = useDeviceSize();

  const views = ['current', 'upcoming', 'past'];
  const onSelectView = useCallback((view) => {
    setSelectedView(view);
  }, [setSelectedView]);

  const byView = (view, startOfDay, endOfDay) =>
    (enrollment) => {
      switch (view) {
        case 'current':
          return isBeforeOrEqual(enrollment.startDate, startOfDay) && isAfterOrEqual(enrollment.endDate, endOfDay);
        case 'upcoming':
          return isAfter(enrollment.startDate, endOfDay);
        case 'past':
          return isBefore(enrollment.endDate, startOfDay);
        default:
          return false;
      }
    };

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      try {
        const response = await getUserEnrollments(lang);
        if (!isCancelled && Array.isArray(response)) {
          const enrollments = response.map((enrollment) => ({
            ...enrollment,
            startDate: parseISO(enrollment.startDate),
            endDate: parseISO(enrollment.endDate)
          })).filter(byView(selectedView, startOfToday(), endOfToday()));
          const [examsFromEnrollments, coursesFromEnrollments] =
            partitionArray(enrollments, (enrollment) => enrollment.isExam);
          setCourses(coursesFromEnrollments);
          setExams(examsFromEnrollments);
        }
      } catch {
        setCourses([]);
        setExams([]);
      }
    })();
    return () => {
      isCancelled = true;
    };
  }, [lang, selectedView]);

  const viewControls = isMobile(deviceSize)
    ? (
      <div className={`view-select ${styles.controlsContainer}`}>
        <div className={styles.selectViewControls}>
          <select value={selectedView} onChange={(e) => onSelectView(e.target.value)}>
            {views.map((view) => (
              <option key={view} value={view}>{t(`coursesExams.${view}View`)}</option>
            ))}
          </select>
        </div>
      </div>
    ) : (
      <TabGroup
        className={styles.tabGroup}
        tabs={views.map((view) => ({ label: t(`coursesExams.${view}View`) }))}
        onTabSelect={(idx) => onSelectView(views[idx])}
        active={views.findIndex((view) => view === selectedView)}
        controls="coursesExamsContent"
        label={t('coursesExams.title')}
      />
    );

  return (
    <>
      <h2>{t('coursesExams.title')}</h2>
      {viewControls}
      <div
        className={`${styles.enrollments} grid-container`}
        id="coursesExamsContent"
        role={isMobile(deviceSize) ? '' : 'tabpanel'}
        tabIndex={isMobile(deviceSize) ? -1 : 0}
        aria-label={t(`coursesExams.${selectedView}View`)}
      >
        <div className="courses">
          <EnrollmentsByType
            label={t('coursesExams.courses')}
            placeholder={t(`coursesExams.placeholder.${selectedView}Courses`)}
            enrollments={courses}
            translate={t}
            lang={lang}
          />
        </div>
        <div className="exams">
          <EnrollmentsByType
            label={t('coursesExams.exams')}
            placeholder={t(`coursesExams.placeholder.${selectedView}Exams`)}
            enrollments={exams}
            translate={t}
            lang={lang}
          />
        </div>
      </div>
    </>
  );
};

export default CoursesExams;
