import React from 'react';
import PropTypes from 'prop-types';
import CourseUnit from './CourseUnit';
import Message from '../Message';
import { getCourseUnit } from '../../api';
import useApiFunction from '../../hooks/useApiFunction';
import useTranslation from '../../hooks/useTranslation';

const CourseUnitWrapper = ({ courseUnitId }) => {
  const { t } = useTranslation();
  const { data: courseUnit, error, loading } = useApiFunction(getCourseUnit, courseUnitId);

  if (loading) {
    return (
      <Message loading>
        {t('courseUnit.loading', { courseUnitId })}
      </Message>
    );
  }

  if (error) {
    const messageKey = error.status === 404 ? 'courseUnit.notFound' : 'common.error';
    const errorData = error.status === 404 ? {
      courseUnitId
    } : {
      status: error.status,
      message: error.statusText
    };

    return (
      <Message error>
        {t(messageKey, errorData)}
      </Message>
    );
  }

  return (
    <CourseUnit courseUnit={courseUnit} />
  );
};

CourseUnitWrapper.propTypes = {
  courseUnitId: PropTypes.string
};

export default CourseUnitWrapper;
