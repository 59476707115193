import { findIndex } from 'lodash';
import { arrayOf, func, string } from 'prop-types';
import React, {
  createRef, useRef, useEffect, useState
} from 'react';
import useHideOnClickOutside from '../../hooks/useHideOnClickOutside';
import { getTranslationWithLocale } from '../../i18n';
import { eventActions, eventCategories, trackEvent } from '../../services/analytics';
import { dpOptionGroupType } from '../../types';
import styles from './degreeProgrammeSelector.css';
import useTranslation from '../../hooks/useTranslation';

const menuButtonKeyboardNavigation = (e, setMenuOpen, maxIndex, setMenuItemToFocusOnIndex) => {
  switch (e.key) {
    case 'Enter':
    case ' ':
    case 'ArrowDown':
      e.preventDefault();
      setMenuOpen(true);
      setMenuItemToFocusOnIndex(0);
      break;
    case 'ArrowUp':
      e.preventDefault();
      setMenuOpen(true);
      setMenuItemToFocusOnIndex(maxIndex);
      break;
    default:
      break;
  }
};

const menuKeyboardNavigation = (e, menuItemRefs, menuButtonRef, activateMenuItem, setMenuOpen) => {
  const maxIndex = menuItemRefs.current.length - 1;
  const menuItemIndex = findIndex(menuItemRefs.current, (tab) => tab.current === document.activeElement);
  let newMenuItemIndex = -1;
  switch (e.key) {
    case 'End':
      newMenuItemIndex = maxIndex;
      break;
    case 'Home':
      newMenuItemIndex = 0;
      break;
    case 'ArrowUp':
      newMenuItemIndex = menuItemIndex > 0 ? menuItemIndex - 1 : maxIndex;
      break;
    case 'ArrowDown':
      newMenuItemIndex = menuItemIndex < maxIndex ? menuItemIndex + 1 : 0;
      break;
    case ' ': // it's spacebar
    case 'Enter':
      e.preventDefault();
      activateMenuItem(newMenuItemIndex === -1 ? menuItemIndex : newMenuItemIndex);
      menuButtonRef.current?.focus();
      break;
    case 'Escape':
      setMenuOpen(false);
      menuButtonRef.current?.focus();
      e.stopPropagation(); // don't close entire DegreeProgrammeSelector
      break;
    default:
      // focus on option that starts with given character
      newMenuItemIndex = menuItemRefs.current?.findIndex((ref) => ref.current?.innerText[0].toLowerCase() === e.key.toLowerCase());
      break;
  }
  if (newMenuItemIndex >= 0) {
    e.preventDefault();
    menuItemRefs.current[newMenuItemIndex]?.current?.focus();
  }
};

const MobileTypeFilters = ({ groupedProgrammes, typeFilter, setTypeFilter }) => {
  const { t } = useTranslation();
  const { ref: menuRef, isComponentVisible: isTypeSelectorOpen, setIsComponentVisible: setIsTypeSelectorOpen } = useHideOnClickOutside(false, 'child');
  const menuItemRefs = useRef(groupedProgrammes.map(() => createRef()));
  const menuButtonRef = useRef();
  const [menuItemToFocusOnIndex, setMenuItemToFocusOnIndex] = useState(-1);

  // Since it takes a while for React to mount the type filter options,
  // we use refs to focus only once we know React is done with mounting
  useEffect(() => {
    if (menuItemToFocusOnIndex > -1 && isTypeSelectorOpen) {
      menuItemRefs.current[menuItemToFocusOnIndex]?.current?.focus();
      setMenuItemToFocusOnIndex(-1);
    }
  }, [menuItemToFocusOnIndex, isTypeSelectorOpen]);

  const onTypeSelectClicked = (selectedType) => {
    const prevTypeFilter = typeFilter;
    setTypeFilter(selectedType.label);
    setIsTypeSelectorOpen(false);

    if (prevTypeFilter !== selectedType.label) {
      trackEvent(
        eventCategories.dpSearch.SEARCH_FILTER,
        eventActions.TARGET_GROUP,
        getTranslationWithLocale(`degreeProgramStudies.filters.degreeProgramFilter.types.${selectedType.label}`, 'en')
      );
    }
  };

  const activateMenuItem = (menuItemIndex) => onTypeSelectClicked(groupedProgrammes[menuItemIndex]);
  const handleMenuOnKeyDown = (e) => menuKeyboardNavigation(e, menuItemRefs, menuButtonRef, activateMenuItem, setIsTypeSelectorOpen);
  const handleMenuButtonOnKeyDown = (e) =>
    menuButtonKeyboardNavigation(e, setIsTypeSelectorOpen, menuItemRefs.current.length - 1, setMenuItemToFocusOnIndex);

  const menuButtonId = 'mobileTypeFiltersMenuButton';
  const menuId = 'mobileTypeFiltersMenu';

  return (
    <div className={styles.mobileTypeFilters}>
      <button
        type="button"
        id={menuButtonId}
        aria-haspopup="true"
        aria-controls={menuId}
        onClick={() => setIsTypeSelectorOpen(!isTypeSelectorOpen)}
        className={`${styles.typeDropdown} ${isTypeSelectorOpen ? 'icon--caret-up' : 'icon--caret-down'}`}
        onKeyDown={handleMenuButtonOnKeyDown}
        ref={menuButtonRef}
      >
        {t(`degreeProgramStudies.filters.degreeProgramFilter.types.${typeFilter}`)}
      </button>
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
      <div
        id={menuId}
        role="menu"
        aria-labelledby={menuButtonId}
        className={`${styles.menu} ${!isTypeSelectorOpen ? styles.closed : ''}`}
        onKeyDown={handleMenuOnKeyDown}
        ref={menuRef}
      >
        {groupedProgrammes.map((go, idx) => {
          const isSelected = typeFilter === go.label;
          return (
            <button
              type="button"
              className={`${styles.typeFilter} ${isSelected ? styles.selected : ''}`}
              key={go.label}
              role="menuitem"
              tabIndex="-1"
              ref={menuItemRefs.current[idx]}
              onClick={() => onTypeSelectClicked(go)}
              onBlur={() => setIsTypeSelectorOpen(false)}
              onFocus={() => setIsTypeSelectorOpen(true)}
            >
              <span className={styles.typeFilterLabel}>{t(`degreeProgramStudies.filters.degreeProgramFilter.types.${go.label}`)}</span>
              <span> ({go.options.length})</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

MobileTypeFilters.propTypes = {
  groupedProgrammes: arrayOf(dpOptionGroupType).isRequired,
  typeFilter: string,
  setTypeFilter: func
};

export default MobileTypeFilters;
