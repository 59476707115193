import React from 'react';
import { oneOfType } from 'prop-types';
import truncate from 'truncate-html';
import {
  coursesSearchResultType,
  degreeProgramResultType,
  multiSearchResultType
} from '../../../../types';
import { getTranslation } from '../../../../i18n';

import styles from './resultItem.css';
import {
  formatCourseName,
  formatCredits,
  formatDate,
  formatDateTimeSpan,
  getCourseParticipationType,
  getSummaryText,
  parseCUCodes
} from '../../../../utils';
import { HIGHLIGHT_START_TAG } from '../../../../constants';
import TooltipButton from '../../../TooltipButton';
import { eventCategories } from '../../../../services/analytics';
import Link from '../../../Link';
import useTranslation from '../../../../hooks/useTranslation';

const ResultItem = ({
  item, primaryDegreeProgram
}) => {
  const { t, lang } = useTranslation();

  const getDegreePrograms = () => {
    if (item.type === 'news' && item.degreePrograms?.length) {
      let first = item.degreePrograms[0];
      if (primaryDegreeProgram) {
        first = item.degreePrograms.find((dp) => dp.code === primaryDegreeProgram.code) || item.degreePrograms[0];
      }
      const rest = item.degreePrograms.filter((dp) => dp.code !== first.code);
      return (
        <span className={styles.newsDegreePrograms}>
          {getTranslation(first.name, lang)}
          {rest?.length ? (
            <>
              {' '}
              {t('and')}
              {' '}
              <TooltipButton
                className={styles.newsDegreePrograms}
                label={t('multiSearch.moreDegreePrograms', { count: rest.length })}
              >
                {rest.map((dp) => getTranslation(dp.name, lang)).join(', ')}
              </TooltipButton>
            </>
          ) : null}
        </span>
      );
    }
    return null;
  };

  const getTypeHeaderContent = () => {
    const typeName = t(`multiSearch.resultType.${item.type}`);
    switch (item.type) {
      case 'courses': {
        const participationType = getCourseParticipationType(item.curType, item.aiType, lang);
        const dateSpan = formatDateTimeSpan(item.activityPeriod.startDate, item.activityPeriod.endDate, lang, { includeTime: false });
        return [typeName, participationType, dateSpan].filter((s) => s).join(' | ');
      }
      case 'news': {
        const degreePrograms = getDegreePrograms();
        return (
          <>
            {typeName} | {formatDate(item.created, lang)}
            {degreePrograms ? (
              <>| {degreePrograms}</>
            ) : null}
          </>
        );
      }
      case 'themes':
      case 'articles': {
        const targetGroupLabel = (item.targetGroup) ? t(`multiSearch.targetGroups.${item.targetGroup}`) : null;
        const targetGroups = item.targetGroup && Array.isArray(item.targetGroup)
          ? item.targetGroup.map((tg) => t(`multiSearch.targetGroups.${tg}`)).join(' | ')
          : targetGroupLabel;
        return (
          <>
            {typeName}
            {item.targetGroup && (
              <>
                {targetGroups?.length ? ' | ' : null}
                {targetGroups}
              </>
            )}
          </>
        );
      }
      default: return null;
    }
  };

  const renderSnippet = (text) => {
    if (!text) {
      return null;
    }
    const segments = text.split(HIGHLIGHT_START_TAG).reduce((prev, val, index) => {
      if (index % 2 === 1) {
        prev.push(<b key={[index, val].join('-')}>{ val }</b>);
      } else {
        prev.push(val);
      }

      return prev;
    }, []);

    return <>{segments}</>;
  };

  const renderCodes = (cur) => {
    const codes = parseCUCodes(cur);
    return `${codes.codes} ${codes.extraCount ? t('search.multipleCUCodes', { count: codes.extraCount }) : ''}`;
  };

  const getLink = () => {
    if (item.type === 'courses') {
      const linkText = renderSnippet(formatCourseName(item.curId, item.name, item.nameSpecifier, lang));
      return (
        <Link href={item.coursePageUrl} useRouter tracked eventCategory={eventCategories.multiSearch.SEARCH_RESULT} trackingLabel="courses">
          {linkText}
        </Link>
      );
    }
    return (
      <Link href={getTranslation(item.url, lang)} useRouter={item.type === 'news'} tracked eventCategory={eventCategories.multiSearch.SEARCH_RESULT} trackingLabel="news">
        {renderSnippet(getTranslation(item.title, lang), false)}
      </Link>
    );
  };

  const getContentSnippet = () => {
    let snippet;
    switch (item.type) {
      case 'courses': {
        const parts = [];
        parts.push(renderCodes(item));
        parts.push(formatCredits(item.credits, lang));
        parts.push(item.teacherNames.length && item.teacherNames.join(', '));
        parts.push(item.teachingLanguages.length && `${t(item.teachingLanguages.length === 1 ? 'teachingLanguage' : 'teachingLanguages')}: ${item.teachingLanguages.map((tl) => getTranslation(tl, lang)).join((', '))}`);
        parts.push(getSummaryText(item, lang));
        snippet = parts.filter(Boolean).join(' | ');
        break;
      }
      case 'news':
      case 'articles':
      case 'themes':
        snippet = truncate(getTranslation(item.body, lang), 200, { stripTags: true });
        break;
      default: return null;
    }
    return renderSnippet(snippet);
  };

  return (
    <div className={styles.resultItem}>
      <div className={styles.type}>{getTypeHeaderContent()}</div>
      <div className={styles.link}>
        {getLink()}
      </div>
      <div>
        {getContentSnippet()}
      </div>
    </div>
  );
};

ResultItem.propTypes = {
  item: oneOfType([multiSearchResultType, coursesSearchResultType]).isRequired,
  primaryDegreeProgram: degreeProgramResultType
};

export default ResultItem;
