import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';
import {
  bool, func, node, oneOf, string, elementType
} from 'prop-types';
import { eventActions, trackEvent } from '../../services/analytics';
import { ICON_POSITIONS } from '../../constants';
import { ArrowOffsite, ArrowRight } from '../Icon/Arrow';
import styles from './link.css';
import useTranslation from '../../hooks/useTranslation';

const ABSOLUTE_URL_RE = /^(?:[a-z]+:)?\/\//i;

/**
 * Creates a link with following rules controlled by passed properties
 * - default: Anchor that opens in same window
 * - external = true: Anchor that opens in new window with appended informational content for screen reader.
 * - useRouter = true: Uses React router Link component for applications routes
 *
 * If aria-label is provided for external link, screen reader extra informational text is appended to it,
 * otherwise it is appended in link body as element visible only to screen reader.
 *
 * Renders external link icon by default for external links. This behaviour can be disabled by defining iconPosition="none"
 * in which case wrapped content should in most cases provide its own icon or some other
 * visual indication of opening in new window.
 *
 * @param {Object} properties
 * @param {string!} properties.href - Link target
 * @param {node!} properties.children - Content to wrap in the link
 * @param {string} properties.className - Optional classes for link
 * @param {boolean} properties.useRouter - Use react router link, only to be used with routes react router recognizes
 * @param {boolean} properties.external - Link is external, link opens in new tab and aria label is amended with opens in new tab notification
 * @param {boolean} properties.tracked - Use Google analytics tracking for link
 * @param {boolean} properties.fromSearchResult - Add state to router link to indicate that user navigated from search result
 * @param {string} properties.eventCategory - MTM event category
 * @param {string} properties.eventAction - MTM event action
 * @param {string} properties.trackingLabel - MTM event label, defaults to link target (href)
 * @param {boolean} properties.noTrackingLabel - Don't add event label
 * @param {string} properties.iconPosition - Position of link icon
 * @param {string} properties.iconClassName - Optional classes for link icon
 * @param {node} properties.iconOverride - Optional icon to use instead of default for link type
 * @param {function} properties.onClick - Optional onClick and onAuxClick handler for link
 * @param {...any} properties.props - Any optional properties
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Link = ({
  children, className, href, useRouter,
  external, tracked, fromSearchResult, eventCategory, eventAction = eventActions.CLICK,
  trackingLabel, noTrackingLabel, iconPosition, iconClassName,
  iconOverride, onClick, ...props
}) => {
  const { t } = useTranslation();

  const { 'aria-label': ariaLabel, 'aria-labelledby': ariaLabelledBy, ...remainingProps } = props;

  let finalIconPosition = iconPosition;
  if (!iconPosition) {
    finalIconPosition = external ? ICON_POSITIONS.IN_TEXT : ICON_POSITIONS.NONE;
  }
  const iconClassNames = finalIconPosition !== ICON_POSITIONS.NONE
    ? classNames(styles.iconContainer, iconClassName, {
      [styles.inText]: finalIconPosition === ICON_POSITIONS.IN_TEXT,
      [styles.after]: finalIconPosition === ICON_POSITIONS.AFTER
    }) : null;
  const Icon = () => {
    if (finalIconPosition === ICON_POSITIONS.NONE) {
      return null;
    }

    if (iconOverride) {
      const Override = iconOverride;
      return <Override className={iconClassNames} />;
    }

    return external
      ? <ArrowOffsite className={iconClassNames} />
      : <ArrowRight className={iconClassNames} />;
  };

  const LinkContent = () => (
    <>
      {finalIconPosition === ICON_POSITIONS.BEFORE && <Icon />}
      {/* role "text" is not valid role according to WAI-ARIA definitions (https://www.w3.org/TR/wai-aria/#role_definitions)
          but is used here to circumvent VoiceOver reading links "funnily", see https://axesslab.com/text-splitting/ */}
      {/* eslint-disable-next-line jsx-a11y/aria-role */}
      <span role="text">
        {children}
        {finalIconPosition === ICON_POSITIONS.IN_TEXT && <Icon />}
        {external && !ariaLabel && <span className="sr-only">, {t('openInNewWindow')}</span>}
      </span>
      {finalIconPosition === ICON_POSITIONS.AFTER && <Icon />}
    </>
  );

  const linkClassNames = classNames(className, {
    'with-icon': finalIconPosition !== ICON_POSITIONS.NONE
  });

  const getLabel = () => {
    if (noTrackingLabel) {
      return undefined;
    }
    if (trackingLabel) {
      return trackingLabel;
    }
    return ABSOLUTE_URL_RE.test(href) ? href : `${window.location.origin}${href}`;
  };

  const onNavigate = (e) => {
    if (tracked) {
      trackEvent(eventCategory, eventAction, getLabel());
    }
    if (onClick) {
      onClick(e);
    }
  };

  if (useRouter) {
    let to = href;
    if (fromSearchResult) {
      const url = new URL(href, window.location.origin);
      to = {
        pathname: url.pathname,
        search: url.search,
        hash: url.hash,
        ...(fromSearchResult && { state: { fromSearchResult } })
      };
    }
    return (
      <RouterLink
        className={linkClassNames}
        to={to}
        onClick={onNavigate}
        onAuxClick={onNavigate}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...remainingProps}
      >
        <LinkContent />
      </RouterLink>
    );
  }

  return (
    <a
      className={linkClassNames}
      href={href}
      target={external ? '_blank' : undefined}
      rel={external ? 'noopener nofollow noreferrer' : undefined}
      aria-label={ariaLabel ? `${ariaLabel}${external ? `, ${t('openInNewWindow')}` : ''}` : undefined}
      aria-labelledby={ariaLabelledBy}
      onClick={onNavigate}
      onAuxClick={onNavigate}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...remainingProps}
    >
      <LinkContent />
    </a>
  );
};

Link.propTypes = {
  href: string.isRequired,
  className: string,
  useRouter: bool,
  external: bool,
  tracked: bool,
  fromSearchResult: bool,
  eventCategory: string,
  eventAction: string,
  trackingLabel: string,
  noTrackingLabel: bool,
  iconPosition: oneOf(Object.values(ICON_POSITIONS)),
  iconClassName: string,
  iconOverride: elementType,
  onClick: func,
  'aria-label': string,
  'aria-labelledby': string,
  children: node.isRequired
};

export default Link;
