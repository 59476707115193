import { useHistory, useLocation } from 'react-router-dom';
import useLocationSearch from './useLocationSearch';
import { getCurriculumPeriodId } from '../utils';

const useStudyYearPicker = (id, curriculumPeriods, appendCode = false) => {
  const cpId = useLocationSearch().get('cpId');
  const history = useHistory();
  const location = useLocation();
  if (!id) {
    return {};
  }
  const selectedYear = cpId
    || curriculumPeriods?.find((period) => period.objId === id && period.id === getCurriculumPeriodId())?.id
    || curriculumPeriods?.findLast((period) => period.objId === id)?.id
    || curriculumPeriods?.at(-1)?.id
    || '';

  const changeYear = (curriculumPeriodId) => {
    const nextVersion = curriculumPeriods.find((option) => option?.id === curriculumPeriodId);
    const newUrl = `${location.pathname
      .split('/')
      .slice(0, 3)
      .concat(appendCode ? [nextVersion.objId, nextVersion.objCode] : [nextVersion.objId])
      .join('/')
    }?cpId=${curriculumPeriodId}`;
    history.push(newUrl);
  };

  return { selectedYear, changeYear };
};

export default useStudyYearPicker;
