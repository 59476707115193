import { useEffect } from 'react';
import {
  string
} from 'prop-types';

import { login } from '../../../api';

const ShibbolethLogin = ({ referer }) => {
  useEffect(() => login(referer));

  return null;
};

ShibbolethLogin.propTypes = {
  referer: string
};

export default ShibbolethLogin;
