import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';

import { getLocalTestUsers, localLogin } from '../../../api';
import styles from './local-login.css';
import useTranslation from '../../../hooks/useTranslation';

const renderUserButton = (user, referer) => (
  <div key={user.name} className={styles.container}>
    <ul className={styles.userList}>
      <li>
        <button
          className={`button ${styles.selectUserButton}`}
          type="button"
          onClick={() => localLogin(user.username, user.password, referer)}
        >
          {user.name}
        </button>
      </li>
    </ul>
  </div>
);

const LocalLogin = ({ referer }) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      const localTestUsers = await getLocalTestUsers();

      setUsers(localTestUsers);
    };

    getUsers();
  }, []);

  return (
    <div>
      <h1 className={styles.title}>{t('select-user')}</h1>
      { users && users.map((u) => renderUserButton(u, referer)) }
    </div>
  );
};

LocalLogin.propTypes = {
  referer: string
};

export default LocalLogin;
