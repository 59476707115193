import React from 'react';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { string, func, arrayOf } from 'prop-types';
import useHideOnClickOutside from '../../../../hooks/useHideOnClickOutside';
import styles from './mobileTypeFilters.css';
import { option } from '../../../../types';
import useTranslation from '../../../../hooks/useTranslation';

const MobileTypeFilters = ({
  types, selectedType, setSelectedType, resultListId
}) => {
  const { t } = useTranslation();
  const { ref, isComponentVisible, setIsComponentVisible } = useHideOnClickOutside(false);
  const selected = types?.find((type) => type.value === selectedType);
  const listId = uniqueId('types');

  return (
    <fieldset className={styles.mobileTypeFilter}>
      <div className={styles.dropdownButtonContainer}>
        <button
          type="button"
          className={classNames(styles.dropdownButton, 'icon--caret-down')}
          onClick={() => setIsComponentVisible(!isComponentVisible)}
          aria-haspopup="menu"
          aria-controls={listId}
          aria-expanded={isComponentVisible}
          aria-owns={listId}
        >
          {t('show')}: {selected?.label}
        </button>
      </div>
      <div ref={ref} id={listId} className={classNames(styles.checkboxColumn, { hidden: !isComponentVisible })}>
        {types?.map(({ value, label }) => {
          const isSelected = selectedType === value;
          return (
            <div key={value} className={styles.columnButtonContainer}>
              <label htmlFor={`radio-${value}`}>
                <input
                  id={`radio-${value}`}
                  name="radio-search-type"
                  type="radio"
                  value={value}
                  onChange={() => {
                    setSelectedType(value);
                    setIsComponentVisible(false);
                  }}
                  checked={isSelected}
                  aria-label={label}
                  aria-controls={resultListId}
                />
                <span className={styles.columnButtonToggle}>
                  {label}
                  {isSelected && <span className="icon--done" />}
                </span>
              </label>
            </div>
          );
        })}
      </div>
    </fieldset>
  );
};

MobileTypeFilters.propTypes = {
  types: arrayOf(option),
  selectedType: string,
  setSelectedType: func,
  resultListId: string
};

export default MobileTypeFilters;
