import React, {
  useEffect, useMemo, useRef
} from 'react';
import {
  arrayOf, bool, element, func, number, oneOfType, shape, string
} from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import ResultItem from '../../SearchResults/ResultItem';
import SearchPagination, { searchTypes } from '../../SearchPagination';
import {
  coursesSearchResultList, option
} from '../../../types';
import { setUrnValuesToFixedOrder } from '../../../utils';
import { STUDY_LEVEL_URN_ORDER } from '../../../constants';
import styles from './dpSearchResults.css';
import FormattedNumber from '../../FormattedNumber';
import ErrorBoundary from '../../ErrorBoundary';
import Filter from '../../Filter';
import useTranslation from '../../../hooks/useTranslation';

const ALL_STUDYLEVELS = 'all';

const getStudyLevelGroupId = (studyLevel) => `${studyLevel}Group`;

const AggregationButton = ({
  isSelected, onClick, label, role, ariaControls, ariaSelected
}) => (
  <div className={`${styles.aggregation} ${isSelected ? styles.current : ''}`} role={role} aria-controls={ariaControls} aria-selected={ariaSelected}>
    {isSelected}<button type="button" className={styles.aggregationFilter} onClick={onClick}>{label}</button>
  </div>
);

AggregationButton.propTypes = {
  isSelected: bool,
  onClick: func,
  label: oneOfType([string, element]),
  role: string,
  ariaControls: string,
  ariaSelected: bool
};

const ResultHeader = ({
  aggregations, studyLevelTranslations, selectedStudyLevel, setSelectedStudyLevel
}) => {
  const { t } = useTranslation();

  if (aggregations) {
    return (
      <div className={styles.aggregations} role="tablist">
        <AggregationButton
          role="tab"
          isSelected={!selectedStudyLevel}
          onClick={() => setSelectedStudyLevel()}
          label={t('degreeProgramStudies.searchResult-aggregation-all')}
          ariaControls={getStudyLevelGroupId(ALL_STUDYLEVELS)}
          ariaSelected={!selectedStudyLevel}
        />
        { aggregations.map(
          (agg) => (
            <AggregationButton
              key={agg.value}
              isSelected={agg.value === selectedStudyLevel}
              onClick={() => setSelectedStudyLevel(agg.value)}
              label={studyLevelTranslations[agg.value]}
              role="tab"
              ariaControls={getStudyLevelGroupId(agg.value)}
              ariaSelected={agg.value === selectedStudyLevel}
            />
          ))}
        <div className={styles.aggregation} />
      </div>
    );
  }
  return null;
};

ResultHeader.propTypes = {
  setSelectedStudyLevel: func.isRequired,
  selectedStudyLevel: string,
  aggregations: arrayOf(shape({
    value: string,
    docCount: number
  })),
  studyLevelTranslations: shape()
};

const ListWithTitle = ({
  results, studyLevelTranslations, selectedStudyLevel, sortOptions, sort, setSort
}) => {
  const { t, lang } = useTranslation();
  const { state, search } = useLocation();
  const history = useHistory();

  const clearScrollPos = () => {
    history.replace({
      search,
      state: {}
    });
  };

  const setScrollPosToState = () => {
    history.replace({
      search,
      state: {
        scrollY: window.scrollY // so that we know where to scroll when coming back
      }
    });
  };

  const scrollY = state && state.scrollY;

  useEffect(() => {
    if (results && scrollY >= 0) {
      window.scroll(window.scrollX, scrollY);
      clearScrollPos();
    }
  });

  const handleChangeSort = (values) => {
    setSort(values?.[0]);
  };

  return (
    <div id={getStudyLevelGroupId(selectedStudyLevel || ALL_STUDYLEVELS)}>
      <div className={styles.topBar}>
        <h3 className={styles.searchResultSubTitle} role="region" aria-live="polite" aria-atomic="true">
          <>
            {(selectedStudyLevel
              ? studyLevelTranslations[selectedStudyLevel]
              : t('degreeProgramStudies.allResultsTitle'))} <span aria-hidden>(</span>
            <FormattedNumber value={results.totalHits} lang={lang} /><span aria-hidden>)</span>
          </>
        </h3>
        <div className={styles.sortContainer}>
          <Filter
            className={styles.sortFilter}
            filterKey="sortFilter"
            setSelectedItems={handleChangeSort}
            items={sortOptions}
            selectedItems={[sort]}
            isMulti={false}
            isClearable={false}
            legendToLeft
          />
        </div>
      </div>
      {results.hits.map((result) => (
        <ResultItem key={result.id} result={result} setScrollPosToState={setScrollPosToState} isDegreeProgramSearch />
      ))}
    </div>
  );
};

ListWithTitle.propTypes = {
  results: coursesSearchResultList,
  studyLevelTranslations: shape(),
  selectedStudyLevel: string,
  sortOptions: arrayOf(option),
  sort: string,
  setSort: func
};

const SearchResults = ({
  results, studyLevels, degreeProgramme, selectedStudyLevel, setSelectedStudyLevel,
  searchOptions, setPageIndex, sortOptions, setSort
}) => {
  const { t } = useTranslation();
  const aggregations = useMemo(
    () => results?.aggregations || [],
    [results]);
  useEffect(() => {
    if (selectedStudyLevel && !aggregations.some((agg) => agg.value === selectedStudyLevel)) {
      setSelectedStudyLevel(undefined);
    }
  }, [selectedStudyLevel, aggregations, setSelectedStudyLevel]);

  const headingRef = useRef();

  if (!results || !results.hits) {
    return null;
  }

  const sortedAggregations = setUrnValuesToFixedOrder(STUDY_LEVEL_URN_ORDER, aggregations);
  const studyLevelTranslations = studyLevels.reduce((acc, studyLevelOption) => {
    acc[studyLevelOption.value] = studyLevelOption.label;
    return acc;
  }, {});

  const hasResult = results.hits.length;

  return (
    <ErrorBoundary>
      <div className={`${styles.container} grid-container`}>
        <h2 className={styles.dpName} tabIndex="-1" ref={headingRef}>{degreeProgramme}</h2>
        {hasResult ? (
          <>
            <ResultHeader
              setSelectedStudyLevel={setSelectedStudyLevel}
              selectedStudyLevel={selectedStudyLevel}
              aggregations={sortedAggregations}
              studyLevelTranslations={studyLevelTranslations}
              t={t}
            />
            <ListWithTitle
              results={results}
              studyLevelTranslations={studyLevelTranslations}
              selectedStudyLevel={selectedStudyLevel}
              sortOptions={sortOptions}
              sort={searchOptions.sort}
              setSort={setSort}
            />
            <SearchPagination
              setPageIndex={(...args) => {
                setPageIndex(...args);
                headingRef.current.focus();
              }}
              searchOptions={searchOptions}
              results={results}
              searchType={searchTypes.DEGREE_PROGRAMMES}
            />
          </>
        )
          : (
            <>
              <ResultHeader
                setSelectedStudyLevel={setSelectedStudyLevel}
                selectedStudyLevel={selectedStudyLevel}
                aggregations={sortedAggregations}
                studyLevelTranslations={studyLevelTranslations}
                t={t}
              />
              <p>{t('search.noResults')}</p>
            </>
          )}
      </div>
    </ErrorBoundary>
  );
};

SearchResults.propTypes = {
  results: coursesSearchResultList,
  setSelectedStudyLevel: func.isRequired,
  selectedStudyLevel: string,
  studyLevels: arrayOf(option),
  degreeProgramme: string.isRequired,
  searchOptions: shape({ page: number }),
  setPageIndex: func.isRequired,
  sortOptions: arrayOf(option),
  sort: string,
  setSort: func
};

export default SearchResults;
