import React from 'react';
import { string } from 'prop-types';
import { parseISO, isBefore, isAfter } from 'date-fns';

import { formatDateTimeSpan, formatDateTime } from '../../../../../../utils';
import { dateTimeRangeType } from '../../../../../../types';
import styles from './enrolmentPeriod.css';
import useTranslation from '../../../../../../hooks/useTranslation';

const EnrolmentPeriod = ({
  enrolmentPeriod, lateEnrolmentEnd
}) => {
  const { t, lang } = useTranslation();

  if (!enrolmentPeriod && !lateEnrolmentEnd) {
    return null;
  }

  const startDateTime = parseISO(enrolmentPeriod.startDateTime);
  const endDateTime = parseISO(enrolmentPeriod.endDateTime);
  const lateEndDateTime = parseISO(lateEnrolmentEnd);
  const formattedPeriod = formatDateTimeSpan(enrolmentPeriod.startDateTime, enrolmentPeriod.endDateTime, lang);
  const now = new Date();

  if (isAfter(now, lateEnrolmentEnd ? lateEndDateTime : endDateTime)) {
    return (
      <div className={styles.container}>
        <div className={styles.iconContainer}>
          <span className="icon--info-stroke" />
        </div>
        <div>
          <div className={styles.message}>
            {t('sisuEnrolment.enrolled.enrolmentPeriodEnded')}
          </div>
          <div>
            {t('enrolment.wasOpen', { period: formattedPeriod })}
          </div>
        </div>
      </div>
    );
  }

  let description = t('enrolment.isOpen', { period: formattedPeriod });
  if (isBefore(now, startDateTime)) {
    description = t('enrolment.willBeOpen', { period: formattedPeriod });
  }
  if (isAfter(now, endDateTime) && isBefore(now, lateEndDateTime)) {
    description = t('enrolment.lateEnrolmentOpen', { until: formatDateTime(lateEnrolmentEnd, lang) });
  }

  return (
    <div>
      <span>
        {description}
      </span>
    </div>
  );
};

EnrolmentPeriod.propTypes = {
  enrolmentPeriod: dateTimeRangeType,
  lateEnrolmentEnd: string
};

export default EnrolmentPeriod;
