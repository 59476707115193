import React from 'react';
import { arrayOf, shape } from 'prop-types';
import { Link } from 'react-router-dom';
import { getTranslation } from '../../../../i18n';
import Section from '../../../Section';
import styles from './courseUnits.css';
import { eventActions, eventCategories, trackEvent } from '../../../../services/analytics';
import { getCourseUnitUrlPath } from '../../util';
import useTranslation from '../../../../hooks/useTranslation';

const CourseUnits = ({ courseUnits }) => {
  const { t, lang } = useTranslation();
  return (
    <Section className={styles.courseUnitSection} level={2} label={t('courseUnitRealisation.courseUnitDescription.title')}>
      <p className={styles.courseUnitDescription}>{t('courseUnitRealisation.courseUnitDescription.description')}</p>
      <div className={styles.linkList}>
        {courseUnits.map((cu) => (
          <Link
            key={cu.id}
            className="old-button--action-before icon--arrow-left"
            to={getCourseUnitUrlPath(cu, lang)}
            onClick={() => trackEvent(eventCategories.coursePage.LINK, eventActions.CLICK, 'course unit bottom')}
          >
            {t('courseUnit.singular')} {cu.code} <span className={styles.cuName}>{getTranslation(cu.name, lang)}</span>
          </Link>
        ))}
      </div>
      <div className={styles.explanation}>
        <h3>{t('courseUnitRealisation.courseUnitDescription.explanationTitle')}</h3>
        <p>{t('courseUnitRealisation.courseUnitDescription.explanationText')}</p>
      </div>
    </Section>
  );
};

CourseUnits.propTypes = {
  courseUnits: arrayOf(shape()).isRequired
};

export default CourseUnits;
