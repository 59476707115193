import React from 'react';
import { bool } from 'prop-types';

import styles from './depthIndicator.css';
import useTranslation from '../../hooks/useTranslation';

const DepthIndicator = ({
  dp, sm, cu, cur
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.depthIndicator} aria-hidden>
      <span className={dp ? styles.active : undefined}>{t('degreeProgram.degreeProgram')}</span>{' → '}
      <span className={sm ? styles.active : undefined}>{t('studyModule.studyModule')}</span>{' → '}
      <span className={cu ? styles.active : undefined}>{t('courseUnit.courseUnit')}</span>{' → '}
      <span className={cur ? styles.active : undefined}>{t('courseUnitRealisation.courseUnitRealisation')}</span>
    </div>
  );
};

DepthIndicator.defaultProps = {
  dp: false,
  sm: false,
  cu: false,
  cur: false
};

DepthIndicator.propTypes = {
  dp: bool,
  sm: bool,
  cu: bool,
  cur: bool
};

export default DepthIndicator;
