import React, { useEffect, useState } from 'react';

import getDisruptionBulletins from '../../services/tike';
import styles from './tikeBulletins.css';
import HyphenatedText from '../HyphenatedText';
import NewsItem from '../NewsItem';
import { eventCategories } from '../../services/analytics';
import useTranslation from '../../hooks/useTranslation';

const fetchBulletins = async (setBulletins) => {
  const bulletins = await getDisruptionBulletins();
  if (bulletins) {
    setBulletins(bulletins);
  }
};

const TikeBulletins = () => {
  const { t, lang } = useTranslation();
  const [bulletins, setBulletins] = useState([]);

  useEffect(() => {
    fetchBulletins(setBulletins);
  }, []);

  return bulletins.length
    ? (
      <section className={styles.container}>
        <h2 className={styles.header}>
          <HyphenatedText text={t('tikeBulletins.header')} lang={lang} />
        </h2>
        <div className={[styles.bulletinsContainer, 'grid-row', 'stacked-mobile-only'].join(' ')}>
          {bulletins.map((bulletin) => (
            <NewsItem
              item={bulletin}
              typeLabel="tikeBulletins.itemTypeLabel"
              key={bulletin.guid}
              externalLink
              trackedEventCategory={eventCategories.frontpage.CURRENT_TECHNICAL}
              showIngress
            />
          ))}
        </div>
      </section>
    )
    : null;
};

export default TikeBulletins;
