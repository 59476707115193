import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { eventCategories } from '../../../../services/analytics';
import styles from './links.css';
import Link from '../../../Link';
import useTranslation from '../../../../hooks/useTranslation';

const CourseFeedbackLink = ({ feedbackUrl }) => {
  const { t } = useTranslation();
  if (!feedbackUrl) {
    return null;
  }

  return (
    <div className={styles.linksContainer}>
      <h2>{t('courseFeedbackLink.title')}</h2>
      <Link
        href={feedbackUrl}
        className={classNames('button--action-before', styles.link, styles.linkButton)}
        iconPosition="before"
        external
        tracked
        eventCategory={eventCategories.coursePage.LINK}
        trackingLabel="courseFeedback"
      >
        {t('courseFeedbackLink.linkText')}
      </Link>
    </div>
  );
};

CourseFeedbackLink.propTypes = {
  feedbackUrl: string
};

export default CourseFeedbackLink;
