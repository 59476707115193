import React, { useState, useEffect, useRef } from 'react';
import {
  bool, func, string, number
} from 'prop-types';
import { isEmpty } from 'lodash';
import { coursesSearch } from '../../api';
import SearchResults from '../SearchResults';
import SearchPagination, { searchTypes } from '../SearchPagination';
import { formStateType } from '../../types';
import ErrorBoundary from '../ErrorBoundary';
import useIsSlowLoad from '../../hooks/useIsSlowLoad';
import useScrollRestoration from '../../hooks/useScrollRestoration';
import useTranslation from '../../hooks/useTranslation';

const LoadingStatusMessage = ({ hasResults, isLoading, page }) => {
  const { t } = useTranslation();
  if (isLoading) return t('search.loadingResults');
  if (hasResults) return t('search.pageTitle', { page });
  return null;
};

LoadingStatusMessage.propTypes = {
  hasResults: bool,
  isLoading: bool,
  page: number
};

const SearchResultLoader = ({
  setPageIndex, optionValues, lang
}) => {
  const headingRef = useRef();
  const [results, setResults] = useState(null);
  const [resultsLoading, setResultsLoading] = useState(false);

  // signal loading state to user only if its too slow
  const isSlowLoad = useIsSlowLoad(resultsLoading, { thresholdMs: 400 });

  const {
    searchText,
    studyYear,
    teachingLanguageUrn,
    studyLevel,
    type,
    period,
    organisation,
    page,
    customCodeUrns,
    showExams,
    intensivePeriod
  } = optionValues;

  useEffect(() => {
    let isMounted = true;
    const search = async () => {
      if (!isEmpty(optionValues)) { // Form is initializing for first useEffect trigger
        setResultsLoading(true);
        try {
          const res = await coursesSearch({
            searchText,
            studyYear,
            teachingLanguageUrn,
            studyLevel,
            type,
            period,
            organisation,
            page,
            customCodeUrns,
            lang,
            showExams,
            intensivePeriod
          });
          if (isMounted) {
            setResults(res);
          }
        } finally {
          if (isMounted) {
            setResultsLoading(false);
          }
        }
      }
    };
    search();
    return () => {
      isMounted = false;
    };
  }, [
    lang,
    searchText,
    studyYear,
    teachingLanguageUrn,
    studyLevel,
    type,
    period,
    organisation,
    page,
    customCodeUrns,
    optionValues,
    showExams,
    intensivePeriod
  ]);

  // scroll user back to the position prior to navigation
  useScrollRestoration(results && !resultsLoading);

  if (results) {
    return (
      <ErrorBoundary headerLevel={2}>
        <div className="sr-only" role="region" aria-live="assertive">
          <LoadingStatusMessage hasResults={!!results} isLoading={resultsLoading && isSlowLoad} page={(optionValues.page || 0) + 1} />
        </div>
        <SearchResults results={results} lang={lang} ref={headingRef} />
        <SearchPagination
          setPageIndex={setPageIndex}
          searchOptions={optionValues}
          results={results}
          searchType={searchTypes.COURSES}
        />
      </ErrorBoundary>
    );
  }
  return null;
};

SearchResultLoader.propTypes = {
  setPageIndex: func.isRequired,
  optionValues: formStateType.isRequired,
  lang: string.isRequired
};

export default SearchResultLoader;
