import React from 'react';
import {
  courseCmsNoDataReasonTypes,
  courseUnitRealisationType
} from '../../../types';
import {
  routes, AVAILABLE_LANGUAGES, FLOW_STATES
} from '../../../constants';
import DocumentHead from '../../DocumentHead';
import CourseHeader from './CourseHeader';
import CoursePromotion from './CoursePromotion';
import Message from '../../Message';
import CourseUnits from './CourseUnits';
import CourseImage from './CourseImage';
import Links from './Links';
import ImportantNote from './ImportantNote';
import styles from './courseUnitRealisation.css';
import { isDesktop, useDeviceSize } from '../../../context/DeviceSizeContextProvider';
import CourseMaterials from './CourseMaterials';
import CourseResults from './CourseResults';
import CourseCompletion from './CourseCompletion';
import OptionalInformation from './OptionalInformation';
import AdditionalInfo from './CourseAdditionalInfo';
import CourseSchedule from './CourseSchedule';
import OpenUniversityProductMainEnrol from './OpenUniversityProductMainEnrol';
import Literature from '../Literature';
import StudyFormat from '../StudyFormat';
import LearningMaterials from '../LearningMaterials';
import RegistrationInfo from './RegistrationInfo';
import Messages from './Messages';
import CourseAdministration from './CourseAdministration';
import { formatCourseName, formatSemiOpenDateSpan, isPublished } from '../../../utils';
import useTranslation from '../../../hooks/useTranslation';

const DesktopLayout = ({ courseUnitRealisation }) => {
  const { cmsData, courseFeedbackUrl, contactInfo } = courseUnitRealisation;
  return (
    <div className="grid-row">
      <section className="grid-column desktop-size-9 main-content">
        <CoursePromotion courseUnitRealisation={courseUnitRealisation} />
        <ImportantNote cmsData={cmsData} />
        <Messages messages={cmsData?.messages} />
        <CourseSchedule courseUnitRealisation={courseUnitRealisation} />
        <OpenUniversityProductMainEnrol courseUnitRealisation={courseUnitRealisation} />
        <CourseCompletion cmsData={cmsData} />
        <CourseMaterials courseUnitRealisation={courseUnitRealisation} />
        <OptionalInformation cmsData={cmsData} />
        <StudyFormat courseUnitRealisation={courseUnitRealisation} />
        <Literature headerLevel={2} courseUnitRealisation={courseUnitRealisation} />
        <LearningMaterials headerLevel={2} courseUnitRealisation={courseUnitRealisation} />
        <RegistrationInfo courseUnitRealisation={courseUnitRealisation} />
        <CourseResults courseUnitRealisation={courseUnitRealisation} />
        <AdditionalInfo courseUnitRealisation={courseUnitRealisation} />
        <CourseUnits courseUnits={courseUnitRealisation.courseUnits} />
      </section>
      <aside className="grid-column desktop-size-3">
        <CourseImage cmsData={cmsData} />
        <Links cmsData={cmsData} courseFeedbackUrl={courseFeedbackUrl} contactInfo={contactInfo} />
      </aside>
    </div>
  );
};

DesktopLayout.propTypes = {
  courseUnitRealisation: courseUnitRealisationType
};

const MobileLayout = ({ courseUnitRealisation }) => {
  const { cmsData, contactInfo, courseFeedbackUrl } = courseUnitRealisation;
  return (
    <>
      <ImportantNote cmsData={cmsData} />
      <Messages messages={cmsData?.messages} />
      <CoursePromotion courseUnitRealisation={courseUnitRealisation} />
      <Links cmsData={cmsData} courseFeedbackUrl={courseFeedbackUrl} contactInfo={contactInfo} />
      <CourseSchedule courseUnitRealisation={courseUnitRealisation} />
      <CourseCompletion cmsData={cmsData} />
      <CourseMaterials courseUnitRealisation={courseUnitRealisation} />
      <OptionalInformation cmsData={cmsData} />
      <StudyFormat courseUnitRealisation={courseUnitRealisation} />
      <Literature headerLevel={2} courseUnitRealisation={courseUnitRealisation} />
      <LearningMaterials headerLevel={2} courseUnitRealisation={courseUnitRealisation} />
      <RegistrationInfo courseUnitRealisation={courseUnitRealisation} />
      <CourseResults courseUnitRealisation={courseUnitRealisation} />
      <CourseImage cmsData={cmsData} />
      <AdditionalInfo courseUnitRealisation={courseUnitRealisation} />
      <CourseUnits courseUnits={courseUnitRealisation.courseUnits} />
    </>
  );
};

MobileLayout.propTypes = {
  courseUnitRealisation: courseUnitRealisationType
};

const getCmsInfoMessage = (cmsData, t) => (!cmsData || (cmsData?.noContent?.reason === courseCmsNoDataReasonTypes.ERROR)
  ? (<Message status="info">{t('courseUnitRealisation.cmsError')}</Message>)
  : null);

const getNotPublishedInfoMessage = (cur, t) => {
  if (isPublished(cur)) {
    return null;
  }

  if (cur.flowState === FLOW_STATES.CANCELLED) {
    return <Message status="info">{t('courseCanceled')}</Message>;
  }

  return cur.canEditCourse
    ? <Message status="info">{t('courseNotPublishedMessage.editor')}</Message>
    : <Message status="info">{t('courseNotPublishedMessage.default')}</Message>;
};

const CourseUnitRealisation = ({ courseUnitRealisation }) => {
  const { t, lang } = useTranslation();
  const { deviceSize } = useDeviceSize();

  if (!courseUnitRealisation) {
    return null;
  }
  const {
    id, name, nameSpecifier, canEditCourse, activityPeriod, cmsData, courseUnits
  } = courseUnitRealisation;

  if (!isPublished(courseUnitRealisation) && !canEditCourse) {
    return getNotPublishedInfoMessage(courseUnitRealisation, t);
  }

  const courseUnitRealisationTitle = [
    courseUnits?.[0]?.code,
    formatCourseName(id, name, nameSpecifier, lang),
    activityPeriod && formatSemiOpenDateSpan(activityPeriod.startDate, activityPeriod.endDate, lang)
  ].filter(Boolean).join(' ');
  const localizedUrls = AVAILABLE_LANGUAGES.map((l) => [l, routes.cur(l, id)]);

  return (
    <>
      <DocumentHead urls={localizedUrls} title={courseUnitRealisationTitle} />
      {getNotPublishedInfoMessage(courseUnitRealisation, t)}
      {getCmsInfoMessage(cmsData, t)}
      <CourseHeader courseUnitRealisation={courseUnitRealisation} />

      <div className={`grid-container page-wide-content-padding ${styles.content}`}>
        <CourseAdministration courseUnitRealisation={courseUnitRealisation} />

        {isDesktop(deviceSize)
          ? <DesktopLayout courseUnitRealisation={courseUnitRealisation} />
          : <MobileLayout courseUnitRealisation={courseUnitRealisation} />}
      </div>
    </>
  );
};

CourseUnitRealisation.propTypes = {
  courseUnitRealisation: courseUnitRealisationType
};

export default CourseUnitRealisation;
