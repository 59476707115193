import React, { useCallback, useState } from 'react';
import { arrayOf } from 'prop-types';
import { Calendar as ReactCalendar, dateFnsLocalizer, Views } from 'react-big-calendar';

import format from 'date-fns/format';
import parse from 'date-fns/parseISO';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import endOfHour from 'date-fns/endOfHour';
import startOfHour from 'date-fns/startOfHour';
import setHours from 'date-fns/setHours';

import { isMobile, useDeviceSize } from '../../../context/DeviceSizeContextProvider';
import useUserPreferences from '../../../hooks/useUserPreferences';

import { LOCALE as locales, formatTimeSpan, formatDateTimeSpan } from '../../../utils';
import { userPreferenceKeyValues } from '../../../constants';
import { calendarEventSourceTypes, calendarEventType } from '../../../types';

import Toolbar from './Toolbar';
import AgendaView from './AgendaView';
import WeekView from './WeekView';
import Event from './Event';
import MonthEvent from './MonthEvent';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import styles from './calendar.css';
import useTranslation from '../../../hooks/useTranslation';

const LOCALIZER = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
});

const COMPONENTS = {
  event: Event,
  toolbar: Toolbar,
  month: {
    event: MonthEvent
  }
};

const VIEWS = {
  agenda: AgendaView,
  day: true,
  week: WeekView,
  month: true
};

const getFormats = (translate) => ({
  dayFormat: 'd.M. iiiiii',
  monthHeaderFormat: 'LLLL / yyyy',
  // eslint-disable-next-line no-unused-vars
  dayRangeHeaderFormat: ({ start, end }, culture, localizer) => `${translate('calendar.week')} ${localizer.format(start, 'I / yyyy')}`,
  dayHeaderFormat: 'd.M.yyyy iiiiii',
  agendaDateRangeFormat: ({ start, end }, culture) => formatDateTimeSpan(start, end, culture, { includeTime: false }),
  agendaTimeRangeFormat: ({ start, end }, culture) => formatTimeSpan(start, end, culture),
  eventTimeRangeFormat: ({ start, end }, culture) => formatTimeSpan(start, end, culture)
});

const getMessages = (translate) => ({
  showMore: (count) => `+${count} ${translate('calendar.more')}`
});

const getDefaultView = (deviceSize, viewFromPreference) => {
  if (viewFromPreference && Object.keys(VIEWS).includes(viewFromPreference)) {
    return viewFromPreference;
  }
  return isMobile(deviceSize) ? Views.AGENDA : Views.WEEK;
};

const tooltipAccessor = (event) => [
  event.title,
  event.source === calendarEventSourceTypes.COURSE_PAGE && event.courseTitle,
  ...((event.locations && event.locations.map((location) => location.roomName)) || []),
  event.optimeExtrasAsString
].filter(Boolean).join(', ');

const EXAM_EVENT_TYPE = 'EXAM';
const EXAM_EVENT_CLASSNAME = 'examEvent';

const eventClassNames = (event) => [
  event.type === EXAM_EVENT_TYPE ? EXAM_EVENT_CLASSNAME : undefined
].filter(Boolean).join(' ');

const eventPropGetter = (event) => ({
  className: eventClassNames(event),
  style: {
    border: 'none'
  }
});

// Isolate re-render prone component from component doing data fetching to reduce unneeded fetches for event data
const WrappedReactCalendar = ({ events }) => {
  const { t, lang } = useTranslation();
  const { deviceSize } = useDeviceSize();
  const { getUserPreference, setUserPreference } = useUserPreferences();

  const [currentView, setCurrentView] = useState(getDefaultView(deviceSize, getUserPreference(userPreferenceKeyValues.SELECTED_CALENDAR_TAB)));

  const changeView = useCallback((view) => {
    setCurrentView(view);
    setUserPreference(userPreferenceKeyValues.SELECTED_CALENDAR_TAB, view);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      <ReactCalendar
        localizer={LOCALIZER}
        culture={lang}
        events={events}
        defaultView={getDefaultView(deviceSize, getUserPreference(userPreferenceKeyValues.SELECTED_CALENDAR_TAB))}
        formats={getFormats(t)}
        messages={getMessages(t)}
        components={COMPONENTS}
        views={VIEWS}
        /* Don't set calendar height for agenda view to keep layout sane */
        onView={changeView}
        style={currentView !== Views.AGENDA ? { minHeight: 800 } : null}
        eventPropGetter={eventPropGetter}
        /* Constrain day and week view min/max time */
        min={startOfHour(setHours(new Date(), 8))}
        max={endOfHour(setHours(new Date(), 19))}
        step={60}
        timeslots={1}
        length={1}
        tooltipAccessor={tooltipAccessor}
      />
    </div>
  );
};

WrappedReactCalendar.propTypes = {
  events: arrayOf(calendarEventType)
};

export default WrappedReactCalendar;
