import React from 'react';

import { isDesktop, useDeviceSize } from '../../context/DeviceSizeContextProvider';
import {
  AVAILABLE_LANGUAGES,
  routes
} from '../../constants';

import Calendar from './Calendar';
import TodoItems from './TodoItems';
import styles from './myStudies.css';
import CoursesExams from './CoursesExams';
import DocumentHead from '../DocumentHead';
import Breadcrumb from '../Breadcrumb';
import useSession from '../../hooks/useSession';
import Message from '../Message';
import ErrorBoundary from '../ErrorBoundary';
import useTranslation from '../../hooks/useTranslation';

// Section has been renamed to Calendar
const MyStudies = () => {
  const { t } = useTranslation();
  const session = useSession();
  const { deviceSize } = useDeviceSize();

  const localizedUrls = AVAILABLE_LANGUAGES.map((lang) => [lang, routes.myStudies(lang)]);

  if (!session?.hasSisuPersonId) {
    return (
      <>
        <DocumentHead urls={localizedUrls} />
        <Message status="info">{t('myStudies.accessDenied')}</Message>
      </>
    );
  }

  const Layout = () => (isDesktop(deviceSize) ? (
    <div className="grid-row">
      <section className="grid-column desktop-size-9 main-content">
        <Calendar />
      </section>
      <aside className="grid-column desktop-size-3">
        <TodoItems />
      </aside>
    </div>
  ) : (
    <>
      <Calendar />
      <TodoItems />
    </>
  ));

  return (
    <>
      <DocumentHead urls={localizedUrls} />
      <div className="grid-container page-wide-content-padding">
        <Breadcrumb />
        <h1 className={styles.header}>
          {t('myStudies.title')}
        </h1>
        <Layout />
        <CoursesExams />
      </div>
    </>
  );
};

export default () => (
  <ErrorBoundary>
    <MyStudies />
  </ErrorBoundary>
);
