import React from 'react';
import { string } from 'prop-types';
import { parseISO, isWithinInterval } from 'date-fns';
import { getTranslation } from '../../../../../../i18n';
import styles from './externalEnrolment.css';
import { dateTimeRangeType, localizedLinkType } from '../../../../../../types';
import { eventCategories } from '../../../../../../services/analytics';
import Link from '../../../../../Link';
import useTranslation from '../../../../../../hooks/useTranslation';

const ExternalEnrolment = ({
  externalEnrolmentLink, enrolmentPeriod, lateEnrolmentEnd
}) => {
  const { lang } = useTranslation();

  if (!externalEnrolmentLink?.url || !enrolmentPeriod) {
    // If realisation does not have enrolment period, it is not possible to enroll to the realisation
    return null;
  }

  const startDateTime = parseISO(enrolmentPeriod.startDateTime);
  const endDateTime = parseISO(enrolmentPeriod.endDateTime);
  const lateEndDateTime = parseISO(lateEnrolmentEnd);
  const now = new Date();

  if (!isWithinInterval(now, { start: startDateTime, end: lateEnrolmentEnd ? lateEndDateTime : endDateTime })) {
    return null;
  }

  return (
    <Link
      href={getTranslation(externalEnrolmentLink.url, lang)}
      className={`${styles.externalEnrolmentLink} button--action`}
      tracked
      eventCategory={eventCategories.coursePage.LINK}
      trackingLabel="enrollment"
      external
      iconPosition="after"
      iconClassName={styles.offsiteArrow}
    >
      {getTranslation(externalEnrolmentLink.label, lang)}
    </Link>
  );
};

ExternalEnrolment.propTypes = {
  externalEnrolmentLink: localizedLinkType,
  enrolmentPeriod: dateTimeRangeType,
  lateEnrolmentEnd: string
};

export default ExternalEnrolment;
