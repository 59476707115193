import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import CourseUnitRealisation from './CourseUnitRealisation';
import Message from '../Message';
import { getCourseUnitRealisation } from '../../api';
import useTranslation from '../../hooks/useTranslation';

const CourseUnitRealisationWrapper = ({ courseUnitRealisationId }) => {
  const { state: { fromSearchResult = false } = {} } = useLocation();
  const { t, lang } = useTranslation();
  const [courseUnitRealisation, setCourseUnitRealisation] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (courseUnitRealisationId && lang) {
        try {
          const response = await getCourseUnitRealisation(courseUnitRealisationId, lang, fromSearchResult);
          setCourseUnitRealisation(response);
        } catch (e) {
          setError(e);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [courseUnitRealisationId, lang, fromSearchResult]);

  if (loading) {
    return (
      <Message loading>
        {t('courseUnitRealisation.loading', { courseUnitRealisationId })}
      </Message>
    );
  }

  if (error) {
    const messageKey = error.status === 404 ? 'courseUnitRealisation.notFound' : 'common.error';
    const errorData = error.status === 404 ? {
      courseUnitRealisationId
    } : {
      status: error.status,
      message: error.statusText
    };

    return (
      <Message error>
        {t(messageKey, errorData)}
      </Message>
    );
  }

  return (
    <CourseUnitRealisation courseUnitRealisation={courseUnitRealisation} />
  );
};

CourseUnitRealisationWrapper.propTypes = {
  courseUnitRealisationId: PropTypes.string
};

export default CourseUnitRealisationWrapper;
