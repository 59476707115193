import React from 'react';

import CourseBrowser from './CourseBrowser';
import DocumentHead from '../DocumentHead';
import { routes, AVAILABLE_LANGUAGES, breadcrumbs } from '../../constants';
import Breadcrumb from '../Breadcrumb';
import ErrorBoundary from '../ErrorBoundary';
import useTranslation from '../../hooks/useTranslation';

const DegreeProgramStudies = () => {
  const { t } = useTranslation();

  const localizedUrls = AVAILABLE_LANGUAGES.map((lang) => [lang, routes.dps(lang)]);

  return (
    <div>
      <div className="grid-container">
        <DocumentHead urls={localizedUrls} />
        <Breadcrumb crumb={breadcrumbs.COURSES} />
        <h1>
          {t('degreeProgramStudies.title')}
        </h1>
        <CourseBrowser />
      </div>
    </div>
  );
};

export default () => (
  <ErrorBoundary>
    <DegreeProgramStudies />
  </ErrorBoundary>
);
