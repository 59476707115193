import React, { useEffect, useState } from 'react';
import { parseISO, addMinutes } from 'date-fns';
import classNames from 'classnames';
import { getEvents } from '../../api';
import styles from './studentEvents.css';
import {
  formatDate, formatDateTimeSpan, formatTimeSpan
} from '../../utils';
import { eventActions, eventCategories } from '../../services/analytics';
import HyphenatedText from '../HyphenatedText';
import ErrorBoundary from '../ErrorBoundary';
import Link from '../Link';
import useTranslation from '../../hooks/useTranslation';

const SHOW_ALL_LINK = {
  fi: 'https://helsinginyliopisto.etapahtuma.fi/kalenteri/suomi.aspx#.XZQ-lMRS-Uk',
  sv: 'https://helsinginyliopisto.etapahtuma.fi/kalenteri/svenska.aspx#.XZQ-ycRS-Uk',
  en: 'https://helsinginyliopisto.etapahtuma.fi/kalenteri/english.aspx#.XZQ-2MRS-Uk'
};

// Event date is displayed in a box where day/month and year are on separate lines, so format the date to reflect this
const EVENT_DATE_FORMAT = {
  fi: 'd.M. yyyy',
  sv: 'd.M. yyyy',
  en: 'd/M yyyy'
};

const formatEventTimeSpan = (startDate, endDate, duration, lang) => {
  const durationInMinutes = Math.ceil(60 * duration);
  const start = parseISO(startDate);
  const end = parseISO(endDate);

  return `${formatDateTimeSpan(start, end, lang, { includeTime: false, includeWeekday: true })} ${formatTimeSpan(start, addMinutes(start, durationInMinutes), lang)}`;
};

const fetchEvents = async (lang, setEvents, setEventsFetched) => {
  const events = await getEvents(lang);
  if (Array.isArray(events)) {
    setEvents(events);
  }
  setEventsFetched(true);
};

const StudentEvents = () => {
  const { t, lang } = useTranslation();

  const [events, setEvents] = useState([]);
  const [eventsFetched, setEventsFetched] = useState(false);

  useEffect(() => {
    fetchEvents(lang, setEvents, setEventsFetched);
  }, [lang]);

  return (
    <section className={styles.container}>
      <h2 className={styles.header}>{t('studentEvents.header')}</h2>
      <div className={styles.eventContainer}>
        {events?.length
          ? events.map((event) => (
            <div key={event.guid} className={styles.event}>
              <div className={styles.dateContainer}>
                <div className={styles.date}>
                  <span>{formatDate(event.date, lang, { dateFormats: EVENT_DATE_FORMAT })}</span>
                </div>
              </div>
              <div className={styles.infoContainer}>
                <Link
                  href={event.link}
                  className={styles.title}
                  iconClassName={styles.externalLinkIcon}
                  external
                  tracked
                  eventCategory={eventCategories.frontpage.EVENTS}
                >
                  <HyphenatedText text={event.title} lang={lang} className={styles.linkText} />
                </Link>
                <div className={styles.info}>
                  {formatEventTimeSpan(event.date, event.endDate, event.duration, lang)}
                </div>
                <div className={styles.info}>{event.location}</div>
              </div>
            </div>
          ))
          : eventsFetched && <div className={styles.noEvents}>{t('studentEvents.noEvents')}</div>}
      </div>
      <div className={styles.buttonContainer}>
        <Link
          href={SHOW_ALL_LINK[lang]}
          className={classNames('button', 'button--outline', styles.showAllButton)}
          external
          iconPosition="after"
          iconClassName={styles.showMoreExternalIcon}
          tracked
          eventCategory={eventCategories.frontpage.EVENTS}
          eventAction={eventActions.SHOW_ALL}
          noTrackingLabel
          aria-label={t('studentEvents.showAllLabel')}
        >
          {t('showAll')}
        </Link>
      </div>
    </section>
  );
};

export const StudentEventsLocalized = StudentEvents;

export default () => (
  <ErrorBoundary headerLevel={2}>
    <StudentEventsLocalized />
  </ErrorBoundary>
);
