import React, {
  useEffect, useState
} from 'react';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { isWithinInterval, parseISO } from 'date-fns';
import { courseUnitRealisationType } from '../../../../../../types';
import { getTranslation } from '../../../../../../i18n';
import {
  formatCredits,
  formatDateTimeSpan,
  getDate
} from '../../../../../../utils';
import { getEnrolmentEnd } from '../util';
import { isJSONResponse, postEnrolment } from '../../../../../../api';
import { requestStates } from '../../../../../../constants';

import styles from './assistedEnrolment.css';
import ModalDialog from '../../../../../ModalDialog';
import useTranslation from '../../../../../../hooks/useTranslation';

const getStudySubGroups = (studyGroupSet) => studyGroupSet
  .studySubGroups
  .filter((ssg) => !ssg.cancelled)
  .map((ssg) => {
    let earliestStart;
    let earliestEnd;
    ssg.studyEvents.forEach((se) => {
      se.events.forEach((e) => {
        if (!e.cancelled) {
          const startDate = getDate(e.start);
          const endDate = getDate(e.end);
          if (!earliestStart || !earliestEnd) {
            earliestStart = startDate;
            earliestEnd = endDate;
          }
          if (startDate < earliestStart) {
            earliestStart = startDate;
            earliestEnd = endDate;
          }
        }
      });
    });
    return {
      ssgId: ssg.id,
      name: ssg.name,
      start: earliestStart,
      end: earliestEnd
    };
  });

const AssistedEnrolment = ({
  courseUnitRealisation = {}
}) => {
  const { t, lang } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [requestState, setRequestState] = useState(requestStates.READY);
  const [error, setError] = useState('');

  const [primaryChoices, setPrimaryChoices] = useState([]);
  const [secondaryChoices, setSecondaryChoices] = useState([]);
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);

  const modalId = 'assisted-enrolment-dialog';

  // want to target only the close button in the modal
  useEffect(() => {
    if (modalOpen) {
      document.getElementById('modal-close-button')?.focus();
    }
  }, [modalOpen]);

  if (!courseUnitRealisation.enrolmentPeriod) {
    // If realisation does not have enrolment period, it is not possible to enroll to the realisation
    return null;
  }

  const {
    id, courseUnits, studyGroupSets, enrolmentPeriod, lateEnrolmentEnd
  } = courseUnitRealisation;

  const startDateTime = parseISO(enrolmentPeriod.startDateTime);
  const endDateTime = parseISO(enrolmentPeriod.endDateTime);
  const lateEndDateTime = parseISO(lateEnrolmentEnd);
  const now = new Date();
  if (!isWithinInterval(now, { start: startDateTime, end: lateEnrolmentEnd ? lateEndDateTime : endDateTime })) {
    return null;
  }

  const studyGroupSetsFiltered = studyGroupSets.filter((sgs) => sgs.subGroupRange && sgs.subGroupRange.max !== 0);

  const checkRequiredPrimaryChoicesFulfilled = () =>
    studyGroupSetsFiltered.every((sgs, index) => {
      if ((!primaryChoices[index] && !!sgs.subGroupRange.min)
        || primaryChoices[index]?.length < sgs.subGroupRange.min
        || primaryChoices[index]?.length > sgs.subGroupRange.max) {
        document.getElementById(`primaryStudyGroup-${index}`)?.focus();
        return false;
      }
      return true;
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitButtonPressed(true);
    if (checkRequiredPrimaryChoicesFulfilled()) {
      const submitButton = e.target.querySelector('button[type=submit]');
      if (submitButton) {
        submitButton.disabled = true;
      }
      try {
        setRequestState(requestStates.REQUEST_SENT);
        const enrollment = {
          id,
          primaryStudyGroups: primaryChoices,
          secondaryStudyGroups: secondaryChoices,
          lang,
          additionalInfo
        };
        await postEnrolment(enrollment);
        setRequestState(requestStates.SUCCESS);
        setModalOpen(false);
        document.getElementById('enrolment-sent')?.focus();
      } catch (err) {
        const errJson = isJSONResponse(err) && await err.json();
        if (errJson?.error) {
          setError(`${errJson.error.code} — ${errJson.error.message}`);
        } else if (err?.status && err?.statusText) {
          setError(`${err.status} — ${err.statusText}`);
        } else {
          setError(String(err));
        }
        setRequestState(requestStates.ERROR);
      } finally {
        if (submitButton) {
          submitButton.disabled = false;
        }
      }
    }
  };

  const onCancel = () => { setModalOpen(false); };

  const getConfirmLabel = () => (
    <span className={styles.goToSisu}>
      {t('sisuEnrolment.modal.sendEnrolment')}
      <span className={`${styles.sendEnrolmentButton} icon--arrow-right`} aria-hidden="true" />
    </span>
  );

  const langOrDefault = (name, language) => name[language] || name.fi || name.en || name.sv;

  const formatGroupName = (name, start, end) =>
    ((start && end)
      ? `${langOrDefault(name, lang)}, ${t('assistedEnrolment.startingAt')} ${formatDateTimeSpan(start, end, lang, { includeWeekday: true })}`
      : langOrDefault(name, lang));

  const checkPrimaryGroup = (e, studySubGroup, studyGroupSetIndex) => {
    const mutablePrimaryChoices = cloneDeep(primaryChoices);
    if (!e.target.checked) {
      mutablePrimaryChoices[studyGroupSetIndex] = mutablePrimaryChoices[studyGroupSetIndex]
        .filter((pc) => pc.ssgId !== e.target.value);
    } else {
      if (mutablePrimaryChoices[studyGroupSetIndex]) {
        mutablePrimaryChoices[studyGroupSetIndex].push(studySubGroup);
      } else {
        mutablePrimaryChoices[studyGroupSetIndex] = [studySubGroup];
      }
      if (secondaryChoices[studyGroupSetIndex]) {
        const mutableSecondaryChoices = cloneDeep(secondaryChoices);
        mutableSecondaryChoices[studyGroupSetIndex] = mutableSecondaryChoices[studyGroupSetIndex]
          .filter((sc) => sc.ssgId !== e.target.value);
        setSecondaryChoices(mutableSecondaryChoices);
      }
    }
    setPrimaryChoices(mutablePrimaryChoices);
  };

  const selectPrimaryGroup = (e, studySubGroups, studyGroupSetIndex) => {
    if (e.target.value === '') {
      const mutablePrimaryChoices = cloneDeep(primaryChoices);
      mutablePrimaryChoices[studyGroupSetIndex] = null;
      setPrimaryChoices(mutablePrimaryChoices);
    } else {
      const mutablePrimaryChoices = cloneDeep(primaryChoices);
      const selectedOption = studySubGroups.find((ssg) => ssg.ssgId === e.target.value);
      mutablePrimaryChoices[studyGroupSetIndex] = [selectedOption];
      setPrimaryChoices(mutablePrimaryChoices);

      if (secondaryChoices[studyGroupSetIndex]) {
        const mutableSecondaryChoices = cloneDeep(secondaryChoices);
        mutableSecondaryChoices[studyGroupSetIndex] = mutableSecondaryChoices[studyGroupSetIndex]
          .filter((sc) => sc.ssgId !== e.target.value);
        setSecondaryChoices(mutableSecondaryChoices);
      }
    }
  };

  const checkSecondaryGroup = (e, studySubGroup, studyGroupSetIndex) => {
    const mutableSecondaryChoices = cloneDeep(secondaryChoices);
    if (!e.target.checked) {
      mutableSecondaryChoices[studyGroupSetIndex] = mutableSecondaryChoices[studyGroupSetIndex]
        .filter((sc) => sc.ssgId !== e.target.value);
    } else if (mutableSecondaryChoices[studyGroupSetIndex]) {
      mutableSecondaryChoices[studyGroupSetIndex].push(studySubGroup);
    } else {
      mutableSecondaryChoices[studyGroupSetIndex] = [studySubGroup];
    }
    setSecondaryChoices(mutableSecondaryChoices);
  };

  const renderGroupChoices = (studyGroupSet, studyGroupSetIndex) => {
    const studySubGroups = getStudySubGroups(studyGroupSet);
    const key = `studyGroupSet-${studyGroupSet.localId}`;
    // no choice to be made, only 1 option and at least 1 choice is required:
    if (studySubGroups.length === 1 && studyGroupSet.subGroupRange.min) {
      const {
        name, start, end
      } = studySubGroups[0];
      if (!primaryChoices[studyGroupSetIndex]) {
        const mutablePrimaryChoices = cloneDeep(primaryChoices);
        mutablePrimaryChoices[studyGroupSetIndex] = [studySubGroups[0]];
        setPrimaryChoices(mutablePrimaryChoices);
      }
      return (
        <div key={key}>
          <p className={styles.enrolmentInfoItem}>{langOrDefault(studyGroupSet.name, lang)}</p>
          <p>
            {formatGroupName(name, start, end)}
            <span className={styles.alreadySelected}> {t('assistedEnrolment.mandatoryForAllEnrolled')}</span>
          </p>
        </div>
      );
    }
    const max = studyGroupSet.subGroupRange.max
      ? Math.min(studyGroupSet.subGroupRange.max, studySubGroups.length)
      : studyGroupSet.subGroupRange.max;
    // no choice, no options, do nothing:
    if (max === 0) {
      return null;
    }
    const min = studyGroupSet.subGroupRange.min
      ? Math.min(studyGroupSet.subGroupRange.min, studySubGroups.length)
      : studyGroupSet.subGroupRange.min;
    const isRequired = !!(min)
      && (!primaryChoices[studyGroupSetIndex]
      || (primaryChoices[studyGroupSetIndex]?.length < min));

    const minMaxRepresentation = min === max
      ? min
      : `${min}–${max || ''}`;
    const singleSelectLabelKey = min ? 'assistedEnrolment.selectOnePrimaryGroup' : 'assistedEnrolment.selectAtMostOnePrimaryGroup';
    const missingSelectionWarning = (min
      && (primaryChoices[studyGroupSetIndex]?.length === 0
        || (submitButtonPressed && !primaryChoices[studyGroupSetIndex])
      )) ? `${t('assistedEnrolment.missingSelection')}! ` : null;
    return (
      <div key={key}>
        {max === 1
          ? (
            <div className={styles.question}>
              <label
                className={styles.enrolmentInfoItem}
                htmlFor={`primaryStudyGroup-${studyGroupSetIndex}`}
              >
                {missingSelectionWarning}
                {langOrDefault(studyGroupSet.name, lang)}: {t(singleSelectLabelKey)}
              </label>
              <select
                id={`primaryStudyGroup-${studyGroupSetIndex}`}
                name={`primaryStudyGroup-${studyGroupSetIndex}`}
                value={primaryChoices[studyGroupSetIndex]?.[0]?.ssgId || ''}
                onChange={(e) => selectPrimaryGroup(e, studySubGroups, studyGroupSetIndex)}
                aria-required={isRequired}
              >
                <option value="">{t('assistedEnrolment.selectGroup')}</option>
                {studySubGroups.map(({
                  ssgId, name, start, end
                }) => (
                  <option key={`primaryStudyGroup-item-${ssgId}`} value={ssgId}>
                    {formatGroupName(name, start, end)}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <fieldset
              id={`primaryStudyGroup-${studyGroupSetIndex}`}
              className={styles.question}
              tabIndex={-1}
            >
              <legend className={styles.enrolmentInfoItem}>
                {missingSelectionWarning}
                {langOrDefault(studyGroupSet.name, lang)}: {t('assistedEnrolment.selectMultiplePrimaryGroups', { minMax: minMaxRepresentation })}
              </legend>
              <ul>
                {studySubGroups.map((studySubGroup) => {
                  const {
                    ssgId, name, start, end
                  } = studySubGroup;
                  const isChecked = !!(primaryChoices[studyGroupSetIndex]?.some((group) => group.ssgId === ssgId));
                  return (
                    <li key={`primaryStudyGroupChoice-${ssgId}`}>
                      <input
                        id={`primaryStudyGroupChoice-${ssgId}`}
                        name={`primaryStudyGroupChoice-${ssgId}`}
                        type="checkbox"
                        onChange={(e) => checkPrimaryGroup(e, studySubGroup, studyGroupSetIndex)}
                        value={ssgId}
                        checked={isChecked}
                        aria-required={isRequired}
                      />
                      <label htmlFor={`primaryStudyGroupChoice-${ssgId}`}>
                        {formatGroupName(name, start, end)}
                      </label>
                    </li>
                  );
                })}
              </ul>
              {primaryChoices[studyGroupSetIndex]?.length > studyGroupSet.subGroupRange.max && (
                <div aria-live="assertive">
                  {t('assistedEnrolment.tooManyPrimaryGroupsSelected', { max: studyGroupSet.subGroupRange.max })}
                </div>
              )}
            </fieldset>
          )}
        <fieldset className={styles.question}>
          <legend className={styles.enrolmentInfoItem}>
            {t('assistedEnrolment.selectOtherSuitableStudyGroups')}
          </legend>
          <ul>
            {studySubGroups.map((studySubGroup) => {
              const {
                ssgId, name, start, end
              } = studySubGroup;
              const isDisabled = primaryChoices[studyGroupSetIndex]?.some((pc) => pc.ssgId === ssgId);
              const isChecked = !!secondaryChoices[studyGroupSetIndex]?.find((ssg) => ssg.ssgId === ssgId);
              return (
                <li key={`secondaryChoice-${ssgId}`}>
                  <input
                    id={`secondaryChoice-${ssgId}`}
                    name={`secondaryChoice-${ssgId}`}
                    type="checkbox"
                    onChange={(e) => checkSecondaryGroup(e, studySubGroup, studyGroupSetIndex)}
                    value={ssgId}
                    checked={isChecked}
                    disabled={isDisabled}
                  />
                  <label htmlFor={`secondaryChoice-${ssgId}`}>
                    {formatGroupName(name, start, end)}
                    {isDisabled
                      && <span className={styles.alreadySelected}>{t('assistedEnrolment.alreadySelectedAsPrimary')}</span>}
                  </label>
                </li>
              );
            })}
          </ul>
        </fieldset>
      </div>
    );
  };

  return (
    <>
      <button
        type="button"
        className={classNames('button--action', styles.goToEnrolment)}
        onClick={() => setModalOpen(true)}
        aria-haspopup="dialog"
        aria-controls={modalId}
        aria-expanded={modalOpen}
      >
        {t('sisuEnrolment.goToEnrolment')}
      </button>
      {modalOpen ? (
        <ModalDialog
          id={modalId}
          onConfirm={handleSubmit}
          onCancel={onCancel}
          confirmLabel={getConfirmLabel()}
          cancelLabel={t('sisuEnrolment.modal.return')}
          buttonsClassName={styles.dialogButton}
          reverseButtons
          modalDialogTitle={t('sisuEnrolment.modal.title')}
          submitButton
          isEnrolment
        >
          {t('sisuEnrolment.modal.courseUnit')}
          <ul>
            {courseUnits.map((courseUnit) => (
              <li key={courseUnit.id}>
                <span className={styles.boldText}>{courseUnit.code} {getTranslation(courseUnit.name, lang)}{courseUnit.credits ? `, ${formatCredits(courseUnit.credits, lang)}` : ''}</span>
              </li>
            ))}
          </ul>
          {getEnrolmentEnd(courseUnitRealisation, lang, t)}

          {studyGroupSetsFiltered.map(renderGroupChoices)}

          <div className={styles.question}>
            <label className={styles.enrolmentInfoItem} htmlFor="additionalInfo">
              {t('assistedEnrolment.additionalInfo')}
            </label>
            <textarea
              id="additionalInfo"
              name="additionalInfo"
              onChange={(e) => setAdditionalInfo(e.target.value)}
              value={additionalInfo}
            />
          </div>
          {requestState === requestStates.REQUEST_SENT && (
            <div aria-live="polite">
              {t('assistedEnrolment.processingRequest')}
            </div>
          )}
          {requestState === requestStates.ERROR && (
            <div role="alert" className={styles.boldText}>
              {t('assistedEnrolment.errorMessage')}: <span lang="en">{error}</span>
            </div>
          )}
        </ModalDialog>
      ) : null}
      {requestState === requestStates.SUCCESS && (
        <div className={styles.enrolmentStatusContainer}>
          <div className={styles.enrolmentStatusIconContainer}>
            <span className="icon--square-checked" />
          </div>
          <div
            id="enrolment-sent"
            aria-live="assertive"
            className={styles.enrolmentStatus}
            tabIndex={-1}
          >
            {t('sisuEnrolment.enrolled.notConfirmed')}
          </div>
        </div>
      )}
    </>
  );
};

AssistedEnrolment.propTypes = {
  courseUnitRealisation: courseUnitRealisationType
};

export default AssistedEnrolment;
