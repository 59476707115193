import React, {
  useEffect, useState, useReducer
} from 'react';
import { func } from 'prop-types';
import { sub } from 'date-fns';

import bgImage from './background.jpg';
import searchOptionsReducer, { CLEAR } from './searchOptionsReducer';
import styles from './searchForm.css';

import Filter from '../../Filter';

import { formStateType } from '../../../types';
import {
  eventActions, eventCategories, trackCoursesSearch, trackEvent
} from '../../../services/analytics';
import { findFilterOptions, optionTypes, coursesSearchParams } from '../../../api';
import {
  setUrnValuesToFixedOrder,
  formatLocalizedOption,
  asArray,
  DEFAULT_STUDY_YEAR,
  formatDateTimeSpan
} from '../../../utils';
import { ASSESSMENT_ITEM_TYPE_EXAM_URN, STUDY_LEVEL_URN_ORDER } from '../../../constants';
import useTranslation from '../../../hooks/useTranslation';

let language = null;

const {
  COURSE_TYPE,
  TEACHING_LANGUAGE,
  PERIOD,
  STUDY_YEAR,
  ORGANISATION,
  STUDY_LEVEL,
  TARGET_GROUP,
  SEARCH_TEXT,
  SHOW_EXAMS,
  INTENSIVE_PERIOD
} = coursesSearchParams;

const localizedOption = (o) => formatLocalizedOption(o, language);

const prepareOrganisationOptions = (orgOptions) => {
  const localized = orgOptions.map(localizedOption);
  return localized.sort((a, b) => a.label.localeCompare(b.label));
};

const prepareIntensivePeriodOptions = (intensivePeriods) =>
  intensivePeriods.map(({ value, dateRange: { startDate, endDate } }) => ({
    name: Object.fromEntries(
      ['fi', 'sv', 'en'].map((lang) => [
        lang,
        formatDateTimeSpan(
          startDate,
          sub(new Date(endDate), { days: 1 }),
          lang,
          { includeTime: false }
        )
      ])),
    value
  }));

const initializeFilterOptionsAndRemoveInvalidSelections = async (
  setTeachingLanguageOptions,
  setTypeOptions,
  setOrganisationOptions,
  setStudyLevelOptions,
  setStudyYearOptions,
  setTargetGroupOptions,
  setPeriodOptions,
  setIntensivePeriodOptions,
  studyYear,
  formState,
  changeValue
) => {
  const {
    type: typeOptions = [],
    teachingLanguageUrn: teachingLanguageOptions = [],
    studyLevel: studyLevelOptions = [],
    organisation: organisationOptions = [],
    studyYear: studyYearOptions = [],
    period: periodOptions = [],
    intensivePeriod: intensivePeriodOptions = []
  } = await findFilterOptions({ types: Object.values(optionTypes), studyYear }) || [];

  const removeInvalidSelection = (optionType, availableOptions) => {
    let selected = formState[optionType];
    if (selected) {
      if (!Array.isArray(selected)) {
        selected = [selected];
      }
      const options = availableOptions.map((o) => o.value);
      const newValues = selected.filter((value) => options.includes(value));

      if (newValues.length < selected.length) {
        if (!newValues.length) {
          changeValue(optionType)(null);
        } else if (newValues.length === 1) {
          changeValue(optionType)(newValues[0]);
        } else {
          changeValue(optionType)(newValues);
        }
      }
    }
  };

  const studyLevelsSorted =
    setUrnValuesToFixedOrder(STUDY_LEVEL_URN_ORDER, studyLevelOptions);
  const fixedTypes = [
    {
      name: {
        en: 'Contact teaching',
        fi: 'Lähiopetus',
        sv: 'Kontaktundervisning'
      },
      value: 'urn:code:custom:hy-university-root-id:teachingtesti:1lahi'
    },
    {
      name: {
        en: 'Distance teaching',
        fi: 'Etäopetus',
        sv: 'Distansundervisning'
      },
      value: 'urn:code:custom:hy-university-root-id:teachingtesti:2eta'
    },
    {
      name: {
        en: 'Blended teaching',
        fi: 'Monimuoto-opetus',
        sv: 'Flerformsundervisning'
      },
      value: 'urn:code:custom:hy-university-root-id:teachingtesti:3monimuoto'
    },
    {
      name: {
        en: 'Online teaching',
        fi: 'Verkko-opetus',
        sv: 'Nätundervisning'
      },
      value: 'urn:code:custom:hy-university-root-id:opintotarjonta:verkko_kurssi'
    },
    {
      name: {
        en: 'Open online course (MOOC)',
        fi: 'Avoin verkkokurssi (MOOC)',
        sv: 'Öppen webbkurs (Mooc)'
      },
      value: 'urn:code:custom:hy-university-root-id:opintotarjonta:mooc'
    }
  ];
  const newTypeOptions = [...fixedTypes, ...typeOptions].map(localizedOption);

  // Remove option with value "urn:code:assessment-item-type:teaching-participation" from newTypeOptions.
  // This option is not used in the UI and it is not possible to filter courses by this type.
  const index = newTypeOptions.findIndex((option) => option.value === 'urn:code:assessment-item-type:teaching-participation');
  if (index !== -1) {
    newTypeOptions.splice(index, 1);
  }
  // Switch order of "urn:code:assessment-item-type:exam" and "urn:code:assessment-item-type:independent-work"
  const examIndex = newTypeOptions.findIndex((option) => option.value === 'urn:code:assessment-item-type:exam');
  const independentWorkIndex = newTypeOptions.findIndex((option) => option.value === 'urn:code:assessment-item-type:independent-work');
  if (examIndex !== -1 && independentWorkIndex !== -1) {
    [newTypeOptions[examIndex], newTypeOptions[independentWorkIndex]] = [newTypeOptions[independentWorkIndex], newTypeOptions[examIndex]];
  }

  setTypeOptions(newTypeOptions);
  removeInvalidSelection(COURSE_TYPE, newTypeOptions);

  const newTeachingLanguageOptions = teachingLanguageOptions.map(localizedOption);
  setTeachingLanguageOptions(newTeachingLanguageOptions);
  removeInvalidSelection(TEACHING_LANGUAGE, newTeachingLanguageOptions);

  const newOrganisationOptions = prepareOrganisationOptions(organisationOptions);
  setOrganisationOptions(newOrganisationOptions);
  removeInvalidSelection(ORGANISATION, newOrganisationOptions);

  const newStudyLevelOptions = studyLevelsSorted.map(localizedOption);
  setStudyLevelOptions(newStudyLevelOptions);
  removeInvalidSelection(STUDY_LEVEL, newStudyLevelOptions);

  setStudyYearOptions(studyYearOptions);
  const fixedTargetGroups = [
    {
      name: {
        en: 'Courses open for all',
        fi: 'Kaikille avoimet opinnot',
        sv: 'Kurser öppna för alla'
      },
      value: 'hy-org-48645785'
    },
    {
      name: {
        en: 'Suitable for exchange students',
        fi: 'Vaihto-opiskelijoille sopivat opinnot',
        sv: 'Lämplig för utbytesstuderande'
      },
      value: 'urn:code:custom:hy-university-root-id:opintotarjonta:exchange_students'
    },
    {
      name: {
        en: 'Also suitable for upper secondary school students',
        fi: 'Soveltuu myös lukiolaiselle',
        sv: 'Lämplig för gymnasiestuderande'
      },
      value: 'urn:code:custom:hy-university-root-id:opintotarjonta:soveltuu_lukiolaiselle'
    },
    {
      name: {
        en: 'Introduction course',
        fi: 'Tutustumiskurssi',
        sv: 'Introduktionskurs'
      },
      value: 'urn:code:custom:hy-university-root-id:opintotarjonta:tutustumiskurssi_kurssi'
    }
  ];
  const newTargetGroupOptions = fixedTargetGroups.map(localizedOption);
  setTargetGroupOptions(newTargetGroupOptions);
  removeInvalidSelection(TARGET_GROUP, newTargetGroupOptions);
  if (periodOptions) {
    const newPeriodOptions = periodOptions.map(localizedOption);
    setPeriodOptions(newPeriodOptions);
    removeInvalidSelection(PERIOD, newPeriodOptions);
  }

  const newIntensivePeriodOptions =
    prepareIntensivePeriodOptions(intensivePeriodOptions)
      .map(localizedOption);
  setIntensivePeriodOptions(newIntensivePeriodOptions);
  removeInvalidSelection(INTENSIVE_PERIOD, newIntensivePeriodOptions);
};

const SearchForm = ({ onSearch, optionValues }) => {
  const { t, lang } = useTranslation();
  const [teachingLanguageOptions, setTeachingLanguageOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [studyLevelOptions, setStudyLevelOptions] = useState([]);
  const [organisationOptions, setOrganisationOptions] = useState([]);
  const [studyYearOptions, setStudyYearOptions] = useState([]);
  const [targetGroupOptions, setTargetGroupOptions] = useState([]);
  const [periodOptions, setPeriodOptions] = useState([]);
  const [intensivePeriodOptions, setIntensivePeriodOptions] = useState([]);

  language = lang || null;

  const [formState, dispatch] = useReducer(searchOptionsReducer, { [SEARCH_TEXT]: '', [STUDY_YEAR]: DEFAULT_STUDY_YEAR, ...optionValues });

  const changeValue = (type) => (newVal) => {
    dispatch({ type, value: newVal });
  };

  const studyYear = formState[STUDY_YEAR];

  useEffect(() => {
    initializeFilterOptionsAndRemoveInvalidSelections(
      setTeachingLanguageOptions,
      setTypeOptions,
      setOrganisationOptions,
      setStudyLevelOptions,
      setStudyYearOptions,
      setTargetGroupOptions,
      setPeriodOptions,
      setIntensivePeriodOptions,
      studyYear,
      formState,
      changeValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyYear]);

  const onButtonClick = (event) => {
    event.preventDefault();
    onSearch(formState);
    trackCoursesSearch(formState, {
      teachingLanguageOptions,
      studyYearOptions,
      periodOptions,
      organisationOptions,
      studyLevelOptions,
      typeOptions,
      intensivePeriodOptions
    });
  };

  const areSomeFiltersSet = () =>
    formState[SEARCH_TEXT]
    || formState[ORGANISATION]
    || formState[COURSE_TYPE]
    || formState[STUDY_LEVEL]
    || formState[TEACHING_LANGUAGE]
    || formState[TARGET_GROUP]
    || formState[PERIOD]
    || formState[SHOW_EXAMS];

  const clearFilters = () => {
    dispatch(CLEAR);
    trackEvent(eventCategories.courses.SEARCH_TYPE, eventActions.CLICK, 'Clear selection');
  };

  return (
    <div className={styles.fullWidth}>
      <div className={`${styles.imageContainer} grid-container full-width`} style={{ backgroundImage: `url(${bgImage})` }}>
        <div className="grid-container">
          <form className={styles.form}>
            <div className={`grid-row stacked ${styles.searchInput}`}>
              <label className={styles.label} htmlFor="studies-search-text">
                {t('search.fullTextLabel')}
                <input
                  id="studies-search-text"
                  value={formState[SEARCH_TEXT]}
                  type="text"
                  onChange={(event) => changeValue(SEARCH_TEXT)(event.target.value)}
                />
              </label>
            </div>
            <div className="grid-row stacked">
              <Filter
                filterKey="periodFilter"
                items={periodOptions}
                selectedItems={asArray(formState[PERIOD])}
                setSelectedItems={changeValue(PERIOD)}
                disabled={!(periodOptions && periodOptions.length)}
              />
              <Filter
                filterKey="typeFilter"
                items={typeOptions}
                selectedItems={asArray(formState[COURSE_TYPE])}
                setSelectedItems={changeValue(COURSE_TYPE)}
                disabled={!(typeOptions && typeOptions.length)}
              />
              <Filter
                filterKey="languageFilter"
                items={teachingLanguageOptions}
                selectedItems={asArray(formState[TEACHING_LANGUAGE])}
                setSelectedItems={changeValue(TEACHING_LANGUAGE)}
                disabled={!(teachingLanguageOptions && teachingLanguageOptions.length)}
              />
              <Filter
                filterKey="targetGroupFilter"
                items={targetGroupOptions}
                selectedItems={asArray(formState[TARGET_GROUP])}
                setSelectedItems={changeValue(TARGET_GROUP)}
                disabled={!(targetGroupOptions && targetGroupOptions.length)}
              />
            </div>
            <div className={`${styles.moreOptions} grid-row stacked`}>
              <Filter
                filterKey="studyYearFilter"
                items={studyYearOptions}
                selectedItems={asArray(formState[STUDY_YEAR])}
                setSelectedItems={changeValue(STUDY_YEAR)}
                disabled={!(studyYearOptions && studyYearOptions.length)}
              />
              <Filter
                filterKey="intensivePeriodFilter"
                items={intensivePeriodOptions}
                selectedItems={asArray(formState[INTENSIVE_PERIOD])}
                setSelectedItems={changeValue(INTENSIVE_PERIOD)}
                disabled={!(studyYearOptions && studyYearOptions.length)}
              />
              <Filter
                filterKey="organisationFilter"
                items={organisationOptions}
                selectedItems={asArray(formState[ORGANISATION])}
                setSelectedItems={changeValue(ORGANISATION)}
                disabled={!(organisationOptions && organisationOptions.length)}
              />
              <Filter
                filterKey="studyLevelFilter"
                items={studyLevelOptions}
                selectedItems={asArray(formState[STUDY_LEVEL])}
                setSelectedItems={changeValue(STUDY_LEVEL)}
                disabled={!(studyLevelOptions && studyLevelOptions.length)}
              />
            </div>
            <div className={styles.buttonContainer}>
              <div>
                <input
                  name="showExams"
                  id="showExams"
                  type="checkbox"
                  checked={formState[SHOW_EXAMS] || formState[COURSE_TYPE]?.includes(ASSESSMENT_ITEM_TYPE_EXAM_URN)}
                  disabled={formState[COURSE_TYPE]?.includes(ASSESSMENT_ITEM_TYPE_EXAM_URN)}
                  onChange={(event) => changeValue(SHOW_EXAMS)(event.target.checked)}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="showExams">
                  {t('filters.showExams')}
                </label>
              </div>
              <div className={styles.rightContainer}>
                {areSomeFiltersSet() && (
                <button
                  type="button"
                  className={`link ${styles.linkLikeButton}`}
                  onClick={() => clearFilters()}
                >
                  <span className={`${styles.linkIcon} icon--remove`} />
                  {t('search.clearFilters')}
                </button>
                )}
                <input
                  type="submit"
                  className={`button ${styles.button}`}
                  onClick={onButtonClick}
                  value={t('search.button')}
                />
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

SearchForm.propTypes = {
  onSearch: func.isRequired,
  optionValues: formStateType
};

export default SearchForm;
