import React, { useState } from 'react';
import {
  node, bool, string, number
} from 'prop-types';
import classNames from 'classnames';
import collapseStyles from '../collapse.css';
import tableStyles from './tableCollapse.css';

const getLabel = (collapsed, toggleLabel, expandLabel, collapseLabel) => {
  if (toggleLabel) {
    return toggleLabel;
  }

  return collapsed ? expandLabel : collapseLabel;
};

const TableCollapse = ({
  children, alwaysShow, toggleLabel, expandLabel, collapseLabel, collapsed, collapseArrow,
  upperCaseLabel, className, columnCount, head, buttonClassName
}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const tableClasses = classNames(className, tableStyles.fixedTable);

  const noContent = Array.isArray(children) && children.length === 0;

  const showCollapseArrow = collapseArrow && !noContent;

  const buttonClasses = classNames('button--outline', collapseStyles.collapseButton, {
    'button--action-before': showCollapseArrow,
    'icon--caret-down': isCollapsed && showCollapseArrow,
    'icon--caret-up': !isCollapsed && showCollapseArrow,
    [`${collapseStyles.upperCaseLabel}`]: upperCaseLabel
  }, buttonClassName);

  if (noContent) {
    return null;
  }

  if (alwaysShow && Array.isArray(children)) {
    return (
      <table className={tableClasses}>
        {head}
        <tbody aria-expanded={!isCollapsed}/* eslint-disable-line jsx-a11y/role-supports-aria-props */>
          {children.slice(0, alwaysShow)}
          {children.length > alwaysShow && (
            <>
              {!isCollapsed && children.slice(alwaysShow)}
              <tr>
                <td colSpan={columnCount}>
                  <button type="button" className={buttonClasses} onClick={() => setIsCollapsed(!isCollapsed)}>
                    {getLabel(isCollapsed, toggleLabel, expandLabel, collapseLabel)}
                  </button>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    );
  }

  return (
    <>
      <button type="button" className={buttonClasses} onClick={() => setIsCollapsed(!isCollapsed)}>
        {getLabel(isCollapsed, toggleLabel, expandLabel, collapseLabel)}
      </button>
      {!isCollapsed && (
        <table className={tableClasses}>
          {head}
          <tbody>
            {children}
          </tbody>
        </table>
      )}
    </>
  );
};

TableCollapse.propTypes = {
  children: node.isRequired,
  alwaysShow: number,
  toggleLabel: string,
  expandLabel: string,
  collapseLabel: string,
  collapsed: bool,
  collapseArrow: bool,
  upperCaseLabel: bool,
  className: string,
  columnCount: number,
  head: node,
  buttonClassName: string
};

TableCollapse.defaultProps = {
  collapseArrow: true
};

export default TableCollapse;
