import {
  addDays, format, isAfter, isBefore, isEqual, parseISO, startOfToday
} from 'date-fns';
import localeFi from 'date-fns/locale/fi';
import localeSv from 'date-fns/locale/sv';
import localeEn from 'date-fns/locale/en-GB';
import Hypher from 'hypher';
import hyphenationFi from 'hyphenation.fi';
import hyphenationSv from 'hyphenation.sv';
import hyphenationEn from 'hyphenation.en-gb';
import isSameYear from 'date-fns/isSameYear';
import isSameDay from 'date-fns/isSameDay';
import apostropheSanitizeHtml from 'sanitize-html';
import { merge } from 'lodash';
import {
  FLOW_STATES,
  HIGHLIGHT_START_TAG,
  IMAGE_SIZES,
  COURSE_PAGE_API_PATH,
  minorStudyRightAcceptanceTypes,
  MOOC,
  studyRightSelectionTypes,
  responsibilityRoleUrns
} from './constants';
import { IS_PROD } from './env';
import { getTranslation, getTranslationWithLocale } from './i18n';

const defaultOrganisationBackgroundImage = require('./images/background-1.jpg');

/* eslint-disable no-multi-spaces */
const robotsRegExp = new RegExp([
  /bot/, /crawl/, /spider/,                                              // General terms
  /APIs-Google/, /Mediapartners-Google/, /AdsBot-(?:Mobile-)?-Google/,   // Google bots
  /Googlebot-?(?:Image|News|Video)?/, /AdsBot-Google-Mobile-Apps/,
  /FeedFetcher-Google/, /Google-Read-Aloud/, /DublexWeb-Google/,
  /googleweblight/, /Storebot-Google/, /Google Favicon/,
  /bing/, /yandex/, /baidu/, /duckduck/, /yahoo/,                        // Other engines
  /ecosia/, /ia_archiver/,
  /facebook/, /instagram/, /pinterest/, /reddit/,                        // Social media and messengers
  /slack/, /twitter/, /whatsapp/, /youtube/,
  /semrush/                                                              // Other
].map((re) => re.source).join('|'), 'i');
/* eslint-enable no-multi-spaces */

/**
 * Date time formats for language based locales
 *
 * @typedef {Object} DateTimeFormats - Date and/or time formats for locales
 * @property {string} fi - Format for finnish
 * @property {string} sv - Format for swedish
 * @property {string} en - Format for english
 */

/**
 * Localized text
 *
 * @typedef {Object} LocalizedText - Localized text
 * @property {string} fi - Text in Finnish
 * @property {string} sv - Text in Swedish
 * @property {string} en - Text in English
 */

/**
 * @type {DateTimeFormats}
 */
export const DATE_FORMAT = {
  fi: 'd.M.yyyy',
  sv: 'd.M.yyyy',
  en: 'd/M/yyyy'
};

/**
 * @type {DateTimeFormats}
 */
export const DATE_FORMAT_WEEKDAY = {
  fi: 'iiiiii d.M.yyyy',
  sv: 'iiiiii d.M.yyyy',
  en: 'iiiiii d/M/yyyy'
};

/**
 * @type {DateTimeFormats}
 */
export const DATE_TIME_FORMAT = {
  fi: 'd.M.yyyy H.mm',
  sv: 'd.M.yyyy H.mm',
  en: 'd/M/yyyy h:mm a'
};

/**
 * @type {DateTimeFormats}
 */
export const DATE_TIME_FORMAT_WEEKDAY = {
  fi: 'iiiiii d.M.yyyy H.mm',
  sv: 'iiiiii d.M.yyyy H.mm',
  en: 'iiiiii d/M/yyyy h:mm a'
};

/**
 * @type {DateTimeFormats}
 */
export const TIME_FORMAT = {
  fi: 'H.mm',
  sv: 'H.mm',
  en: 'h:mm a'
};

export const LOCALE = {
  fi: localeFi,
  sv: localeSv,
  en: localeEn
};

/**
 * Parses and returns ISO format date time string as date object or if passed date object returns as is
 *
 * @param {string|Date} date - input date, either ISO date time string or date object
 * @returns {Date} Parsed date
 */
export const getDate = (date) => (typeof date === 'string' ? parseISO(date) : date);

const getDateTimeFormat = (lang, includeWeekday, includeTime) => {
  if (includeTime) {
    return (includeWeekday ? DATE_TIME_FORMAT_WEEKDAY : DATE_TIME_FORMAT)[lang];
  }
  return (includeWeekday ? DATE_FORMAT_WEEKDAY : DATE_FORMAT)[lang];
};

/**
 * Formats ISO 8601 date string or date objects for given language based locale
 *
 * @param {string|Date} date - Date in ISO 8601 format
 * @param {string} lang - Language code to use as locale
 * @param {Object} [options] - Optional extra parameters to change formatting
 * @param {boolean=false} [options.includeWeekday] - Whether to use default format that includes weekday
 * @param {DateTimeFormats} [options.dateFormats] - Optional localized date formats to use
 * @returns {string} Formatted date or empty string if date is falsy value
 */
export const formatDate = (date, lang, options) => {
  if (!date) {
    return '';
  }
  const dateFormat = options?.dateFormats
    ? options.dateFormats[lang]
    : getDateTimeFormat(lang, options?.includeWeekday, false);
  return format(getDate(date), dateFormat, { locale: LOCALE[lang] });
};

/**
 * Formats ISO 8601 date time string or date objects for given language based locale
 *
 * @param {string|Date} date - Date time in ISO 8601 format
 * @param {string} lang - Language code to use as locale
 * @param {Object} [options] Optional extra parameters to change formatting
 * @param {boolean=false} [options.includeWeekday] - Whether to use default format that includes weekday
 * @returns {string} Formatted date time or empty string if date is falsy value
 */
export const formatDateTime = (date, lang, options) => {
  if (!date) {
    return '';
  }
  const dateTimeFormat = getDateTimeFormat(lang, options?.includeWeekday, true);
  return format(getDate(date), dateTimeFormat, { locale: LOCALE[lang] });
};

/**
 * Formats ISO 8601 date time string or date object as time for given language based locale
 *
 * @param {string|Date} date - The date to format
 * @param {string} lang - Language code to use as locale
 * @returns {string} Formatted time string or empty string if date is falsy value
 */
export const formatTime = (date, lang) => {
  if (!date) {
    return '';
  }
  return format(getDate(date), TIME_FORMAT[lang], { locale: LOCALE[lang] });
};

/**
 * Formats ISO 8601 date time string or date objects as time span for given language based locale
 *
 * @param {string|Date} start - The start date time
 * @param {string|Date} end - The end date time
 * @param {string} lang - Language code to use as locale
 * @returns {string} Formatted time span string
 */
export const formatTimeSpan = (start, end, lang) =>
  `${formatTime(start, lang)}\u2013${formatTime(end, lang)}`;

/**
 * Date span formatting for semi open date ranges from sisu.
 * Formats ISO 8601 date strings or Date objects with exclusive end date as date span string for given language based locale.
 *
 * @param {string|Date} start - The date to start span with
 * @param {string|Date} end - The exclusive date to end span with
 * @param {string} lang - Language code to use as locale
 * @param {Object} [options] - Optional extra parameters to change formatting
 * @param {boolean=false} [options.includeYear] - Include start date year when start and end are at same year
 * @param {boolean=false} [options.includeWeekday] - Whether to use default format that includes weekday
 * @returns {string} Formatted date span string
 */
export const formatSemiOpenDateSpan = (start, end, lang, options) => {
  const {
    includeWeekday, includeYear
  } = merge({ includeWeekday: false, includeYear: false }, options);
  const formatOptions = { locale: LOCALE[lang] };

  const dateFormat = getDateTimeFormat(lang, includeWeekday, false);

  const startDate = start ? getDate(start) : null;
  const endDate = end ? addDays(getDate(end), -1) : null;
  if (!start && !end) {
    return '';
  }
  if (!start) {
    return `\u2013${format(endDate, dateFormat, formatOptions)}`;
  }
  if (!end) {
    return `${format(startDate, dateFormat, formatOptions)}\u2013`;
  }

  if (isSameDay(startDate, endDate)) {
    return format(startDate, dateFormat, formatOptions);
  }

  const startDateFormat = (!includeYear && isSameYear(startDate, endDate))
    ? dateFormat.replace(/\/?yyyy/, '') : dateFormat;
  return `${format(startDate, startDateFormat, formatOptions)}\u2013${format(endDate, dateFormat, formatOptions)}`;
};

/**
 * General use date time span formatting for closed date time ranges from sisu or other sources.
 * Formats ISO 8601 date time strings or Date objects end date as date span string for given language based locale.
 *
 * @param {string|Date} start - The date to start span with
 * @param {string|Date} end - The date to end span with
 * @param {string} lang - Language code to use as locale
 * @param {Object} [options] - Optional extra parameters to change formatting
 * @param {boolean=true} [options.includeTime] - Whether to use default format that includes time part
 * @param {boolean=false} [options.includeWeekday] - Whether to use default format that includes weekday
 * @param {boolean=false} [options.includeYear] - Include start date year when start and end are at same year and time part is not included in format
 * @returns {string} Formatted date time span string
 */
export const formatDateTimeSpan = (start, end, lang, options) => {
  const {
    includeTime, includeWeekday, includeYear
  } = merge({}, { includeTime: true, includeWeekday: false, includeYear: false }, options);
  const formatOptions = { locale: LOCALE[lang] };

  const startDate = getDate(start);
  const endDate = getDate(end);
  const sameDay = isSameDay(startDate, endDate);

  const dateTimeFormat = getDateTimeFormat(lang, includeWeekday, (includeTime && !sameDay));
  if (sameDay) {
    return includeTime
      ? `${format(startDate, dateTimeFormat, formatOptions)} ${formatTimeSpan(startDate, endDate, lang)}`
      : format(startDate, dateTimeFormat, formatOptions);
  }

  const startDateTimeFormat = (!includeYear && !includeTime && isSameYear(startDate, endDate))
    ? dateTimeFormat.replace(/\/?yyyy/, '') : dateTimeFormat;

  return `${format(startDate, startDateTimeFormat, formatOptions)}\u2013${format(endDate, dateTimeFormat, formatOptions)}`;
};

export const DEFAULT_STUDY_YEAR = (((d) => d.getFullYear() - (d.getMonth() < 7 ? 1 : 0))(new Date())).toString();

export const UTCOffsetTimeZoneString = (lang) => {
  const separator = lang === 'en' ? ':' : '.';
  const padding = lang === 'en' ? '00' : '';

  const offset = Number((new Date()).getTimezoneOffset());
  const absOffset = Math.abs(offset);

  let prefix = '-';
  if (offset < 0) {
    prefix = '+';
  }
  if (offset === 0) {
    prefix = '±';
  }

  const hourString = prefix + (padding + Math.floor(absOffset / 60)).slice(-2);
  const minuteString = absOffset % 60 === 0 && lang !== 'en' ? '' : separator + (padding + (absOffset % 60)).slice(-2);
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const timeZoneLocation = (timeZone && timeZone.includes('/')) ? timeZone.split('/')[1] : timeZone;
  return `UTC${hourString}${minuteString}${timeZoneLocation ? '/' : ''}${timeZoneLocation}`;
};

const HYPHENATOR = {
  fi: new Hypher(hyphenationFi),
  sv: new Hypher(hyphenationSv),
  en: new Hypher(hyphenationEn)
};

export const hyphenateText = (text, lang = 'fi') => {
  const isString = typeof text === 'string';
  const isHyphenateable = isString && lang in HYPHENATOR;
  if (isHyphenateable) {
    return HYPHENATOR[lang]?.hyphenateText(text);
  }
  if (isString) {
    return text;
  }
  return '';
};

export const formatLocalizedOption = (o, lang) => {
  const langOrder = [lang, 'fi', 'sv', 'en'];

  const localizedLabelKeys = langOrder.filter((l) => o.name[l]);

  return { label: o.name[localizedLabelKeys[0]], ...o };
};

// 786x524 rectangle with #107eab (blue) fill, used for common news items without image
const EMPTY_IMAGE_URI = 'data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iNzg2IiBoZWlnaHQ9IjUyNCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6IzEwN2VhYiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KIDxnPgogIDx0aXRsZT48L3RpdGxlPgogPC9nPgo8L3N2Zz4K';
export const withPlaceholderImage = (news) => (news ? { ...news, image: news.image || EMPTY_IMAGE_URI } : null);

export const setUrnValuesToFixedOrder = (orderOfUrnValues, urnValues = []) => urnValues
  .sort((a, b) => {
    if (orderOfUrnValues.indexOf(a.value) < 0) {
      return 1;
    }
    if (orderOfUrnValues.indexOf(b.value) < 0) {
      return -1;
    }
    return orderOfUrnValues.indexOf(a.value) - orderOfUrnValues.indexOf(b.value);
  });

export const partitionArray = (array, predicate) =>
  array.reduce(([a, b], elem) => (predicate(elem) ? [[...a, elem], b] : [a, [...b, elem]]), [[], []]);

export const isAfterOrEqual = (date, toCompare) => isAfter(date, toCompare) || isEqual(date, toCompare);
export const isBeforeOrEqual = (date, toCompare) => isBefore(date, toCompare) || isEqual(date, toCompare);

const guideLangCodeToPath = {
  fi: '/ohjeet',
  en: '/instructions',
  sv: '/instruktioner'
};

export const guideAllNewsLinkForLanguage = (lang) => `${guideLangCodeToPath[lang]}/news`;

// When expecting output to conform with Drupal's restricted_html:
// https://api.drupal.org/api/drupal/core%21profiles%21standard%21config%21install%21filter.format.restricted_html.yml/8.2.x
// Additionally b, i, sup, sub, and br are allowed to support html formatted fields from sisu.
const restrictedHtmlSettings = {
  allowedTags: [
    'a', 'b', 'i', 'em', 'strong', 'cite', 'blockquote', 'code', 'ul', 'ol', 'li', 'dl', 'dt', 'dd', 'h2', 'h3', 'h4', 'h5', 'h6', 'br', 'p', 'sup', 'sub', 'span'
  ],
  allowedAttributes: {
    a: ['href', 'hreflang', 'rel'],
    blockquote: ['cite'],
    ul: ['type'],
    ol: ['start', 'type'],
    h2: ['id'],
    h3: ['id'],
    h4: ['id'],
    h5: ['id'],
    h6: ['id'],
    p: ['id'],
    span: ['class']
  }
};

const restrictedGuideBodyHtmlSettings = {
  allowedTags: [
    ...restrictedHtmlSettings.allowedTags, 'img', 'figure', 'table', 'tbody', 'thead', 'tr', 'th', 'td', 'caption', 'figcaption'
  ],
  allowedAttributes: {
    ...restrictedHtmlSettings.allowedAttributes,
    img: ['alt', 'src', 'class'],
    figure: ['class']
  }
};

export const asRestrictedHtml = (dirty) => apostropheSanitizeHtml(dirty, restrictedHtmlSettings);

export const asRestrictedGuideBodyHtml = (dirty) => apostropheSanitizeHtml(dirty, restrictedGuideBodyHtmlSettings);

/**
 * Open online course (MOOC)
 *
 * @param {string} tags
 * @returns
 */
export const isMOOC = (tags) => tags && tags.includes(MOOC);

export const getSiteimproveScriptSrc = (siteimproveScriptId) => `https://siteimproveanalytics.com/js/siteanalyze_${siteimproveScriptId}.js`;

export const asArray = (val) => {
  if (Array.isArray(val)) {
    return val;
  }
  return val ? [val] : [];
};

const NUMBER_FORMATS = {
  fi: new Intl.NumberFormat('fi-FI'),
  sv: new Intl.NumberFormat('sv-FI'),
  en: new Intl.NumberFormat('en-GB')
};

/**
 * Formats number with locale inferred from provided language code
 *
 * @param {string|number} number - The number to format
 * @param {string} lang - The language code to infer locale from
 * @returns {string} - Formatted number
 */
export const formatNumber = (number, lang) =>
  (NUMBER_FORMATS[lang] || NUMBER_FORMATS.fi).format(number);

/**
 * Turn credit range to string representation without abbreviation,
 * e.g { min: 1, max: 10 } as "1-10".
 *
 * @param {{ min: number, max: number }} credits - Credits to format
 * @returns Credits in string representation
 */
export const getCreditsRange = (credits) => {
  if (!credits || (credits.min == null && credits.max == null)) {
    return '';
  }
  if (credits.min === credits.max) {
    return `${credits.min}`;
  }
  return credits.max
    ? `${credits.min}–${credits.max}`
    : `≥ ${credits.min}`;
};

/**
 * Formats a Sisu CreditRange
 * https://sis-tuni.funidata.fi/kori/docs/index.html#_creditrange
 *
 * @param {*} credits - The credits to format
 * @param {string} lang - The language code to infer locale from
 * @returns Formatted credits
 */
export const formatCredits = (credits, lang) => {
  const creditsRange = getCreditsRange(credits);
  if (!creditsRange) return '';
  const creditAbbr = getTranslationWithLocale('credits.abbreviation', lang);
  return `${creditsRange} ${creditAbbr}`;
};

// Documents created in sisu have id format of `otm-{UUID}`
export const isSisuNativeId = (id) => id && id.startsWith('otm-');

export const isAiliOriginatingId = (id) => id && id.startsWith('hy-cur-aili-');

export const hasSisuLikeNamingConvention = (id) => id.startsWith('otm-') || id.startsWith('hy-cur-aili-');

export const isOptimeOriginatingId = (id) => id && id.startsWith('hy-opt-cur-');

const courseNameWithCourseType = (name, type, lang) => {
  const nameTranslated = typeof (name) === 'string' ? name : getTranslation(name, lang);
  const typeTranslated = typeof (type) === 'string' ? type : getTranslation(type, lang);

  if (!nameTranslated) {
    return typeTranslated;
  }
  if (!typeTranslated) {
    return nameTranslated;
  }
  return `${nameTranslated}, ${typeTranslated}`;
};

/**
 * Translate and format course name.
 *
 * Realisations created in Sisu (id format "otm-<nnn>") contain course type in "name" field and descriptive name in "nameSpecifier" field.
 * Realisations created in Optime (id format "hy-opt-cur-<nnn>") or Oodi (id format "hy-CUR-<nnn>") are opposite of this.
 *
 * Returns course name as "Descriptive name, Course type" for sisu native and oodi courses and courses descriptive name for
 * realisations created in Optime.
 *
 * @param {string} id - Course unit realisation id
 * @param {LocalizedText|string} name - Course unit realisation name from sisu
 * @param {LocalizedText|string} nameSpecifier - Course unit realisation nameSpecifier from sisu
 * @param {string} lang - Language to translate name in
 * @returns {string} - Formatted course name
 */
export const formatCourseName = (id, name, nameSpecifier, lang) => {
  if (hasSisuLikeNamingConvention(id)) {
    return courseNameWithCourseType(nameSpecifier, name, lang);
  } if (isOptimeOriginatingId(id)) {
    return courseNameWithCourseType(name, null, lang);
  }
  return courseNameWithCourseType(name, nameSpecifier, lang);
};

/**
 * Derives and translates course participation type from course unit realisation type
 * and assessment item type to be used in course search results.
 *
 * @param {string} curType course unit realisation type
 * @param {string} aiType assessment item type
 * @param {string} lang language to translate participation type in
 * @returns {string|null} returns translated course participation type or null if type is not found
 */
export const getCourseParticipationType = (curType = '', aiType, lang) => {
  let courseType = null;

  if (curType.startsWith('urn:code:course-unit-realisation-type:exam-')) {
    courseType = 'urn:code:assessment-item-type:exam';
  } else if (['urn:code:assessment-item-type:independent-work', 'urn:code:assessment-item-type:teaching-participation'].includes(aiType)) {
    courseType = aiType;
  }

  return courseType ? getTranslationWithLocale(`filters.${courseType}`, lang) : null;
};

export const isPublished = (cur) => cur.flowState === FLOW_STATES.PUBLISHED
  && (!cur.publishDate || isBeforeOrEqual(parseISO(cur.publishDate), startOfToday()));

export const isBotOrCrawler = (userAgent) => userAgent && robotsRegExp.test(userAgent);

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Turns an array of strings to comma separated string, with the last comma replaced by "and" translated to the given language.
 * E.g. ["a", "b", "c"] becomes "a, b and c".
 *
 * @param {string[]} arr - Array of strings
 * @param {string} lang - Language to translate the "and" separator to. Defaults to "fi"
 * @returns {string} - Oxford comma formatted string
 */
export const listToOxfordComma = (arr = [], lang = 'fi') => {
  const commaSeparated = arr.slice(0, arr.length - 1).join(', ');
  const lastWord = arr.slice(arr.length - 1, arr.length);
  return [commaSeparated, lastWord].filter(Boolean).join(` ${getTranslationWithLocale('and', lang)} `);
};

// This is same as in backend and should be kept same.
export const getStudyYearStartYear = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();

  if (month < 7) {
    return year - 1;
  }

  return year;
};

export const getCurriculumPeriodId = (year) => `hy-lv-${(year || getStudyYearStartYear(new Date())) - 1949}`;

/**
 *
 * @param {string} studyYears
 * @returns
 */
export const parseStudyYearsAsRequestParams = (studyYears) => `?studyYear=${studyYears
  .split(',')
  .reduce((prev, curr) =>
    `${prev}&studyYear=${curr}`)}`;

export const withPTag = (html) => {
  if (!html) {
    return { __html: '' };
  }
  const cleanHtml = asRestrictedHtml(html.trim());
  if (!cleanHtml) {
    return { __html: '' };
  }
  return { __html: cleanHtml.startsWith('<') ? cleanHtml : `<p>${cleanHtml}</p>` };
};

export const sanitizeDangerousHtml = (html) => (html ? { __html: asRestrictedHtml(html) } : { __html: '' });

// Possible to use locally run eduviewer for testing purposes by passing useLocal
export const getEduviewerEmbedUrl = (useLocal = false) => {
  let url;

  if (useLocal) {
    // Not localhost, because then eduviewer will try to do things that break this
    // This can be used by building eduviewer with npm run dist and then using live server plugin on VSCode to run dist/index.html as server
    url = 'http://127.0.0.1:5500/dist/eduviewer.var.js';
  } else if (IS_PROD) {
    url = 'https://eduviewer.it.helsinki.fi/eduviewer.var.js';
  } else {
    url = 'https://eduviewer-qa.it.helsinki.fi/eduviewer.var.js';
  }
  return url;
};

export const parseCUCodes = (cur) => {
  const countTags = new RegExp(HIGHLIGHT_START_TAG, 'g');
  let codes;
  let extraCount;
  const allCUCodes = [cur.code, ...(cur.altCodes || [])];

  if (allCUCodes && allCUCodes.length > 1) {
    if (allCUCodes.find((code) => code.includes(HIGHLIGHT_START_TAG))) {
      allCUCodes.sort((code, code2) => {
        if (code.includes(HIGHLIGHT_START_TAG) && code2.includes(HIGHLIGHT_START_TAG)) {
          return code2.match(countTags, 'g').length - code.match(countTags, 'g').length;
        }
        if (code.includes(HIGHLIGHT_START_TAG)) {
          return -1;
        }
        if (code2.includes(HIGHLIGHT_START_TAG)) {
          return 1;
        }
        return 0;
      });
    }

    if (allCUCodes.length === 2) {
      codes = allCUCodes.join(', ');
    } else {
      extraCount = allCUCodes.length - 2;
      codes = allCUCodes.slice(0, 2).join(', ');
    }
  } else {
    codes = cur.code;
  }
  return { codes, extraCount };
};

const getTranslationWithSecondaryLangUrn = (source, pageLang, teachingLangUrn) =>
  source && (
    source[pageLang]
    || (teachingLangUrn && source[teachingLangUrn.split(':').pop()])
    || source.fi || source.sv || source.en
  );

export const getSummaryText = (item, pageLang) =>
  getTranslationWithSecondaryLangUrn(item.cmsFieldSummary, pageLang, item.teachingLanguageUrn)
  || getTranslationWithSecondaryLangUrn(item.tweetText, pageLang, item.teachingLanguageUrn)
  || '';

export const getOrganisationBackgroundImage = (images, backupBackgroundImage = defaultOrganisationBackgroundImage) => {
  if (images) {
    const orgBackground = IMAGE_SIZES.filter((s) => images[s]?.url)
      .map((s) => images[s])
      .shift();
    if (orgBackground?.url) {
      return `${COURSE_PAGE_API_PATH}/cur/image/${orgBackground.url}`;
    }
  }
  return backupBackgroundImage;
};

export const getAvailabilityAsMinorStudyRightTranslationKey = (studyRightSelectionTypeUrn, studyRightAcceptanceTypeUrn) => {
  if (studyRightSelectionTypeUrn === studyRightSelectionTypes.NONE) {
    return 'studyModule.no';
  }
  if (studyRightAcceptanceTypeUrn === minorStudyRightAcceptanceTypes.ACCEPTED_BY_TEACHER) {
    return 'studyModule.studyRightRequired';
  }
  if (studyRightAcceptanceTypeUrn === minorStudyRightAcceptanceTypes.AUTOMATIC) {
    return 'studyModule.freelyChosenModel';
  }
  // Fallback to avoid translation errors
  return 'studyModule.no';
};

export const getResponsibleTeachers = (responsibilityInfos) => (
  responsibilityInfos
    .filter(({ person, roleUrn }) => !!person && roleUrn === responsibilityRoleUrns.RESPONSIBLE_TEACHER_MODULE_ROLE_URN)
    .map(({ person: { firstName, lastName } }) => `${firstName || ''} ${lastName || ''}`.trim())
    .filter(Boolean)
    .join(', ') || '-');
