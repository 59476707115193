import React, { useContext } from 'react';
import classNames from 'classnames';
import LoginLink from '../LoginLink';
import FlammaNews from '../FlammaNews';
import TikeBulletins from '../TikeBulletins';
import GuideNews from '../GuideNews';
import Contact from '../Contact';
import LunchMenus from '../LunchMenus';
import StudentEvents from '../StudentEvents';
import styles from './frontPage.css';
import { AVAILABLE_LANGUAGES, routes } from '../../constants';
import { isDesktop, isMobile, useDeviceSize } from '../../context/DeviceSizeContextProvider';
import DocumentHead from '../DocumentHead';
import { SITEIMPROVE_ENABLED } from '../../env';
import { getSiteimproveScriptSrc } from '../../utils';
import RootContext from '../../context/RootContext';
import BulletinLiftup from '../BulletinLiftup';
import ErrorBoundary from '../ErrorBoundary';
import useIsLoggedIn from '../../hooks/useIsLoggedIn';
import useTranslation from '../../hooks/useTranslation';

const heroImage = require('../../images/hy_opiskelijapalvelut_hero.jpg');

const MobileContent = () => (
  <div className="grid-column tablet-size-8 mobile-size-4">
    <BulletinLiftup />
    <GuideNews />
    <FlammaNews />
    <StudentEvents />
    <Contact />
    <LunchMenus />
    <TikeBulletins />
  </div>
);

const DesktopContent = () => (
  <>
    <section className="grid-column desktop-size-9 main-content">
      <BulletinLiftup />
      <GuideNews />
      <FlammaNews />
      <StudentEvents />
    </section>
    <aside className="grid-column desktop-size-3">
      <Contact />
      <LunchMenus />
      <TikeBulletins />
    </aside>
  </>
);

const Hero = () => {
  const { t } = useTranslation();
  const { deviceSize } = useDeviceSize();
  const loggedIn = useIsLoggedIn();

  return (
    <div className={classNames(styles.heroContainer, {
      'grid-container': !isMobile(deviceSize),
      'page-wide-content-padding': !isMobile(deviceSize),
      [styles.loggedIn]: loggedIn
    })}
    >
      <div className={styles.hero}>
        <div className={classNames(styles.heroItem, styles.ingressContainer)}>
          <h1>{t('header')}</h1>
          <p className={styles.ingress}>{t('ingress')}</p>
          <LoginLink />
        </div>
        {!loggedIn && (
          <div className={styles.heroItem}>
            <div className={styles.imageContainer}>
              <img src={heroImage} alt="" />
            </div>
          </div>
        )}
      </div>
      <span className={styles.divider} />
    </div>
  );
};

const FrontPage = () => {
  const { deviceSize } = useDeviceSize();
  const { config } = useContext(RootContext);

  const localizedUrls = AVAILABLE_LANGUAGES.map((lang) => [lang, routes.frontpage(lang)]);

  // See https://jira.it.helsinki.fi/browse/OPISKELU-1511
  const frontPageScripts = (SITEIMPROVE_ENABLED)
    ? [{
      async: true,
      src: getSiteimproveScriptSrc(config.siteimproveAnalyticsScriptId),
      type: 'text/plain',
      cookieconsent: 'statistics'
    }]
    : null;

  return (
    <div className={styles.container}>
      <DocumentHead urls={localizedUrls} scripts={frontPageScripts} />
      <Hero />
      <div className="grid-container page-wide-content-padding">
        <div className="grid-row stacked">
          {(isDesktop(deviceSize))
            ? <DesktopContent />
            : <MobileContent />}
        </div>
      </div>
    </div>
  );
};

export default () => (
  <ErrorBoundary>
    <FrontPage />
  </ErrorBoundary>
);
