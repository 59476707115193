import {
  arrayOf, oneOf, oneOfType, shape, string, number, bool, instanceOf, func, objectOf
} from 'prop-types';
import {
  ICON_POSITIONS, FLOW_STATES, DOCUMENT_STATES, minorStudyRightAcceptanceTypes, studyRightSelectionTypes, openUniversityProductTypes
} from './constants';

export const localizedTextType = shape({
  fi: string,
  sv: string,
  en: string
});

export const localizedLinkType = shape({
  label: localizedTextType,
  url: localizedTextType
});

export const dateRangeType = shape({
  startDate: string.isRequired,
  endDate: string.isRequired
});

export const dateTimeRangeType = shape({
  startDateTime: string.isRequired,
  endDateTime: string.isRequired
});

export const optionalDateRangeType = shape({
  startDate: string,
  endDate: string
});

export const optionalDateTimeRangeType = shape({
  startDateTime: string,
  endDateTime: string
});

export const minMaxRangeType = shape({
  min: number,
  max: number
});

export const coursesSearchResultType = shape({
  name: localizedTextType,
  nameSpecifier: localizedTextType,
  plainName: localizedTextType,
  activityPeriod: dateRangeType,
  enrolmentPeriod: dateTimeRangeType,
  typeName: localizedTextType,
  curId: string.isRequired,
  studyGroupSets: arrayOf(shape({})),
  isOpenUniversityCourse: bool.isRequired,
  coursePageUrl: string
});

export const bucket = shape({ key: string, doc_count: number });

export const coursesSearchResultList = shape({
  totalHits: number,
  hits: arrayOf(coursesSearchResultType),
  aggregations: arrayOf(shape({ studyLevel: shape({ buckets: arrayOf(bucket) }) })),
  queryExceedsMaxResultWindow: bool
});

export const option = shape({
  label: string.isRequired,
  value: string.isRequired
});

export const breadcrumbType = shape({
  names: localizedTextType,
  paths: localizedTextType,
  useRouter: bool
});

export const languageType = shape({
  shortName: localizedTextType,
  name: localizedTextType
});

export const cmsMaterialTypes = {
  TITLE: 'TITLE',
  FILE: 'FILE',
  LINK: 'LINK'
};

const cmsMaterialType = {
  type: oneOf([cmsMaterialTypes.FILE, cmsMaterialTypes.LINK]),
  id: string,
  instructions: string,
  restricted: bool
};

export const cmsMaterialTitleType = shape({
  type: oneOf([cmsMaterialTypes.TITLE]),
  id: string,
  title: string
});

export const cmsMaterialFileType = shape({
  ...cmsMaterialType,
  name: string,
  path: string,
  description: string,
  size: number,
  mime: string
});

export const cmsMaterialLinkType = shape({
  ...cmsMaterialType,
  title: string,
  url: string
});

export const cmsMaterialsType = arrayOf(oneOfType([cmsMaterialFileType, cmsMaterialLinkType, cmsMaterialTitleType]));

export const courseMaterialsType = shape({
  info: string,
  materials: cmsMaterialsType
});

export const moodleLinkType = shape({
  url: string.isRequired,
  key: string
});

export const zoomLinkType = shape({
  url: string.isRequired,
  meetingId: string.isRequired,
  pw: string.isRequired
});

export const cmsAttentionMessageType = shape({
  message: string,
  lastDate: string,
  creator: string,
  createdDate: string
});

export const cmsMessageType = shape({
  topic: string.isRequired,
  content: string,
  created: string.isRequired
});

export const cmsMessagesType = arrayOf(cmsMessageType);

export const courseCmsDataType = shape({
  title: string,
  body: string,
  introductionTitle: string,
  summary: string,
  attentionMessage: cmsAttentionMessageType,
  scheduleInstructions: string,
  imagePath: string,
  imageAltText: string,
  imageCopyRight: string,
  links: arrayOf(shape({
    uri: string.isRequired,
    title: string.isRequired
  })),
  courseMaterials: courseMaterialsType,
  courseCompletion: string,
  optionalInfo: arrayOf(shape({
    title: string,
    content: string
  })),
  moodleLink: moodleLinkType,
  zoomLink: zoomLinkType,
  messages: cmsMessagesType
});

export const courseCmsNoDataReasonTypes = {
  ERROR: 'ERROR',
  NO_CMS_DATA: 'NO_CMS_DATA'
};

export const courseCmsNoDataType = shape({
  noContent: shape({
    reason: oneOf(Object.values(courseCmsNoDataReasonTypes)).isRequired
  })
});

export const gradeType = shape({
  count: number,
  passed: bool,
  localId: string,
  name: string,
  abbreviation: string,
  comment: string
});

export const courseResultsType = shape({
  distribution: arrayOf(gradeType),
  showGradeDistribution: bool,
  showBarForFailed: bool,
  aborted: number,
  userGrade: gradeType
});

export const courseUserAffiliationTypes = {
  NONE: 'NONE',
  ENROLMENT_PENDING: 'ENROLMENT_PENDING',
  STUDENT: 'STUDENT',
  TEACHER: 'TEACHER',
  RESPONSIBLE_TEACHER: 'RESPONSIBLE_TEACHER',
  ADMINISTRATOR: 'ADMINISTRATOR'
};

export const personType = shape({
  firstName: string,
  lastName: string
});

export const responsibilityInfoType = shape({
  roleUrn: string.isRequired,
  person: personType,
  text: localizedTextType,
  validityPeriod: optionalDateRangeType.isRequired
});

export const literatureType = shape({
  name: string,
  url: string
});

export const courseUnitRealisationOrganisationType = shape({
  organisation: shape({
    id: string,
    name: localizedTextType
  })
});

export const studySubEventType = shape({
  cancelled: bool,
  end: string.isRequired,
  excluded: bool,
  start: string.isRequired,
  notice: string
});

const addressType = shape({
  city: string,
  postalCode: string,
  streetAddress: string
});

export const locationType = shape({
  building: shape({
    address: addressType,
    name: localizedTextType.isRequired
  }),
  id: string,
  name: localizedTextType.isRequired
});

export const studySubGroupType = shape({
  id: string,
  cancelled: bool,
  name: localizedTextType,
  teachers: arrayOf(personType),
  studyEvents: arrayOf(shape({
    events: arrayOf(studySubEventType),
    locations: arrayOf(locationType)
  }))
});

export const studyGroupSetType = shape({
  localId: string,
  studySubGroups: arrayOf(studySubGroupType)
});

export const organisationBackgroundImageType = shape({
  url: string,
  height: number,
  width: number
});

export const completionMethodType = shape({
  studyType: string.isRequired
});

export const courseUnitRealisationType = shape({
  id: string.isRequired,
  flowState: oneOf(Object.values(FLOW_STATES)).isRequired,
  responsibilityInfos: arrayOf(responsibilityInfoType).isRequired,
  courseUnitRealisationType: localizedTextType.isRequired,
  name: localizedTextType.isRequired,
  nameSpecifier: localizedTextType,
  courseUnits: arrayOf(shape({
    id: string.isRequired,
    code: string.isRequired,
    name: localizedTextType.isRequired
  })),
  teachingLanguage: languageType,
  studyFormat: localizedTextType,
  additionalInfo: localizedTextType,
  literature: arrayOf(literatureType),
  learningMaterial: localizedTextType,
  tweetText: localizedTextType,
  studyGroupSets: arrayOf(studyGroupSetType),
  assessmentItems: arrayOf(shape({
    credits: minMaxRangeType,
    completionMethods: arrayOf(completionMethodType)
  })),
  organisations: arrayOf(courseUnitRealisationOrganisationType),
  activityPeriod: dateRangeType,
  courseResults: courseResultsType,
  tags: arrayOf(string),
  affiliation: oneOf(Object.values(courseUserAffiliationTypes)),
  cmsBaseUrl: string,
  displayCourseFee: bool,
  displayFreeOfCharge: bool,
  cmsData: oneOfType([courseCmsDataType, courseCmsNoDataType]),
  enrolmentPeriod: optionalDateTimeRangeType,
  lateEnrolmentEnd: string,
  externalEnrolmentLink: localizedLinkType,
  useSisuEnrolment: bool,
  courseFeedbackUrl: string,
  coTeachingRealisation: bool,
  showRequestParticipantList: bool,
  organisationBackgroundImages: shape({
    mobile: organisationBackgroundImageType,
    tablet: organisationBackgroundImageType,
    desktop: organisationBackgroundImageType
  }),
  openUniversityProducts: arrayOf(
    shape({
      allowedCURIds: arrayOf(string),
      id: string,
      overriddenCURIds: arrayOf(string),
      price: string,
      enrolmentlink: string
    })
  ),
  openUniversityProductType: oneOf(Object.values(openUniversityProductTypes)),
  openUniversityProductMainCourseUnitRealisations: arrayOf(shape({
    id: string.isRequired,
    name: localizedTextType.isRequired,
    nameSpecifier: localizedTextType,
    assessmentItems: arrayOf(shape({
      credits: minMaxRangeType
    }))
  }))
});

export const courseUnitRealisationSmallType = shape({
  id: string.isRequired,
  activityPeriod: dateRangeType,
  name: localizedTextType,
  courseUnitRealisationType: shape({
    name: localizedTextType
  })
});

export const courseUnitPrerequisite = arrayOf(shape({
  code: string,
  credits: minMaxRangeType,
  name: localizedTextType
}));

export const hierarchyModule = shape({
  id: string.isRequired,
  groupId: string.isRequired,
  code: string,
  name: localizedTextType,
  childIds: arrayOf(string),
  parentIds: arrayOf(string),
  studyRightSelectionType: string,
  documentState: oneOf(Object.values(DOCUMENT_STATES)),
  type: string,
  gradeScaleId: string,
  curriculumPeriodIds: arrayOf(string)
});

export const formalPrerequisites = arrayOf(shape({
  prerequisites: arrayOf(shape({
    type: string.isRequired,
    moduleGroupId: string,
    courseUnitGroupId: string,
    courseUnits: arrayOf(shape({
      id: string.isRequired,
      code: string.isRequired,
      credits: minMaxRangeType,
      name: localizedTextType.isRequired
    })),
    modules: arrayOf(shape({
      id: string.isRequired,
      code: string.isRequired,
      groupId: string,
      name: localizedTextType.isRequired,
      targetCredits: shape({
        min: number,
        max: number
      })
    }))
  }))
}));

export const courseUnitType = shape({
  id: string.isRequired,
  code: string.isRequired,
  name: localizedTextType,
  courseUnitRealisations: arrayOf(courseUnitRealisationSmallType),
  additionalInfo: localizedTextType,
  literature: arrayOf(literatureType),
  learningMaterial: localizedTextType,
  prerequisites: localizedTextType,
  compulsoryFormalPrerequisites: arrayOf(objectOf(formalPrerequisites)),
  recommendedFormalPrerequisites: arrayOf(objectOf(formalPrerequisites)),
  outcomes: localizedTextType,
  content: localizedTextType,
  inclusionApplicationInstruction: localizedTextType,
  curriculumPeriods: arrayOf(shape({
    activePeriod: dateRangeType
  })),
  hierarchy: objectOf(hierarchyModule)
});

export const bulletinItemType = shape({
  guid: string.isRequired,
  isoDate: string.isRequired,
  link: string.isRequired,
  pubDate: string.isRequired,
  title: string.isRequired,
  content: string
});

export const newsItemType = shape({
  id: string.isRequired,
  isoDate: string.isRequired,
  pubDate: string.isRequired,
  title: string.isRequired,
  content: string,
  image: string,
  summary: string
});

export const announcementTypes = {
  REGULAR: 'REGULAR',
  CRITICAL: 'CRITICAL'
};

export const announcementItemType = shape({
  id: number.isRequired,
  crisisType: oneOf(Object.values(announcementTypes)).isRequired,
  target: string.isRequired,
  title: localizedTextType.isRequired,
  content: localizedTextType.isRequired,
  link: localizedTextType.isRequired,
  linkDescription: localizedTextType.isRequired,
  modifiedDate: string.isRequired
});

export const restaurantsType =
  shape({
    Unicafe: arrayOf(shape({
      id: number.isRequired,
      name: string.isRequired
    })),
    Sodexo: arrayOf(shape({
      id: number.isRequired,
      name: string.isRequired,
      name_en: string.isRequired
    }))
  });

export const unicafeLunchMenuType = arrayOf(
  shape({
    name: string.isRequired,
    meta: shape({
      0: arrayOf(string)
    })
  })
);

export const sodexoLunchMenuType = objectOf(
  shape({
    title_fi: string.isRequired,
    title_en: string.isRequired,
    dietcodes: string.isRequired
  })
);

export const formStateType = shape({
  searchText: string,
  organisation: oneOfType([string, arrayOf(string)]),
  type: oneOfType([string, arrayOf(string)]),
  teachingLanguageUrn: oneOfType([string, arrayOf(string)]),
  studyLevel: oneOfType([string, arrayOf(string)]),
  studyYear: oneOfType([string, arrayOf(string)]),
  period: oneOfType([string, arrayOf(string)]),
  page: number,
  customCodeUrns: oneOfType([string, arrayOf(string)])
});

export const dpSelectorOptionType = shape({
  label: string.isRequired,
  value: string.isRequired,
  type: string.isRequired,
  code: string,
  groupId: string
});

export const dpOptionGroupType = shape({
  label: string.isRequired,
  options: arrayOf(dpSelectorOptionType).isRequired,
  types: arrayOf(string).isRequired
});

export const calendarEventSourceTypes = {
  COURSE_PAGE: 'COURSE_PAGE',
  STUDY_REGISTRY: 'STUDY_REGISTRY'
};

export const calendarEventType = shape({
  title: string.isRequired,
  start: instanceOf(Date).isRequired,
  end: instanceOf(Date).isRequired,
  locations: arrayOf(shape({
    locationString: string,
    roomName: string,
    streetAddress: string,
    zipCode: string
  })),
  optimeExtras: shape({
    staffNotes: string,
    roomNotes: string,
    otherNotes: string
  }),
  optimeExtrasAsString: string,
  courseUri: string,
  source: oneOf(Object.values(calendarEventSourceTypes)),
  courseTitle: string
});

export const todoItemStatusValues = {
  OPEN: 'OPEN',
  DONE: 'DONE'
};

export const todoItemType = shape({
  id: number,
  createdDate: instanceOf(Date),
  content: string.isRequired,
  status: oneOf(Object.values(todoItemStatusValues)).isRequired
});

export const iconPositionType = oneOf(Object.values(ICON_POSITIONS));

export const degreeProgramResultType = shape({
  code: string,
  name: localizedTextType,
  isPrimary: bool
});

export const multiSearchResultTypes = {
  COURSES: 'courses',
  NEWS: 'news',
  ARTICLES: 'articles',
  THEMES: 'themes'
};

export const multiSearchResultTargetGroupTypes = [
  'degree_students',
  'doctoral_candidates',
  'specialist_training',
  'open_university'
];

export const multiSearchResultType = shape({
  id: string,
  type: oneOf(Object.values(multiSearchResultTypes)),
  title: localizedTextType,
  body: localizedTextType,
  keywords: localizedTextType,
  articleParagraphs: arrayOf(shape({
    title: localizedTextType,
    body: localizedTextType
  })),
  created: string,
  changed: string,
  degreePrograms: arrayOf(shape({
    code: string,
    name: localizedTextType
  })),
  targetGroups: arrayOf(oneOf(multiSearchResultTargetGroupTypes)),
  url: localizedTextType
});

export const multiSearchResultListType = shape({
  hits: arrayOf(oneOfType([multiSearchResultType, coursesSearchResultType])),
  totalHits: number,
  pageSize: number,
  queryExceedsMaxResultWindow: bool
});

export const refType = oneOfType([
  func,
  shape({ current: instanceOf(Element) })
]);

export const studyModuleType = shape({
  id: string.isRequired,
  groupId: string.isRequired,
  code: string.isRequired,
  name: localizedTextType,
  gradeScaleId: string,
  gradeScaleName: localizedTextType,
  studyLevel: shape({
    name: localizedTextType
  }),
  organisations: arrayOf(
    shape({
      organisation: shape({
        name: localizedTextType
      })
    })
  ),
  targetCredits: shape({
    min: number,
    max: number
  }),
  responibilityTypes: arrayOf(
    shape({
      roleUrn: string.isRequired,
      person: shape({
        firstName: string,
        lastName: string
      }).isRequired
    })
  ),
  curriculumPeriods: arrayOf(
    shape({
      id: string.isRequired,
      name: localizedTextType,
      objId: string.isRequired,
      objCode: string
    })
  ),
  minorStudyRightAcceptanceTypeUrn: oneOf(Object.values(minorStudyRightAcceptanceTypes)),
  studyRightSelectionTypeUrn: oneOf(Object.values(studyRightSelectionTypes)),
  possibleAttainmentLanguages: arrayOf(
    shape({
      name: localizedTextType
    })),
  contentDescription: localizedTextType,
  prerequisites: localizedTextType,
  outcomes: localizedTextType,
  additionalInfo: localizedTextType,
  hierarchy: objectOf(hierarchyModule)
});

export const degreeProgramType = shape({
  id: string.isRequired,
  groupId: string.isRequired,
  code: string.isRequired,
  name: localizedTextType,
  gradeScaleId: string,
  studyLevel: shape({
    name: localizedTextType
  }),
  degreeProgrammeType: shape({
    name: localizedTextType
  }),
  degreeTitles: arrayOf(shape({
    name: localizedTextType
  })),
  organisations: arrayOf(
    shape({
      organisation: shape({
        name: localizedTextType
      })
    })
  ),
  targetCredits: shape({
    min: number,
    max: number
  }),
  responibilityTypes: arrayOf(
    shape({
      person: shape({
        firstName: string,
        lastName: string
      })
    })
  ),
  curriculumPeriods: arrayOf(
    shape({
      id: string,
      name: localizedTextType
    })
  ),
  possibleAttainmentLanguages: arrayOf(
    shape({
      name: localizedTextType
    })),
  contentDescription: localizedTextType,
  prerequisites: localizedTextType,
  outcomes: localizedTextType,
  additionalInfo: localizedTextType,
  degreeLanguages: arrayOf(shape({
    name: localizedTextType
  })),
  learningOutcomes: localizedTextType
});

export const courseContactPersonType = shape({
  firstName: string.isRequired,
  lastName: string.isRequired,
  emailAddress: string.isRequired,
  picture: string,
  title: shape({
    fi: string,
    sv: string,
    en: string
  })
});
