import React from 'react';
import classNames from 'classnames';
import styles from './icons.css';

const EmailIcon = () => (
  <span className="icon">
    <svg
      viewBox="0 0 16 12"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('icon', styles.emailIcon)}
    >
      <g clipPath="url(#clip0_2983_45)">
        <path
          d="M16 0.106136L15.9204 11.9138L0 11.8076L0.079602 0L16 0.106136ZM1.60531 2.22886L1.55224 9.65837L6.19569 6.15589L1.60531 2.22886ZM2.42786 1.56551L8 6.32836L13.6385 1.64511H12.9088L2.42786 1.56551ZM7.01824 6.8325L2.42786 10.2819L13.5058 10.3615L8.96849 6.84577L7.98673 7.65506L7.01824 6.8325ZM14.3947 9.69818L14.4478 2.30846L9.80431 6.18242L14.3947 9.69818Z"
          fill="#0E688B"
        />
      </g>
      <defs>
        <clipPath id="clip0_2983_45">
          <rect width="16" height="11.927" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </span>
);

export default EmailIcon;
