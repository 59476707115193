import React from 'react';
import { shape } from 'prop-types';
import { isSameDay } from 'date-fns';

import styles from './agendaEvent.css';
import { calendarEventType } from '../../../../../types';
import EventTitle from '../../Event/EventTitle';

const formatTimeRange = ({ start, end }, localizer) => {
  if (isSameDay(start, end)) {
    return localizer.format({ start, end }, 'agendaTimeRangeFormat');
  }
  return localizer.format({ start, end }, 'agendaDateRangeFormat');
};

const AgendaEvent = ({ event, localizer }) => (
  <div className={styles.container}>
    <div className={styles.eventTimeRange}>{formatTimeRange(event, localizer)}</div>
    <div><EventTitle event={event} /></div>
    {event.locations && event.locations.map((location, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={idx}>{location.locationString}</div>
    ))}
    {event.optimeExtrasAsString && (
    <div>{event.optimeExtrasAsString}</div>
    )}
  </div>
);

AgendaEvent.propTypes = {
  event: calendarEventType,
  localizer: shape({})
};

export default AgendaEvent;
