import React, { useEffect } from 'react';
import {
  string,
  arrayOf,
  shape,
  bool
} from 'prop-types';
import { Helmet } from 'react-helmet';
import {
  newsPathTranslations,
  frontPagePathTranslations,
  searchByDpPathTranslations,
  searchPathTranslations,
  multiSearchPathTranslations,
  myStudiesPathTranslations
} from '../../constants';
import useTranslation from '../../hooks/useTranslation';

const getPathBasedServiceTitlePart = (path, lang, translate) => {
  if (path.includes(newsPathTranslations[lang])) {
    return null;
  }
  if (path.includes(frontPagePathTranslations[lang])) {
    return translate('serviceName.frontpage');
  }
  if (path.includes(searchByDpPathTranslations[lang])) {
    return translate('serviceName.degreeProgramme');
  }
  if (path.includes(searchPathTranslations[lang])) {
    return translate('serviceName.courses');
  }
  if (path.includes(multiSearchPathTranslations[lang])) {
    return translate('serviceName.multiSearch');
  }
  if (path.includes(myStudiesPathTranslations[lang])) {
    return translate('serviceName.myStudies');
  }
  return null;
};

const DocumentHead = ({
  urls,
  title,
  scripts
}) => {
  const { t, lang } = useTranslation();

  useEffect(() => () => {
    scripts?.forEach((s) => {
      if (!s.removeOnUnmount) return;
      const scriptToRemove = document.getElementById(s.id);
      if (!scriptToRemove) return;

      scriptToRemove.remove();
    });
  }, [scripts]);

  const serviceTitle = getPathBasedServiceTitlePart(decodeURIComponent(window.location.pathname), lang, t);
  const titleEnd = `${t('studiesService')} | ${t('UH')}`;

  const fullTitle = [title, serviceTitle, titleEnd].filter(Boolean).join(' | ');
  return (
    <Helmet htmlAttributes={{ lang }}>
      <title>{fullTitle}</title>
      {scripts?.map((s) => (<script key={s.src} type={s.type} data-cookieconsent={s.cookieconsent} async={s.async} src={s.src} id={s.id} />))}
      {urls?.filter(([urlLang]) => lang !== urlLang).map(([urlLang, url]) => <link key={urlLang} rel="alternate" hrefLang={urlLang} href={url} />)}
    </Helmet>
  );
};

DocumentHead.propTypes = {
  urls: arrayOf(arrayOf(string)).isRequired,
  scripts: arrayOf(shape({
    src: string,
    type: string,
    cookieconsent: string,
    async: bool,
    removeOnUnmount: bool
  })),
  title: string
};

export default DocumentHead;
