import React, { useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { string, arrayOf } from 'prop-types';
import { dpSelectorOptionType } from '../../../types';
import { routes } from '../../../constants';
import DegreeProgrammeSelector from '../../DegreeProgrammeSelector';
import useDegreePrograms from '../../../hooks/useDegreePrograms';
import usePersistingDegreeProgramme from '../../../hooks/usePersistingDegreeProgramme';

const DegreeProgramSelector = ({ degreeProgrammes, lang }) => {
  const history = useHistory();
  const { degreeProgramId } = useParams();
  const userDegreePrograms = useDegreePrograms();
  const { setPerstistingDegreeProgramme } = usePersistingDegreeProgramme();

  const goToDegreeProgramme = useCallback((value) => {
    history.replace(routes.dp(lang, value));
  }, [history, lang]);

  useEffect(() => {
    if (userDegreePrograms && !degreeProgramId) {
      const primaryDegreeProgram = userDegreePrograms.find((dp) => dp.isPrimary);
      if (primaryDegreeProgram) {
        const primaryValue = degreeProgrammes.find((dpOpt) => primaryDegreeProgram?.code === dpOpt.code)?.value;
        if (primaryValue) {
          goToDegreeProgramme(primaryValue);
        }
      }
    }
  }, [degreeProgrammes, userDegreePrograms, goToDegreeProgramme, degreeProgramId]);

  return (
    <DegreeProgrammeSelector
      options={degreeProgrammes}
      onSelect={(id) => {
        setPerstistingDegreeProgramme('id', id);
        goToDegreeProgramme(id);
      }}
      selectedItem={degreeProgramId}
      ownMultiple
    />
  );
};

DegreeProgramSelector.propTypes = {
  degreeProgrammes: arrayOf(dpSelectorOptionType),
  lang: string
};

export default DegreeProgramSelector;
