import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import RootContext from '../../context/RootContext';
import LocalLogin from './local';
import ShibbolethLogin from './shibboleth';

const getReferer = (location) => {
  const { from } = location.state || { from: document.referrer };
  if (from && from.includes(window.location.hostname)) {
    return from;
  }
  return undefined;
};

const Login = () => {
  const { config } = useContext(RootContext);
  const location = useLocation();

  const referer = getReferer(location);

  if (config.auth === 'local') {
    return <LocalLogin referer={referer} />;
  }

  return <ShibbolethLogin referer={referer} />;
};

export default Login;
