import React from 'react';
import Section from '../../../Section';
import { courseUnitRealisationType } from '../../../../types';
import { getTranslation } from '../../../../i18n';
import styles from './additionalInfo.css';
import useTranslation from '../../../../hooks/useTranslation';

const AdditionalInfo = ({ courseUnitRealisation }) => {
  const { t, lang } = useTranslation();

  const additionalInfoTranslated = getTranslation(courseUnitRealisation?.additionalInfo, lang);
  return additionalInfoTranslated ? (
    <Section
      className={styles.additionalInfo}
      level={2}
      label={t('additionalInfo')}
      html
    >
      {additionalInfoTranslated}
    </Section>
  ) : null;
};

AdditionalInfo.propTypes = {
  courseUnitRealisation: courseUnitRealisationType
};

export default AdditionalInfo;
