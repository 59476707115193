import React from 'react';
import { Redirect } from 'react-router-dom';
import { shape, string } from 'prop-types';
import ErrorBoundary from '../ErrorBoundary';
import DegreeStructureWrapper from './DegreeStructureWrapper';
import DocumentHead from '../DocumentHead';
import { AVAILABLE_LANGUAGES, routes } from '../../constants';
import usePersistingDegreeProgramme from '../../hooks/usePersistingDegreeProgramme';
import useTranslation from '../../hooks/useTranslation';

const DegreeStructurePage = ({ match }) => {
  const { persistingDegreeProgramme } = usePersistingDegreeProgramme();
  const { lang } = useTranslation();
  const { params: { degreeProgramId } } = match;

  const localizedUrls = degreeProgramId
    ? AVAILABLE_LANGUAGES.map((availableLanguage) => [availableLanguage, routes.dp(availableLanguage, degreeProgramId)])
    : AVAILABLE_LANGUAGES.map((availableLanguage) => [availableLanguage, routes.degreeStructure(availableLanguage)]);

  if (!degreeProgramId && persistingDegreeProgramme?.id) {
    return (
      <Redirect
        to={{
          pathname: `${routes.dp(lang, persistingDegreeProgramme.id)}`,
          state: { from: window.location.href }
        }}
      />
    );
  }

  return (
    <ErrorBoundary>
      <DocumentHead urls={localizedUrls} />
      <DegreeStructureWrapper degreeProgramId={degreeProgramId} />;
    </ErrorBoundary>
  );
};

DegreeStructurePage.propTypes = {
  match: shape({
    params: shape({
      degreeProgramId: string
    })
  })
};

export default DegreeStructurePage;
