import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { stringify, parse } from 'query-string';
import SearchForm from './SeachForm';
import SearchResultLoader from './SearchResultLoader';
import { routes, AVAILABLE_LANGUAGES } from '../../constants';
import DocumentHead from '../DocumentHead';
import Breadcrumb from '../Breadcrumb';
import ExploreStudies from './ExploreStudies';
import styles from './courseSearch.css';
import ErrorBoundary from '../ErrorBoundary';
import useTranslation from '../../hooks/useTranslation';
import { sanitizeDangerousHtml } from '../../utils';

const CourseSearch = () => {
  const { t, lang } = useTranslation();
  const history = useHistory();

  // Child SearchForm component uses this to update search params, which then get passed on to SearchResultLoader.
  const paramsToLocation = (searchOptions) => {
    history.replace({
      search: stringify(searchOptions)
    });
  };

  const initialValues = parse(useLocation().search, { parseNumbers: true });
  if (initialValues?.studyYear) {
    initialValues.studyYear = Array.isArray(initialValues.studyYear) ? initialValues.studyYear.map(String) : initialValues.studyYear.toString();
  }

  const setPageIndex = (pageIndex) => {
    history.push({
      search: stringify({ ...initialValues, page: pageIndex }),
      state: {
        scrollY: 0
      }
    });
  };

  const search = stringify(initialValues);
  const localizedUrls = AVAILABLE_LANGUAGES.map((l) => [l, `${routes.search(l)}${search && '?'}${search}`]);

  return (
    <>
      <DocumentHead urls={localizedUrls} />
      <div className="grid-container page-wide-content-padding">
        <Breadcrumb />
        <h1>
          {t('search.title')}
        </h1>
        {/* eslint-disable-next-line react/no-danger */}
        <p dangerouslySetInnerHTML={sanitizeDangerousHtml(t('search.tagline'))} />
      </div>
      <SearchForm onSearch={paramsToLocation} optionValues={initialValues} />
      <SearchResultLoader lang={lang} setPageIndex={setPageIndex} optionValues={initialValues} />

      <div className={`grid-container page-wide-content-padding ${styles.links}`}>
        <ExploreStudies />
      </div>
    </>
  );
};

export const CoursesSearchLocalized = CourseSearch;

export default () => (
  <ErrorBoundary>
    <CoursesSearchLocalized />
  </ErrorBoundary>
);
