import React from 'react';

import classNames from 'classnames';
import { routes } from '../../constants';
import styles from './login-link.css';
import { trackLogin } from '../../services/analytics';
import useIsLoggedIn from '../../hooks/useIsLoggedIn';
import Link from '../Link';
import useTranslation from '../../hooks/useTranslation';

/*
 * Use a and not React router Link here so that the link works the same as when obar link is clicked
 */
const LoginLink = () => {
  const { t } = useTranslation();

  return useIsLoggedIn() ? null : (
    <Link
      onClick={trackLogin}
      className={classNames(styles.loginlink, 'button--action')}
      href={routes.LOGIN}
      iconPosition="after"
      iconClassName={styles.icon}
    >
      {t('login')}
    </Link>
  );
};

export default LoginLink;
