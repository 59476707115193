import React from 'react';
import { arrayOf, number } from 'prop-types';
import classNames from 'classnames';
import {
  formatTimeSpan,
  formatDate
} from '../../../../../../utils';
import { getTranslation } from '../../../../../../i18n';
import styles from './studyEvent.css';
import {
  studySubEventType,
  locationType
} from '../../../../../../types';
import {
  ONLINE_COURSE_LOCATION_ID
} from '../../../../../../constants';
import { isDesktop, useDeviceSize } from '../../../../../../context/DeviceSizeContextProvider';
import ShowMore from '../../../../../ShowMore';
import { eventCategories } from '../../../../../../services/analytics';
import Link from '../../../../../Link';
import useTranslation from '../../../../../../hooks/useTranslation';

const DESKTOP_LAYOUT_CONTAINS_ADDITIONAL_INFO_COLUMN_COUNT = 4;

const renderMapLink = (location, lang) => {
  const { building: { address } } = location;
  const baseUrl = 'https://www.google.fi/maps/place';
  const href = `${baseUrl}/${address.streetAddress}, ${address.postalCode}`;

  return (
    <Link
      tracked
      eventCategory={eventCategories.coursePage.LINK}
      trackingLabel="location"
      href={href}
      external
      iconPosition="none"
    >
      {getTranslation(location.building.name, lang)}
    </Link>
  );
};

const StudyEvent = ({
  studySubEvent, eventLocations, columnCount
}) => {
  const { t, lang } = useTranslation();
  const { deviceSize } = useDeviceSize();

  const locations = eventLocations?.map((location) => (
    location.id === ONLINE_COURSE_LOCATION_ID
      ? (
        <div key={location.id}>
          {getTranslation(location.name, lang)}
        </div>
      )
      : (
        <div key={location.id}>
          {getTranslation(location.name, lang)},{' '}
          {renderMapLink(location, lang)}
        </div>
      )
  )) || null;

  const getDateTimeContent = (event) => (isDesktop(deviceSize) ? (
    <>
      <td className={styles.studyEventDate}>
        {formatDate(event.start, lang, { includeWeekday: true })}
      </td>
      <td className={styles.studyEventTime}>
        {formatTimeSpan(event.start, event.end, lang)}
      </td>
    </>
  ) : (
    <td className={styles.studyEventDateTime}>
      <span>{formatDate(event.start, lang)}</span>
      {' '}
      <span>{formatTimeSpan(event.start, event.end, lang)}</span>
    </td>
  ));

  if (isDesktop(deviceSize)) {
    return (
      <tr className={styles.studyEvent}>
        {getDateTimeContent(studySubEvent)}
        <td className={styles.studyEventLocation}>
          {locations}
        </td>
        {columnCount === DESKTOP_LAYOUT_CONTAINS_ADDITIONAL_INFO_COLUMN_COUNT ? (
          <td className={styles.studyEventAdditionalInformation}>
            <ShowMore className={styles.studyEventAdditionalInformation} content={studySubEvent.notice || ''} />
          </td>
        ) : null}
      </tr>
    );
  }

  const eventRowClasses = classNames(styles.studyEvent, { [styles.hasNotice]: !!studySubEvent.notice });

  return (
    <>
      <tr className={eventRowClasses} key={`${studySubEvent.start}${studySubEvent.end}`}>
        {getDateTimeContent(studySubEvent)}
        <td className={styles.studyEventLocation}>
          {locations || '-'}
        </td>
      </tr>
      {studySubEvent.notice && (
        <tr className={styles.studyEventAdditionalInformationRow}>
          <td colSpan={columnCount} className={styles.studyEventAdditionalInformation}>
            <ShowMore
              className={styles.studyEventAdditionalInformation}
              content={studySubEvent.notice}
              preContent={(<span className={styles.notice}>{t('additionalInfo')}:{' '}</span>)}
            />
          </td>
        </tr>
      )}
    </>
  );
};

StudyEvent.propTypes = {
  studySubEvent: studySubEventType.isRequired,
  eventLocations: arrayOf(locationType),
  columnCount: number.isRequired
};

export default StudyEvent;
