import React from 'react';
import { degreeProgramType } from '../../types';
import DegreeProgramInfoContainer from './DegreeProgramInfoContainer';
import DegreeProgramStructure from './DegreeProgramStructure';
import DegreeProgramDescription from './DegreeProgramDescription';
import StudyYearPicker from '../StudyYearPicker';
import useStudyYearPicker from '../../hooks/useStudyYearPicker';
import useTranslation from '../../hooks/useTranslation';

const DegreeProgram = ({
  degreeProgram
}) => {
  const { t } = useTranslation();
  const { selectedYear, changeYear } = useStudyYearPicker(degreeProgram.id, degreeProgram.curriculumPeriods);
  return (
    <>
      <StudyYearPicker value={selectedYear} options={degreeProgram.curriculumPeriods} label={t('studyModule.studyYear')} onChange={changeYear} />
      <DegreeProgramInfoContainer degreeProgram={degreeProgram} />
      <DegreeProgramStructure id={degreeProgram.code} academicYear={selectedYear} />
      <DegreeProgramDescription degreeProgram={degreeProgram} />
    </>
  );
};

DegreeProgram.propTypes = {
  degreeProgram: degreeProgramType
};

export default DegreeProgram;
