/**
 *
 * @param {Array} prequisities
 * @returns
 */
const getPrequisities = (prequisities) => {
  const courseUnitCodes = [];

  prequisities.forEach((prerequisite, index) => {
    prerequisite.prerequisites.forEach((p) => {
      // OPISKELU-1982: Allow only HY courses.
      if (p.courseUnitGroupId
        && !p.courseUnitGroupId.startsWith('hy-')
        && !p.courseUnitGroupId.startsWith('otm-')) {
        return;
      }
      if (p.moduleGroupId
        && !p.moduleGroupId.startsWith('hy-')
        && !p.moduleGroupId.startsWith('otm-')) {
        return;
      }
      if (p.type === 'Module') {
        p.modules.forEach((cu) => {
          if (courseUnitCodes[index] === undefined) {
            courseUnitCodes[index] = [];
          }
          const courseUnitPrerequisite = new Map([['code', cu.code], ['name', cu.name], ['credits', cu.targetCredits]]);
          courseUnitCodes[index].push(courseUnitPrerequisite);
        });
      }
      if (p.type === 'CourseUnit') {
        p.courseUnits.forEach((cu) => {
          if (courseUnitCodes[index] === undefined) {
            courseUnitCodes[index] = [];
          }
          const courseUnitPrerequisite = new Map([['code', cu.code], ['name', cu.name], ['credits', cu.credits]]);
          courseUnitCodes[index].push(courseUnitPrerequisite);
        });
      }
    });
  });
  return courseUnitCodes;
};

/**
 *
 * @param {Array} compulsoryFormalPrerequisites
 * @param {Array} recommendedFormalPrerequisites
 * @returns {Object}
 */
const usePrerequisites = (compulsoryFormalPrerequisites = false, recommendedFormalPrerequisites = false) => {
  if (!compulsoryFormalPrerequisites && !recommendedFormalPrerequisites) {
    return {};
  }

  const data = {};
  if (compulsoryFormalPrerequisites) {
    data.compulsoryFormalPrerequisites = getPrequisities(compulsoryFormalPrerequisites);
  }
  if (recommendedFormalPrerequisites) {
    data.recommendedFormalPrerequisites = getPrequisities(recommendedFormalPrerequisites);
  }
  return data;
};

export default usePrerequisites;
