import React from 'react';
import StudyGroupSet from '../StudyGroupSet';
import Section from '../../../Section';
import { courseUnitRealisationType } from '../../../../types';
import { formatSemiOpenDateSpan } from '../../../../utils';
import styles from './courseSchedule.css';
import { ONLINE_COURSE_REALISATION_TYPE_URN } from '../../../../constants';
import ParagraphedText from '../../ParagraphedText';
import Link from '../../../Link';
import { ArrowRight } from '../../../Icon/Arrow';
import useTranslation from '../../../../hooks/useTranslation';

const getStudyGroupSets = (courseUnitRealisation) => courseUnitRealisation.studyGroupSets.map(
  (studyGroupSet) => (
    <StudyGroupSet
      key={studyGroupSet.localId}
      studyGroupSet={studyGroupSet}
    />
  )
);

const getOnlineCourseSchedule = (courseDateRange, t, lang) => {
  const { startDate, endDate } = courseDateRange;
  const dateSpan = formatSemiOpenDateSpan(startDate, endDate, lang, { includeYear: true, includeWeekday: true });
  return (<h3 className={`online-course-schedule ${styles.onlineCourseSchedule}`}>{t('scheduleOnlineCourse')} {dateSpan}</h3>);
};

const getClassroomAccessibilityNote = (t, lang) => (
  <div aria-label={t('scheduleAccessibility')} className={`course-schedule-study-location-accessibility-note ${styles.accessibilityNote}`}>
    <Link href={`https://tilavaraus.helsinki.fi/${lang}`} external iconOverride={ArrowRight}>
      <span className="icon--alert" aria-hidden="true" />{t('scheduleAccessibility')}
    </Link>
  </div>
);

const CourseSchedule = ({ courseUnitRealisation = {} }) => {
  const { t, lang } = useTranslation();
  const { cmsData } = courseUnitRealisation;

  const allEvents = courseUnitRealisation.studyGroupSets
    ?.flatMap((sgs) => sgs.studySubGroups
      .flatMap((ssg) => (ssg.studyEvents || []).flatMap((se) => se.events.map((e) => ({ ...e, locations: se.locations }))))) || [];

  const isOnlineCourse = courseUnitRealisation.courseUnitRealisationType?.urn === ONLINE_COURSE_REALISATION_TYPE_URN;
  const hasStudyEventLocations = allEvents?.some((e) => e.locations?.length);

  if (!cmsData?.scheduleInstructions
    && allEvents.length === 0
    && !isOnlineCourse
    && !hasStudyEventLocations) {
    return null;
  }

  return (
    <Section label={t('schedule')} level={2}>
      {cmsData?.scheduleInstructions && <ParagraphedText text={cmsData.scheduleInstructions} className={styles.scheduleInstructions} />}
      {allEvents.length === 0
        ? isOnlineCourse && getOnlineCourseSchedule(courseUnitRealisation.activityPeriod, t, lang)
        : getStudyGroupSets(courseUnitRealisation)}
      {hasStudyEventLocations && getClassroomAccessibilityNote(t, lang)}
    </Section>
  );
};

CourseSchedule.propTypes = {
  courseUnitRealisation: courseUnitRealisationType
};

export default CourseSchedule;
