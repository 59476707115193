import React from 'react';
import {
  arrayOf, bool, node, number, oneOfType, string
} from 'prop-types';
import classNames from 'classnames';

import { localizedTextType, newsItemType } from '../../types';
import styles from './newsContainer.css';
import NewsItem from '../NewsItem';
import { getTranslation } from '../../i18n';
import { eventActions } from '../../services/analytics';
import HyphenatedText from '../HyphenatedText';
import Link from '../Link';
import useTranslation from '../../hooks/useTranslation';

const NewsContainer = ({
  header, subHeader, controls, newsItems, itemTypeLabel, showAllLink, showAllLabel, itemsToShow,
  external, trackedEventCategory, showItemIngress = true
}) => {
  const { t, lang } = useTranslation();
  // don't render if there are no controls AND A) news are loading (undefined)
  // or B) there is no content (empty array)
  if (!controls && (newsItems === undefined || newsItems?.length === 0)) {
    return null;
  }

  const SubHeader = () => {
    if (!subHeader) {
      return null;
    }

    const translated = typeof subHeader === 'string'
      ? t(subHeader, lang)
      : getTranslation(subHeader, lang);

    return (
      <div className={styles.subHeader}>
        <HyphenatedText text={translated} lang={lang} />
      </div>
    );
  };

  const ShowAllLink = () => {
    const ariaLabel = t(showAllLabel);
    const isTracked = Boolean(trackedEventCategory);

    return (
      <Link
        href={showAllLink}
        className={classNames('button', 'button--outline', styles.showAllButton)}
        external={external}
        iconPosition="after"
        iconClassName={styles.externalLinkIcon}
        tracked={isTracked}
        eventCategory={isTracked ? trackedEventCategory : undefined}
        eventAction={isTracked ? eventActions.SHOW_ALL : undefined}
        noTrackingLabel
        aria-label={ariaLabel}
      >
        {t('showAll')}
      </Link>
    );
  };

  return (
    <section className={styles.container}>
      <h2 className={styles.header}>
        <HyphenatedText text={t(header)} lang={lang} />
        <SubHeader />
      </h2>
      <div className={styles.controlsContainer}>
        {controls}
      </div>
      <div className={`${styles.newsContainer} grid-row stacked-mobile-only`}>
        {Array.isArray(newsItems) || newsItems === undefined
          ? newsItems?.slice(0, itemsToShow).map((item) => (
            <NewsItem
              key={item.id}
              externalLink={external}
              item={item}
              typeLabel={itemTypeLabel}
              trackedEventCategory={trackedEventCategory}
              showIngress={showItemIngress}
            />
          ))
          : (<span className={styles.noNews}>{t('noNews', lang)}</span>)}
      </div>
      {Array.isArray(newsItems) && newsItems.length > 0 && (
        <div className={styles.buttonContainer}>
          <ShowAllLink />
        </div>
      )}
    </section>
  );
};

NewsContainer.propTypes = {
  header: node.isRequired,
  subHeader: oneOfType([string, localizedTextType]),
  controls: node,
  newsItems: arrayOf(newsItemType),
  itemTypeLabel: string.isRequired,
  showAllLink: string.isRequired,
  showAllLabel: string.isRequired,
  itemsToShow: number,
  external: bool,
  trackedEventCategory: string,
  showItemIngress: bool
};

NewsContainer.defaultProps = {
  subHeader: undefined,
  itemsToShow: 3,
  external: false
};

export default NewsContainer;
