import React, { useState, useEffect } from 'react';
import { addHours, parseISO } from 'date-fns';
import { getUserEvents } from '../../../api';
import WrappedReactCalendar from './WrappedReactCalendar';
import ErrorBoundary from '../../ErrorBoundary';
import useTranslation from '../../../hooks/useTranslation';

const Calendar = () => {
  const { lang } = useTranslation();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      try {
        const response = await getUserEvents(lang);
        if (!isCancelled && Array.isArray(response)) {
          const parsed = response.map((event) => {
            const start = parseISO(event.startDate);
            const end = event.endDate ? parseISO(event.endDate) : addHours(start, 1);
            return {
              ...event, start, end
            };
          });
          setEvents(parsed);
        }
      } catch {
        setEvents([]);
      }
    })();
    return () => {
      isCancelled = true;
    };
  }, [lang]);

  return <WrappedReactCalendar events={events} />;
};

export const CalendarLocalized = Calendar;

export default () => (
  <ErrorBoundary headerLevel={2}>
    <CalendarLocalized />
  </ErrorBoundary>
);
