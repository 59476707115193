import React from 'react';
import { shape } from 'prop-types';
import { getTranslation } from '../../../i18n';
import { withPTag } from '../../../utils';
import Section from '../../Section';
import useTranslation from '../../../hooks/useTranslation';

const DegreeProgramDescription = (
  {
    degreeProgram: { learningOutcomes, contentDescription, additionalInfo }
  }) => {
  const { t, lang } = useTranslation();

  return ((learningOutcomes || contentDescription || additionalInfo) ? (
    <>
      <h2>{t('degreeProgram.degreeProgramDescription')}</h2>
      { learningOutcomes && (
        <Section>
          <h3>{t('degreeProgram.properties.learningOutcomes')}</h3>
          {/* eslint-disable-next-line react/no-danger */}
          <div className="rich-text" dangerouslySetInnerHTML={withPTag(getTranslation(learningOutcomes, lang))} />
        </Section>
      )}
      { contentDescription && (
        <Section>
          <h3>{t('degreeProgram.properties.contentDescription')}</h3>
          {/* eslint-disable-next-line react/no-danger */}
          <div className="rich-text" dangerouslySetInnerHTML={withPTag(getTranslation(contentDescription, lang))} />
        </Section>
      )}
      { additionalInfo && (
        <Section>
          <h3>{t('degreeProgram.properties.additionalInfo')}</h3>
          {/* eslint-disable-next-line react/no-danger */}
          <div className="rich-text" dangerouslySetInnerHTML={withPTag(getTranslation(additionalInfo, lang))} />
        </Section>
      )}
    </>
  ) : null);
};

DegreeProgramDescription.propTypes = {
  degreeProgram: shape({})
};

export default DegreeProgramDescription;
