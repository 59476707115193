import React from 'react';
import { courseUnitRealisationType } from '../../../../types';
import { breadcrumbs } from '../../../../constants';
import { getOrganisationBackgroundImage } from '../../../../utils';

import InfoCard from './InfoCard';
import Enrolment from './Enrolment';
import Tags from './Tags';
import styles from './courseHeader.css';
import Breadcrumb from '../../../Breadcrumb';
import DepthIndicator from '../../../DepthIndicator';

const CourseHeader = ({ courseUnitRealisation }) => {
  const backgroundImageUrl = getOrganisationBackgroundImage(courseUnitRealisation.organisationBackgroundImages);

  return (
    <>
      <div className={`grid-container ${styles.container}`} style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
        <div className={`page-wide-content-padding ${styles.courseCard}`}>
          <Breadcrumb crumb={breadcrumbs.COURSES} />
          <section className={`${styles.topHat}`}>
            <InfoCard courseUnitRealisation={courseUnitRealisation} />
            <Tags courseUnitRealisation={courseUnitRealisation} />
            <Enrolment courseUnitRealisation={courseUnitRealisation} />
          </section>
        </div>
      </div>
      <div className={`grid-container page-wide-content-padding ${styles.depthContainer}`}> <DepthIndicator cur /> </div>
    </>
  );
};

CourseHeader.propTypes = {
  courseUnitRealisation: courseUnitRealisationType.isRequired
};

export default CourseHeader;
