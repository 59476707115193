import React from 'react';
import PropTypes, { string } from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import styles from './courseUnitStudyModules.css';
import Section from '../../../Section';
import { hierarchyModule } from '../../../../types';
import { DOCUMENT_STATES, smPathTranslations } from '../../../../constants';
import { getTranslation } from '../../../../i18n';
import useTranslation from '../../../../hooks/useTranslation';

const getStudyModuleUrlPath = (sm, lang, cpId) => `/${smPathTranslations[lang]}/${sm.id}${cpId ? `?cpId=${cpId}` : ''}`;

const CourseUnitStudyModules = ({
  majorStudyModules,
  minorStudyModules,
  selectedYear
}) => {
  const { t, lang } = useTranslation();
  const smLink = (sm) => (sm.documentState === DOCUMENT_STATES.ACTIVE ? (
    <Link
      key={sm.id}
      className="old-button--action-before icon--arrow-left"
      to={getStudyModuleUrlPath(sm, lang, selectedYear)}
    >
      {t('courseUnit.studyModule.title')} {sm.code} {getTranslation(sm.name, lang)}
    </Link>
  ) : (
    <span key={sm.id} className="old-button--action-before--disabled icon--arrow-left">
      {t('courseUnit.studyModule.title')} {sm.code} {getTranslation(sm.name, lang)}
      {' ('}{t('notAvailable')}{')'}
    </span>
  ));

  return (
    <Section className={classNames('page-wide-content-padding', styles.courseUnitStudyModules)}>
      <h2>{t('courseUnit.studyModule.title')}</h2>
      {!!majorStudyModules.length
        && (
          <p>
            {t(`courseUnit.studyModule.descriptionStart${majorStudyModules.length === 1 ? 'Single' : 'Multiple'}`)}
            {' '}
            {t('courseUnit.studyModule.descriptionEnd')}
          </p>
        )}
      <div className={styles.linkList}>
        {majorStudyModules.map(smLink)}
      </div>
      {!!minorStudyModules.length
        && (
          <>
            <h3>{t('courseUnit.studyModule.availableAsMinor')}</h3>
            <div className={styles.linkList}>
              {minorStudyModules.map(smLink)}
            </div>
          </>
        )}
    </Section>
  );
};

CourseUnitStudyModules.propTypes = {
  majorStudyModules: PropTypes.arrayOf(hierarchyModule).isRequired,
  minorStudyModules: PropTypes.arrayOf(hierarchyModule).isRequired,
  selectedYear: string
};

export default CourseUnitStudyModules;
