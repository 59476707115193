import React from 'react';
import { func, bool } from 'prop-types';
import classNames from 'classnames';

import TodoItemInput from './TodoItemInput';
import { todoItemStatusValues, todoItemType } from '../../../../types';

import styles from './todoItem.css';
import { READ_ONLY_DB } from '../../../../env';
import useTranslation from '../../../../hooks/useTranslation';

const TodoItem = ({
  item,
  isSelected,
  onMarkItemDone,
  onSelectItem,
  onSaveItem,
  onRemoveItem,
  onCancel,
  disabled = false,
  buttonRef
}) => {
  const { t } = useTranslation();
  const itemDone = item.status === todoItemStatusValues.DONE;
  const checkboxId = item.id ? item.id : 'New';
  const itemClasses = classNames(styles.todoItem, { [styles.done]: itemDone });
  const buttonClasses = classNames('button', 'button--link', styles.button);

  const onSetContent = READ_ONLY_DB ? () => {} : (content, pressedEnter) => {
    item.content = content;
    onSaveItem(item, pressedEnter);
  };

  const onContentClick = READ_ONLY_DB ? () => {} : () => {
    if (!itemDone) {
      onSelectItem(item.id);
    }
  };

  const onContentKeyDown = READ_ONLY_DB ? () => {} : (e) => {
    if (!itemDone && e.key === 'Enter') {
      onSelectItem(item.id, true);
    }
  };

  return (
    <li className={itemClasses}>
      <div>
        <input
          id={checkboxId}
          type="checkbox"
          checked={itemDone}
          disabled={itemDone}
          onChange={() => onMarkItemDone(item.id)}
          aria-label={itemDone ? t('todo.itemDone') : t('todo.markDone', { item: item.content })}
          aria-checked={itemDone}
        />
      </div>
      {isSelected
        ? (
          <TodoItemInput
            content={item.content}
            onSetContent={onSetContent}
            onCancel={onCancel}
          />
        )
        : (
          <button
            className={`${styles.content} content`}
            ref={buttonRef}
            type="button"
            tabIndex={itemDone ? -1 : 0}
            onClick={onContentClick}
            onKeyDown={onContentKeyDown}
            aria-label={itemDone ? '' : `${t('todo.editItem')} ${item.content}`}
            disabled={disabled}
          >
            {item.content}
          </button>
        )}
      <div>
        <button
          className={buttonClasses}
          type="button"
          onClick={() => onRemoveItem(item.id)}
          aria-label={`${t('todo.delete')} ${item.content}`}
          disabled={disabled}
        >
          <span className={`icon--remove ${styles.removeIcon}`} />
        </button>
      </div>
    </li>
  );
};

TodoItem.propTypes = {
  item: todoItemType.isRequired,
  isSelected: bool.isRequired,
  onSelectItem: func,
  onCancel: func.isRequired,
  onSaveItem: func.isRequired,
  onRemoveItem: func.isRequired,
  onMarkItemDone: func,
  disabled: bool,
  buttonRef: func
};

export default TodoItem;
