import React from 'react';
import {
  arrayOf, bool, func, instanceOf, number, shape, string
} from 'prop-types';

import {
  formatCourseName,
  formatCredits,
  formatDate,
  formatDateTimeSpan
} from '../../../../utils';
import { isTabletOrSmaller, useDeviceSize } from '../../../../context/DeviceSizeContextProvider';
import { eventActions, eventCategories } from '../../../../services/analytics';

import styles from './enrollmentsByType.css';
import Link from '../../../Link';
import useTranslation from '../../../../hooks/useTranslation';

const enrollmentType = shape({
  realisationId: string,
  name: string,
  nameSpecifier: string,
  credits: shape({ min: number, max: number }),
  typeCode: number,
  startDate: instanceOf(Date),
  endDate: instanceOf(Date),
  teachers: arrayOf(string),
  coursePageUri: string,
  courseMaterial: shape({
    courseMaterialType: string,
    moodleLink: string,
    moodlePublishTime: string,
    moodleSchedule: bool,
    moodlePublished: bool,
    moodleSchedPublished: bool
  })
});

const Enrollment = ({ enrollment, lang }) => {
  const { t } = useTranslation();
  const { deviceSize } = useDeviceSize();

  const title = `${formatCourseName(enrollment.realisationId, enrollment.name, enrollment.nameSpecifier, lang)}, ${formatCredits(enrollment.credits, lang)}`;

  const dateSpan = formatDateTimeSpan(enrollment.startDate, enrollment.endDate, lang, { includeTime: false });

  const EnrollmentTitle = () => (enrollment.coursePageUri ? (
    <Link
      tracked
      eventCategory={eventCategories.myStudies.COURSES_EXAMS}
      eventAction={eventActions.COURSES}
      href={enrollment.coursePageUri}
      className={styles.title}
    >
      {title}
    </Link>
  ) : (
    <span className={styles.title}>{title}</span>
  ));

  const EnrollmentInfo = () => (<div>{[enrollment.code, ...enrollment.teachers].filter(Boolean).join(', ')}</div>);

  const EnrollmentType = () => (<span className={styles.type}>{t(`coursesExams.courseTypes.${enrollment.typeCode}`)}</span>);

  const MoodleLink = () => ((enrollment.courseMaterial && enrollment.courseMaterial.moodleLink && enrollment.courseMaterial.courseMaterialType === 'MOODLE') ? (
    <div className={styles.moodleLinkContainer}>
      <Link
        tracked
        eventCategory={eventCategories.myStudies.COURSES_EXAMS}
        eventAction={eventActions.MOODLE}
        href={enrollment.courseMaterial.moodleLink}
        external
        className="button button--outline"
      >
        {t('coursesExams.moodleLink')}
      </Link>
      { enrollment.courseMaterial.moodleSchedule && enrollment.courseMaterial.moodlePublishTime && !enrollment.courseMaterial.moodleSchedPublished
        && (
          <span>
            {t('moodleLink.schedule', { publishTime: formatDate(enrollment.courseMaterial.moodlePublishTime, lang) })}
          </span>
        )}
    </div>
  ) : null);

  if (isTabletOrSmaller(deviceSize)) {
    return (
      <div className={`enrollment ${styles.enrollmentContainer}`}>
        <EnrollmentType />
        <EnrollmentTitle />
        {dateSpan}
        <EnrollmentInfo />
        <MoodleLink />
      </div>
    );
  }

  return (
    <div className={`enrollment ${styles.enrollmentContainer}`}>
      <div className={styles.infoContainer}>
        <EnrollmentTitle />
        <EnrollmentInfo />
      </div>
      <div className={styles.moodleLinkContainer}>
        <MoodleLink />
      </div>
      <div className={styles.sideContainer}>
        <EnrollmentType />
        {dateSpan}
      </div>
    </div>
  );
};

Enrollment.propTypes = {
  enrollment: enrollmentType.isRequired,
  lang: string
};

const EnrollmentsByType = ({
  label, placeholder, enrollments, translate: t, lang
}) => (
  <>
    <h3 className={styles.typeHeader}>{label} ({enrollments.length} {t('pcs')})</h3>
    {enrollments && enrollments.length > 0
      ? enrollments.map((enrollment) =>
        <Enrollment key={enrollment.realisationId} enrollment={enrollment} lang={lang} />)
      : <div className={`enrollment-placeholder ${styles.placeholder}`}>{placeholder}</div>}
  </>
);

EnrollmentsByType.propTypes = {
  label: string,
  placeholder: string,
  enrollments: arrayOf(enrollmentType),
  translate: func,
  lang: string
};

export default EnrollmentsByType;
