import React from 'react';

import { number } from 'prop-types';
import Section from '../../Section';
import { getTranslation } from '../../../i18n';
import { courseUnitRealisationType, courseUnitType } from '../../../types';
import useTranslation from '../../../hooks/useTranslation';

const LearningMaterials = ({
  courseUnitRealisation, courseUnit, headerLevel
}) => {
  const { t, lang } = useTranslation();

  const content = getTranslation((courseUnitRealisation || courseUnit)?.learningMaterial, lang);

  if (!content) {
    return null;
  }

  return (
    <Section level={headerLevel} label={t('learningMaterials')} noUpperCaseLabel={Boolean(courseUnit)} html>
      {content}
    </Section>
  );
};

LearningMaterials.propTypes = {
  courseUnitRealisation: courseUnitRealisationType,
  courseUnit: courseUnitType,
  headerLevel: number.isRequired
};

export default LearningMaterials;
