import React, { useRef, useState } from 'react';
import { bool, func } from 'prop-types';
import { flatMap } from 'lodash';
import { isAfter, parseISO } from 'date-fns';
import { ASSESSMENT_ITEM_TYPE_EXAM_URN, HIGHLIGHT_START_TAG } from '../../../constants';

import { coursesSearchResultType } from '../../../types';
import { getTranslation, getTranslationWithLocale } from '../../../i18n';
import {
  formatCourseName,
  formatCredits,
  formatDate,
  formatDateTimeSpan,
  formatSemiOpenDateSpan,
  getCourseParticipationType,
  getSummaryText,
  isBeforeOrEqual,
  parseCUCodes
} from '../../../utils';

import styles from './resultItem.css';
import { eventActions, eventCategories } from '../../../services/analytics';
import TeachingLanguages from '../../TeachingLanguages';
import Link from '../../Link';
import useTranslation from '../../../hooks/useTranslation';

const jointCourseImage = require('../../../images/joint_course.png');

const ResultItem = ({
  result, isDegreeProgramSearch, setScrollPosToState = () => {}
}) => {
  const { t, lang } = useTranslation();
  const [studyGroupsVisible, setStudyGroupsVisible] = useState(false);
  const focusRef = useRef(null);

  const hasMultipleStudySubGroups = (studyGroupSets) => (
    studyGroupSets && flatMap(studyGroupSets, (sgs) => sgs.studySubGroups?.filter((ssg) => !ssg.cancelled)).length > 1
  );

  const searchResultEventCategory = (isDegreeProgramSearch ? eventCategories.dpSearch : eventCategories.courses).SEARCH_RESULT;

  const renderSnippet = (text) => {
    const segments = text.split(HIGHLIGHT_START_TAG).reduce((prev, val, index) => {
      if (index % 2 === 1) {
        prev.push(<b key={[index, val].join('-')}>{ val }</b>);
      } else {
        prev.push(val);
      }

      return prev;
    }, []);

    return <>{segments}</>;
  };

  const renderStudyGroupSetHeader = (studyGroupSet, studySubGroups) => (studySubGroups.length > 1 ? (
    <h5 className={styles.studyGroupSetName}>
      {getTranslation(studyGroupSet.name, lang)}
    </h5>
  ) : null);

  const formatStudySubGroupTiming = (studySubGroup) => (studySubGroup.fromDate && studySubGroup.toDate
    ? formatDateTimeSpan(studySubGroup.fromDate, studySubGroup.toDate, lang, { includeTime: false })
    : null);

  const renderStudySubGroups = (studyGroupSet, studySubGroups, index) => studySubGroups.map((ssg) => (
    <div key={`${index}-${studyGroupSet.localId}-${ssg.id}`} className={styles.studySubGroupContainer}>
      <div className={styles.studySubGroupName}>
        <b>{getTranslation(ssg.name, lang)}</b>
        {ssg.teacherNames.length ? renderSnippet(`: ${ssg.teacherNames.join(', ')}`) : ''}
      </div>
      <div className={styles.studySubGroupTiming}>
        {formatStudySubGroupTiming(ssg)}
      </div>
    </div>
  ));

  const renderStudyGroupSet = (studyGroupSet, index) => {
    const studySubGroups = studyGroupSet.studySubGroups.filter((ssg) => !ssg.cancelled);
    if (!studySubGroups.length) {
      return null;
    }
    return (
      <div key={`${index}-${studyGroupSet.localId}`} className={styles.studyGroup}>
        <div>
          {renderStudyGroupSetHeader(studyGroupSet, studySubGroups)}
          {renderStudySubGroups(studyGroupSet, studySubGroups, index)}
        </div>
      </div>
    );
  };

  const renderStudyGroupSection = (studyGroupSets, isExam) => (
    hasMultipleStudySubGroups(studyGroupSets) && !isExam ? (
      <div className={[styles.studyGroups, 'result-studygroups'].join(' ')}>
        {studyGroupsVisible
          ? (
            <div>
              {studyGroupSets.map(renderStudyGroupSet)}
            </div>
          ) : null}
        <div>
          <button
            type="button"
            className={`link theme-transparent button--action-before ${(studyGroupsVisible ? 'icon--caret-up' : 'icon--caret-down')} ${styles.moreContent}`}
            onClick={() => ((!setStudyGroupsVisible && focusRef.current.focus()) || setStudyGroupsVisible(!studyGroupsVisible))}
            aria-expanded={studyGroupsVisible}
          >
            {t((studyGroupsVisible && 'search.results.lessContent') || 'search.results.moreContent')}
          </button>
        </div>
      </div>
    ) : null
  );

  const formatTeacherNames = (teacherNames) => (
    [...new Set(teacherNames)].map((teacher, i) => (<span key={teacher}>{i > 0 ? ', ' : ''}{renderSnippet(teacher)}</span>))
  );

  const renderLinkText = () => (
    <span>{renderSnippet(formatCourseName(result.curId, result.name, result.nameSpecifier, lang))}</span>
  );

  const renderLinkToCoursePage = () => {
    const { coursePageUrl } = result;

    const handleLinkClick = (e) => {
      // only navigate to the top of the results on left mouse 'click'
      // 'context menu' opens on right mouse click (e.type === 'contextmenu')
      if (e.type === 'click' && !e.ctrlKey) {
        setScrollPosToState(e);
      }
    };

    return coursePageUrl ? (
      <Link
        href={coursePageUrl}
        onClick={handleLinkClick}
        useRouter
        tracked
        fromSearchResult
        eventCategory={searchResultEventCategory}
        eventAction={eventActions.NEW_COURSE_PAGE}
      >
        {renderLinkText()}
      </Link>
    ) : null;
  };

  const renderCodes = (cur) => {
    const codes = parseCUCodes(cur);
    return <>{renderSnippet(codes.codes)}{ codes.extraCount ? ` ${t('search.multipleCUCodes', { count: codes.extraCount })}` : ''}</>;
  };

  const summary = getSummaryText(result, lang);
  const courseSummary = (
    <div className={styles.courseIngress}>
      {summary ? renderSnippet(summary) : ''}
    </div>
  );

  const courseBasicInfo = (
    <div className="course-info">
      {renderCodes(result)}
      {' | '}
      {formatCredits(result.credits, lang)}
      {Boolean(result.teacherNames?.length) && <> | {formatTeacherNames(result.teacherNames)}</>}
      {Boolean(result.teachingLanguages?.length) && (
        <>
          {' | '}
          <TeachingLanguages teachingLanguages={result.teachingLanguages} titleSeparator=":" />
        </>
      )}
    </div>
  );

  const courseActivityPeriod = result.activityPeriod != null ? (
    <div className={styles.activityPeriod}>
      {formatSemiOpenDateSpan(result.activityPeriod.startDate, result.activityPeriod.endDate, lang)}
    </div>
  ) : null;

  const renderCourseEnrolmentPeriod = () => {
    if (!result.enrolmentPeriod) {
      return null;
    }

    const enrolmentEnd = parseISO(result.enrolmentPeriod.endDateTime);
    const lateEnrolmentEnd = parseISO(result.lateEnrolmentEnd);
    const now = new Date();

    let translationKey;
    let translationData;
    if (isAfter(now, enrolmentEnd) && isBeforeOrEqual(now, lateEnrolmentEnd)) {
      translationKey = 'search.lateEnrolment';
      translationData = { until: formatDate(result.lateEnrolmentEnd, lang) };
    } else {
      translationKey = 'search.enrolmentPeriod';
      translationData = {
        period: formatDateTimeSpan(result.enrolmentPeriod.startDateTime,
          result.enrolmentPeriod.endDateTime, lang, { includeTime: false })
      };
    }

    return (
      <div className={styles.enrolmentPeriod}>
        {t(translationKey, translationData)}
      </div>
    );
  };

  const jointCourseImageAlt = getTranslationWithLocale('search.jointCourseImageAlt', lang);

  return (
    <div className={styles.container}>
      <div className={styles.resultHeader}>
        <div className={styles.mobileResultType}>
          {(result.isOpenUniversityCourse) && <img className={styles.jointCourseImg} src={jointCourseImage} alt={jointCourseImageAlt} />}
          {getCourseParticipationType(result.curType, result.type, lang)}
        </div>
        <div className={styles.coursePageLink}>
          {renderLinkToCoursePage()}
        </div>
        <div className={styles.mobileResultTime}>
          {courseActivityPeriod}
        </div>
        {courseBasicInfo}
        {courseSummary}
      </div>
      <div className={styles.resultTime}>
        <div className={styles.resultType}>
          {(result.isOpenUniversityCourse) && <img className={styles.jointCourseImg} src={jointCourseImage} alt={jointCourseImageAlt} />}
          {getCourseParticipationType(result.curType, result.type, lang)}
        </div>
        {courseActivityPeriod}
        {renderCourseEnrolmentPeriod()}
      </div>
      <div className={styles.break} />
      <div className={styles.resultContent} aria-expanded={studyGroupsVisible}>
        {renderStudyGroupSection(result.studyGroupSets, result.type === ASSESSMENT_ITEM_TYPE_EXAM_URN)}
      </div>
    </div>
  );
};

ResultItem.propTypes = {
  result: coursesSearchResultType.isRequired,
  setScrollPosToState: func,
  isDegreeProgramSearch: bool
};

ResultItem.defaultProps = {
  isDegreeProgramSearch: false
};

export default ResultItem;
