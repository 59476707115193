// Modified from: https://github.com/sergeyleschev/react-custom-hooks
import { useState, useCallback } from 'react';
import Cookies from 'js-cookie';

const uriEncodedCookies = Cookies.withConverter({
  read(value) {
    return decodeURIComponent(value);
  },
  write(value) {
    return encodeURIComponent(value);
  }
});

export default function useUriEncodedCookie(name, defaultValue) {
  const [value, setValue] = useState(() => {
    const cookie = uriEncodedCookies.get(name);
    if (cookie) return cookie;
    uriEncodedCookies.set(name, defaultValue);
    return defaultValue;
  });

  const updateCookie = useCallback(
    (newValue, options) => {
      uriEncodedCookies.set(name, newValue, options);
      setValue(newValue);
    },
    [name]
  );

  const deleteCookie = useCallback(() => {
    uriEncodedCookies.remove(name);
    setValue(null);
  }, [name]);

  return [value, updateCookie, deleteCookie];
}
