import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './message.css';

const Message = ({
  children, status, loading, error
}) => {
  const classes = classNames('messages', status, {
    [`icon--spinner icon-spin ${styles.loading}`]: loading,
    loading,
    error
  });
  return <div className={classes}>{children}</div>;
};

Message.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  status: PropTypes.string
};

Message.defaultProps = {
  loading: false,
  error: false,
  status: 'status'
};

export default Message;
