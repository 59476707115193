import PropTypes, { shape } from 'prop-types';
import React from 'react';
import styles from './degreeProgramInfoContainer.css';
import { formatCredits, getResponsibleTeachers } from '../../../utils';
import { studyModuleType } from '../../../types';
import { getTranslation } from '../../../i18n';
import DepthIndicator from '../../DepthIndicator';
import useTranslation from '../../../hooks/useTranslation';

const DegreeProgramInfoField = ({
  degreeProgram,
  property,
  formatFunc
}) => {
  const { t, lang } = useTranslation();

  const data = degreeProgram[property];
  if (!data) return null;
  return (
    <p><span className={styles.boldSpan}>{t(`degreeProgram.properties.${property}`)}:</span> {formatFunc(data, lang)}</p>
  );
};

DegreeProgramInfoField.propTypes = {
  degreeProgram: shape({}),
  property: PropTypes.string,
  formatFunc: PropTypes.func
};

const getTranslationToName = (objectWithName, lang) => getTranslation(objectWithName.name, lang);

const DegreeProgramInfoContainer = ({
  degreeProgram
}) => {
  const { lang } = useTranslation();

  return (
    <div className={`page-wide-content-padding ${styles.content}`}>
      <h1>
        {getTranslation(degreeProgram.name, lang)}
        {' '}
        ({formatCredits(degreeProgram.targetCredits, lang)})
      </h1>
      <DegreeProgramInfoField degreeProgram={degreeProgram} property="degreeProgrammeType" formatFunc={getTranslationToName} />
      <DegreeProgramInfoField
        degreeProgram={degreeProgram}
        property="degreeTitles"
        formatFunc={(titles) => titles.map((title) => getTranslationToName(title, lang)).join(', ')}
      />
      <DegreeProgramInfoField
        degreeProgram={degreeProgram}
        property="responsibilityInfos"
        formatFunc={getResponsibleTeachers}
      />
      <DegreeProgramInfoField
        degreeProgram={degreeProgram}
        property="organisations"
        formatFunc={(organisations) => (
          organisations
            .map(({ organisation }) => getTranslationToName(organisation, lang))
            .join(', ')
        )}
      />
      <DegreeProgramInfoField
        degreeProgram={degreeProgram}
        property="degreeLanguages"
        formatFunc={(languages) => languages.map((language) => getTranslationToName(language, lang)).join(', ')}
      />
      <DepthIndicator dp />
    </div>
  );
};

DegreeProgramInfoContainer.propTypes = {
  degreeProgram: studyModuleType
};

export default DegreeProgramInfoContainer;
