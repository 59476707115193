import React, { useState } from 'react';
import {
  isWithinInterval, parseISO
} from 'date-fns';
import classNames from 'classnames';

import ModalDialog from '../../../../../ModalDialog';
import { courseUnitRealisationType } from '../../../../../../types';
import { getTranslation } from '../../../../../../i18n';

import styles from './subOpenUniversityProductEnrolment.css';
import { ArrowOffsite } from '../../../../../Icon/Arrow';
import { getEnrolmentEnd } from '../util';
import { cuPathTranslations } from '../../../../../../constants';
import useTranslation from '../../../../../../hooks/useTranslation';
import Link from '../../../../../Link';

const SubOpenUniversityProductEnrolment = ({ courseUnitRealisation }) => {
  const { t, lang } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const {
    courseUnits, enrolmentPeriod, lateEnrolmentEnd, sisuUrl
  } = courseUnitRealisation;

  if (!enrolmentPeriod) {
    // If realisation does not have enrolment period, it is not possible to enroll to the realisation
    return null;
  }

  if (!courseUnits.length) {
    // If no course units, it is not possible to enrol to the open university product
    return null;
  }

  const isSingleCourseUnit = courseUnits.length === 1;

  const modalId = 'sub-oup-enrolment-dialog';

  const startDateTime = parseISO(enrolmentPeriod.startDateTime);
  const endDateTime = parseISO(enrolmentPeriod.endDateTime);
  const lateEndDateTime = parseISO(lateEnrolmentEnd);
  const now = new Date();

  if (!isWithinInterval(now, { start: startDateTime, end: lateEnrolmentEnd ? lateEndDateTime : endDateTime })) {
    return null;
  }

  const goToSisu = () => {
    window.open(`${sisuUrl}/student/enrolments`, '_blank');
    setModalOpen(false);
  };

  const getConfirmLabel = () => (
    <span className={styles.goToSisu}>
      {t('openUniversityProductEnrolment.modal.goToSisu')}
      <span className="sr-only">, {t('openInNewWindow')}</span>
      <ArrowOffsite className={styles.offsiteArrow} aria-hidden="true" />
    </span>
  );

  return (
    <>
      <button
        type="button"
        className={classNames('button--action', styles.goToEnrolment)}
        onClick={() => setModalOpen(true)}
        aria-haspopup="dialog"
        aria-controls={modalId}
        aria-expanded={modalOpen}
      >
        {t('openUniversityProductEnrolment.goToEnrolment')}
      </button>
      {modalOpen ? (
        <ModalDialog
          id={modalId}
          onConfirm={goToSisu}
          onCancel={() => setModalOpen(false)}
          confirmLabel={getConfirmLabel()}
          cancelLabel={t('openUniversityProductEnrolment.modal.return')}
          buttonsClassName={styles.dialogButton}
          reverseButtons
          modalDialogTitle={t('openUniversityProductEnrolment.modal.title')}
          isEnrolment
        >
          <div>
            {isSingleCourseUnit
              ? t('openUniversityProductEnrolment.modal.descriptionSingle')
              : t('openUniversityProductEnrolment.modal.descriptionMany')}
          </div>
          {courseUnits.map((courseUnit) => (
            <div key={courseUnit.id} className={styles.courseUnit}>
              <p id={`enrolment-course-unit-${courseUnit.id}`} className={styles.courseUnitDescription}>
                {courseUnit.code} {getTranslation(courseUnit.name, lang)}
              </p>
              <Link
                id={`enrolment-course-unit-link-${courseUnit.id}`}
                aria-labelledby={`enrolment-course-unit-link-${courseUnit.id} enrolment-course-unit-${courseUnit.id}`}
                external
                href={`/${getTranslation(cuPathTranslations, lang)}/${courseUnit.id}/${courseUnit.code}`}
              >
                {t('openUniversityProductEnrolment.modal.courseUnitLink')}
              </Link>
            </div>
          ))}
          <div className={styles.enrolmentInfoItem}>
            <span className={styles.index}>1.</span>
            {t('openUniversityProductEnrolment.modal.enrolBelow')}
          </div>
          {getEnrolmentEnd(courseUnitRealisation, lang, t)}
        </ModalDialog>
      ) : null}
    </>
  );
};

SubOpenUniversityProductEnrolment.propTypes = {
  courseUnitRealisation: courseUnitRealisationType.isRequired
};

export default SubOpenUniversityProductEnrolment;
