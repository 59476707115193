import React from 'react';
import {
  elementType, string
} from 'prop-types';
import {
  Route,
  Redirect
} from 'react-router-dom';

import useIsLoggedIn from '../../hooks/useIsLoggedIn';

import { routes } from '../../constants';

const PrivateRoute = ({ component: Component, path }) => {
  const isLoggedIn = useIsLoggedIn();
  return (
    <Route
      path={path}
      render={() => (
        isLoggedIn ? <Component />
          : (
            <Redirect
              to={{
                pathname: routes.LOGIN,
                state: { from: window.location.href }
              }}
            />
          )
      )}
    />
  );
};

PrivateRoute.propTypes = {
  component: elementType,
  path: string
};

export default PrivateRoute;
