import React from 'react';
import PropTypes from 'prop-types';

import styles from './courseUnitStudyPrerequisites.css';
import Section from '../../../Section';
import { courseUnitPrerequisite } from '../../../../types';
import useTranslation from '../../../../hooks/useTranslation';
import { formatCredits } from '../../../../utils';

const CourseUnitStudyPrerequisites = ({
  compulsoryFormalPrerequisites,
  recommendedFormalPrerequisites
}) => {
  const { t, lang } = useTranslation();
  if ((!Array.isArray(compulsoryFormalPrerequisites) || !compulsoryFormalPrerequisites.length)
    && (!Array.isArray(recommendedFormalPrerequisites) || !recommendedFormalPrerequisites.length)) {
    return null;
  }

  const formatPrerequisites = (prerequisites, index, type) => (
    <div key={index}>
      <div>
        {index > 0 && <p className={styles.or}>{t('or', lang)}</p>}
        <strong className={styles.strong}>{t(type, lang)} {index + 1}</strong>
      </div>
      <ul className={styles.courseList}>
        {prerequisites.map((prerequisite) => (
          <li key={prerequisite.get('code')} className={styles.courseListItem}>
            <span className={styles.courseListCode}>
              {prerequisite.get('code')}
            </span>
            <span className={styles.courseListName}>
              {prerequisite.get('name')[lang]}
            </span>
            <span>
              {prerequisite.has('credits') && formatCredits(prerequisite.get('credits'), lang)}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <Section>
      {compulsoryFormalPrerequisites?.length > 0
        && (
          <>
            <div className={styles.compulsoryPriorKnowledge}>
              <h3 className={styles.courseListTitle}>{t('courseUnit.studyPrerequisites.compulsoryTitle')}</h3>
              <div>
                {compulsoryFormalPrerequisites.map(
                  (val, index) => formatPrerequisites(val, index, 'compulsoryPriorKnowledge')
                )}
              </div>
            </div>
          </>
        )}
      {recommendedFormalPrerequisites?.length > 0
        && (
          <div className={styles.recommendedPriorKnowledge}>
            <hr className={styles.recommendedPriorKnowledgeHr} />
            <h3 className={styles.courseListTitle}>{t('courseUnit.studyPrerequisites.recommendedTitle')}</h3>
            <div>
              {recommendedFormalPrerequisites.map(
                (val, index) => formatPrerequisites(val, index, 'recommendedPriorKnowledge')
              )}
            </div>
          </div>
        )}
    </Section>
  );
};

CourseUnitStudyPrerequisites.propTypes = {
  compulsoryFormalPrerequisites: PropTypes.arrayOf(courseUnitPrerequisite),
  recommendedFormalPrerequisites: PropTypes.arrayOf(courseUnitPrerequisite)
};

export default CourseUnitStudyPrerequisites;
