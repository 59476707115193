import { useEffect, useState } from 'react';
import { stringify } from 'query-string';
import { getJson } from '../api';
import { API_PATH } from '../constants';

/**
 * - has *independent* cache for distinct path + queryParams combinations
 * - aborts requests on fetch effect cleanup
 */
const useApiQuery = (path, queryParams) => {
  const [cache, setCache] = useState({});
  const [error, setError] = useState(null);
  const [usedQueryParams, setUsedQueryParams] = useState(null);

  const cacheKey = `${path}#${JSON.stringify(queryParams)}`;

  useEffect(() => {
    if (cache[cacheKey]) {
      return () => {};
    }
    let mounted = true;
    const controller = new AbortController();
    const { signal } = controller;

    (async () => {
      try {
        const result = await getJson(`${API_PATH}${path}?${stringify(queryParams)}`, signal);
        if (mounted) {
          setCache({ ...cache, [cacheKey]: result });
          setUsedQueryParams(queryParams);
        }
      } catch (e) {
        if (mounted) {
          setError(e);
        }
      }
    })();

    return () => {
      mounted = false;
      controller.abort();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, JSON.stringify(queryParams)]);

  return {
    data: cache[cacheKey], loading: cacheKey in cache === false, error, queryParams, usedQueryParams
  };
};

export default useApiQuery;
