import classNames from 'classnames';
import {
  bool, oneOf, oneOfType, string
} from 'prop-types';
import React from 'react';
import { ArrowOffsite, ArrowRight } from '../Arrow';

import styles from './iconBox.css';

const SvgIconTypes = [ArrowOffsite, ArrowRight];

const IconBox = ({
  icon, isLinkBoxIcon = false, className, 'aria-hidden': ariaHidden = true
}) => {
  let iconElement = null;
  if ((typeof icon) === 'string') {
    iconElement = <span className={classNames(styles.fontIcon, icon)} />;
  } else {
    const Icon = icon;
    iconElement = <Icon className={classNames(styles.svgIcon)} />;
  }

  const box = (
    <div aria-hidden={ariaHidden} className={classNames(styles.box, className)}>
      {iconElement}
    </div>
  );

  return isLinkBoxIcon ? <div className={styles.lowerRightContainer}>{box}</div> : box;
};

IconBox.propTypes = {
  icon: oneOfType([string, oneOf(SvgIconTypes)]).isRequired,
  className: string,
  isLinkBoxIcon: bool,
  'aria-hidden': bool
};

export default IconBox;
