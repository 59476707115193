const BASE_PATH = '/';

export const AUTO_LOGIN_COOKIE_NAME = 'STUDIES-AUTOLOGIN';
export const CSRF_PROTECTION_HEADER_NAME = 'X-CSRF-Protection';
export const SENTRY_DSN = 'https://c69bb4d75522418d9280bce669dc6b52@sentry.io/5186335';
export const SENTRY_TRACKED_HEADERS = ['User-Agent'];

export const UNICAFE_API_BASE_PATH = 'https://messi.unicafe.fi/publicapi';
export const API_PATH = '/api';
export const COURSE_PAGE_API_PATH = `${API_PATH}/courses`;
export const SODEXO_API_BASE_PATH = 'https://www.sodexo.fi/ruokalistat/output/daily_json';

export const SODEXO_RESTAURANTS = {
  Sodexo: [
    { id: 1045996, name: 'Helsingin yliopisto päärakennus', name_en: 'Helsinki University Main Building' }
  ]
};

export const ONLINE_COURSE_LOCATION_ID = 'hy-opetpaik-104596081';
export const OPEN_UNIVERSITY_ORGANISATION_ID = 'hy-org-48645785';

const swap = (obj) => Object.entries(obj).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {});

// Old paths for course search. Used to redirect to new paths
export const oldSearchPathTranslations = {
  fi: 'opintotarjonta',
  sv: 'studieutbud',
  en: 'courses'
};
export const oldSearchPathLanguages = swap(oldSearchPathTranslations);

// Paths for course search
export const searchPathTranslations = {
  fi: 'kurssit',
  sv: 'kurser',
  en: 'courses'
};
export const searchPathLanguages = swap(searchPathTranslations);

// Paths for course unit pages
export const cuPathTranslations = {
  fi: 'kurssit/opintojakso',
  sv: 'kurser/studieavsnitt',
  en: 'courses/course-unit'
};
export const cuPathLanguages = swap(cuPathTranslations);

// Paths for course unit realisation pages
export const curPathTranslations = {
  fi: 'kurssit/toteutus',
  sv: 'kurser/studiemoment',
  en: 'courses/course-implementation'
};
export const curPathLanguages = swap(curPathTranslations);

// Paths for course search by degree programme
export const searchByDpPathTranslations = {
  fi: 'kurssit/koulutusohjelma',
  sv: 'kurser/utbildningsprogram',
  en: 'courses/degree-programme'
};
export const searchByDpPathLanguages = swap(searchByDpPathTranslations);

export const frontPagePathTranslations = {
  fi: 'etusivu',
  sv: 'startsida',
  en: 'frontpage'
};
export const frontPagePathLanguages = swap(frontPagePathTranslations);

export const newsPathTranslations = {
  fi: 'etusivu/tiedotteet',
  sv: 'startsida/meddelanden',
  en: 'frontpage/bulletins'
};
export const newsPathLanguages = swap(newsPathTranslations);

export const myStudiesPathTranslations = {
  fi: 'kalenteri',
  sv: 'kalender',
  en: 'calendar'
};
export const myStudiesPathLanguages = swap(myStudiesPathTranslations);

export const multiSearchPathTranslations = {
  fi: 'haku',
  sv: 'sök',
  en: 'search'
};
export const multiSearchPathLanguages = swap(multiSearchPathTranslations);

export const degreeStructurePathTranslations = {
  fi: 'tutkintorakenne',
  sv: 'examensstruktur',
  en: 'degree-structure'
};
export const degreeStructurePathLanguages = swap(degreeStructurePathTranslations);

export const degreeProgramPathTranslations = {
  fi: 'koulutusohjelma',
  sv: 'utbildningsprogram',
  en: 'degree-programme'
};
export const degreeProgramPathLanguages = swap(degreeProgramPathTranslations);

// Paths for study module pages
export const smPathTranslations = {
  fi: 'tutkintorakenne/opintokokonaisuus',
  sv: 'examensstruktur/studiehelhet',
  en: 'degree-structure/study-module'
};
export const smPathLanguages = swap(smPathTranslations);

export const breadcrumbs = {
  NEWS_ARCHIVE: {
    name: {
      fi: 'Tiedotearkisto',
      sv: 'Meddelandearkiv',
      en: 'Bulletin archive'
    },
    path: {
      fi: '/ohjeet/news',
      sv: '/instruktioner/news',
      en: '/instructions/news'
    },
    useRouter: false
  },
  COURSES: {
    name: {
      fi: 'Kurssit',
      sv: 'Kurser',
      en: 'Courses'
    },
    path: {
      fi: '/opintotarjonta',
      sv: '/studieutbud',
      en: '/courses'
    },
    useRouter: true
  }
};

const guideLangCodeToPath = {
  fi: 'ohjeet',
  sv: 'instruktioner',
  en: 'instructions'
};

export const enrolmentModes = {
  SISU: 'sisu',
  OPEN_UNIVERSITY: 'open-university',
  COMBINED: 'combined',
  ASSISTED: 'assisted'
};

export const requestStates = {
  READY: 'READY',
  REQUEST_SENT: 'REQUEST_SENT',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};

export const pathLanguages = {
  ...curPathLanguages,
  ...cuPathLanguages,
  ...oldSearchPathLanguages,
  ...searchPathLanguages,
  ...searchByDpPathLanguages,
  ...frontPagePathLanguages,
  ...myStudiesPathLanguages,
  ...multiSearchPathLanguages,
  ...degreeStructurePathLanguages,
  ...degreeProgramPathLanguages,
  ...smPathLanguages
};

const SEARCH_DEFAULT = searchPathTranslations.fi;
const DPS_DEFAULT = searchByDpPathTranslations.fi;
const search = (lang) => `/${lang ? searchPathTranslations[lang] : SEARCH_DEFAULT}`;
const dps = (lang) => `/${lang ? searchByDpPathTranslations[lang] : DPS_DEFAULT}`;
const frontpage = (lang) => `/${frontPagePathTranslations[lang]}`;
const myStudies = (lang) => `/${myStudiesPathTranslations[lang]}`;
const guide = (lang) => `/${guideLangCodeToPath[lang]}`;
const news = (lang, newsId, isDegreeProgrammeNews, alias) => `/${newsPathTranslations[lang]}/${isDegreeProgrammeNews ? 'f/' : ''}${newsId}/${alias}`;
const cu = (lang, cuId) => `/${cuPathTranslations[lang]}/${cuId}`;
const cur = (lang, curId) => `/${curPathTranslations[lang]}/${curId}`;
const multiSearch = (lang) => `/${multiSearchPathTranslations[lang]}`;
const sm = (lang, smId) => `/${smPathTranslations[lang]}/${smId}`;

const dp = (lang, dpId, code) => `/${degreeStructurePathTranslations[lang]}/${degreeProgramPathTranslations[lang]}/${dpId}${
  (code) ? `/${code}` : ''}`;
const degreeStructure = (lang) => `/${degreeStructurePathTranslations[lang]}`;

export const routes = {
  FRONT_PAGE: `${BASE_PATH}`,
  LOGIN: `${BASE_PATH}login`,
  frontpage,
  myStudies,
  guide,
  dps,
  news,
  search,
  cu,
  cur,
  multiSearch,
  sm,
  dp,
  degreeStructure
};

export const LANG_COOKIE_NAME = 'OBAR_LANG';

export const AVAILABLE_LANGUAGES = ['fi', 'sv', 'en'];

export const DEFAULT_LANG = 'fi';

export const MOBILE_MEDIA = '(max-width: 479px)';
export const TABLET_MEDIA = '(max-width: 959px)';
export const SMALL_DESKTOP_MEDIA = '(max-width: 1200px)';
export const IMAGE_SIZES = ['desktop', 'tablet', 'mobile'];

export const deviceSizes = {
  MOBILE: 3,
  TABLET: 2,
  SMALL_DESKTOP: 1,
  LARGE_DESKTOP: 0
};

export const STUDY_LEVEL_URN_ORDER = [
  'urn:code:study-level:basic-studies',
  'urn:code:study-level:intermediate-studies',
  'urn:code:study-level:advanced-studies',
  'urn:code:study-level:other-studies',
  'urn:code:study-level:postgraduate-studies'
];

export const ONLINE_COURSE_REALISATION_TYPE_URN = 'urn:code:course-unit-realisation-type:teaching-participation-online';

export const HIGHLIGHT_START_TAG = '\u241F';

export const ASSESSMENT_ITEM_TYPE_EXAM_URN = 'urn:code:assessment-item-type:exam';

export const MOOC = 'mooc';

const RESPONSIBLE_TEACHER_ROLE_URN = 'urn:code:course-unit-realisation-responsibility-info-type:responsible-teacher';
const TEACHER_ROLE_URN = 'urn:code:course-unit-realisation-responsibility-info-type:teacher';
const ADMINISTRATIVE_PERSON_ROLE_URN = 'urn:code:course-unit-realisation-responsibility-info-type:administrative-person';

const RESPONSIBLE_TEACHER_MODULE_ROLE_URN = 'urn:code:module-responsibility-info-type:responsible-teacher';
const TEACHER_MODULE_ROLE_URN = 'urn:code:module-responsibility-info-type:teacher';
const ADMINISTRATIVE_PERSON_MODULE_ROLE_URN = 'urn:code:module-responsibility-info-type:administrative-person';

export const responsibilityRoleUrns = {
  RESPONSIBLE_TEACHER_ROLE_URN,
  TEACHER_ROLE_URN,
  ADMINISTRATIVE_PERSON_ROLE_URN,
  RESPONSIBLE_TEACHER_MODULE_ROLE_URN,
  TEACHER_MODULE_ROLE_URN,
  ADMINISTRATIVE_PERSON_MODULE_ROLE_URN
};

export const RESPONSIBLE_TEACHER_ROLE_URNS = [
  RESPONSIBLE_TEACHER_ROLE_URN,
  TEACHER_ROLE_URN,
  RESPONSIBLE_TEACHER_MODULE_ROLE_URN
];

export const OPEN_UNIVERSITY_COURSE_PRICE_PER_CREDIT = 15;

export const GUIDE_NEWS_DP_TYPES = {
  BACHELORS: 'bachelor',
  MASTERS: 'master',
  DOCTOR: 'doctoral'
};

export const userPreferenceKeyValues = {
  SELECTED_CALENDAR_TAB: 'selectedCalendarTab',
  UNICAFE_RESTAURANTS: 'unicafeRestaurants'
};

export const DEGREE_PROGRAMME_GROUP_LABELS = {
  OWN: 'own',
  ALL: 'all',
  BACHELORS_DEGREE: 'bachelors-degree',
  MASTERS_DEGREE: 'masters-degree',
  DOCTOR: 'doctor',
  OTHER: 'other'
};

export const organisationRoles = {
  RESPONSIBLE: 'urn:code:organisation-role:responsible-organisation',
  COORDINATOR: 'urn:code:organisation-role:coordinating-organisation'
};

export const studyRightSelectionTypes = {
  MINOR_STUDY_RIGHT: 'urn:code:study-right-selection-type:minor-study-right',
  NONE: 'urn:code:study-right-selection-type:none'
};

export const minorStudyRightAcceptanceTypes = {
  AUTOMATIC: 'urn:code:study-right-acceptance-type:automatic',
  ACCEPTED_BY_TEACHER: 'urn:code:study-right-acceptance-type:accepted-by-teacher',
  NULL: null
};

export const ICON_POSITIONS = {
  IN_TEXT: 'in-text',
  AFTER: 'after',
  BEFORE: 'before',
  NONE: 'none'
};

export const FLOW_STATES = {
  CANCELLED: 'CANCELLED',
  PUBLISHED: 'PUBLISHED',
  NOT_READY: 'NOT_READY',
  ARCHIVED: 'ARCHIVED'
};

export const DOCUMENT_STATES = {
  ACTIVE: 'ACTIVE',
  DRAFT: 'DRAFT',
  DELETED: 'DELETED'
};

export const openUniversityProductTypes = {
  // Main CUR for OUP, used for enrolment to OUP
  MAIN: 'Main',
  // CUR also available outside open university
  COMBINED_MAIN: 'CombinedMain',
  // Sub CUR for OUP, need to enrol to OUP first before possible to enrol to CUR
  SUB: 'Sub',
  // CUR also available outside open university
  COMBINED_SUB: 'CombinedSub'
};

export const NON_ALPHANUMERIC_REGEX = /[^\w\u00C0-\u017F]+/g; // \w is ascii alphanumeric, \u00C0-\u017F adds accented letters
