import React from 'react';

import classNames from 'classnames';
import styles from './contact.css';
import Link from '../Link';
import { sanitizeDangerousHtml } from '../../utils';
import useTranslation from '../../hooks/useTranslation';

const linkClassNames = classNames(styles.link, 'button--action', 'with-icon');

const Contact = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.container}>
      <h2 className={styles.header}>{t('contact.header')}</h2>
      <div className={styles.linkContainer}>
        <Link
          href={t('contact.guidanceCompass.link')}
          className={linkClassNames}
          iconPosition="after"
        >
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={sanitizeDangerousHtml(t('contact.guidanceCompass.text'))} />
        </Link>
        <Link
          href={t('contact.studentServices.link')}
          className={linkClassNames}
          iconPosition="after"
        >
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={sanitizeDangerousHtml(t('contact.studentServices.text'))} />
        </Link>
        <Link
          href={t('contact.helpdesk.link')}
          className={linkClassNames}
          iconPosition="after"
          external
        >
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={sanitizeDangerousHtml(t('contact.helpdesk.text'))} />
        </Link>
      </div>
    </section>
  );
};

export default Contact;
