import React from 'react';
import { studyModuleType } from '../../../types';
import { getTranslation } from '../../../i18n';
import { formatCredits, getAvailabilityAsMinorStudyRightTranslationKey, getResponsibleTeachers } from '../../../utils';
import styles from './studyModuleHeader.css';
import DepthIndicator from '../../DepthIndicator';
import useTranslation from '../../../hooks/useTranslation';

const getCurriculumPeriods = (curriculumPeriods, id, lang) => curriculumPeriods.filter((period) => period.objId === id).map((period) => getTranslation(period.name, lang)).sort().join(', ');

const getPossibleAttainmentLanguages = (possibleAttainmentLanguages, lang) =>
  possibleAttainmentLanguages
    ?.map((possibleLang) => getTranslation(possibleLang.name, lang))
    .filter(Boolean)
    .join(', ') || '-';

const StudyModuleHeader = ({ studyModule = {} }) => {
  const { t, lang } = useTranslation();
  return (
    <div className={`page-wide-content-padding ${styles.content}`}>
      <div className="heading-like size-3">
        {t('studyModule.studyModule')} {studyModule.code}
      </div>
      <h1>
        {getTranslation(studyModule.name, lang)}
        {' '}
        <span className={styles.lowercase}>
          ({formatCredits(studyModule.targetCredits, lang)})
        </span>
      </h1>
      {studyModule.curriculumPeriods?.length > 0 && (
        <p><span className={styles.boldSpan}>{t('studyModule.inUse')}:</span> {getCurriculumPeriods(studyModule.curriculumPeriods, studyModule.id, lang)}</p>
      )}
      {studyModule.gradeScaleName && (
        <p><span className={styles.boldSpan}>{t('studyModule.gradeScale')}:</span> {getTranslation(studyModule.gradeScaleName, lang)}</p>
      )}
      {studyModule.studyLevel && (
        <p><span className={styles.boldSpan}>{t('studyModule.studyLevel')}:</span> {getTranslation(studyModule.studyLevel?.name, lang)}</p>
      )}
      {studyModule.studyRightSelectionTypeUrn && (
        <p><span className={styles.boldSpan}>{t('studyModule.availableAsMinor')}:</span> {t(getAvailabilityAsMinorStudyRightTranslationKey(studyModule.studyRightSelectionTypeUrn, studyModule.minorStudyRightAcceptanceTypeUrn)) || ''}</p>
      )}
      {studyModule.organisations?.length > 0 && (
        <p><span className={styles.boldSpan}>{t('responsibleOrganisation')}:</span> {studyModule.organisations?.map((org) => getTranslation(org?.organisation?.name, lang)).join(', ') || '-'}</p>
      )}
      {studyModule.responsibilityInfos?.length > 0 && (
        <p><span className={styles.boldSpan}>{t('studyModule.responsiblePersons')}:</span> {getResponsibleTeachers(studyModule.responsibilityInfos)}</p>
      )}
      {studyModule.possibleAttainmentLanguages?.length && (
        <p><span className={styles.boldSpan}>{t('studyModule.possibleAttainmentLanguages')}:</span> {getPossibleAttainmentLanguages(studyModule.possibleAttainmentLanguages, lang)}</p>
      )}
      <DepthIndicator sm />
    </div>
  );
};

StudyModuleHeader.propTypes = {
  studyModule: studyModuleType
};

export default StudyModuleHeader;
