import React from 'react';
import StudySubGroup from './StudySubGroup';
import { getTranslation } from '../../../../i18n';
import { studyGroupSetType } from '../../../../types';

import styles from './studyGroupSet.css';
import useTranslation from '../../../../hooks/useTranslation';

const StudyGroupSet = ({ studyGroupSet }) => {
  const { lang } = useTranslation();

  const studySubGroups = studyGroupSet.studySubGroups.filter((ssg) => !ssg.cancelled).map((ssg, idx, arr) => (
    <StudySubGroup key={ssg.id} studySubGroup={ssg} wrappedInCollapse={arr.length > 1} />
  ));

  return studySubGroups.length ? (
    <div className={styles.studyGroupSet}>
      {studySubGroups.length > 1
      && <h3 className={styles.studyGroupSetTitle}>{getTranslation(studyGroupSet.name, lang)}</h3>}
      {studySubGroups}
    </div>
  ) : null;
};

StudyGroupSet.propTypes = {
  studyGroupSet: studyGroupSetType
};

export default StudyGroupSet;
