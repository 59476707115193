import React from 'react';
import parseISO from 'date-fns/parseISO';
import startOfToday from 'date-fns/startOfToday';

import styles from './importantNote.css';
import { formatDateTime, isAfterOrEqual } from '../../../../utils';
import { courseCmsDataType } from '../../../../types';
import Section from '../../../Section';
import useTranslation from '../../../../hooks/useTranslation';

const ImportantNote = ({ cmsData }) => {
  const { lang } = useTranslation();

  if (!cmsData?.attentionMessage?.message) {
    return null;
  }

  const {
    attentionMessage: {
      message, creator, createdDate, lastDate
    }
  } = cmsData;

  return !lastDate || isAfterOrEqual(parseISO(lastDate), startOfToday()) ? (
    <Section className={styles.noteSection}>
      <div className={styles.noteContainer}>
        <div className={styles.iconContainer} aria-hidden="true">
          <span className="icon--alert icon-2x" />
        </div>
        <div className={styles.messageContainer}>
          {creator || createdDate ? (
            <div className={styles.metaData}>
              {createdDate ? formatDateTime(createdDate, lang) : ''}
              {' '}
              {creator}
            </div>
          ) : null}
          {message}
        </div>
      </div>
    </Section>
  ) : null;
};

ImportantNote.propTypes = {
  cmsData: courseCmsDataType
};

export default ImportantNote;
