import React from 'react';
import Section from '../../../Section';
import { courseUnitRealisationType } from '../../../../types';
import { openUniversityProductTypes } from '../../../../constants';
import useTranslation from '../../../../hooks/useTranslation';

const RegistrationInfo = ({ courseUnitRealisation }) => {
  const { t } = useTranslation();

  if (!courseUnitRealisation?.openUniversity && !courseUnitRealisation?.openUniversityProducts?.length > 0) {
    return null;
  }

  const registrationInfo = courseUnitRealisation?.cmsData?.registrationInfo;
  if (!registrationInfo) {
    return null;
  }

  const label = courseUnitRealisation.coTeachingRealisation
  || courseUnitRealisation.openUniversityProductType === openUniversityProductTypes.COMBINED_MAIN
  || courseUnitRealisation.openUniversityProductType === openUniversityProductTypes.COMBINED_SUB
    ? t('registrationInfoCoTeaching')
    : t('registrationInfo');

  return (
    <Section level={2} label={label} html>
      {registrationInfo}
    </Section>
  );
};

RegistrationInfo.propTypes = {
  courseUnitRealisation: courseUnitRealisationType
};

export default RegistrationInfo;
