import React from 'react';
import { parseISO } from 'date-fns';
import { getTranslation } from '../../../i18n';
import DocumentHead from '../../DocumentHead';
import Section from '../../Section';
import { courseUnitType } from '../../../types';
import {
  routes, AVAILABLE_LANGUAGES, studyRightSelectionTypes, DOCUMENT_STATES
} from '../../../constants';
import CourseUnitRealisationTable from './CourseUnitRealisationTable';
import CourseUnitStudyPrerequisites from './CourseUnitStudyPrerequisites';
import LearningMaterials from '../LearningMaterials';
import Literature from '../Literature';

import CourseUnitHeader from './CourseUnitHeader';
import styles from './courseUnit.css';
import CourseUnitStudyModules from './CourseUnitStudyModules';
import useStudyYearPicker from '../../../hooks/useStudyYearPicker';
import usePrerequisites from '../../../hooks/usePrerequisites';
import useTranslation from '../../../hooks/useTranslation';

const getActiveYearsRange = (curriculumPeriods) => {
  if (!curriculumPeriods?.length) {
    return null;
  }

  const [startYears, endYears] = curriculumPeriods.reduce((range, { activePeriod }) => {
    if (activePeriod) {
      const [start, end] = range;
      start.push(parseISO(activePeriod.startDate).getFullYear());
      end.push(parseISO(activePeriod.endDate).getFullYear());
    }
    return range;
  }, [[], []]);

  if (!startYears?.length) {
    return Math.max(...endYears);
  }
  if (!endYears?.length) {
    return Math.min(...startYears);
  }

  return `${Math.min(...startYears)}\u2013${Math.max(...endYears)}`;
};

const CourseUnit = ({
  courseUnit
}) => {
  const { t, lang } = useTranslation();
  const { selectedYear, changeYear } = useStudyYearPicker(courseUnit?.id, courseUnit?.curriculumPeriods, true);
  const {
    compulsoryFormalPrerequisites,
    recommendedFormalPrerequisites
  } = usePrerequisites(courseUnit?.compulsoryFormalPrerequisites, courseUnit?.recommendedFormalPrerequisites);

  if (!courseUnit) {
    return null;
  }

  const courseUnitTitle = [
    t('courseUnit.pageTitle'),
    courseUnit.code,
    getTranslation(courseUnit.name, lang),
    getActiveYearsRange(courseUnit.curriculumPeriods)
  ].filter(Boolean).join(' ');
  const localizedUrls = AVAILABLE_LANGUAGES.map((language) => [language, routes.cu(language, courseUnit.id)]);

  const collectStudyModules = (id, majors, minors, visited) => {
    if (visited.has(id)) {
      return [majors, minors];
    }
    visited.add(id);
    const module = courseUnit.hierarchy?.[id];
    if (!module) {
      return [majors, minors];
    }
    if (module.type === 'StudyModule'
      && module.gradeScaleId
      && module.curriculumPeriodIds?.includes(selectedYear)
      && [DOCUMENT_STATES.DRAFT, DOCUMENT_STATES.ACTIVE].includes(module.documentState)) {
      if (module.studyRightSelectionType === studyRightSelectionTypes.MINOR_STUDY_RIGHT) {
        minors.push(module);
      } else {
        majors.push(module);
      }
    }
    module.parentIds?.forEach((parentId) => collectStudyModules(parentId, majors, minors, visited));
    return [majors, minors];
  };

  // const initialId = Object.values(courseUnit.hierarchy).find((module) => module.type === 'CourseUnit').id;
  // then use initialId instead of courseUnit.id for local testing
  const [majorStudyModules, minorStudyModules] = collectStudyModules(courseUnit.id, [], [], new Set());
  majorStudyModules.sort((a, b) => (a.code > b.code ? 1 : -1));
  minorStudyModules.sort((a, b) => (a.code > b.code ? 1 : -1));

  return (
    <>
      <DocumentHead urls={localizedUrls} title={courseUnitTitle} />
      <section id="course-unit">
        <CourseUnitHeader courseUnit={courseUnit} selectedYear={selectedYear} changeYear={changeYear} />
        <div className="grid-container page-wide-content-padding">
          <CourseUnitRealisationTable courseUnit={courseUnit} />
          <h2 className={styles.infoTitle}>{t('courseUnit.informationTitle')}</h2>

          <Section level={3} label={t('courseUnit.tweetText')} noUpperCaseLabel html>
            {getTranslation(courseUnit.tweetText, lang)}
          </Section>

          <Section level={3} label={t('courseUnit.prerequisites')} noUpperCaseLabel html>
            {getTranslation(courseUnit.prerequisites, lang)}
          </Section>

          <CourseUnitStudyPrerequisites
            compulsoryFormalPrerequisites={compulsoryFormalPrerequisites}
            recommendedFormalPrerequisites={recommendedFormalPrerequisites}
          />

          <Section level={3} label={t('courseUnit.outcomes')} noUpperCaseLabel html>
            {getTranslation(courseUnit.outcomes, lang)}
          </Section>

          <Section level={3} label={t('courseUnit.content')} noUpperCaseLabel html>
            {getTranslation(courseUnit.content, lang)}
          </Section>

          <LearningMaterials headerLevel={3} courseUnit={courseUnit} />

          <Literature headerLevel={3} courseUnit={courseUnit} />

          <Section level={3} label={t('courseUnit.inclusionApplicationInstruction')} noUpperCaseLabel html>
            {getTranslation(courseUnit.inclusionApplicationInstruction, lang)}
          </Section>

          <Section className={styles.additionalInfo} level={3} label={t('additionalInfo')} noUpperCaseLabel html>
            {getTranslation(courseUnit.additionalInfo, lang)}
          </Section>

          {(majorStudyModules.length || !!minorStudyModules.length)
            && <CourseUnitStudyModules majorStudyModules={majorStudyModules} minorStudyModules={minorStudyModules} selectedYear={selectedYear} />}
        </div>
      </section>
    </>
  );
};

CourseUnit.propTypes = {
  courseUnit: courseUnitType
};

export default CourseUnit;
