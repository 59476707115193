import { useEffect, useRef, useState } from 'react';

const useHideOnClickOutside = (initialIsVisible) => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    // In some cases where hiding with inverting isComponentVisible is connected to another event firing outside of
    // watched component this handler could fire first and leave component visible when external event handler inverts.
    // Using 0 timeout is enough to give priority to external event handler.
    setTimeout(() => {
      if (ref.current && !ref.current.contains(event.target) && isComponentVisible) {
        setIsComponentVisible(false);
      }
    }, 0);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
};

export default useHideOnClickOutside;
