import { AVAILABLE_LANGUAGES } from '../constants';
import translations from './translations.json';
import coursePageTranslations from './translations-course-page.json';
import searchTranslations from './translations-search.json';
import myStudiesTranslations from './translations-my-studies.json';
import studyModulePageTranslations from './translations-study-module-page.json';
import degreeProgramTranslations from './translations-degree-program.json';

const TRANSLATIONS = {
  ...translations,
  ...coursePageTranslations,
  ...searchTranslations,
  ...myStudiesTranslations,
  ...studyModulePageTranslations,
  ...degreeProgramTranslations
};

export const getTranslation = (strMap, lang) => {
  if (strMap) {
    return strMap[lang]
      || strMap[AVAILABLE_LANGUAGES[0]]
      || AVAILABLE_LANGUAGES
        .filter((l) => strMap[l])
        .map((l) => strMap[l])
        .shift();
  }

  return null;
};

/**
 * @deprecated Use t(translationKey, null, locale) from useTranslation
 */
export const getTranslationWithLocale = (translationKey, locale) => {
  const localeIndex = AVAILABLE_LANGUAGES.findIndex((l) => l === locale);
  const keys = translationKey.split('.');
  const texts = keys.reduce((acc, key) => acc && acc[key], TRANSLATIONS);
  return texts[localeIndex];
};
