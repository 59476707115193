import React, { createRef, useRef, useEffect } from 'react';
import classNames from 'classnames';
import {
  string, func, arrayOf, bool
} from 'prop-types';
import styles from './typeFilters.css';
import { option } from '../../../../types';
import { tabGroupKeyboardNavigation } from '../../../TabGroup';
import useFocusAfterContentChange from '../../../../hooks/useFocusAfterContentChange';

const TypeFilters = ({
  types, selectedType, setSelectedType, label: tablistLabel, panelPrefix, tabPrefix, hasContent, isLoading
}) => {
  const tabRefs = useRef(types ? types.map(() => createRef()) : []);
  const activateTab = (idx) => setSelectedType(types[idx].value);
  const handleKeyDown = (e) => tabGroupKeyboardNavigation(e, tabRefs, activateTab);

  const selectedTypeIndex = types?.map(({ value }) => value)?.indexOf(selectedType);
  const setIsInitialLoad = useFocusAfterContentChange(hasContent, isLoading, tabRefs?.current?.[selectedTypeIndex]);

  useEffect(() => {
    setIsInitialLoad(true);
  }, [selectedType, setIsInitialLoad]);

  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <div className={styles.typeFilters} role="tablist" aria-label={tablistLabel} onKeyDown={handleKeyDown}>
      {types?.map(({ label, value }, index) => {
        const isSelected = selectedType === value;
        return (
          <button
            type="button"
            key={value}
            role="tab"
            id={tabPrefix + value}
            aria-selected={isSelected}
            aria-controls={panelPrefix + value}
            tabIndex={isSelected ? 0 : -1}
            ref={tabRefs.current[index]}
            className={classNames(styles.typeFilter, { [styles.current]: isSelected })}
            onClick={() => setSelectedType(value)}
          >
            <span>{label}</span>
          </button>
        );
      })}
    </div>
  );
};

TypeFilters.propTypes = {
  types: arrayOf(option),
  selectedType: string,
  setSelectedType: func,
  label: string,
  panelPrefix: string,
  tabPrefix: string,
  hasContent: bool,
  isLoading: bool
};

export default TypeFilters;
