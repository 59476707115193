import React from 'react';

import { courseUnitRealisationType } from '../../../../types';
import { FLOW_STATES } from '../../../../constants';
import styles from './courseAdministration.css';
import EditCoursePage from './EditCoursePage';
import { isPublished } from '../../../../utils';
import RequestParticipantList from './RequestParticipantList';

const CourseAdministration = ({ courseUnitRealisation = {} }) => {
  const { canEditCourse, flowState, showRequestParticipantList } = courseUnitRealisation;
  if (!canEditCourse) {
    return null;
  }

  const showEditAction = flowState !== FLOW_STATES.CANCELLED;
  const showRequestParticipantListAction = showRequestParticipantList && isPublished(courseUnitRealisation);

  return (
    <div className={styles.container}>
      {showEditAction && <EditCoursePage courseUnitRealisation={courseUnitRealisation} />}
      {showRequestParticipantListAction && <RequestParticipantList courseUnitRealisation={courseUnitRealisation} />}
    </div>
  );
};

CourseAdministration.propTypes = {
  courseUnitRealisation: courseUnitRealisationType
};

export default CourseAdministration;
