import React, { useEffect, useState } from 'react';

import { getBulletinLiftup } from '../../api';
import styles from './bulletinLiftup.css';
import { formatDate, withPlaceholderImage } from '../../utils';
import { routes } from '../../constants';
import { eventCategories } from '../../services/analytics';
import IconBox from '../Icon/IconBox';
import HyphenatedText from '../HyphenatedText';
import Link from '../Link';
import { ArrowRight } from '../Icon/Arrow';
import useTranslation from '../../hooks/useTranslation';

// remove non-breaking spaces
const normalizeText = (text) => text?.replace(/\u00A0/g, ' ');

const BulletinLiftup = () => {
  const { t, lang } = useTranslation();
  const [liftup, setLiftup] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const data = await getBulletinLiftup(lang);
        setLiftup(withPlaceholderImage(data));
      } catch {
        setLiftup(null);
      }
    })();
  }, [lang]);

  if (!liftup) {
    return null;
  }

  const getFieldId = (fieldName) => `liftup-${liftup.id}-${fieldName}`;

  const imageId = getFieldId('Image');
  const dateId = getFieldId('Date');
  const titleId = getFieldId('Title');
  const summaryId = getFieldId('Summary');
  const labelledBy = titleId;
  const describedBy = [imageId, dateId, summaryId].join(' ');

  return (
    <section className={styles.container}>
      <Link
        href={routes.news(lang, liftup.id, false, liftup.alias)}
        className={styles.liftupLink}
        useRouter
        tracked
        eventCategory={eventCategories.frontpage.BULLETIN_LIFTUP}
        aria-labelledby={labelledBy}
        aria-describedby={describedBy}
      >
        <article className={styles.content}>
          <div className={styles.image}>
            <img id={imageId} src={liftup.image} alt={liftup.imageAltText} />
          </div>
          <div className={styles.textContent}>
            <div className={styles.date}>
              {t('commonNews.itemTypeLabel')} <span aria-hidden="true">|</span> <span id={dateId}>{formatDate(liftup.isoDate, lang)}</span>
            </div>
            <h3 id={titleId} className={styles.title}>
              <HyphenatedText text={liftup.title} lang={lang} />
            </h3>
            <p id={summaryId} className={styles.ingress}>
              {normalizeText(liftup.summary)}
            </p>
          </div>
          <IconBox isLinkBoxIcon icon={ArrowRight} />
        </article>
      </Link>
    </section>
  );
};

export default BulletinLiftup;
