import React from 'react';
import PropTypes from 'prop-types';
import { parseISO, isSameDay } from 'date-fns';

import TableCollapse from '../../../../Collapse/TableCollapse';
import StudyEvent from './StudyEvent';
import styles from './studySubGroup.css';
import { isDesktop, isMobile, useDeviceSize } from '../../../../../context/DeviceSizeContextProvider';
import { getTranslation } from '../../../../../i18n';
import { studySubGroupType } from '../../../../../types';
import {
  formatDateTimeSpan,
  formatTime,
  UTCOffsetTimeZoneString
} from '../../../../../utils';
import TooltipButton from '../../../../TooltipButton';
import useTranslation from '../../../../../hooks/useTranslation';

const FINNISH_UTC_OFFSET_IN_SUMMER_TIME = -180; // minutes
const FINNISH_UTC_OFFSET_IN_NORMAL_TIME = -120;

const sortStudyEvents = (a, b) => {
  const date1 = parseISO(a.startTime);
  const date2 = parseISO(b.startTime);

  if (date1 > date2) {
    return 1;
  }

  if (date1 < date2) {
    return -1;
  }

  return 0;
};

const isSummerTime = () => {
  const today = new Date();
  const year = today.getFullYear();

  const lastSundayOfMonth = (month) => {
    const lastDay = new Date(year, month + 1, 0);
    return new Date(year, month, lastDay.getDate() - lastDay.getDay());
  };

  const summerTimeStart = lastSundayOfMonth(2); // march
  const summerTimeEnd = lastSundayOfMonth(9); // october
  return summerTimeStart <= today && today < summerTimeEnd;
};

const MOBILE_EVENT_LIMIT = 2;
const TABLET_AND_DESKTOP_EVENT_LIMIT = 4;

const getStudySubGroupDateRange = (sortedStudyEvents, lang) => (
  sortedStudyEvents.length > 0
    ? formatDateTimeSpan(
      sortedStudyEvents[0].startTime,
      sortedStudyEvents[sortedStudyEvents.length - 1].event.end,
      lang,
      { includeWeekday: true, includeTime: false }
    ) : null
);

const getTableHead = (deviceSize, renderAdditionalInfo, t, lang) => {
  const finnishUtcOffset = isSummerTime() ? FINNISH_UTC_OFFSET_IN_SUMMER_TIME : FINNISH_UTC_OFFSET_IN_NORMAL_TIME;
  let head;

  const getTimeZoneInfo = () => {
    const browserCurrentTime = formatTime(new Date(), lang);
    const UTCTimestring = UTCOffsetTimeZoneString(lang);

    return (
      <>
        <span className={styles.timeZone}>
          ({UTCTimestring})
        </span>
        <TooltipButton
          label={<span className="icon--info-stroke" />}
          className={styles.timeZoneTooltipIcon}
          ariaHidden
        >
          <div className={styles.timeZoneTooltipContent}>
            {t('timeZoneInfo', {
              browserCurrentTime, UTCTimestring
            })}
          </div>
        </TooltipButton>
      </>
    );
  };

  const timeZoneInfo = (new Date()).getTimezoneOffset() === finnishUtcOffset
    ? null
    : getTimeZoneInfo();

  if (isDesktop(deviceSize)) {
    head = (
      <thead className={styles.studyEventsHeading}>
        <tr>
          <th className={styles.columnDate}>{t('date')}</th>
          <th className={styles.columnTime}>{t('time')} {timeZoneInfo}</th>
          <th className={styles.columnLocation}>{t('location')}</th>
          {renderAdditionalInfo && <th className={styles.columnAdditionalInfo}>{t('additionalInfo')}</th>}
        </tr>
      </thead>
    );
  } else {
    head = (
      <thead className={styles.studyEventsHeading}>
        <tr>
          <th className={styles.columnDateTime}>{t('time')} {timeZoneInfo}</th>
          <th className={styles.columnLocation}>{t('location')}</th>
        </tr>
      </thead>
    );
  }

  return head;
};

const getToggleLabel = (name, studySubGroupDateRange) => (studySubGroupDateRange
  ? `${name}, ${studySubGroupDateRange}`
  : name
);

const getEventLocations = (event, studyEventParent) => {
  const override = studyEventParent.overrides?.find((o) => isSameDay(parseISO(o.eventDate), parseISO(event.start)));
  return override?.irregularLocations || studyEventParent.locations;
};

const StudySubGroup = ({
  studySubGroup,
  wrappedInCollapse
}) => {
  const { t, lang } = useTranslation();
  const { deviceSize } = useDeviceSize();

  const studyEventsFlattened = (studySubGroup?.studyEvents || [])
    .flatMap((studyEvent) =>
      studyEvent.events
        .filter((event) => !event.cancelled && !event.excluded)
        .map((event) => ({
          // event specific times used for sorting, hence startTime: event.start
          // ex. if you have a studyevent with events on 12., 16. and 19. and another
          // studyevent that has one event on 14., the order is supposed to be
          // 12., 14., 16. and 19.
          id: studyEvent.id, event, studyEventParent: studyEvent, startTime: event.start
        }))
    );

  const sortedStudyEvents = studyEventsFlattened.sort(sortStudyEvents);

  const hasAdditionalInfo = sortedStudyEvents.reduce((acc, studyEvent) => acc || Boolean(studyEvent.event.notice), false);

  let columnCount = 2;
  if (isDesktop(deviceSize)) {
    columnCount = hasAdditionalInfo ? 4 : 3;
  }

  const studyEvents = sortedStudyEvents.map((studyEvent) => (
    <StudyEvent
      key={studyEvent.id + studyEvent.event.start}
      studySubEvent={studyEvent.event}
      eventLocations={getEventLocations(studyEvent.event, studyEvent.studyEventParent)}
      columnCount={columnCount}
    />
  ));

  if (wrappedInCollapse) {
    const toggleLabel = getToggleLabel(
      getTranslation(studySubGroup?.name, lang),
      getStudySubGroupDateRange(sortedStudyEvents, lang)
    );
    return (
      <section>
        <TableCollapse
          alwaysShow={0}
          toggleLabel={toggleLabel}
          className={styles.studySubGroupOuterCollapse}
          collapsed
          columnCount={columnCount}
          head={getTableHead(deviceSize, hasAdditionalInfo, t, lang)}
          buttonClassName={styles.studySubGroupOuterCollapseButton}
        >
          {studyEvents}
        </TableCollapse>
      </section>
    );
  }

  return (
    <section>
      <h3 className={styles.studySubGroupTitle}>{getTranslation(studySubGroup?.name, lang)}</h3>
      <TableCollapse
        alwaysShow={isMobile(deviceSize) ? MOBILE_EVENT_LIMIT : TABLET_AND_DESKTOP_EVENT_LIMIT}
        expandLabel={t('studyGroupSet.showAll')}
        collapseLabel={t('studyGroupSet.showLess')}
        upperCaseLabel
        collapsed
        collapseArrow={false}
        className={styles.studySubGroupOuterCollapse}
        columnCount={columnCount}
        head={getTableHead(deviceSize, hasAdditionalInfo, t, lang)}
        buttonClassName={styles.studySubGroupCollapseButton}
      >
        {studyEvents}
      </TableCollapse>
    </section>
  );
};

StudySubGroup.propTypes = {
  studySubGroup: studySubGroupType,
  wrappedInCollapse: PropTypes.bool
};

StudySubGroup.defaultProps = {
  wrappedInCollapse: false
};

export default StudySubGroup;
