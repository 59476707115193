import React, { useEffect } from 'react';
import { element } from 'prop-types';

import PersistingUserSelectionsContext, { initialDegreeProgramme, getInitialUserSelections } from './PersistingUserSelectionsContext';
import useUriEncodedCookie from '../hooks/useUriEncodedCookie';

const isString = (value) => typeof value === 'string';
const isObject = (value) => value && value.constructor === Object;

const isValidDegreeProgramme = (nextState) => isObject(nextState) && Object.values(nextState).every((value) => value === null || isString(value));
const isPartialDegreeProgramme = (nextState) => isObject(nextState) && isString(nextState.code);
const isFullDegreeProgramme = (nextState) => isObject(nextState) && Object.values(nextState).every(isString);

const isValidState = (nextState) => isObject(nextState) && isValidDegreeProgramme(nextState.degreeProgramme);

const PersistingUserSelectionsContextProvider = ({ children }) => {
  const [userSelections, updateUserSelections] = useUriEncodedCookie(
    'STUDIES_SESSION_USER_SELECTIONS',
    JSON.stringify(getInitialUserSelections())
  );

  const setState = (nextState) => {
    updateUserSelections(JSON.stringify(nextState));
  };

  const getState = () => {
    try {
      return JSON.parse(userSelections);
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    // Parse cookie stored in the browser
    if (!isValidState(getState())) {
      setState(getInitialUserSelections());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelections, updateUserSelections]);

  const updateContextKeyValue = (key, validatorFn) => (nextState) => {
    if (!validatorFn(nextState)) {
      setState({
        ...getState(),
        [key]: getInitialUserSelections()[key]
      });
      return;
    }

    setState({
      ...getState(),
      [key]: nextState
    });
  };

  const handleUpdateDegreeProgramme = updateContextKeyValue('degreeProgramme', isValidDegreeProgramme);

  return (
    <PersistingUserSelectionsContext.Provider
      value={{
        userSelections: getState(),
        degreeProgramme: getState()?.degreeProgramme ?? initialDegreeProgramme,
        handleUpdateDegreeProgramme,
        isPartialDegreeProgramme,
        isFullDegreeProgramme
      }}
    >
      {children}
    </PersistingUserSelectionsContext.Provider>
  );
};

PersistingUserSelectionsContextProvider.propTypes = {
  children: element.isRequired
};

export default PersistingUserSelectionsContextProvider;
