import React, { useState, useEffect, useContext } from 'react';
import { element } from 'prop-types';
import Cookies from 'js-cookie';

import RootContext from './RootContext';
import {
  getSession, getClientConfig, getObarConfig, updateUserPreferences, doPassiveLogin
} from '../api';
import {
  routes, AUTO_LOGIN_COOKIE_NAME, AVAILABLE_LANGUAGES
} from '../constants';
import { isBotOrCrawler } from '../utils';
import { READ_ONLY_DB } from '../env';
import LangContext from './LangContext';

const initSession = async (setSession) => {
  let s;
  try {
    s = await getSession();
  } catch (e) {
    s = null;
  }

  setSession(s);
};

const initClientConfig = async (setConfig, setUserPreferences) => {
  const { userPreferences: up, ...c } = await getClientConfig();

  setConfig(c);
  setUserPreferences(up);
};

const initObarConfig = async (setObarConfig, lang) => {
  const oc = await getObarConfig(lang);
  setObarConfig(oc);
};

const RootContextProvider = ({ children }) => {
  const { lang } = useContext(LangContext);
  const [session, setSession] = useState(null);
  const [config, setConfig] = useState(null);
  const [obarConfig, setObarConfig] = useState(null);
  const [userPreferences, setUserPreferences] = useState({});

  useEffect(() => {
    (async () => {
      await initSession(setSession);
      await Promise.all([
        initClientConfig(setConfig, setUserPreferences),
        initObarConfig(setObarConfig, lang)
      ]);
    })();
  }, [lang]);

  // Insert Cookiebot to header if it is not there already (from Obar), so that it is present before Google Analytics
  useEffect(() => {
    let cbot;
    if (!document.getElementById('Cookiebot')) {
      cbot = document.createElement('script');
      cbot.setAttribute('id', 'Cookiebot');
      cbot.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
      cbot.setAttribute('data-cbid', 'e422c4ee-0ebe-400c-b22b-9c74b6faeac3');
      cbot.setAttribute('data-blockingmode', 'auto');
      cbot.setAttribute('type', 'text/javascript');
      cbot.setAttribute('data-culture', lang);
      document.head.insertBefore(cbot, document.head.getElementsByTagName('meta')[0]);
    }
    return () => {
      if (cbot) {
        document.head.removeChild(cbot);
      }
    };
  }, [lang]);

  // Attempt passive Shibboleth login when navigating in from another web app.
  useEffect(() => {
    const isGuideUrl = (url) => AVAILABLE_LANGUAGES.map((l) => routes.guide(l)).some((p) => url.startsWith(document.location?.origin + p));

    // Referrer is set and is HY Login or Studies itself, but not Guide.
    const internalNavigationOrFromLogin = () => {
      const { referrer } = document;
      return referrer
        && (referrer === 'https://login-test.it.helsinki.fi/'
        || referrer === 'https://login.helsinki.fi/'
        || (referrer.startsWith(document.location?.origin) && !isGuideUrl(referrer)));
    };

    // We use a short lived cookie in order to avoid a loop when the referer header is suppressed eg. by a browser extension.
    // That kind of a user will get an extra round trip to login.helsinki.fi at every navigation.
    // We also try to detect bots and crawlers, which should not be subject to redirect based passive login.
    if (!session
      && config?.auth === 'proxy'
      && !isBotOrCrawler(navigator.userAgent)
      && !Cookies.get(AUTO_LOGIN_COOKIE_NAME)
      && !internalNavigationOrFromLogin()) {
      doPassiveLogin();
    }
  }, [session, config]);

  // This doesn't save new preference to state anymore to avoid minute changes in RootContext causing rerenderings everywhere
  // If setUserPreferences call here is needed, add an argument for this method and make the call conditional.
  const handleUpdateUserPreference = (key, value) => {
    const newPreferences = {
      ...(userPreferences || {}),
      [key]: value
    };

    if (!READ_ONLY_DB) {
      updateUserPreferences(newPreferences);
    }
  };

  if (config) {
    return (
      <RootContext.Provider value={{
        session, config, obarConfig, userPreferences, handleUpdateUserPreference
      }}
      >
        { children }
      </RootContext.Provider>
    );
  }

  return null;
};

RootContextProvider.propTypes = {
  children: element.isRequired
};

export default RootContextProvider;
