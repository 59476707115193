import { isAfter, parseISO } from 'date-fns';
import React from 'react';
import { formatDateTime, isBeforeOrEqual } from '../../../../../utils';
import styles from './enrolment.css';

// eslint-disable-next-line import/prefer-default-export
export const getEnrolmentEnd = (courseUnitRealisation, lang, t) => {
  const {
    enrolmentPeriod, lateEnrolmentEnd
  } = courseUnitRealisation;

  const endDateTime = parseISO(enrolmentPeriod.endDateTime);
  const lateEndDateTime = parseISO(lateEnrolmentEnd);
  const now = new Date();

  if (!enrolmentPeriod?.endDateTime && !lateEnrolmentEnd) {
    return null;
  }

  let formattedEndDateTime;
  if (isAfter(now, endDateTime) && isBeforeOrEqual(now, lateEndDateTime)) {
    formattedEndDateTime = formatDateTime(lateEnrolmentEnd, lang);
  } else {
    formattedEndDateTime = formatDateTime(enrolmentPeriod.endDateTime, lang);
  }

  return (
    <div className={styles.enrolmentEnd}>
      {t('sisuEnrolment.modal.enrolmentEnd', { endDateTime: formattedEndDateTime })}
    </div>
  );
};

export const courseUnitsWithRelatedMainOpenUniversityProducts = (courseUnits, openUniversityProducts) => courseUnits
  .filter((courseUnit) => openUniversityProducts
    ?.some((oup) => oup.courseUnitId === courseUnit.id && oup.enrolmentLink));
