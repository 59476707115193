import React from 'react';
import { instanceOf } from 'prop-types';
import { Navigate } from 'react-big-calendar';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import startOfWeek from 'date-fns/startOfWeek';
import addDays from 'date-fns/addDays';
import addWeeks from 'date-fns/addWeeks';

const WeekView = (props) => {
  const { date } = props;
  const range = WeekView.range(date);
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <TimeGrid {...props} range={range} eventOffset={15} />;
};

WeekView.navigate = (date, action) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return addWeeks(date, -1);
    case Navigate.NEXT:
      return addWeeks(date, 1);
    default:
      return date;
  }
};

WeekView.range = (date) => {
  // date-fns weekday 0 is sunday
  const start = startOfWeek(date, { weekStartsOn: 1 });
  // Return array of dates from monday to saturday
  return [start, ...Array(5).fill(null).map((_, i) => addDays(start, i + 1))];
};

WeekView.title = (date, { localizer }) => {
  const [start, ...rest] = WeekView.range(date);
  return localizer.format({ start, end: rest.pop() }, 'dayRangeHeaderFormat');
};

WeekView.propTypes = {
  date: instanceOf(Date)
};

export default WeekView;
