import React, { useState, useRef } from 'react';
import {
  isWithinInterval, parseISO
} from 'date-fns';
import classNames from 'classnames';

import ModalDialog from '../../../../../ModalDialog';
import { courseUnitRealisationType } from '../../../../../../types';
import { getTranslation } from '../../../../../../i18n';

import styles from './openUniversityProductEnrolment.css';
import { ArrowOffsite } from '../../../../../Icon/Arrow';
import {
  getEnrolmentEnd, courseUnitsWithRelatedMainOpenUniversityProducts
} from '../util';
import { cuPathTranslations } from '../../../../../../constants';
import { sanitizeDangerousHtml } from '../../../../../../utils';
import useTranslation from '../../../../../../hooks/useTranslation';
import Link from '../../../../../Link';

const OpenUniversityProductEnrolment = ({ courseUnitRealisation }) => {
  const { t, lang } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCourseUnitId, setSelectedCourseUnitId] = useState();
  const firstCourseUnitRef = useRef();

  const {
    courseUnits, enrolmentPeriod, lateEnrolmentEnd, openUniversityProducts
  } = courseUnitRealisation;

  if (!enrolmentPeriod) {
    // If realisation does not have enrolment period, it is not possible to enroll to the realisation
    return null;
  }

  const relevantCourseUnits = courseUnitsWithRelatedMainOpenUniversityProducts(courseUnits, openUniversityProducts);

  if (!relevantCourseUnits.length) {
    // If no course unit match any open university products, it is not possible to enrol to the open university product
    return null;
  }

  const isSingleCourseUnit = relevantCourseUnits.length === 1;

  const modalId = 'oup-enrolment-dialog';

  const startDateTime = parseISO(enrolmentPeriod.startDateTime);
  const endDateTime = parseISO(enrolmentPeriod.endDateTime);
  const lateEndDateTime = parseISO(lateEnrolmentEnd);
  const now = new Date();

  if (!isWithinInterval(now, { start: startDateTime, end: lateEnrolmentEnd ? lateEndDateTime : endDateTime })) {
    return null;
  }

  const goToSisu = () => {
    const courseUnitId = isSingleCourseUnit ? relevantCourseUnits[0].id : selectedCourseUnitId;
    const url = openUniversityProducts.find((oup) => oup.courseUnitId === courseUnitId)?.enrolmentLink;
    if (url) {
      window.open(url, '_blank');
      setModalOpen(false);
    } else {
      firstCourseUnitRef?.current.focus();
    }
  };

  const getConfirmLabel = () => (
    <span className={styles.goToSisu}>
      {t('openUniversityProductEnrolment.modal.goToSisu')}
      <span className="sr-only">, {t('openInNewWindow')}</span>
      <ArrowOffsite className={styles.offsiteArrow} aria-hidden="true" />
    </span>
  );

  const firstCourseUnit = relevantCourseUnits[0];

  const singleCourseModalContents = (
    <>
      <p className={styles.descriptionBody}>{t('openUniversityProductEnrolment.modal.descriptionSingle')}</p>
      <div className={styles.courseUnit}>
        <p id={`enrolment-course-unit-${firstCourseUnit.id}`} className={styles.courseUnitDescriptionSingle}>
          {firstCourseUnit.code} {getTranslation(firstCourseUnit.name, lang)}
        </p>
        <Link
          id={`enrolment-course-unit-link-${firstCourseUnit.id}`}
          aria-labelledby={`enrolment-course-unit-link-${firstCourseUnit.id} enrolment-course-unit-${firstCourseUnit.id}`}
          external
          href={`/${getTranslation(cuPathTranslations, lang)}/${firstCourseUnit.id}/${firstCourseUnit.code}`}
        >
          {t('openUniversityProductEnrolment.modal.courseUnitLink')}
        </Link>
      </div>
      {getEnrolmentEnd(courseUnitRealisation, lang, t)}
      <div className={styles.enrolmentInfoItem}>
        <span className={styles.index}>1.</span>
        {t('openUniversityProductEnrolment.modal.enrolBelow')}
      </div>
    </>
  );

  const multiCourseModalContents = (
    <>
      <p className={styles.description}>{t('openUniversityProductEnrolment.modal.descriptionMany')}</p>
      {/* eslint-disable-next-line react/no-danger */}
      <p dangerouslySetInnerHTML={sanitizeDangerousHtml(t('openUniversityProductEnrolment.modal.descriptionBody'))} />

      <fieldset>
        <legend className={styles.enrolmentInfoItem}>
          <span className={styles.index}>1.</span>
          {t('openUniversityProductEnrolment.modal.selectCourseUnit')}
        </legend>
        <div
          className={styles.courseUnits}
        >
          {relevantCourseUnits
            .map((courseUnit, i) => (
              <div key={courseUnit.id} className={styles.courseUnit}>
                <input
                  id={`enrolment-course-unit-${courseUnit.id}`}
                  type="radio"
                  name="enrolment-course-unit-picker"
                  ref={i === 0 ? firstCourseUnitRef : undefined}
                  required
                  value={courseUnit.id}
                  onChange={() => setSelectedCourseUnitId(courseUnit.id)}
                  checked={selectedCourseUnitId === courseUnit.id}
                />
                <label htmlFor={`enrolment-course-unit-${courseUnit.id}`}>
                  <p className={styles.courseUnitDescriptionMulti}>
                    {courseUnit.code} {getTranslation(courseUnit.name, lang)}
                  </p>
                </label>
                <Link
                  className={styles.courseUnitInputLink}
                  id={`enrolment-course-unit-link-${courseUnit.id}`}
                  aria-labelledby={`enrolment-course-unit-link-${courseUnit.id} enrolment-course-unit-${courseUnit.id}`}
                  external
                  href={`/${getTranslation(cuPathTranslations, lang)}/${courseUnit.id}/${courseUnit.code}`}
                >{t('openUniversityProductEnrolment.modal.courseUnitLink')}
                </Link>
              </div>
            ))}
        </div>
      </fieldset>

      <div className={styles.enrolmentInfoItem}>
        <span className={styles.index}>2.</span>
        {t('openUniversityProductEnrolment.modal.enrol')}
      </div>
      {getEnrolmentEnd(courseUnitRealisation, lang, t)}
    </>
  );

  return (
    <>
      <button
        type="button"
        className={classNames('button--action', styles.goToEnrolment)}
        onClick={() => setModalOpen(true)}
        aria-haspopup="dialog"
        aria-controls={modalId}
        aria-expanded={modalOpen}
      >
        {t('openUniversityProductEnrolment.goToEnrolment')}
      </button>
      {modalOpen ? (
        <ModalDialog
          id={modalId}
          onConfirm={goToSisu}
          onCancel={() => setModalOpen(false)}
          confirmLabel={getConfirmLabel()}
          cancelLabel={t('openUniversityProductEnrolment.modal.return')}
          buttonsClassName={styles.dialogButton}
          reverseButtons
          modalDialogTitle={t('openUniversityProductEnrolment.modal.title')}
          isEnrolment
        >
          {isSingleCourseUnit
            ? singleCourseModalContents
            : multiCourseModalContents}
        </ModalDialog>
      ) : null}
    </>
  );
};

OpenUniversityProductEnrolment.propTypes = {
  courseUnitRealisation: courseUnitRealisationType.isRequired
};

export default OpenUniversityProductEnrolment;
