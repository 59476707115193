import React, { useState } from 'react';
import {
  isWithinInterval, parseISO
} from 'date-fns';
import classNames from 'classnames';

import ModalDialog from '../../../../../ModalDialog';
import { courseUnitRealisationType } from '../../../../../../types';
import { getTranslation } from '../../../../../../i18n';
import { formatCredits } from '../../../../../../utils';

import styles from './sisuEnrolment.css';
import { ArrowOffsite } from '../../../../../Icon/Arrow';
import { getEnrolmentEnd } from '../util';
import useTranslation from '../../../../../../hooks/useTranslation';

const SisuEnrolment = ({ courseUnitRealisation }) => {
  const { t, lang } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  if (!courseUnitRealisation.enrolmentPeriod) {
    // If realisation does not have enrolment period, it is not possible to enroll to the realisation
    return null;
  }

  const {
    courseUnits, enrolmentPeriod, lateEnrolmentEnd, sisuUrl
  } = courseUnitRealisation;

  const modalId = 'sisu-enrolment-dialog';

  const startDateTime = parseISO(enrolmentPeriod.startDateTime);
  const endDateTime = parseISO(enrolmentPeriod.endDateTime);
  const lateEndDateTime = parseISO(lateEnrolmentEnd);
  const now = new Date();

  if (!isWithinInterval(now, { start: startDateTime, end: lateEnrolmentEnd ? lateEndDateTime : endDateTime })) {
    return null;
  }

  const goToSisu = () => {
    window.open(`${sisuUrl}/student/plan`, '_blank');
    setModalOpen(false);
  };

  const getConfirmLabel = () => (
    <span className={styles.goToSisu}>
      {t('sisuEnrolment.modal.goToSisu')}
      <span className="sr-only">, {t('openInNewWindow')}</span>
      <ArrowOffsite className={styles.offsiteArrow} aria-hidden="true" />
    </span>
  );

  return (
    <>
      <button
        type="button"
        className={classNames('button--action', styles.goToEnrolment)}
        onClick={() => setModalOpen(true)}
        aria-haspopup="dialog"
        aria-controls={modalId}
        aria-expanded={modalOpen}
      >
        {t('sisuEnrolment.goToEnrolment')}
      </button>
      {modalOpen ? (
        <ModalDialog
          id={modalId}
          onConfirm={goToSisu}
          onCancel={() => setModalOpen(false)}
          confirmLabel={getConfirmLabel()}
          cancelLabel={t('sisuEnrolment.modal.return')}
          buttonsClassName={styles.dialogButton}
          reverseButtons
          modalDialogTitle={t('sisuEnrolment.modal.title')}
          isEnrolment
        >
          <p>{t('sisuEnrolment.modal.description')}</p>

          <div className={styles.enrolmentInfoItem}>
            <span className={styles.index}>1.</span>
            {t('sisuEnrolment.modal.courseUnit')}
          </div>
          <ul className={styles.courseUnits}>
            {courseUnits.map((courseUnit) => (
              <li key={courseUnit.id} className={styles.courseUnit}>
                {courseUnit.code} {getTranslation(courseUnit.name, lang)}{courseUnit.credits ? `, ${formatCredits(courseUnit.credits, lang)}` : ''}
              </li>
            ))}
          </ul>

          <div className={styles.enrolmentInfoItem}>
            <span className={styles.index}>2.</span>
            {t('sisuEnrolment.modal.studyPlan')}
          </div>

          <div className={styles.enrolmentInfoItem}>
            <span className={styles.index}>3.</span>
            {t('sisuEnrolment.modal.completion')}
          </div>

          {getEnrolmentEnd(courseUnitRealisation, lang, t)}

        </ModalDialog>
      ) : null}
    </>
  );
};

SisuEnrolment.propTypes = {
  courseUnitRealisation: courseUnitRealisationType.isRequired
};

export default SisuEnrolment;
