import React from 'react';
import { string } from 'prop-types';

/**
 * Splits text into paragraphs by splitting on 2 or more sequential instances of \r\n or \n
 *
 * @param {Object} properties
 * @param {string} properties.text - Text to split in paragraphs
 * @param {string} [properties.className] - Optional class name for wrapping div
 * @returns {JSX.Element|null} Text split in paragraphs or null if input string is blank
 */
const ParagraphedText = ({ text, className }) => {
  const split = text?.split(/(\r?\n){2,}/).map((t) => t.trim()).filter(Boolean);

  if (!split?.length) {
    return null;
  }

  return (
    <div className={className}>
      {split.map((s) => <p key={s}>{s}</p>)}
    </div>
  );
};

ParagraphedText.propTypes = {
  text: string,
  className: string
};

ParagraphedText.defaultProps = {
  className: ''
};

export default ParagraphedText;
