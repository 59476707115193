import React from 'react';
import PropTypes, { string } from 'prop-types';

import { courseUnitType } from '../../../../types';
import { formatCredits, getOrganisationBackgroundImage } from '../../../../utils';

import styles from './courseUnitHeader.css';
import { getTranslation } from '../../../../i18n';
import ResponsibilityInfos from '../../ResponsibilityInfos';
import Organisation from '../../Organisation';
import StudyYearPicker from '../../../StudyYearPicker';
import { getOrganisationId, getOrganisationName } from '../../util';
import DepthIndicator from '../../../DepthIndicator';
import useTranslation from '../../../../hooks/useTranslation';

const defaultImage = require('../../../../images/background-2.jpg');

const CourseUnitHeader = ({
  courseUnit = {}, selectedYear, changeYear
}) => {
  const { t, lang } = useTranslation();
  const backgroundImageUrl = getOrganisationBackgroundImage(courseUnit.organisationBackgroundImages, defaultImage);

  const organisations = (courseUnit.organisations || [])
    .filter((org) => org.organisation || org.educationalInstitution)
    .map((org, index, array) => (
      <Organisation
        key={getOrganisationId(org)}
        name={getOrganisationName(org)}
        appendSeparator={index < array.length - 1}
      />
    ));

  const possibleAttainmentLanguages = (courseUnit.possibleAttainmentLanguages || [])
    .map((language) => getTranslation(language.name, lang))
    .join(', ');

  return (
    <div className="grid-container">
      <StudyYearPicker value={selectedYear} options={courseUnit.curriculumPeriods} label={t('studyModule.studyYear')} onChange={changeYear} />
      <div className={`page-wide-content-padding ${styles.content}`}>
        <div className={styles.innerContent}>
          <div>
            <div className={`${styles.courseUnitCode} heading-like size-3`}>
              {t('courseUnit.singular')} {courseUnit.code}
            </div>
            <h1 id={courseUnit.id} className={styles.title}>
              {getTranslation(courseUnit.name, lang)}
              {' '}
              ({formatCredits(courseUnit.credits, lang)})
            </h1>
            {backgroundImageUrl && (
            <div className={styles.organisationImageMobile} aria-hidden="true">
              <img
                alt=""
                src={backgroundImageUrl}
              />
            </div>
            )}

            {!!organisations.length && (
            <p>
              <span className={styles.boldSpan}>{t('responsibleOrganisation')}:</span>
              {' '}
              {organisations}
            </p>
            )}

            <ResponsibilityInfos
              responsibilityInfos={courseUnit.responsibilityInfos}
              description={`${t('courseUnit.responsibleTeacher')}:`}
              descriptionClassName={styles.boldSpan}
              lang={lang}
            />

            {courseUnit.studyLevel && (
            <p>
              <span className={styles.boldSpan}>{t('courseUnit.studyLevel')}:</span>
              {' '}
              {getTranslation(courseUnit.studyLevel.name, lang)}
            </p>
            )}

            {courseUnit.courseUnitType && (
            <p>
              <span className={styles.boldSpan}>{t('courseUnit.type')}:</span>
              {' '}
              {getTranslation(courseUnit.courseUnitType.name, lang)}
            </p>
            )}

            {possibleAttainmentLanguages.length && (
            <p>
              <span className={styles.boldSpan}>{t('courseUnit.possibleAttainmentLanguages')}:</span>
              {' '}
              {possibleAttainmentLanguages}
            </p>
            )}
          </div>
          {backgroundImageUrl && (
          <div className={styles.organisationImageContainer} aria-hidden="true">
            <img
              alt=""
              src={backgroundImageUrl}
            />
          </div>
          )}
        </div>
        <div className={styles.depthContainer}>
          <DepthIndicator cu />
        </div>
      </div>
    </div>
  );
};

CourseUnitHeader.propTypes = {
  courseUnit: courseUnitType,
  selectedYear: string.isRequired,
  changeYear: PropTypes.func.isRequired
};

export default CourseUnitHeader;
