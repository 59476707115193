import React from 'react';
import { bool, string } from 'prop-types';
import { getTranslation } from '../../../i18n';
import { localizedTextType } from '../../../types';
import useTranslation from '../../../hooks/useTranslation';

const Organisation = ({
  name,
  appendSeparator,
  partnerLocation
}) => {
  const { lang } = useTranslation();

  return (
    <>
      <span>{getTranslation(name, lang)}{partnerLocation && `, ${partnerLocation}`}</span>
      { appendSeparator && ', ' }
    </>
  );
};

Organisation.propTypes = {
  name: localizedTextType.isRequired,
  appendSeparator: bool,
  partnerLocation: string
};

export default Organisation;
