import React from 'react';
import { func } from 'prop-types';
import styles from './degreeProgrammeSelector.css';
import useTranslation from '../../hooks/useTranslation';

const DegreeProgrammeSearch = ({ onChange }) => {
  const { t, lang } = useTranslation();

  return (
    <label className={styles.dpFilterLabel} htmlFor="degreeProgrammeInput">{t('degreeProgramStudies.filters.degreeProgramFilter.nameFilterTitle')}
      <div className={styles.dpFilterContainer}>
        <input
          id="degreeProgrammeInput"
          type="text"
          name="filter"
          placeholder={t('degreeProgramStudies.filters.degreeProgramFilter.placeholder', lang)}
          onChange={onChange}
        />
        <i aria-hidden="true" className={`${styles.searchIcon} icon--search`} />
      </div>
    </label>
  );
};

DegreeProgrammeSearch.propTypes = {
  onChange: func.isRequired
};

export default DegreeProgrammeSearch;
