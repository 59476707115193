import React from 'react';
import { shape, string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import ErrorBoundary from '../ErrorBoundary';
import StudyModuleWrapper from './StudyModuleWrapper';

const WHITESPACE_RE = /\s/g;

const StudyModulePage = ({ match }) => {
  const history = useHistory();

  const { params: { studyModuleId } } = match;

  // Clean up url if study module id contains whitespace
  // Not sure if needed, but leaving here just in case
  const idWithWhiteSpace = [studyModuleId].filter((id) => id && WHITESPACE_RE.test(id)).shift();
  if (idWithWhiteSpace) {
    history.replace(history.location.pathname.replace(idWithWhiteSpace, idWithWhiteSpace.replace(WHITESPACE_RE, '')));
    return null;
  }

  let wrapper;
  if (studyModuleId) {
    wrapper = <StudyModuleWrapper studyModuleId={studyModuleId} />;
  }

  return (
    <ErrorBoundary>
      { wrapper }
    </ErrorBoundary>
  );
};

StudyModulePage.propTypes = {
  match: shape({
    params: shape({
      studyModuleId: string
    })
  })
};

export default StudyModulePage;
