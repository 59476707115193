import React, { useMemo } from 'react';
import {
  arrayOf, instanceOf, number, shape
} from 'prop-types';
import addDays from 'date-fns/addDays';
import compareAsc from 'date-fns/compareAsc';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import { Navigate } from 'react-big-calendar';

import AgendaEvent from './AgendaEvent';
import styles from './agendaView.css';
import { isAfterOrEqual, isBeforeOrEqual } from '../../../../utils';
import { calendarEventType } from '../../../../types';

const inRange = (event, range) => isBeforeOrEqual(event.start, range.end) && isAfterOrEqual(event.end, range.start);

const AgendaView = ({
  events, date, length, localizer
}) => {
  const range = AgendaView.range(date, { length });
  const eventsToShow = useMemo(() => events
    .filter((event) => inRange(event, range))
    .sort((a, b) => compareAsc(a.start, b.start)), [events, range]);

  return (
    <div className={styles.container}>
      {/* eslint-disable-next-line react/no-array-index-key */}
      {eventsToShow.map((e, idx) => <AgendaEvent key={idx} event={e} localizer={localizer} />)}
    </div>
  );
};

AgendaView.propTypes = {
  events: arrayOf(calendarEventType),
  date: instanceOf(Date),
  length: number,
  localizer: shape({})
};

AgendaView.navigate = (date, action, { length }) => {
  switch (action) {
    case Navigate.NEXT:
      return addDays(date, length);
    case Navigate.PREVIOUS:
      return addDays(date, -length);
    default:
      return date;
  }
};

AgendaView.range = (date, { length }) => {
  const start = startOfDay(date);
  return { start, end: endOfDay(addDays(start, length - 1)) };
};

AgendaView.title = (date, { length, localizer }) => (length === 1
  ? localizer.format(date, 'dayHeaderFormat')
  : localizer.format(AgendaView.range(date, length), 'agendaHeaderFormat'));

export default AgendaView;
