import React, { forwardRef } from 'react';
import { string } from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import ResultItem from './ResultItem';
import { coursesSearchResultList } from '../../types';
import styles from './searchResults.css';
import FormattedNumber from '../FormattedNumber';
import useTranslation from '../../hooks/useTranslation';

const jointCourseImage = require('../../images/joint_course.png');

const SearchResults = forwardRef(({ results, lang }, ref) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();

  const setScrollPosToState = () => {
    history.replace({
      search,
      state: {
        scrollY: window.scrollY // so that we know where to scroll when coming back
      }
    });
  };

  if (!results) {
    return null;
  }

  return (
    <div className={styles.background}>
      <div className="grid-container page-wide-content-padding">
        <h2 role="region" aria-live="polite" aria-atomic="true" ref={ref} tabIndex="-1">
          {t('search.resultsTitle')}{' '}<span aria-hidden>(</span><FormattedNumber value={results.totalHits} lang={lang} /><span aria-hidden>)</span>
        </h2>
        <p aria-hidden>
          {t('search.jointCourse')}
          <img aria-hidden className={styles.jointCourseImg} src={jointCourseImage} alt="" />
        </p>
        {
          results?.hits?.length
            ? results.hits.map((result) => <ResultItem key={result.id} result={result} setScrollPosToState={setScrollPosToState} />)
            : <p>{t('search.noResults')}</p>
        }
        {results?.queryExceedsMaxResultWindow && <p>{t('search.tooManyResults')}</p>}
      </div>
    </div>
  );
});

SearchResults.propTypes = {
  results: coursesSearchResultList,
  lang: string
};

export default SearchResults;
