import React from 'react';

import { routes } from '../../../constants';

import LinkItem from './LinkItem';
import styles from './exploreStudies.css';
import useTranslation from '../../../hooks/useTranslation';

const degreeStructureImg = require('../../../images/explore_studies_degree_structure.png');
const degreeProgramImg = require('../../../images/explore_studies_dp.png');
const openUniImage = require('../../../images/explore_studies_open_uni.png');
const languageCenterImg = require('../../../images/explore_studies_language.png');
const prelimImg = require('../../../images/explore_studies_preliminary.png');
const swedishImg = require('../../../images/explore_studies_swedish.png');

const LINKS = [{
  key: 'degreeStructure',
  image: degreeStructureImg,
  trackingLabel: 'Tutkintorakenne',
  external: false
}, {
  key: 'degreeProgrammeStudies',
  route: routes.dps,
  image: degreeProgramImg,
  trackingLabel: 'KOOT',
  external: false
}, {
  key: 'openUniversity',
  image: openUniImage,
  trackingLabel: 'Avoin yliopisto',
  external: true
}, {
  key: 'languageCenter',
  image: languageCenterImg,
  trackingLabel: 'Kielikeskus',
  external: false
}, {
  key: 'preliminaryTeachingProgramme',
  image: prelimImg,
  trackingLabel: 'Alustava opetusohjelma',
  external: false
}, {
  key: 'studiesInSwedish',
  image: swedishImg,
  trackingLabel: 'Ruotsinkieliset opinnot',
  external: false
}];

const ExploreStudies = () => {
  const { t, lang } = useTranslation();

  return (
    <section>
      <h2>{t('search.exploreStudies.title')}</h2>

      <div className={`${styles.linksContainer} grid-row`}>
        {LINKS.map((item) => (
          <LinkItem
            key={item.key}
            url={item.route ? item.route(lang) : t(`search.exploreStudies.${item.key}.url`, lang)}
            text={t(`search.exploreStudies.${item.key}.text`, lang)}
            tagLine={t(`search.exploreStudies.${item.key}.tagLine`, lang)}
            image={item.image}
            trackingLabel={item.trackingLabel}
            external={item.external}
          />
        ))}
      </div>
    </section>
  );
};

export default ExploreStudies;
