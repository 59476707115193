import 'core-js/actual';
import 'regenerator-runtime/runtime';
import 'react-hot-loader';

import React from 'react';
import ReactDOM from 'react-dom';

import Main from './components/Main';
import './styles/global';

// Initialize Sentry
import './sentry';

ReactDOM.render(<Main />, document.getElementById('root'));
