import React from 'react';
import { intersection } from 'lodash';

import { getCreditsRange, isMOOC } from '../../../../../utils';
import { courseUnitRealisationType } from '../../../../../types';
import { OPEN_UNIVERSITY_COURSE_PRICE_PER_CREDIT } from '../../../../../constants';
import styles from './tags.css';
import useTranslation from '../../../../../hooks/useTranslation';

const TAGS_TO_DISPLAY = [
  'aloita_joustavasti',
  'tutustumiskurssi',
  'sopii_lukiolaisille',
  'exchange_students',
  'partner_coordinator'
];

const FREE_FOR_DEGREE_STUDENT_TAGS = [
  'free_for_degree_students',
  'free_places_for_degree_students'
];

const renderTag = (translationBase, t) => (tag) => (
  <li key={tag}>{t(`${translationBase}.${tag}`)}</li>
);

const getPriceForCredits = (ai, property) =>
  ai?.credits?.[property] && ai.credits[property] * OPEN_UNIVERSITY_COURSE_PRICE_PER_CREDIT;

// Currently supports only one price, possibly nothing else needed
const getPriceForOpenUniversityProducts = (oups, curId, lang) => {
  const price = oups
  // Check that this is primary cur for product
    .find((oup) => oup.overriddenCURIds?.some((id) => id === curId))
  // Price is usually in format e.g. EUR 15.00
    ?.price?.replace(/(^...\s*)/, '');
  return lang === 'en' ? price : price?.replace(/\./, ',');
};

const Tags = ({ courseUnitRealisation = {} }) => {
  const { t, lang } = useTranslation();
  const {
    id,
    tags = [],
    assessmentItems = [],
    displayCourseFee,
    displayFreeOfCharge,
    openUniversityPartnerCoordinators,
    openUniversityProducts = []
  } = courseUnitRealisation;

  if (!tags.length && !displayCourseFee && !displayFreeOfCharge && !openUniversityProducts.length) {
    return null;
  }

  const ai = assessmentItems.length && assessmentItems[0];
  const priceRange = {
    min: getPriceForCredits(ai, 'min'),
    max: getPriceForCredits(ai, 'max')
  };
  const formattedPriceRange = getCreditsRange(priceRange) || false;

  const openUniversityProductsPrice = openUniversityProducts.length && getPriceForOpenUniversityProducts(openUniversityProducts, id, lang);

  const courseFee = displayCourseFee
    && !displayFreeOfCharge
    && !isMOOC(tags)
    && !openUniversityPartnerCoordinators?.length
    && formattedPriceRange;

  const price = openUniversityProductsPrice || courseFee;

  const freeForDegreeStudentTags = intersection(tags, FREE_FOR_DEGREE_STUDENT_TAGS);

  return (
    <ul className={styles.tagContainer}>
      {tags.filter((tag) => TAGS_TO_DISPLAY.includes(tag)).map(renderTag('courseTags', t))}
      {price && (
        <li>{t('courseFee.price', { price })}</li>
      )}
      {(price && freeForDegreeStudentTags.length) ? freeForDegreeStudentTags.map(renderTag('freeForDegreeStudentTags', t)) : null}
      {displayFreeOfCharge && (
        <li>{t('courseFee.freeOfCharge')}</li>
      )}
    </ul>
  );
};

Tags.propTypes = {
  courseUnitRealisation: courseUnitRealisationType
};

export default Tags;
