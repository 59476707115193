import React from 'react';
import PropTypes from 'prop-types';
import Message from '../Message';
import { getDegreeProgram, getDegreeProgrammes } from '../../api';
import useApiFunction from '../../hooks/useApiFunction';
import DegreeStructure from './DegreeStructure';
import { getStudyYearStartYear } from '../../utils';
import useTranslation from '../../hooks/useTranslation';

const DegreeStructureWrapper = ({ degreeProgramId }) => {
  const { t } = useTranslation();
  const {
    data, error, loading, isSlowLoad
  } = useApiFunction(getDegreeProgram, degreeProgramId);
  const currentStudyYear = getStudyYearStartYear(new Date());
  const degreeProgrammes = useApiFunction(getDegreeProgrammes, `${currentStudyYear},${currentStudyYear + 1}`);

  const degreeProgram = degreeProgramId ? data : null;

  // dont blink loading message with fast loads if a degreeProgram is loaded
  if (degreeProgramId && ((loading && !degreeProgram) || isSlowLoad)) {
    return (
      <Message loading>
        {t('studyModule.loading', { degreeProgramId })}
      </Message>
    );
  }

  if (error) {
    const messageKey = error.status === 404 ? 'degreeProgram.notFound' : 'common.error';
    const errorData = error.status === 404 ? {
      degreeProgramId
    } : {
      status: error.status,
      message: error.statusText
    };

    return (
      <Message error>
        {t(messageKey, errorData)}
      </Message>
    );
  }
  const degreeProgrammesData = Array.isArray(degreeProgrammes?.data) ? degreeProgrammes.data : [];

  return (
    <DegreeStructure
      degreeProgram={degreeProgram?.degreeProgramme}
      degreeProgrammes={degreeProgrammesData}
    />
  );
};

DegreeStructureWrapper.propTypes = {
  degreeProgramId: PropTypes.string
};

export default DegreeStructureWrapper;
