import React, { useEffect } from 'react';
import { string } from 'prop-types';
import styles from './studyModuleStructure.css';
import useTranslation from '../../../hooks/useTranslation';

const StudyModuleStructure = ({
  id, academicYear
}) => {
  const { t, lang } = useTranslation();

  useEffect(() => {
    window.eduviewer_var?.render();
  }, [academicYear]);

  return (
    <>
      <h2>{t('studyModule.studyModuleStructure')}</h2>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <div className={styles.eduviewerContainer} id="eduviewer-root" module-code={id} lang={lang} academic-year={academicYear} selected-academic-year-only="true" hide-selections="true" disable-global-style="true" hide-accordion="true" internal-course-links="true" />
    </>
  );
};

StudyModuleStructure.propTypes = {
  id: string,
  academicYear: string
};

export default StudyModuleStructure;
