import React, { useEffect, useState } from 'react';
import useIsLoggedIn from '../../hooks/useIsLoggedIn';

import { findFilterOptions, getFlammaNews, optionTypes } from '../../api';
import NewsContainer from '../NewsContainer';
import { eventCategories } from '../../services/analytics';
import useDegreePrograms from '../../hooks/useDegreePrograms';
import { getTranslation } from '../../i18n';
import Filter from '../Filter';
import styles from './flammaNews.css';
import useTranslation from '../../hooks/useTranslation';

const getFacultyOptions = async (lang) => {
  const { [optionTypes.FACULTY]: faculties } = await findFilterOptions({ types: [optionTypes.FACULTY] });
  return faculties.map((f) => {
    const { abbreviation, name } = f;
    return {
      value: abbreviation,
      label: getTranslation(name, lang)
    };
  }).sort((f1, f2) => (f1.label > f2.label ? 1 : -1));
};

const FlammaNews = () => {
  const { t, lang } = useTranslation();
  const isLoggedIn = useIsLoggedIn();
  const degreeProgrammes = useDegreePrograms();
  const [facultyOptions, setFacultyOptions] = useState(null);
  const [news, setNews] = useState();
  const [includeFacultyNews, setIncludeFacultyNews] = useState(true);
  const [selectedFaculty, setSelectedFaculty] = useState();

  useEffect(() => {
    if (isLoggedIn && !facultyOptions) {
      (async () => {
        try {
          const options = await getFacultyOptions(lang);
          setFacultyOptions(options);
          const primaryDegreeProgramme = degreeProgrammes?.find((dp) => dp.isPrimary);
          const primaryDegreeProgrammeInOptions = options.find((f) => f.value === primaryDegreeProgramme?.facultyAbbreviation);
          if (primaryDegreeProgrammeInOptions) {
            setSelectedFaculty(primaryDegreeProgrammeInOptions.value);
          }
        } catch (e) {
          setFacultyOptions(null);
        }
      })();
    }
  }, [degreeProgrammes, facultyOptions, isLoggedIn, lang]);

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        try {
          const facultyParam = includeFacultyNews ? selectedFaculty : undefined;
          const items = await getFlammaNews(lang, facultyParam);
          setNews(items);
        } catch (e) {
          setNews(null);
        }
      })();
    }
  }, [isLoggedIn, lang, includeFacultyNews, selectedFaculty]);

  const includeFacultyNewsCheckboxId = 'includeFacultyNews';

  return isLoggedIn && (
    <NewsContainer
      header="flammaNews.header"
      controls={facultyOptions?.length && (
        <>
          <label className={styles.includeFacultyNewsLabel} htmlFor={includeFacultyNewsCheckboxId}>
            <input
              id={includeFacultyNewsCheckboxId}
              className={styles.includeFacultyNews}
              type="checkbox"
              checked={includeFacultyNews}
              onChange={(e) => setIncludeFacultyNews(e.target.checked)}
            />
            <span>{t('filters.facultyFilter.includeFacultyNews')}</span>
          </label>
          <Filter
            isMulti={false}
            isClearable
            filterKey="facultyFilter"
            selectedItems={selectedFaculty ? [selectedFaculty] : []}
            items={facultyOptions}
            setSelectedItems={(selected) => setSelectedFaculty(selected[0])}
            defaultTextLocalizationKey="filters.facultyFilter.prompt"
          />
        </>
      )}
      itemTypeLabel="flammaNews.itemTypeLabel"
      newsItems={news}
      showAllLink={`https://flamma.helsinki.fi/${lang}/group/ajankohtaista/uutiset?topic=961983&unit=994384&importance=news%2Cmainnews`}
      showAllLabel="flammaNews.showAllLabel"
      external
      trackedEventCategory={eventCategories.frontpage.COMMUNITY_NEWS}
      showItemIngress={false}
      itemsToShow={6}
    />
  );
};

export default FlammaNews;
