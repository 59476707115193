import React from 'react';
import { getTranslation } from '../../i18n';
import DocumentHead from '../DocumentHead';
import { studyModuleType } from '../../types';
import { routes, AVAILABLE_LANGUAGES, DOCUMENT_STATES } from '../../constants';
import StudyYearPicker from '../StudyYearPicker';
import { getEduviewerEmbedUrl } from '../../utils';

import StudyModuleHeader from './StudyModuleHeader';
import StudyModuleDescription from './StudyModuleDescription';
import StudyModuleStructure from './StudyModuleStructure';
import DegreeProgrammes from './DegreeProgrammes';
import useStudyYearPicker from '../../hooks/useStudyYearPicker';
import useTranslation from '../../hooks/useTranslation';

const StudyModule = ({
  studyModule
}) => {
  const { t, lang } = useTranslation();

  const { selectedYear, changeYear } = useStudyYearPicker(studyModule.id, studyModule.curriculumPeriods);

  const studyModuleTitle = [
    t('studyModule.studyModule'),
    studyModule.code,
    getTranslation(studyModule.name, lang)
  ].filter(Boolean).join(' ');

  // Pass true to getEduviewerEmbedUrl if you want to test local version of eduviewer
  const EDUVIEWER_SRC = getEduviewerEmbedUrl();
  const localizedUrls = AVAILABLE_LANGUAGES.map((language) => [language, routes.sm(language, studyModule.id)]);
  const scripts = [
    {
      src: EDUVIEWER_SRC,
      type: 'text/javascript',
      async: true,
      id: 'eduviewer-script',
      removeOnUnmount: true
    }
  ];

  const collectDegreeProgrammes = (id, dps, visited) => {
    if (visited.has(id)) {
      return dps;
    }
    visited.add(id);
    const module = studyModule.hierarchy?.[id];
    if (!module || (module.curriculumPeriodIds?.length && !module.curriculumPeriodIds.includes(selectedYear))) {
      return dps;
    }
    if (module.type === 'DegreeProgramme'
      && module.gradeScaleId
      && !module.code?.startsWith('2_')
      && module.curriculumPeriodIds?.includes(selectedYear)
      && [DOCUMENT_STATES.DRAFT, DOCUMENT_STATES.ACTIVE].includes(module.documentState)) {
      dps.push(module);
    }
    module.parentIds?.forEach((parentId) => collectDegreeProgrammes(parentId, dps, visited));
    return dps;
  };

  // for local testing w. qa-data use studyModuleId instead of studyModule.id when calling collect degreeProgrammes
  // const studyModuleId = location.pathname.split('/').pop();
  const degreeProgrammes = collectDegreeProgrammes(studyModule.id, [], new Set());
  degreeProgrammes.sort((a, b) => (a.code > b.code ? 1 : -1));

  return (
    <>
      {/* Key is used to force rerender when year is changed. Otherwise Eduviewer doesn't pick up on changed year */}
      <DocumentHead key={selectedYear} urls={localizedUrls} title={studyModuleTitle} scripts={scripts} />
      <section id="study-module-unit">
        <div className="grid-container">
          <StudyYearPicker value={selectedYear} options={studyModule.curriculumPeriods} label={t('studyModule.studyYear')} onChange={changeYear} />
          <StudyModuleHeader studyModule={studyModule} />
        </div>

        <div className="grid-container page-wide-content-padding">
          <StudyModuleStructure id={studyModule.code} academicYear={selectedYear} />
          <StudyModuleDescription studyModule={studyModule} lang={lang} />
          {!!degreeProgrammes.length
          && <DegreeProgrammes degreeProgrammes={degreeProgrammes} selectedYear={selectedYear} />}
        </div>
      </section>
    </>
  );
};

StudyModule.propTypes = {
  studyModule: studyModuleType.isRequired
};

export default StudyModule;
