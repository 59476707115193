import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { organisationRoles, OPEN_UNIVERSITY_ORGANISATION_ID } from '../../../../../constants';
import { courseUnitRealisationType } from '../../../../../types';
import { getTranslation } from '../../../../../i18n';
import styles from './infoCard.css';
import ResponsibilityInfos from '../../../ResponsibilityInfos';
import { eventActions, eventCategories, trackEvent } from '../../../../../services/analytics';
import {
  formatCourseName,
  formatCredits,
  formatSemiOpenDateSpan,
  listToOxfordComma
} from '../../../../../utils';
import TeachingLanguages from '../../../../TeachingLanguages';
import { getCourseUnitUrlPath, getOrganisationName } from '../../../util';
import CoordinatorOrganisations from '../../../CoordinatorOrganisations';
import useTranslation from '../../../../../hooks/useTranslation';

const CUT_CU_LINK_LIST_THRESHOLD = 3;

const isOpenUniversityResponsible = (organisations) => organisations?.some((organisation) =>
  organisation.roleUrn === organisationRoles.RESPONSIBLE
  && organisation.organisation?.id === OPEN_UNIVERSITY_ORGANISATION_ID);

const transformToLowerCaseWhen = (condition, name) =>
  (condition ? name.toLowerCase() : name);

const InfoCard = ({ courseUnitRealisation: cur }) => {
  const { t, lang } = useTranslation();
  const [cutCuLinkList, setCutCuLinkList] = useState(cur.courseUnits.length > CUT_CU_LINK_LIST_THRESHOLD);

  const lastCourseUnitSeparator = t('common.and');
  const coordinatorOrganisations = cur.openUniversity && cur.openUniversityPartnerCoordinators;

  const organisations = cur.organisations
    ?.filter((org) => org.roleUrn === organisationRoles.RESPONSIBLE)
    .map((curOrganisation, idx) => {
      const orgName = getTranslation(getOrganisationName(curOrganisation), lang);
      return transformToLowerCaseWhen(
        idx > 0 && curOrganisation.organisation?.id === OPEN_UNIVERSITY_ORGANISATION_ID,
        orgName
      );
    }) || [];

  if (cur.openUniversityProducts?.length && !isOpenUniversityResponsible(cur.organisations)) {
    organisations.push(transformToLowerCaseWhen(
      organisations.length > 0,
      t('openUniversity')
    ));
  }

  const getCourseUnits = () => {
    const links = (cutCuLinkList ? cur.courseUnits.slice(0, CUT_CU_LINK_LIST_THRESHOLD - 1) : cur.courseUnits).map((cu) => (
      <Link
        key={cu.id}
        to={getCourseUnitUrlPath(cu, lang)}
        title={getTranslation(cu.name, lang)}
        onClick={() => trackEvent(eventCategories.coursePage.LINK, eventActions.CLICK, 'course unit top')}
      >
        {cu.code}
      </Link>
    ));

    if (links.length && cutCuLinkList) {
      links.push(
        <button type="button" className="button--link" onClick={() => setCutCuLinkList(false)}>
          {t('courseUnitRealisation.more', { count: cur.courseUnits.length - CUT_CU_LINK_LIST_THRESHOLD + 1 })}
        </button>
      );
    }

    const allLinks = links.reduce((previous, current, idx, linksArr) => {
      if (idx === 0) {
        return current;
      }

      const isLastLink = idx === linksArr.length - 1;
      const separator = isLastLink ? ` ${lastCourseUnitSeparator} ` : ', ';
      return [previous, separator, current];
    }, null);
    return links.length > 1
      ? <div>{t('courseUnit.plural')} {allLinks}</div>
      : <div>{t('courseUnit.singular')} {allLinks}</div>;
  };

  const getRealisationGroupsetNames = (studyGroupSets) => {
    const contentTypes = studyGroupSets.map((groupSet, index) => {
      const translation = getTranslation(groupSet.name, lang);
      return index !== 0 ? translation.toLowerCase() : translation;
    });
    if (contentTypes.length > 1) {
      const [last1, last2] = contentTypes.slice(contentTypes.length - 2, contentTypes.length);
      return [
        contentTypes.slice(0, contentTypes.length - 2).join(', '),
        `${contentTypes.length > 2 ? ',' : ''} ${last1} ${lastCourseUnitSeparator} ${last2}`
      ];
    }
    return contentTypes;
  };

  const formatCurType = () => {
    const curType = getTranslation(cur.courseUnitRealisationType.name, lang);
    if (!cur.teachingClassification) return curType;
    return `${curType} (${t(`teachingClassification.${cur.teachingClassification}`)})`;
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.courseTitle}>
        {formatCourseName(cur.id, cur.name, cur.nameSpecifier, lang)}
        {' '}
        <span className="nowrap">
          {formatCredits(cur.assessmentItems?.[0]?.credits, lang)}
        </span>
      </h1>

      {coordinatorOrganisations && (
        <CoordinatorOrganisations
          organisations={coordinatorOrganisations}
          partnerLocation={cur.cmsData.partnerLocation}
        />
      )}

      <div className={`${styles.courseUnits} course-units`}>
        {getCourseUnits()}
      </div>

      <div>
        <span data-testid="realisation-curtype">{formatCurType()}</span>
        {cur.activityPeriod && (
          <>
            <span>, </span>
            <span className={styles.activityPeriod}>
              {formatSemiOpenDateSpan(cur.activityPeriod.startDate, cur.activityPeriod.endDate, lang)}
            </span>
          </>
        )}
      </div>

      <div className="realisation-groupsets">{getRealisationGroupsetNames(cur.studyGroupSets)}</div>

      <div className="course-organisations"><span>{listToOxfordComma(organisations, lang)}</span></div>

      <div className={styles.responsibilityInfos}>
        <ResponsibilityInfos
          responsibilityInfos={cur.responsibilityInfos}
          lang={lang}
        />
      </div>

      {cur.teachingLanguages && <TeachingLanguages teachingLanguages={cur.teachingLanguages} languageListClassName={styles.teachingLanguageList} />}
    </div>
  );
};

InfoCard.propTypes = {
  courseUnitRealisation: courseUnitRealisationType.isRequired
};

export default InfoCard;
