import React from 'react';
import classNames from 'classnames';

import styles from './links.css';
import { zoomLinkType } from '../../../../types';
import { eventCategories } from '../../../../services/analytics';
import Link from '../../../Link';
import useTranslation from '../../../../hooks/useTranslation';

const ZoomLink = ({ link }) => {
  const { t } = useTranslation();

  if (!link) {
    return null;
  }

  const zoomSecrets = [
    { label: t('zoomLink.meetingId'), value: link.meetingId },
    { label: t('zoomLink.password'), value: link.pw }
  ];

  return (
    <div className={classNames(styles.linksContainer, styles.zoomLinkContainer)}>
      <h2>Zoom</h2>
      <Link
        href={link.url}
        className={`button--action ${styles.zoomLinkButton}`}
        tracked
        eventCategory={eventCategories.coursePage.LINK}
        trackingLabel="zoom"
        external
        iconPosition="after"
      >
        {t('zoomLink.goToZoom')}
      </Link>
      <div className={styles.zoomSecrets}>
        {zoomSecrets.map(({ label, value }) => (
          <div key={value} className={styles.zoomSecret}>
            <span className={classNames('icon--permission-private', styles.icon)} />
            <span className={styles.label}>{label}:</span>
            {' '}
            {value}
          </div>
        ))}
      </div>
    </div>
  );
};

ZoomLink.propTypes = {
  link: zoomLinkType
};

export default ZoomLink;
