import React, { useState } from 'react';
import { parseISO } from 'date-fns';
import classNames from 'classnames';

import { courseUnitType } from '../../../../types';
import styles from './courseUnitRealisationsTable.css';
import CourseUnitRealisationRow from './CourseUnitRealisationRow';
import { partitionArray, isBeforeOrEqual } from '../../../../utils';
import useTranslation from '../../../../hooks/useTranslation';
import EmptyState from './EmptyState';

const jointCourseImage = require('../../../../images/joint_course.png');

const sortCourseUnitRealisations = (a, b) => {
  const date1 = parseISO(a.activityPeriod.startDate);
  const date2 = parseISO(b.activityPeriod.endDate);

  if (date1 > date2) {
    return -1;
  }

  if (date1 < date2) {
    return 1;
  }

  return 0;
};

const CourseUnitRealisationTable = ({ courseUnit }) => {
  const { t } = useTranslation();
  const [showPast, setShowPast] = useState(false);

  const now = new Date();
  const [past, future] = partitionArray(
    courseUnit?.courseUnitRealisations?.sort(sortCourseUnitRealisations) || [],
    (cur) => isBeforeOrEqual(parseISO(cur.activityPeriod.endDate), now)
  );
  const isRealisations = future.length || (showPast && past.length);
  const jointCourseImageAlt = t('courseUnit.courseUnitRealisations.jointCourseImageAlt');

  return (
    <div className={styles.courseUnitRealisations}>
      <h2 className={styles.title}>{t('courseUnit.courseUnitRealisations.title')}</h2>
      <div className={styles.explanation}>
        {t('courseUnit.courseUnitRealisations.explanation')}
      </div>
      <p className={styles.jointCourseExplanationText}>
        {t('courseUnit.courseUnitRealisations.jointCourse')}
        <img className={styles.jointCourseImg} src={jointCourseImage} alt={jointCourseImageAlt} />
      </p>
      <div className={styles.courseUnitRealisationTableContainer}>
        <table className={styles.realisationsTable}>
          <thead className={styles.heading}>
            <tr>
              <th>
                {t('courseUnit.courseUnitRealisations.heading.name')}
              </th>
              <th>
                {t('courseUnit.courseUnitRealisations.heading.type')}
              </th>
              <th>
                {t('courseUnit.courseUnitRealisations.heading.time')}
              </th>
              <th>
                {t('courseUnit.courseUnitRealisations.heading.additionalInfo')}
              </th>
            </tr>
          </thead>
          <tbody>
            {isRealisations ? (
              <>
                {future.map((cur) => <CourseUnitRealisationRow key={cur.id} courseUnitRealisation={cur} courseUnitCode={courseUnit.code} />)}
                {showPast && past.map((cur) => (
                  <CourseUnitRealisationRow
                    key={cur.id}
                    courseUnitRealisation={cur}
                    courseUnitCode={courseUnit.code}
                  />
                ))}
              </>
            ) : <EmptyState colSpan={3}>{t('courseUnit.courseUnitRealisations.emptyState')}</EmptyState>}
          </tbody>
        </table>
      </div>

      {past.length ? (
        <button
          type="button"
          className={classNames('button--outline', styles.showPastButton)}
          onClick={() => setShowPast(!showPast)}
        >
          {showPast
            ? t('courseUnit.courseUnitRealisations.hidePast')
            : t('courseUnit.courseUnitRealisations.showPast')}
        </button>
      ) : null}

    </div>
  );
};

CourseUnitRealisationTable.propTypes = {
  courseUnit: courseUnitType
};

export default CourseUnitRealisationTable;
