import { multiSearchParams } from '../../api';

const {
  SEARCH_TEXT,
  DEGREE_PROGRAMME,
  PAGE_INDEX,
  TARGET_GROUP,
  TYPE
} = multiSearchParams;

export const searchTypeFilters = {
  ALL: 'all',
  ARTICLES: 'articles',
  NEWS: 'news',
  THEMES: 'themes',
  COURSES: 'courses'
};

export const searchTargetGroupFilters = {
  DEGREE_STUDENTS: 'degree_students',
  DOCTORAL_CANDIDATES: 'doctoral_candidates',
  SPECIALIST_TRAINING: 'specialist_training',
  OPEN_UNIVERSITY: 'open_university'
};

export const typesWithExtraFilters = [searchTypeFilters.ARTICLES, searchTypeFilters.NEWS];

export const CLEAR = { type: 'clear' };

const searchOptionsReducer = (state, action) => {
  switch (action.type) {
    case SEARCH_TEXT:
    case DEGREE_PROGRAMME:
    case TARGET_GROUP:
      return { ...state, [action.type]: action.value, [PAGE_INDEX]: 0 };
    case TYPE:
      return {
        ...state,
        [action.type]: action.value,
        // clear targetGroup and degreeProgram selection for types not filtered by them
        ...(!typesWithExtraFilters.includes(action.value) ? { [DEGREE_PROGRAMME]: undefined, [TARGET_GROUP]: undefined } : {}),
        [PAGE_INDEX]: 0
      };
    case PAGE_INDEX:
      return { ...state, [action.type]: action.value };
    case CLEAR.type:
      return { searchText: '', [PAGE_INDEX]: 0 };
    default:
      throw new Error(`Unknown state transfer: '${action.type}'`);
  }
};

export default searchOptionsReducer;
