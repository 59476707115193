import React from 'react';
import {
  Route,
  Redirect
} from 'react-router-dom';

import { routes } from '../../constants';
import useTranslation from '../../hooks/useTranslation';

const FrontpageURLSelector = () => {
  const { lang } = useTranslation();
  return (
    (lang
      && (
        <Route exact path="/">
          <Redirect to={routes.frontpage(lang)} />
        </Route>
      )) || null);
};

export default FrontpageURLSelector;
