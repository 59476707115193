import React from 'react';
import { Link } from 'react-router-dom';
import { string } from 'prop-types';
import { getTranslation, getTranslationWithLocale } from '../../../../../i18n';
import styles from './courseUnitRealisationRow.css';
import { formatCourseName, formatSemiOpenDateSpan } from '../../../../../utils';
import { courseUnitRealisationSmallType } from '../../../../../types';
import { curPathTranslations } from '../../../../../constants';
import useTranslation from '../../../../../hooks/useTranslation';

const jointCourseImage = require('../../../../../images/joint_course.png');

const getCourseUnitRealisationUrlPath = (id, code, lang) =>
  `/${curPathTranslations[lang]}/${id}/${code}`;

const CourseUnitRealisationRow = ({
  courseUnitRealisation, courseUnitCode
}) => {
  const { lang } = useTranslation();

  const {
    id,
    courseUnitRealisationType: { name: typeName },
    activityPeriod: { startDate, endDate },
    isOpenUniversityCourse,
    name,
    nameSpecifier
  } = courseUnitRealisation;
  const url = getCourseUnitRealisationUrlPath(id, courseUnitCode, lang);

  const jointCourseImageAlt = getTranslationWithLocale('courseUnit.courseUnitRealisations.jointCourseImageAlt', lang);

  return (
    <tr className={styles.courseUnitRealisation}>
      <td>
        <Link to={url}>
          {formatCourseName(id, name, nameSpecifier, lang)}
        </Link>
      </td>
      <td>
        {getTranslation(typeName, lang)}
      </td>
      <td className="course-unit-realisation-row-activity-period">
        {formatSemiOpenDateSpan(startDate, endDate, lang)}
      </td>
      <td>
        {isOpenUniversityCourse && <img className={styles.jointCourseImg} src={jointCourseImage} alt={jointCourseImageAlt} />}
      </td>
    </tr>
  );
};

CourseUnitRealisationRow.propTypes = {
  courseUnitRealisation: courseUnitRealisationSmallType.isRequired,
  courseUnitCode: string
};

export default CourseUnitRealisationRow;
