import { getTranslationWithLocale } from '../i18n';
import { asArray, DEFAULT_STUDY_YEAR } from '../utils';

export const eventCategories = {
  coursePage: {
    INSTRUCTIONS: 'coursePageInstructions',
    LINK: 'coursePageLink'
  },
  courses: {
    LINKS: 'coursesLinks',
    SEARCH_FILTER: 'coursesSearchFilter',
    SEARCH_PAGINATION: 'coursesSearchResultPagination',
    SEARCH_RESULT: 'coursesSearchResult',
    SEARCH_TYPE: 'coursesSearchType',
    SEARCH_WORD: 'coursesSearchWord'
  },
  dpSearch: {
    SEARCH_FILTER: 'KOOTSearchFilter',
    SEARCH_PAGINATION: 'KOOTSearchResultPagination',
    SEARCH_RESULT: 'KOOTSearchResult',
    SEARCH_STUDY_LEVEL: 'KOOTSearchResultStudyLevel',
    SELECT_PROGRAM: 'KOOTSelectProgram'
  },
  multiSearch: {
    SEARCH_FILTER: 'multiSearchFilter',
    SEARCH_PAGINATION: 'multiSearchResultPagination',
    SEARCH_RESULT: 'multiSearchResult',
    SEARCH_WORD: 'multiSearchWord',
    SEARCH_PROGRAM: 'multiSearchSelectProgram',
    SEARCH_TARGET: 'multiSearchSelectTarget',
    SEARCH_TYPE: 'multiSearchType'
  },
  frontpage: {
    BULLETIN_LIFTUP: 'frontPageBulletinLiftup',
    COMMUNITY_NEWS: 'frontPageCommunityNews',
    CURRENT_TECHNICAL: 'frontpageCurrentTechnical',
    CURRENT_TOPICS_DEGREE_PROGRAMME: 'frontpageCurrentTopicsInYourDegreeProgramme',
    CURRENT_TOPICS: 'frontpageCurrentTopics',
    EVENTS: 'frontpageEvents',
    LOGIN: 'frontpageLogin',
    UNICAFE: 'frontpageUnicafe'
  },
  myStudies: {
    CALENDAR: 'myStudiesCalendar',
    COURSES_EXAMS: 'myStudiesMyCoursesAndExams',
    TODO: 'myStudiesTodoItems'
  }
};

export const eventActions = {
  ADD: 'add',
  ADD_TO_CALENDAR: 'addToCalendar',
  CLICK: 'click',
  COURSES: 'courses',
  DAY: 'day',
  LIST: 'list',
  LOGIN: 'login',
  MARK_DONE: 'markAsDone',
  MONTH: 'month',
  MOODLE: 'moodle',
  NEW_COURSE_PAGE: 'newCoursePage',
  OPEN_UNI_COURSE_PAGE: 'openUniversityCoursePage',
  OTHER_DEGREE_PROGRAMME: 'otherProgram',
  OWN_DEGREE_PROGRAMME: 'ownProgram',
  REMOVE: 'remove',
  SEARCH: 'search',
  SEARCH_WORD: 'searchWord',
  SEND: 'send',
  SHOW_ALL: 'showAll',
  TARGET_GROUP: 'targetGroup',
  UPDATE: 'update',
  WEEK: 'week',
  DEGREE_PROGRAMME: 'degreeProgram'
};

const loadMatomoTagManagerScript = () => {
  /* eslint-disable */
  var _mtm = window._mtm = window._mtm || [];
  _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
  var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
  g.async=true; g.src='https://matomo.it.helsinki.fi/matomo/js/container_NMx6LTvF.js'; s.parentNode.insertBefore(g,s);
  /* eslint-enable */
};
/* eslint-disable no-underscore-dangle */

export const initializeTracking = (matomoTracking) => {
  if (matomoTracking !== 'true') {
    return;
  }

  if (!window._mtm) {
    loadMatomoTagManagerScript();
  }
};

export const trackEvent = (category, action, label) => {
  if (window._mtm) {
    window._mtm.push({
      event: action, category, label
    });
  }
};

export const trackLogin = () => trackEvent(eventCategories.frontpage.LOGIN, eventActions.LOGIN);

export const trackAddRestaurant = () => trackEvent(eventCategories.frontpage.UNICAFE, eventActions.ADD);

export const trackRemoveRestaurant = () => trackEvent(eventCategories.frontpage.UNICAFE, eventActions.REMOVE);

const defaultCoursesSearchFilters = [
  'customCodeUrns',
  'teachingLanguageUrn',
  'type'
];

const additionalCoursesSearchFilters = [
  'organisation',
  'period',
  'studyLevel'
];

const getLabelsForTranslation = (prefix, value) =>
  (Array.isArray(value) ? value : [value]).map((v) => getTranslationWithLocale(`${prefix}.${v}`, 'en'));

const getLabelsForOptions = (options, value) =>
  (asArray(value)).map((v) => {
    const option = options.find((o) => v && String(o.value) === String(v));
    return option && (option.name?.en || option.label);
  }).filter(Boolean);

const eventDataBySearchFilter = {
  studyYear: {
    action: 'academicYear',
    label: (value, mappings) => {
      const { studyYearOptions } = mappings;
      return getLabelsForOptions(studyYearOptions, value);
    }
  },
  customCodeUrns: {
    action: 'targetGroup',
    label: (value) => getLabelsForTranslation('filters', value)
  },
  teachingLanguageUrn: {
    action: 'teachingLanguage',
    label: (value, mappings) => {
      const { teachingLanguageOptions } = mappings;
      return getLabelsForOptions(teachingLanguageOptions, value);
    }
  },
  type: {
    action: 'formOfStudies',
    label: (value) => getLabelsForTranslation('filters', value)
  },
  organisation: {
    action: 'responsibleUnit',
    label: (value, mappings) => {
      const { organisationOptions } = mappings;
      return getLabelsForOptions(organisationOptions, value);
    }
  },
  period: {
    action: 'time',
    label: (value, mappings) => {
      const { periodOptions } = mappings;
      return getLabelsForOptions(periodOptions, value);
    }
  },
  studyLevel: {
    action: 'studyModuleLevel',
    label: (value, mappings) => {
      const { studyLevelOptions } = mappings;
      return getLabelsForOptions(studyLevelOptions, value);
    }
  },
  intensivePeriod: {
    action: 'intensivePeriod',
    label: (value, mappings) => {
      const { intensivePeriodOptions } = mappings;
      return getLabelsForOptions(intensivePeriodOptions, value);
    }
  }
};

const getSearchTypeLabel = (searchOptions, mappings) => {
  const { studyYearOptions } = mappings;

  const hasSearchTextSet = !!searchOptions.searchText;
  const hasCurrentStudyYearSet = studyYearOptions
    .find((sy) => sy.value === DEFAULT_STUDY_YEAR);
  const hasDefaultFilterSet = Object.keys(searchOptions)
    .some((option) => defaultCoursesSearchFilters.includes(option));
  const hasAdditionalFilterSet = Object.keys(searchOptions)
    .some((option) => additionalCoursesSearchFilters.includes(option)) || !hasCurrentStudyYearSet;

  if (!hasSearchTextSet && !hasDefaultFilterSet && !hasAdditionalFilterSet) {
    return 'without keyword or filters or additional filters';
  } if (!hasSearchTextSet && hasDefaultFilterSet && !hasAdditionalFilterSet) {
    return 'without keyword but with filters';
  } if (hasSearchTextSet && !hasDefaultFilterSet && !hasAdditionalFilterSet) {
    return 'with keyword but without filters';
  } if (hasSearchTextSet && hasDefaultFilterSet && !hasAdditionalFilterSet) {
    return 'with keyword and filters';
  } if (hasSearchTextSet && hasDefaultFilterSet && hasAdditionalFilterSet) {
    return 'with keyword and filters and additional filters';
  } if (!hasSearchTextSet && !hasDefaultFilterSet && hasAdditionalFilterSet) {
    return 'without keyword and filters but with additional filters';
  } if (hasSearchTextSet && !hasDefaultFilterSet && hasAdditionalFilterSet) {
    return 'with keyword and additional filters';
  }
  // !hasSearchTextSet && !hasDefaultFilterSet && hasAdditionalFilterSet
  return 'without keyword but with additional filters';
};

export const trackCoursesSearch = (searchOptions, valueMappings) => {
  if (searchOptions.searchText) {
    trackEvent(eventCategories.courses.SEARCH_WORD, eventActions.SEARCH_WORD, searchOptions.searchText);
  }
  trackEvent(eventCategories.courses.SEARCH_TYPE, eventActions.SEARCH, getSearchTypeLabel(searchOptions, valueMappings));

  Object.entries(searchOptions).forEach(([option, value]) => {
    if (!Object.keys(eventDataBySearchFilter).includes(option) || !value) {
      return;
    }
    const event = eventDataBySearchFilter[option];
    event.label(value, valueMappings).map((label) => trackEvent(eventCategories.courses.SEARCH_FILTER, event.action, label));
  });
};

const eventDataByDegreeProgramSearchType = {
  period: {
    action: 'time',
    label: (value, mapping) => {
      const { periodOptions } = mapping;
      return getLabelsForOptions(periodOptions, value);
    }
  },
  teachingLanguageUrn: {
    action: 'teachingLanguage',
    label: (value, mapping) => {
      const { languageOptions } = mapping;
      return getLabelsForOptions(languageOptions, value);
    }
  },
  type: {
    action: 'formOfStudies',
    label: (value, mapping) => {
      const { typeOptions } = mapping;
      return getLabelsForOptions(typeOptions, value);
    }
  }
};

export const trackDegreeProgramSearch = (type, value, degreeProgram, valueMappings) => {
  if (type === 'degreeProgramme' && value) {
    const selectedDegreeProgram = valueMappings.degreeProgrammeOptions.find((dp) => dp.value === value);
    const isOwnProgram = degreeProgram && degreeProgram.groupId && selectedDegreeProgram && selectedDegreeProgram.groupId === degreeProgram.groupId;
    trackEvent(eventCategories.dpSearch.SELECT_PROGRAM,
      isOwnProgram ? eventActions.OWN_DEGREE_PROGRAMME : eventActions.OTHER_DEGREE_PROGRAMME,
      selectedDegreeProgram.name.en);
  } else if (type === 'studyLevel') {
    const studyLevel = getLabelsForOptions(valueMappings.studyLevelOptions, value);
    trackEvent(eventCategories.dpSearch.SEARCH_STUDY_LEVEL, eventActions.CLICK, studyLevel.length ? studyLevel[0] : 'All');
  } else if (type && value && Object.keys(eventDataByDegreeProgramSearchType).includes(type)) {
    const event = eventDataByDegreeProgramSearchType[type];
    event.label(value, valueMappings).map((label) => trackEvent(eventCategories.dpSearch.SEARCH_FILTER, event.action, label));
  }
};

export const trackMultiSearch = (searchOptions) => {
  const {
    SEARCH_TYPE, SEARCH_WORD, SEARCH_PROGRAM, SEARCH_TARGET
  } = eventCategories.multiSearch;
  trackEvent(SEARCH_TYPE, eventActions.SEARCH, searchOptions.type);
  if (searchOptions.searchText) {
    trackEvent(SEARCH_WORD, eventActions.SEARCH_WORD, searchOptions.searchText);
  }
  if (searchOptions.degreeProgram) {
    trackEvent(SEARCH_PROGRAM, eventActions.DEGREE_PROGRAMME, searchOptions.degreeProgram);
  }
  if (searchOptions.targetGroup) {
    trackEvent(SEARCH_TARGET, eventActions.TARGET_GROUP, searchOptions.targetGroup);
  }
};
