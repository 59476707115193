import React,
{
  useState, useEffect, useRef
} from 'react';
import { func, number, bool } from 'prop-types';
import { format } from 'date-fns';
import { flatMap, isEmpty } from 'lodash';

import UnicafeMenu from './UnicafeMenu';
import { restaurantsType } from '../../types';
import { getUnicafeRestaurantMenu, getSodexoRestaurantMenu } from '../../api';

import styles from './restaurant.css';
import { SODEXO_RESTAURANTS } from '../../constants';
import SodexoMenu from './SodexoMenu';
import useHideOnClickOutside from '../../hooks/useHideOnClickOutside';
import useTranslation from '../../hooks/useTranslation';

const sodexoIds = SODEXO_RESTAURANTS.Sodexo.map((x) => x.id);

const Restaurant = ({
  restaurants = {}, restaurantId, index,
  updateRestaurantSelection, onRemoveRestaurant,
  showRemove
}) => {
  const { t, lang } = useTranslation();
  const [selectedRestaurant, setSelectedRestaurant] = useState(restaurantId);

  const [unicafeMenu, setUnicafeMenu] = useState([]);
  const [sodexoMenu, setSodexoMenu] = useState({});

  const { ref, isComponentVisible, setIsComponentVisible } = useHideOnClickOutside(false);

  const buttonRef = useRef();
  const restaurantElementIds = useRef([]);

  useEffect(() => {
    restaurantElementIds.current = Object.entries(restaurants)
      .map(([group, restaurantArray]) => (restaurantArray.map((item) => (`${group}-${item.id}`))))
      .flat();
  });

  const getRestaurantName = (id) => {
    const restaurantData = flatMap(restaurants).find(((restaurant) => restaurant.id === id));
    return (lang === 'en' && restaurantData?.name_en) || restaurantData?.name || 'Not found';
  };
  const [restaurantName, setRestaurantName] = useState(restaurantId
    ? getRestaurantName(restaurantId)
    : t('lunchMenus.selectRestaurant')
  );

  const fetchMenu = async (id) => {
    setSodexoMenu({});
    setUnicafeMenu([]);
    if (sodexoIds.includes(id)) {
      const today = format(new Date(), 'yyyy-MM-dd');
      const response = await getSodexoRestaurantMenu(id, today);
      if (response?.courses) {
        setSodexoMenu(response.courses);
      }
    } else {
      try {
        const response = await getUnicafeRestaurantMenu(id);
        const today = format(new Date(), 'iii dd.MM');
        if (response?.data) {
          const menuData = response.data.find((dailyMenu) => dailyMenu.date_en === today);
          if (menuData?.data) {
            setUnicafeMenu(menuData.data);
          }
        }
      } catch (e) {
        // error while fetching restaurant
      }
    }
  };

  useEffect(() => {
    if (selectedRestaurant) {
      fetchMenu(selectedRestaurant);
    }
  }, [selectedRestaurant]);

  const closeDropdownAndReturnFocus = () => {
    setIsComponentVisible(false);
    buttonRef.current.focus();
  };

  const onRestaurantChange = (id) => {
    setSelectedRestaurant(id);
    closeDropdownAndReturnFocus();
    setRestaurantName(getRestaurantName(id));
    fetchMenu(id);
    updateRestaurantSelection(index, id);
  };

  const getSelectedOrDefaultOption = () => {
    let restaurantData = null;
    Object.entries(restaurants).forEach(([group, groupRestaurants]) => {
      const result = groupRestaurants.find((restaurant) => restaurant.id === selectedRestaurant);
      if (!isEmpty(result)) {
        restaurantData = { ...result, group };
      }
    });

    if (isEmpty(restaurantData)) {
      return ref.current.focus();
    }

    const selectedRestaurantElementId = `${restaurantData.group}-${restaurantData.id}`;
    return document.getElementById(selectedRestaurantElementId);
  };

  const openDropdownAndFocusSelectedOrDefaultRestaurant = () => {
    setIsComponentVisible(true);
    setTimeout(() => {
      getSelectedOrDefaultOption()?.focus();
    }, 0);
  };

  const handleKeyDown = (e) => {
    if (['ArrowUp', 'ArrowDown'].includes(e.key) && !isComponentVisible) {
      e.preventDefault();
      openDropdownAndFocusSelectedOrDefaultRestaurant();
    } else if (isComponentVisible) {
      const activeElementId = document.activeElement.getAttribute('id');
      const currentFocusIndex = restaurantElementIds.current.findIndex((id) => id === activeElementId);
      let focusIndex = null;
      switch (e.key) {
        case 'End':
          focusIndex = restaurantElementIds.current.length - 1;
          break;
        case 'Home':
          focusIndex = 0;
          break;
        case 'ArrowDown':
        case 'ArrowRight':
          focusIndex = (currentFocusIndex + 1) % restaurantElementIds.current.length;
          break;
        case 'ArrowUp':
        case 'ArrowLeft':
          focusIndex = currentFocusIndex > 0
            ? currentFocusIndex - 1
            : restaurantElementIds.current.length - 1;
          break;
        case 'Escape':
        case 'Tab':
          closeDropdownAndReturnFocus();
          break;
        default: break;
      }

      if (focusIndex !== null) {
        e.preventDefault();
        document.getElementById(restaurantElementIds.current[focusIndex]).focus();
      }
    }
  };

  const handleToggleButtonClick = () => {
    if (isComponentVisible) {
      setIsComponentVisible(false);
    } else {
      openDropdownAndFocusSelectedOrDefaultRestaurant();
    }
  };

  return (
    <div className={styles.container}>
      { showRemove && (
        <button
          className={styles.closeButton}
          type="button"
          onClick={() => onRemoveRestaurant(index)}
          aria-label={t('lunchMenus.removeRestaurant')}
        >
          <span className="icon icon--remove" />
        </button>
      )}
      <label htmlFor={`select-restaurant-${index}`} className={styles.selectRestaurantLabel} ref={ref}>
        {t('lunchMenus.restaurant')}
      </label>
      <button
        id={`select-restaurant-${index}`}
        ref={buttonRef}
        type="button"
        onClick={handleToggleButtonClick}
        className={`${styles.dropdownButton} ${isComponentVisible ? 'icon--caret-up' : 'icon--caret-down'}`}
        aria-haspopup="menu"
        aria-expanded={isComponentVisible}
        aria-controls={`restaurant-list-${index}`}
        aria-owns={`restaurant-list-index-${index}`}
        onKeyDown={handleKeyDown}
      >
        {restaurantName}
      </button>
      {isComponentVisible && (
        <div id={`restaurant-list-${index}`} className={styles.restaurantList} role="menu">
          {Object.entries(restaurants).map(([restaurantGroup, restaurantArray]) => (
            <div key={restaurantGroup} className={styles.restaurantGroup} role="group" aria-labelledby={`${restaurantGroup}-group-${index}`}>
              <span id={`${restaurantGroup}-group-${index}`} className={styles.restaurantGroupHeader}>{restaurantGroup}</span>
              {restaurantArray.map((restaurant) => (
                <button
                  id={`${restaurantGroup}-${restaurant.id}`}
                  key={`${restaurantGroup}-${restaurant.id}`}
                  type="button"
                  role="menuitemradio"
                  tabIndex={-1}
                  onClick={() => onRestaurantChange(restaurant.id)}
                  onKeyDown={handleKeyDown}
                  className={styles.restaurantButton}
                  aria-checked={selectedRestaurant === restaurant.id}
                >
                  {selectedRestaurant === restaurant.id && <span className={`${styles.selectionMarker} icon--done`} />}
                  <span className={styles.restaurantName}>{restaurant[`name_${lang}`] || restaurant.name}</span>
                </button>
              ))}
            </div>
          ))}
        </div>
      )}
      <UnicafeMenu menu={unicafeMenu} />
      <SodexoMenu menu={sodexoMenu} />
    </div>
  );
};

Restaurant.defaultProps = {
  restaurantId: null
};

Restaurant.propTypes = {
  restaurants: restaurantsType,
  restaurantId: number,
  index: number.isRequired,
  updateRestaurantSelection: func.isRequired,
  onRemoveRestaurant: func.isRequired,
  showRemove: bool.isRequired
};

export default Restaurant;
