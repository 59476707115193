import React, { useEffect, useState } from 'react';

import { getCommonNews } from '../../api';
import NewsContainer from '../NewsContainer';
import { guideAllNewsLinkForLanguage, withPlaceholderImage } from '../../utils';
import { eventCategories } from '../../services/analytics';
import useTranslation from '../../hooks/useTranslation';

const CommonNews = () => {
  const { lang } = useTranslation();
  const [news, setNews] = useState();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const data = await getCommonNews(lang);
        setNews(data.map(withPlaceholderImage));
      } catch (e) {
        setNews(null);
      }
    };

    fetchNews();
  }, [lang]);

  return (
    <NewsContainer
      itemsToShow={9}
      header="commonNews.header"
      newsItems={news}
      showItemIngress={false}
      itemTypeLabel="commonNews.itemTypeLabel"
      showAllLink={guideAllNewsLinkForLanguage(lang)}
      showAllLabel="commonNews.showAllLabel"
      trackedEventCategory={eventCategories.frontpage.CURRENT_TOPICS}
    />
  );
};

export default CommonNews;
