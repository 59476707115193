import React, { useState, useEffect } from 'react';

import Restaurant from './Restaurant';
import { getUnicafeRestaurants } from '../../api';
import useIsLoggedIn from '../../hooks/useIsLoggedIn';
import useUserPreferences from '../../hooks/useUserPreferences';
import { SODEXO_RESTAURANTS, userPreferenceKeyValues } from '../../constants';

import { trackAddRestaurant, trackRemoveRestaurant } from '../../services/analytics';
import styles from './lunchmenus.css';
import ErrorBoundary from '../ErrorBoundary';
import useTranslation from '../../hooks/useTranslation';

const DEFAULT_RESTAURANT = 9;
const EXCLUDED_AREA_CODES = [999];

const isAvailableRestaurant = (restaurant) => !EXCLUDED_AREA_CODES.includes(restaurant.areacode);

const LunchMenus = () => {
  const { t } = useTranslation();
  const isLoggedIn = useIsLoggedIn();
  const { getUserPreference, setUserPreference } = useUserPreferences();

  const [restaurants, setRestaurants] = useState({});
  const [userRestaurants, setUserRestaurants] = useState([DEFAULT_RESTAURANT]);

  const restaurantsFromPreference = getUserPreference(userPreferenceKeyValues.UNICAFE_RESTAURANTS);

  const saveUserRestaurantSelection = (selection) => {
    if (isLoggedIn) {
      const filteredSelection = selection.filter((selected) => selected !== null);
      setUserPreference(userPreferenceKeyValues.UNICAFE_RESTAURANTS, filteredSelection);
    }
  };

  const saveSelectedRestaurants = (selectedRestaurants) => {
    setUserRestaurants(selectedRestaurants);
    saveUserRestaurantSelection(selectedRestaurants);
  };

  const updateRestaurantSelection = (index, value) => {
    userRestaurants[index] = value;
    saveUserRestaurantSelection(userRestaurants);
  };

  const addRestaurant = () => {
    saveSelectedRestaurants([...userRestaurants, null]);
    trackAddRestaurant();
  };

  const removeRestaurant = (index) => {
    userRestaurants.splice(index, 1);
    saveSelectedRestaurants([...userRestaurants]);
    trackRemoveRestaurant();
  };

  const getKey = (index, id) => `${index}-${id}`;

  useEffect(() => {
    (async () => {
      if (Object.keys(restaurants).length === 0) {
        const response = await getUnicafeRestaurants();
        if (response && response.data) {
          setRestaurants({
            Unicafe: response.data.filter(isAvailableRestaurant),
            ...SODEXO_RESTAURANTS
          });
        }
      }
    })();

    if (isLoggedIn && restaurantsFromPreference) {
      setUserRestaurants(restaurantsFromPreference);
    }
  }, [restaurants, isLoggedIn, restaurantsFromPreference]);

  if (!Object.keys(restaurants).length) {
    return null;
  }

  return (
    <section className={styles.container}>
      <h2 className={styles.header}>{t('lunchMenus.title')}</h2>
      <div className={['grid-row', styles.restaurants].join(' ')}>
        {userRestaurants.map((id, index) => (
          <div key={getKey(index, id)} className="grid-column desktop-size-12 tablet-size-4 mobile-size-4">
            <Restaurant
              index={index}
              restaurants={restaurants}
              restaurantId={id}
              updateRestaurantSelection={updateRestaurantSelection}
              onRemoveRestaurant={removeRestaurant}
              showRemove={isLoggedIn}
            />
          </div>
        ))}
      </div>
      {isLoggedIn && (
        <div className={styles.actions}>
          <button type="button" className="button--outline" onClick={addRestaurant}>
            <span aria-hidden="true" className="icon--plus" />
            {' '}
            {t('lunchMenus.addRestaurant')}
          </button>
        </div>
      )}
    </section>
  );
};

export default () => (
  <ErrorBoundary headerLevel={2}>
    <LunchMenus />
  </ErrorBoundary>
);
