import React from 'react';
import { arrayOf, node } from 'prop-types';
import { uniq } from 'lodash';
import { responsibilityInfoType } from '../../../types';
import { RESPONSIBLE_TEACHER_ROLE_URNS } from '../../../constants';
import { formatSemiOpenDateSpan } from '../../../utils';
import { getTranslation } from '../../../i18n';
import useTranslation from '../../../hooks/useTranslation';

const formatResponsibilityInfos = (responsibilityInfos, nameListClassName, lang) => {
  const names = responsibilityInfos
    .filter((info) => RESPONSIBLE_TEACHER_ROLE_URNS.includes(info.roleUrn) && (info.person || info.text))
    .map((info) => {
      const { person, text, validityPeriod: { startDate, endDate } } = info;
      let validityPeriod = '';
      if (startDate || endDate) {
        validityPeriod = ` (${formatSemiOpenDateSpan(startDate, endDate, lang)})`;
      }

      if (person) {
        const { firstName, lastName } = person;
        return `${firstName} ${lastName}${validityPeriod}`.trim();
      }
      if (text) {
        return `${getTranslation(text, lang)}${validityPeriod}`;
      }

      return null;
    })
    .filter(Boolean);

  const namesString = uniq(names).join(', ');

  return namesString ? <span className={nameListClassName || ''}>{namesString}</span> : null;
};

const ResponsibilityInfos = ({
  responsibilityInfos, description, nameListClassName, descriptionClassName
}) => {
  const { lang } = useTranslation();

  if (!responsibilityInfos) {
    return null;
  }

  return (
    <p>
      {description && (
        <span className={descriptionClassName}>
          {description}
          {' '}
        </span>
      )}
      {formatResponsibilityInfos(responsibilityInfos, nameListClassName, lang)}
    </p>
  );
};

ResponsibilityInfos.propTypes = {
  responsibilityInfos: arrayOf(responsibilityInfoType),
  description: node,
  descriptionClassName: node,
  nameListClassName: node
};

export default ResponsibilityInfos;
