const SUBDOMAIN = window.location.hostname.split('.')[0];

const QA_SUBDOMAIN = 'studies-qa';
const PROD_SUBDOMAIN = 'studies';

const subdomainToSentryEnv = (subdomain) => {
  switch (subdomain) {
    case QA_SUBDOMAIN:
      return 'QA';
    case PROD_SUBDOMAIN:
      return 'PRODUCTION';
    default:
      return subdomain.toUpperCase();
  }
};

export const SENTRY_ENV = subdomainToSentryEnv(SUBDOMAIN);

const IS_QA_OR_PROD = [
  QA_SUBDOMAIN,
  PROD_SUBDOMAIN
].includes(SUBDOMAIN);

export const IS_PROD = SUBDOMAIN === PROD_SUBDOMAIN;

export const IS_QA = SUBDOMAIN === QA_SUBDOMAIN;

export const SENTRY_ENABLED = IS_QA_OR_PROD;

export const SITEIMPROVE_ENABLED = IS_PROD;

export const READ_ONLY_DB = process.env.READ_ONLY_DB != null && process.env.JEST_WORKER_ID === undefined
  ? process.env.READ_ONLY_DB === 'true' || process.env.READ_ONLY_DB === true
  : false;
