import { asRestrictedHtml } from '../../utils';
import { AVAILABLE_LANGUAGES, cuPathTranslations } from '../../constants';

const LOCALES = {
  fi: 'fi',
  sv: 'sv-FI',
  en: 'en-GB'
};

export const getLocale = (language) => LOCALES[language] || LOCALES[AVAILABLE_LANGUAGES[0]];

const sanitizeMarkup = (markup) => markup.replace(/(<br\s*\/>)+/g, '<br />').replace(/(\s)+/g, ' ');

export const renderHtml = (markup) => ({ __html: asRestrictedHtml(sanitizeMarkup(markup)) });

export const getCourseUnitUrlPath = (courseUnit, lang) =>
  `/${cuPathTranslations[lang]}/${courseUnit.id}/${courseUnit.code}`;

export const getOrganisationId = (curOrganisation) => {
  if (curOrganisation?.organisation) return curOrganisation.organisation.id;
  return curOrganisation?.educationalInstitution?.urn;
};

export const getOrganisationName = (curOrganisation) => {
  if (curOrganisation?.organisation) return curOrganisation.organisation.name;
  return curOrganisation?.educationalInstitution?.name;
};
