// Modified from: https://docs.sentry.io/platforms/javascript/configuration/micro-frontend-support/
import * as Sentry from '@sentry/browser';

import { SENTRY_DSN, SENTRY_TRACKED_HEADERS } from './constants';
import { SENTRY_ENABLED, SENTRY_ENV } from './env';

const EXTRA_KEY = 'ROUTE_TO';

// reroutes the event to another Sentry project if its from a module
const transport = Sentry.makeMultiplexedTransport(Sentry.makeFetchTransport, (args) => {
  const event = args.getEvent();

  if (event
    && event.extra
    && EXTRA_KEY in event.extra
    && Array.isArray(event.extra[EXTRA_KEY])
  ) {
    return event.extra[EXTRA_KEY];
  }
  return []; // use default DSN
});

if (SENTRY_ENABLED) {
  Sentry.init({
    dsn: SENTRY_DSN, // default DSN (studies-frontend)
    environment: SENTRY_ENV,
    release: `studies-frontend@${process.env.SENTRY_RELEASE}`,
    integrations: [new Sentry.ModuleMetadata()],
    transport,
    beforeSend(event) {
      delete event.user;
      delete event.request.cookies;
      Object.keys(event.request.headers).forEach((key) => {
        if (!SENTRY_TRACKED_HEADERS.includes(key)) {
          delete event.request.headers[key];
        }
      });

      // OPISKELU-2175: when the error is from a module (eg. obar) add the module's dsn
      // into the event so its possible to reroute the error
      if (event?.exception?.values?.[0]?.stacktrace?.frames) {
        const { frames } = event.exception.values[0].stacktrace;
        // Find the last frame with module metadata containing a DSN
        const routeTo = frames
          .filter((frame) => frame.module_metadata && frame.module_metadata.dsn)
          .map((v) => v.module_metadata)
          .slice(-1);

        if (routeTo.length) {
          event.extra = {
            ...event.extra,
            [EXTRA_KEY]: routeTo
          };
        }
      }

      return event;
    },
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /chrome:\/\//i,
      /chrome-extension:\/\//i,
      // firefox extensions
      /moz-extension:\/\//i
    ],
    ignoreErrors: [
      // safari webkit
      /.*@webkit-masked-url.*/
    ]
  });
}
