import React from 'react';
import RootContext from '../../context/RootContext';

const loadScript = (baseUrl) => {
  const scriptSources = Array.from(document.querySelectorAll('script')).map((script) => script.src);
  const obarScriptSrc = `${baseUrl}/obar.js`;

  if (!scriptSources.includes(obarScriptSrc)) {
    const scriptElement = document.createElement('script');

    scriptElement.async = false;
    scriptElement.src = obarScriptSrc;
    document.body.appendChild(scriptElement);
  }
};

const loadStylesheet = (baseUrl) => {
  const linkHrefs = Array.from(document.querySelectorAll('link')).map((link) => link.href);
  const obarLinkHref = `${baseUrl}/obar.css`;

  if (!linkHrefs.includes(obarLinkHref)) {
    const linkElement = document.createElement('link');

    linkElement.rel = 'stylesheet';
    linkElement.href = obarLinkHref;
    linkElement.crossOrigin = 'anonymous';
    document.head.appendChild(linkElement);
  }
};

const Header = () => (
  <RootContext.Consumer>
    {({ obarConfig }) => {
      if (!obarConfig) {
        return null;
      }

      loadScript(obarConfig.baseUrl);
      loadStylesheet(obarConfig.baseUrl);
      return (
        <header
          id="obar-header"
          className="obar"
          data-announcement-sources="studies"
          data-app-name={obarConfig.appName}
          data-content-id={obarConfig.contentId}
          data-jwt-token={obarConfig.jwtToken}
          data-base-url={obarConfig.baseUrl}
        />
      );
    }}
  </RootContext.Consumer>
);

export default Header;
