import React, { useState, useEffect, useRef } from 'react';
import { string, func } from 'prop-types';

import styles from './todoItemInput.css';
import useTranslation from '../../../../../hooks/useTranslation';

const CONTENT_MAX_LENGTH = 500;

const TodoItemInput = ({
  content,
  onSetContent,
  onCancel
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(content);
  const [contentTooLong, setContentTooLong] = useState(inputValue.length > CONTENT_MAX_LENGTH);
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const onInputChange = (e) => {
    setInputValue(e.target.value);
    setContentTooLong(e.target.value.length > CONTENT_MAX_LENGTH);
  };

  const onBlur = () => {
    if (inputValue.length > 0 && !contentTooLong) {
      onSetContent(inputValue);
    } else {
      onCancel();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.length > 0 && !contentTooLong) {
      onSetContent(inputValue, true);
    } else if (e.key === 'Escape') {
      onCancel();
    }
  };

  return (
    <span>
      <input
        className={`${styles.contentInput} contentInput`}
        ref={inputRef}
        value={inputValue}
        onChange={onInputChange}
        onBlur={onBlur}
        onKeyDown={handleKeyDown}
        aria-label={t('todo.contentInput')}
      />
      {contentTooLong && (
        <div className={`${styles.contentTooLong} contentTooLong`} role="alert">
          {t('todo.contentTooLong')}
        </div>
      )}
    </span>
  );
};

TodoItemInput.propTypes = {
  content: string.isRequired,
  onSetContent: func.isRequired,
  onCancel: func.isRequired
};

export default TodoItemInput;
