import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { courseUnitRealisationType } from '../../../../../types';
import { postCourseParticipantListRequest, getLatestCourseParticipantRequest } from '../../../../../api';
import { formatDate } from '../../../../../utils';
import { requestStates } from '../../../../../constants';
import Section from '../../../../Section';
import styles from './requestParticipantLIst.css';
import { READ_ONLY_DB } from '../../../../../env';
import useTranslation from '../../../../../hooks/useTranslation';

const RequestParticipantList = ({ courseUnitRealisation = {} }) => {
  const { t, lang } = useTranslation();
  const [lastRequested, setLastRequested] = useState(null);
  const [initialized, setInitialized] = useState(false);
  let [buttonDisabled, setButtonDisabled] = useState(false);

  if (READ_ONLY_DB) {
    buttonDisabled = true;
    setButtonDisabled = () => {};
  }

  const [requestState, setRequestState] = useState(requestStates.READY);
  const [showError, setShowError] = useState(false);

  const { id } = courseUnitRealisation;
  const showRequestParticipantList = true;

  useEffect(() => {
    if (!showRequestParticipantList) {
      return;
    }
    (async () => {
      if (!initialized) {
        try {
          const response = await getLatestCourseParticipantRequest(id);
          setLastRequested(response?.requestTime);
        } finally {
          setInitialized(true);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (requestState) {
      case requestStates.READY:
        setButtonDisabled(false);
        break;
      case requestStates.REQUEST_SENT:
        setShowError(false);
        setButtonDisabled(true);
        break;
      case requestStates.SUCCESS:
        setShowError(false);
        break;
      case requestStates.ERROR:
        setShowError(true);
        setTimeout(() => {
          setRequestState(requestStates.READY);
        }, 1000);
        break;
      default:
        break;
    }
  }, [requestState, setButtonDisabled]);

  if (!initialized || !showRequestParticipantList) {
    return null;
  }

  const requestParticipantList = async () => {
    try {
      setRequestState(requestStates.REQUEST_SENT);
      const response = await postCourseParticipantListRequest(id, lang);
      setLastRequested(response?.requestTime);
      setRequestState(requestStates.SUCCESS);
    } catch {
      setRequestState(requestStates.ERROR);
    }
  };

  const buttonClasses = classNames(
    'button--outline', 'icon--mail', 'button--action', { [styles.disabled]: buttonDisabled }
  );

  let buttonTranslationKey = 'requestParticipantList.button.firstRequest';
  let descriptionTranslationKey = 'requestParticipantList.description.firstRequest';
  let descriptionTranslationData;
  if (lastRequested) {
    buttonTranslationKey = 'requestParticipantList.button.hasRequested';
    descriptionTranslationKey = 'requestParticipantList.description.hasRequested';
    descriptionTranslationData = { date: formatDate(lastRequested, lang) };
  }
  if (requestState === requestStates.SUCCESS) {
    buttonTranslationKey = 'requestParticipantList.button.requestSent';
  }

  return (
    <Section className={styles.container}>
      <div className={styles.requestContainer}>
        <div>
          <span className={classNames('icon-2x', 'icon--log', styles.icon)} />
        </div>
        <div>
          <h2 className={styles.title}>{t('requestParticipantList.title')}</h2>
          <div className="request-description">
            {lastRequested && <span className={classNames('icon--square-checked', styles.requested)} />}
            {t(descriptionTranslationKey, descriptionTranslationData)}
            {' '}
            {t('requestParticipantList.description.delayReminder')}
          </div>
          {showError && <div className="request-error">{t('requestParticipantList.error')}</div>}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <button type="button" className={buttonClasses} onClick={requestParticipantList} disabled={buttonDisabled}>
          {t(buttonTranslationKey)}
        </button>
      </div>
    </Section>
  );
};

RequestParticipantList.propTypes = {
  courseUnitRealisation: courseUnitRealisationType
};

export default RequestParticipantList;
