import React from 'react';
import { string, arrayOf } from 'prop-types';
import { getTranslation } from '../../i18n';
import { localizedTextType } from '../../types';
import useTranslation from '../../hooks/useTranslation';

const TeachingLanguages = ({
  teachingLanguages, titleSeparator, languageListClassName
}) => {
  const { t, lang } = useTranslation();

  return (
    <span className="teaching-languages">
      {t(teachingLanguages.length > 1 ? 'teachingLanguages' : 'teachingLanguage')}
      <>{titleSeparator} </>
      <span className={languageListClassName}>
        {teachingLanguages.map((teachingLanguage) => getTranslation(teachingLanguage, lang)).join(', ')}
      </span>
    </span>
  );
};

TeachingLanguages.propTypes = {
  teachingLanguages: arrayOf(localizedTextType).isRequired,
  titleSeparator: string,
  languageListClassName: string
};

TeachingLanguages.defaultProps = {
  languageListClassName: ''
};

export default TeachingLanguages;
