import React from 'react';

import Section from '../../../Section';
import { COURSE_PAGE_API_PATH } from '../../../../constants';
import styles from './courseImage.css';
import { courseCmsDataType } from '../../../../types';

const CourseImage = ({ cmsData }) => {
  if (!cmsData?.imagePath) {
    return null;
  }

  const { imagePath, imageAltText, imageCopyright } = cmsData;
  return (
    <Section>
      <div className={styles.courseImageContainer}>
        <img
          className={styles.courseImage}
          alt={imageAltText}
          src={`${COURSE_PAGE_API_PATH}/cur/image${imagePath}`}
        />
      </div>
      {imageCopyright && <small className={styles.courseImageCopyright}>{imageCopyright}</small>}
    </Section>
  );
};

CourseImage.propTypes = {
  cmsData: courseCmsDataType
};

export default CourseImage;
