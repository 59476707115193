import { useContext, useEffect, useMemo } from 'react';
import PersistingUserSelectionsContext from '../context/PersistingUserSelectionsContext';
import useApiFunction from './useApiFunction';
import { getDegreeProgrammes } from '../api';
import { getStudyYearStartYear } from '../utils';

const usePersistingDegreeProgramme = () => {
  const {
    degreeProgramme: persistingDegreeProgramme,
    handleUpdateDegreeProgramme,
    isFullDegreeProgramme,
    isPartialDegreeProgramme
  } = useContext(PersistingUserSelectionsContext);
  const currentStudyYear = getStudyYearStartYear(new Date());
  const { data, loading } = useApiFunction(
    getDegreeProgrammes,
    `${currentStudyYear},${currentStudyYear + 1}`
  );

  const cleanedData = useMemo(() => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.map((dp) => ({
      code: dp.code,
      id: dp.id,
      groupId: dp.groupId
    }));
  }, [data]);

  // See https://jira.it.helsinki.fi/browse/OPISKELU-2155
  useEffect(() => {
    if (!loading
      && isPartialDegreeProgramme(persistingDegreeProgramme)
      && !isFullDegreeProgramme(persistingDegreeProgramme)) {
      handleUpdateDegreeProgramme(cleanedData.find((dp) => dp.code === persistingDegreeProgramme.code));
    }
  }, [
    loading,
    persistingDegreeProgramme,
    cleanedData,
    isPartialDegreeProgramme,
    isFullDegreeProgramme,
    handleUpdateDegreeProgramme
  ]);

  return {
    persistingDegreeProgramme,
    setPerstistingDegreeProgramme: (key, value) => {
      handleUpdateDegreeProgramme(
        cleanedData.find((dp) => dp[key] === value)
      );
    }
  };
};

export default usePersistingDegreeProgramme;
