import React, { useEffect, useState } from 'react';
import { shape, string, func } from 'prop-types';
import { multiSearchParams, multiSearchPath } from '../../api';
import SearchResults from './SearchResults';
import useDebounce from '../../hooks/useDebounce';
import { searchTypeFilters } from './searchOptionsReducer';
import ErrorBoundary from '../ErrorBoundary';
import useApiQuery from '../../hooks/useApiQuery';

const { TYPE } = multiSearchParams;

const SearchResultLoader = ({
  optionValues, formState, dispatch, onOptionsChange, lang
}) => {
  const debouncedOptions = useDebounce(optionValues, 100);

  const {
    searchText,
    degreeProgramCode,
    targetGroup,
    type,
    page
  } = debouncedOptions;

  const commonQuery = {
    lang,
    searchText,
    degreeProgramCode,
    targetGroup
  };
  const allQuery = useApiQuery(multiSearchPath, {
    ...commonQuery,
    type: searchTypeFilters.ALL,
    page: type === searchTypeFilters.ALL ? page : 0
  });
  const articlesQuery = useApiQuery(multiSearchPath, {
    ...commonQuery,
    type: [searchTypeFilters.ARTICLES, searchTypeFilters.THEMES],
    page: type === searchTypeFilters.ARTICLES ? page : 0
  });
  const newsQuery = useApiQuery(multiSearchPath, {
    ...commonQuery,
    type: searchTypeFilters.NEWS,
    page: type === searchTypeFilters.NEWS ? page : 0
  });
  const coursesQuery = useApiQuery(multiSearchPath, {
    ...commonQuery,
    type: searchTypeFilters.COURSES,
    page: type === searchTypeFilters.COURSES ? page : 0
  });
  const queries = [allQuery, articlesQuery, newsQuery, coursesQuery];
  const currentType = formState[TYPE];
  const activeQuery = queries.find((q) => {
    const tQuery = q.queryParams.type;
    return typeof tQuery === 'string' ? tQuery === currentType : tQuery.includes(currentType);
  });
  const queriesNotLoading = queries.every((q) => !q.loading);

  const [activeSearchText, setActiveSearchText] = useState();
  useEffect(() => {
    if (queriesNotLoading) {
      setActiveSearchText(activeQuery?.usedQuery?.searchText);
    }
  }, [
    activeQuery?.usedQuery?.searchText, queriesNotLoading
  ]);

  return (
    <ErrorBoundary headerLevel={2}>
      <SearchResults
        results={activeQuery.data}
        resultsLoading={activeQuery.loading}
        formState={formState}
        debouncedOptions={debouncedOptions}
        dispatch={dispatch}
        onOptionsChange={onOptionsChange}
        searchText={activeSearchText}
      />
    </ErrorBoundary>
  );
};

SearchResultLoader.propTypes = {
  optionValues: shape({}),
  formState: shape({}),
  dispatch: func,
  onOptionsChange: func,
  lang: string.isRequired
};

export default SearchResultLoader;
