import React from 'react';
import Section from '../../../Section';
import styles from './coursePromotion.css';
import { getTranslation } from '../../../../i18n';
import ShowMore from '../../../ShowMore';
import { courseUnitRealisationType } from '../../../../types';
import useTranslation from '../../../../hooks/useTranslation';

const CoursePromotion = ({ courseUnitRealisation = {} }) => {
  const { lang } = useTranslation;
  const { tweetText, cmsData } = courseUnitRealisation;

  const title = cmsData?.introductionTitle;
  const ingress = cmsData?.summary || getTranslation(tweetText, lang);
  const body = cmsData?.body || '';

  if (!ingress && !body) {
    return null;
  }

  return (
    <Section level={2} label={title} className={`${styles.coursePromotion} rich-text`}>
      {ingress && <div className={styles.ingress}>{ingress}</div>}
      <ShowMore className={styles.showMore} content={body} length={500} isHtml />
    </Section>
  );
};

CoursePromotion.propTypes = {
  courseUnitRealisation: courseUnitRealisationType
};

export default CoursePromotion;
