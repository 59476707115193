import React from 'react';
import {
  arrayOf, func, node, string
} from 'prop-types';
import { Navigate } from 'react-big-calendar';

import TabGroup from '../../../TabGroup';
import SubscribeToCalendar from './SubscribeToCalendar';
import styles from './toolbar.css';
import { isTabletOrSmaller, useDeviceSize } from '../../../../context/DeviceSizeContextProvider';
import useHideOnClickOutside from '../../../../hooks/useHideOnClickOutside';
import { eventActions, eventCategories, trackEvent } from '../../../../services/analytics';
import useTranslation from '../../../../hooks/useTranslation';

const Toolbar = ({
  view, views, label, onNavigate, onView
}) => {
  const { t } = useTranslation();
  const { deviceSize } = useDeviceSize();
  const { ref, isComponentVisible, setIsComponentVisible } = useHideOnClickOutside(false);

  const calendarViewToEventAction = (selectedView) => {
    switch (selectedView) {
      case 'agenda':
        return eventActions.LIST;
      case 'day':
        return eventActions.DAY;
      case 'week':
        return eventActions.WEEK;
      case 'month':
        return eventActions.MONTH;
      default:
        return isTabletOrSmaller(deviceSize) ? eventActions.LIST : eventActions.WEEK;
    }
  };

  const onSelectView = (selectedView) => {
    onView(selectedView);
    trackEvent(eventCategories.myStudies.CALENDAR, calendarViewToEventAction(selectedView));
  };

  const Navigation = () => (
    <div className={styles.navigateControls}>
      <button type="button" className="button button--outline" onClick={() => onNavigate(Navigate.TODAY)}>
        {t('calendar.today')}
      </button>
      <button
        type="button"
        className="button button--outline"
        onClick={() => onNavigate(Navigate.PREVIOUS)}
        aria-label={t('previous')}
      >
        <span className="icon--caret-left" />
      </button>
      <button
        type="button"
        className="button button--outline"
        onClick={() => onNavigate(Navigate.NEXT)}
        aria-label={t('next')}
      >
        <span className="icon--caret-right" />
      </button>
    </div>
  );

  const MobileActions = () => (
    <div ref={ref} className={`${styles.mobileActionsContainer} ${styles.dropdownContainer}`}>
      <button
        type="button"
        className="button button--outline"
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        {t('calendar.actions.title')}
      </button>
      {isComponentVisible && (
        <div className={`${styles.mobileActionsContentContainer} ${styles.dropdownContentContainer}`}>
          <SubscribeToCalendar />
        </div>
      )}
    </div>
  );

  if (isTabletOrSmaller(deviceSize)) {
    return (
      <div className={styles.container}>
        <div className={styles.controlsContainer}>
          <Navigation />
          <MobileActions />
        </div>
        <div className={styles.controlsContainer}>
          <h3 className={styles.label}>{label}</h3>
          <div className={styles.selectViewControls}>
            <select value={view} onChange={(e) => onSelectView(e.target.value)}>
              {views.map((v) => (
                /* rlr renders [Object object] when using Translate component in option elements */
                <option key={v} value={v}>{t(`calendar.${v}View`)}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <TabGroup
        tabs={views.map((v) => ({ label: t(`calendar.${v}View`) }))}
        onTabSelect={(tabIndex) => onSelectView(views[tabIndex])}
        active={views.findIndex((v) => v === view)}
        controls="calendarControls"
        label={t('calendar.title')}
      />
      <div
        className={styles.controlsContainer}
        id="calendarControls"
        role="tabpanel"
        tabIndex={0}
        aria-label={t(`calendar.${view}View`)}
      >
        <Navigation />
        <h3 className={styles.label}>{label}</h3>
        <div className={styles.actionControls}>
          <SubscribeToCalendar />
        </div>
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  view: string.isRequired,
  views: arrayOf(string).isRequired,
  label: node.isRequired,
  onNavigate: func.isRequired,
  onView: func.isRequired
};

export default Toolbar;
