import { string } from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { hyphenateText } from '../../utils';

const HyphenatedText = ({ text, lang, className }) => (
  <span aria-label={text} className={classNames(className)}>
    {hyphenateText(text, lang)}
  </span>
);

HyphenatedText.propTypes = {
  text: string.isRequired,
  lang: string.isRequired,
  className: string
};

export default HyphenatedText;
