import React from 'react';

import { unicafeLunchMenuType } from '../../types';
import styles from './restaurant.css';
import useTranslation from '../../hooks/useTranslation';

const translateName = (lang, item) => {
  if (lang === 'fi') {
    return item.name;
  }
  return item[`name_${lang}`];
};

const UnicafeMenu = ({ menu }) => {
  const { lang } = useTranslation();

  return (
    <>
      {menu.map((menuItem) => (
        <div key={menuItem.name} className={styles.menuItem}>
          <div>{translateName(lang, menuItem)}</div>
          <div>
            {menuItem.meta[0].join(', ')}
          </div>
        </div>
      ))}
    </>
  );
};

UnicafeMenu.propTypes = {
  menu: unicafeLunchMenuType.isRequired
};

export default UnicafeMenu;
