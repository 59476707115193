import React, { useContext, useEffect, useState } from 'react';
import { node } from 'prop-types';
import Cookies from 'js-cookie';
import {
  useLocation
} from 'react-router-dom';

import { pathLanguages, DEFAULT_LANG } from '../../constants';
import LangContext from '../../context/LangContext';

const LANG_COOKIE_NAME = 'OBAR_LANG';

const InitializeLang = ({ children }) => {
  const { setLang } = useContext(LangContext);
  const [langInitialized, setLangInitialized] = useState(false);
  const { pathname } = useLocation();

  const prefix = pathname.match(/\/([^/]+)/);

  let urlLang;

  if (prefix && prefix.length > 1) {
    urlLang = pathLanguages[prefix[1]];
  }

  const cookieLang = Cookies.get(LANG_COOKIE_NAME);
  const defaultLanguage = urlLang || cookieLang || DEFAULT_LANG;

  useEffect(() => {
    if (!langInitialized) {
      setLang(defaultLanguage);
      setLangInitialized(true);
    }
  }, [langInitialized, defaultLanguage, setLang]);

  return (
    <>
      {langInitialized ? children : null}
    </>
  );
};

InitializeLang.propTypes = {
  children: node.isRequired
};

export default InitializeLang;
