import React from 'react';
import { bool, string } from 'prop-types';
import { isSameDay } from 'date-fns';

import { calendarEventType } from '../../../../types';

import styles from './event.css';
import EventTitle from './EventTitle';

const Event = ({ event, className, hideOptionalInfo }) => {
  const classNames = [
    styles.container,
    className,
    !isSameDay(event.start, event.end) ? styles.eventRange : undefined // all-day event, since it is grey, make the text black
  ].filter(Boolean).join(' ');
  return (
    <div className={classNames}>
      <div><EventTitle event={event} /></div>
      {!hideOptionalInfo && event.locations && event.locations.map((location, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={idx}>{location.roomName}</div>
      ))}
      {!hideOptionalInfo && event.optimeExtrasAsString && (
        <div>{event.optimeExtrasAsString}</div>
      )}
    </div>
  );
};

Event.propTypes = {
  event: calendarEventType,
  className: string,
  hideOptionalInfo: bool
};

Event.defaultProps = {
  hideOptionalInfo: false
};

export default Event;
