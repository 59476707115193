import React from 'react';
import { number } from 'prop-types';

import Section from '../../Section';
import { courseUnitRealisationType, courseUnitType } from '../../../types';

import styles from './literature.css';
import Link from '../../Link';
import useTranslation from '../../../hooks/useTranslation';

const formatLiterature = (literature) => {
  const { name, url } = literature;

  if (url) {
    return (
      <Link href={url} external>
        {name || url}
      </Link>
    );
  }

  return name;
};

const Literature = ({
  courseUnitRealisation, courseUnit, headerLevel
}) => {
  const { t } = useTranslation();

  const { literature } = courseUnitRealisation || courseUnit || {};

  if (!literature?.length) {
    return null;
  }

  return (
    <Section level={headerLevel} label={t('literature')} noUpperCaseLabel={Boolean(courseUnit)}>
      {literature.filter((l) => l.name || l.url).map((l) => (
        <p key={l.name + l.url} className={styles.entry}>
          {formatLiterature(l)}
        </p>
      ))}
    </Section>
  );
};

Literature.propTypes = {
  courseUnitRealisation: courseUnitRealisationType,
  courseUnit: courseUnitType,
  headerLevel: number.isRequired
};

export default Literature;
