import { coursesSearchParams } from '../../../api';
import { DEFAULT_STUDY_YEAR } from '../../../utils';

const {
  ORGANISATION,
  COURSE_TYPE,
  STUDY_LEVEL,
  TEACHING_LANGUAGE,
  STUDY_YEAR,
  PERIOD,
  TARGET_GROUP,
  DEGREE_PROGRAMME,
  STUDY_TRACK,
  PAGE_INDEX,
  SORT,
  SHOW_EXAMS,
  INTENSIVE_PERIOD
} = coursesSearchParams;

const searchOptionsReducer = (state, action) => {
  switch (action.type) {
    case 'searchText':
    case ORGANISATION:
    case STUDY_TRACK:
    case COURSE_TYPE:
    case STUDY_LEVEL:
    case TEACHING_LANGUAGE:
    case TARGET_GROUP:
    case SORT:
    case PERIOD:
    case INTENSIVE_PERIOD:
    case SHOW_EXAMS: {
      return { ...state, [action.type]: action.value, [PAGE_INDEX]: 0 };
    }
    case DEGREE_PROGRAMME: {
      return {
        ...state, [action.type]: action.value, [STUDY_TRACK]: undefined, [PAGE_INDEX]: 0
      };
    }
    case STUDY_YEAR: {
      return {
        ...state,
        [action.type]: action.value,
        [PERIOD]: undefined,
        [INTENSIVE_PERIOD]: undefined,
        [PAGE_INDEX]: 0
      };
    }
    case PAGE_INDEX: {
      return { ...state, [action.type]: action.value };
    }
    case 'clear':
      return { [STUDY_YEAR]: DEFAULT_STUDY_YEAR, searchText: '', [PAGE_INDEX]: 0 };
    default:
      throw new Error(`Unknown state transfer: '${action.type}'`);
  }
};

export const CLEAR = { type: 'clear' };

export default searchOptionsReducer;
