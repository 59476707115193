import React from 'react';
import { func, shape } from 'prop-types';
import classNames from 'classnames';

import { multiSearchParams } from '../../../api';

import styles from './searchForm.css';
import useTranslation from '../../../hooks/useTranslation';

const { SEARCH_TEXT } = multiSearchParams;

const SearchForm = ({
  onSearch, formState = {}, dispatch
}) => {
  const { t } = useTranslation();

  const onChangeValue = (type) => (value) => {
    dispatch({ type, value });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    onSearch(formState);
  };

  return (
    <div className="grid-container">
      <form className={styles.form} role="search">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="text-search" className="sr-only">{t('multiSearch.label')}</label>
        <input
          id="text-search"
          type="search"
          className={styles.searchInput}
          placeholder={t('multiSearch.searchPlaceholder')}
          value={formState[SEARCH_TEXT]}
          onChange={(event) => onChangeValue(SEARCH_TEXT)(event.target.value)}
        />
        <input
          type="submit"
          className={classNames('button', styles.submit)}
          value={t('search.button')}
          onClick={onSubmit}
        />
      </form>
    </div>
  );
};

SearchForm.propTypes = {
  onSearch: func,
  formState: shape({}),
  dispatch: func
};

export default SearchForm;
