import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import {
  arrayOf, string, func, bool
} from 'prop-types';
import { dpSelectorOptionType } from '../../types';
import { getTranslation } from '../../i18n';
import useHideOnClickOutside from '../../hooks/useHideOnClickOutside';
import useDegreePrograms from '../../hooks/useDegreePrograms';
import { DEGREE_PROGRAMME_GROUP_LABELS as LABELS, GUIDE_NEWS_DP_TYPES } from '../../constants';
import HyphenatedText from '../HyphenatedText';
import OptionGroup, { getOptionGroupId } from './OptionGroup';
import TypeFilters from './TypeFilters';
import DegreeProgrammeSearch from './DegreeProgrammeSearch';
import styles from './degreeProgrammeSelector.css';
import filterStyles from '../Filter/filter.css';
import MobileTypeFilters from './MobileTypeFilters';
import { isTabletOrSmaller, useDeviceSize } from '../../context/DeviceSizeContextProvider';
import useTranslation from '../../hooks/useTranslation';

const findCorresponding = (option, degreePrograms, ownMultiple) =>
  degreePrograms.find((dp) =>
    (ownMultiple || dp.isPrimary)
    && (dp.code === option.value || dp.groupId === option.value));

const getUserDegreeProgramsFromOptions = (degreePrograms, options, ownMultiple) =>
  options
    .filter((opt) => findCorresponding(opt, degreePrograms, ownMultiple));

const getSelectedOption = (options, selectedItem, ownOptions, useEmptyDefault = false) => {
  let selectedOption = options.find((option) => option.value === selectedItem);
  if (!selectedOption && ownOptions?.length && !useEmptyDefault) {
    selectedOption = options.find((option) => option.value === ownOptions[0].value);
  }

  return selectedOption;
};

const DegreeProgrammeSelector = ({
  options, onSelect, selectedItem = null, ownMultiple = false, isClearable = false
}) => {
  const { t, lang } = useTranslation();
  const degreePrograms = useDegreePrograms();
  const primaryDegreeProgram = degreePrograms?.find((dp) => dp?.isPrimary);

  const { deviceSize } = useDeviceSize();

  const optionsSorted = useMemo(() =>
    (Array.isArray(options)
      ? options.sort((o1, o2) => {
        const localizedName1 = getTranslation(o1.name, lang);
        const localizedName2 = getTranslation(o2.name, lang);

        return localizedName1.localeCompare(localizedName2, lang);
      })
      : []),
  [options, lang]);

  const groupedProgrammes = useMemo(() => {
    const groups = [
      { label: LABELS.OWN, types: [], options: [] },
      { label: LABELS.ALL, types: [], options: [] },
      { label: LABELS.BACHELORS_DEGREE, types: ['bachelors-degree', GUIDE_NEWS_DP_TYPES.BACHELORS], options: [] },
      { label: LABELS.MASTERS_DEGREE, types: ['masters-degree', 'lic', GUIDE_NEWS_DP_TYPES.MASTERS], options: [] },
      { label: LABELS.DOCTOR, types: ['doctor', GUIDE_NEWS_DP_TYPES.DOCTOR], options: [] },
      { label: LABELS.OTHER, types: ['other'], options: [] }
    ];

    if (degreePrograms) {
      const dps = getUserDegreeProgramsFromOptions(degreePrograms, optionsSorted, ownMultiple);
      if (dps?.length) {
        groups[0].options = dps;
      }
    }

    optionsSorted.forEach((o) => {
      const { type } = o;
      const grp = groups.find((e) => e.types.indexOf(type) > -1) || groups.find((e) => e.label === 'other');
      if (grp) {
        grp.options.push(o);
      }
      groups[1].options.push(o);
    });

    return groups;
  }, [optionsSorted, degreePrograms, ownMultiple]);

  const groupsWithProgrammes = useMemo(() => groupedProgrammes.slice(1).filter((go) => go.options.length > 0), [groupedProgrammes]);

  const { ref, isComponentVisible: isDPSelectorOpen, setIsComponentVisible: setIsDPSelectorOpen } = useHideOnClickOutside(false, 'parent');
  const [typeFilter, setTypeFilter] = useState('all');
  const [nameFilter, setNameFilter] = useState();

  const byType = useCallback(
    (group) => group.label !== 'all' && group.options.length > 0
      && (!typeFilter || group.types.indexOf(typeFilter) > -1 || group.label === 'own' || typeFilter === 'all'),
    [typeFilter]);

  const byName = useCallback(
    (o) => !nameFilter || getTranslation(o.name, lang).toLowerCase().indexOf(nameFilter.toLowerCase()) >= 0,
    [nameFilter, lang]);

  const selectedOption = getSelectedOption(optionsSorted, selectedItem, groupedProgrammes[0]?.options, isClearable);

  const resultsCount = useMemo(() => {
    if (!nameFilter && typeFilter === 'all') {
      return optionsSorted?.length;
    }
    return groupedProgrammes.filter(byType).flatMap((group) => group.options).filter(byName).length;
  }, [optionsSorted, groupedProgrammes, nameFilter, typeFilter, byName, byType]);

  useEffect(() => {
    const isSelected = selectedItem !== null;
    const isUserPrimaryDegreeProgram = !!primaryDegreeProgram?.groupId;
    if (!isSelected && isUserPrimaryDegreeProgram) {
      const programmesByGroupId = Object.fromEntries(groupedProgrammes.flatMap((group) => group.options.map((o) => [o.groupId, o])));
      const userPrimaryOption = programmesByGroupId[primaryDegreeProgram.groupId];
      if (userPrimaryOption) {
        onSelect(userPrimaryOption.value);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryDegreeProgram, selectedItem, groupedProgrammes]);

  if (!optionsSorted?.length) {
    return null;
  }

  const onResetSelection = () => {
    onSelect(undefined);
    setIsDPSelectorOpen(false);
  };

  const handleKeyDown = (e) => {
    if (isDPSelectorOpen) {
      switch (e.key) {
        case 'Escape':
          setIsDPSelectorOpen(false);
          break;
        default: break;
      }
    }
  };

  return (
    <fieldset className={filterStyles.filter}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className={styles.degreeProgrammeSwitcher} ref={ref} onKeyDown={handleKeyDown}>
        <legend className={filterStyles.filterTitle}>
          {t('degreeProgramStudies.filters.degreeProgramFilter.title')}
        </legend>

        <button
          type="button"
          className={`${filterStyles.toggleRowVisible} ${isDPSelectorOpen ? filterStyles.rowOpen : ''} icon--caret-down`}
          onClick={() => setIsDPSelectorOpen(!isDPSelectorOpen)}
          aria-haspopup="menu"
          aria-expanded={isDPSelectorOpen}
          aria-owns="dpList"
        >
          {selectedOption
            ? <HyphenatedText text={getTranslation(selectedOption.name, lang)} lang={lang} />
            : t('degreeProgramStudies.filters.degreeProgramFilter.prompt', lang)}
        </button>

        <div id="dpList" className={`${filterStyles.checkboxColumn} ${styles.dropDownContainer} ${!isDPSelectorOpen && 'hidden'}`}>
          {isClearable && (
          <button className={styles.clearSelection} type="button" onClick={onResetSelection}>
            {t('degreeProgramStudies.filters.degreeProgramFilter.clearSelection')} <span className="icon--remove" />
          </button>
          )}

          <DegreeProgrammeSearch onChange={(event) => setNameFilter(event.target.value)} />

          {nameFilter && (
          <div className="sr-only resultsCount" role="region" aria-live="polite" aria-atomic="true">
            {t('degreeProgramStudies.filters.degreeProgramFilter.resultsCount', { count: resultsCount })}
          </div>
          )}

          {isTabletOrSmaller(deviceSize) ? (
            <MobileTypeFilters
              groupedProgrammes={groupsWithProgrammes}
              typeFilter={typeFilter}
              setTypeFilter={setTypeFilter}
            />
          ) : (
            <TypeFilters
              groupedProgrammes={groupsWithProgrammes}
              typeFilter={typeFilter}
              setTypefilter={setTypeFilter}
            />
          )}
          <div id={getOptionGroupId(LABELS.ALL)}>
            {groupedProgrammes.filter(byType).map((go) => (
              <OptionGroup
                key={`d-${go.label}`}
                optionGroup={go}
                nameFilter={byName}
                setIsDPSelectorOpen={setIsDPSelectorOpen}
                onSelect={onSelect}
                ownMultiple={ownMultiple}
              />
            ))}
          </div>
        </div>
      </div>
    </fieldset>
  );
};

DegreeProgrammeSelector.propTypes = {
  selectedItem: string,
  options: arrayOf(dpSelectorOptionType),
  onSelect: func.isRequired,
  ownMultiple: bool,
  isClearable: bool
};

export default DegreeProgrammeSelector;
