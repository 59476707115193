import React from 'react';

import { courseUnitRealisationType } from '../../../../types';
import GradeDistributions from './GradeDistributions';
import Collapse from '../../../Collapse';
import Section from '../../../Section';
import styles from './courseResults.css';
import { getTranslation } from '../../../../i18n';
import useTranslation from '../../../../hooks/useTranslation';

const getUserResults = (userGrade, t, lang) => (userGrade ? (
  <>
    <h3 className={styles.userGrade}>{t('courseResults.userGrade.title')} <span>{getTranslation(userGrade.title, lang)}</span></h3>
  </>
) : null);

const CourseResults = ({ courseUnitRealisation = {} }) => {
  const { t, lang } = useTranslation();
  const { courseResults } = courseUnitRealisation;
  if (!courseResults?.userGrade && !courseResults?.distribution?.length) {
    return null;
  }

  return (
    <Section level={2} label={t('courseResults.title')}>
      {getUserResults(courseResults?.userGrade, t, lang)}
      {courseResults?.userGrade?.comment && (
        <Collapse className={styles.collapse} toggleLabel={t('courseResults.comment')} collapsed>
          {courseResults.userGrade.comment}
        </Collapse>
      )}
      {courseResults?.showGradeDistribution && courseResults?.distribution && (
        <Collapse className={styles.collapse} toggleLabel={t('courseResults.gradeDistribution')} collapsed>
          <GradeDistributions courseResults={courseResults} />
        </Collapse>
      )}
    </Section>
  );
};

CourseResults.propTypes = {
  courseUnitRealisation: courseUnitRealisationType
};

export default CourseResults;
