import React from 'react';

import { sodexoLunchMenuType } from '../../types';
import styles from './restaurant.css';
import useTranslation from '../../hooks/useTranslation';

const translateName = (lang, course) => {
  if (lang === 'en') {
    return course.title_en;
  }
  return course.title_fi;
};

const SodexoMenu = ({ menu }) => {
  const { lang } = useTranslation();
  return (
    <>
      {Object.values(menu).map((course) => (
        <div key={course.title_fi} className={styles.menuItem}>
          <div>{translateName(lang, course)}</div>
          <div>
            {course.dietcodes}
          </div>
        </div>
      ))}
    </>
  );
};

SodexoMenu.propTypes = {
  menu: sodexoLunchMenuType.isRequired
};

export default SodexoMenu;
