import React from 'react';
import {
  func, string, arrayOf, shape
} from 'prop-types';

import { localizedTextType } from '../../types';
import styles from './studyYearPicker.css';
import { getTranslation } from '../../i18n';
import useTranslation from '../../hooks/useTranslation';

const StudyYearPicker = ({
  label, onChange, value, options
}) => {
  const { lang } = useTranslation();

  return (
    <div className={styles.picker}>
      <label htmlFor="studyYearPicker">{label}</label>
      <div className={styles.selectContainer}>
        <select id="studyYearPicker" value={value} onChange={(e) => onChange(e.target.value)}>
          {options?.map((option) => <option key={option.id} value={option.id}>{getTranslation(option.name, lang)}</option>)}
        </select>
      </div>
    </div>
  );
};

StudyYearPicker.propTypes = {
  label: string,
  onChange: func.isRequired,
  value: string.isRequired,
  options: arrayOf(shape({
    id: string,
    name: shape({
      localizedTextType
    })
  })).isRequired
};

export default StudyYearPicker;
