import React from 'react';

import classNames from 'classnames';
import useIsLoggedIn from '../../../../hooks/useIsLoggedIn';
import styles from './links.css';
import { moodleLinkType } from '../../../../types';
import { formatDate } from '../../../../utils';
import { eventCategories } from '../../../../services/analytics';
import Link from '../../../Link';
import useTranslation from '../../../../hooks/useTranslation';

const KEY_NOT_SHOWN = 'NOT_SHOWN';

const MoodleLink = ({ link }) => {
  const { t, lang } = useTranslation();
  const loggedIn = useIsLoggedIn();

  if (!link || !link.published) {
    return null;
  }
  let moodleSecretHint;
  let moodleScheduleHint;
  const moodleSecrets = [];
  if (link.key === KEY_NOT_SHOWN || link.pw === KEY_NOT_SHOWN) {
    moodleSecretHint = loggedIn ? t('moodleLink.enrol') : t('moodleLink.logIn');
  } else {
    if (link.key) {
      moodleSecrets.push({ label: t('moodleLink.key'), value: link.key });
    }
    if (link.pw) {
      moodleSecrets.push({ label: t('moodleLink.password'), value: link.pw });
    }
  }
  if (link.schedule && link.publishTime && !link.schedPublished) {
    moodleScheduleHint = t('moodleLink.schedule', { publishTime: formatDate(link.publishTime, lang) });
  }

  return (
    <div className={classNames(styles.linksContainer, styles.moodleLinkContainer)}>
      <h2>Moodle</h2>
      <Link
        href={link.url}
        className={`button--action ${styles.moodleLinkButton}`}
        tracked
        eventCategory={eventCategories.coursePage.LINK}
        trackingLabel="moodle"
        external
        iconPosition="after"
      >
        {t('moodleLink.goToMoodle')}
      </Link>
      {(moodleSecretHint || moodleScheduleHint || moodleSecrets.length) ? (
        <div className={styles.moodleSecrets}>
          {moodleSecretHint && (
            <div className={styles.moodleHint}>{moodleSecretHint}</div>
          )}
          {moodleScheduleHint && (
            <div className={styles.moodleScheduleHint}>{moodleScheduleHint}</div>
          )}
          {moodleSecrets.length ? moodleSecrets.map(({ label, value }) => (
            <div key={value} className={styles.moodleSecret}>
              <span className={classNames('icon--permission-private', styles.icon)} />
              <span className={styles.label}>{label}:</span>
              {' '}
              {value}
            </div>
          )) : null}
        </div>
      ) : null}
    </div>
  );
};

MoodleLink.propTypes = {
  link: moodleLinkType
};

export default MoodleLink;
