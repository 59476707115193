import RssParser from 'rss-parser';

const parser = new RssParser({
  customFields: {
    item: ['category']
  }
});

// eslint-disable-next-line import/prefer-default-export
export const parseFeed = async (url) => {
  try {
    const response = await fetch(url);
    const feed = await response.text();
    return parser.parseString(feed);
  } catch {
    return {};
  }
};
