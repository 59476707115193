import React from 'react';
import DegreeProgrammeNews from './DegreeProgrammeNews';
import CommonNews from './CommonNews';
import { eventCategories } from '../../services/analytics';

const GuideNewsContainer = () => (
  <>
    <DegreeProgrammeNews trackedEventCategory={eventCategories.frontpage.CURRENT_TOPICS_DEGREE_PROGRAMME} />
    <CommonNews />
  </>
);

export default GuideNewsContainer;
