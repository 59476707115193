import React from 'react';
import PropTypes from 'prop-types';
import StudyModule from './StudyModule';
import Message from '../Message';
import { getStudyModule } from '../../api';
import useApiFunction from '../../hooks/useApiFunction';
import useLocationSearch from '../../hooks/useLocationSearch';
import useTranslation from '../../hooks/useTranslation';

const StudyModuleWrapper = ({ studyModuleId }) => {
  const { t } = useTranslation();
  const locationSearch = useLocationSearch();
  let cpId = locationSearch.get('cpId');
  if (cpId && (cpId.length > 9 || !cpId.startsWith('hy-lv-'))) {
    cpId = '';
  }
  const { data: studyModule, error, loading } = useApiFunction(getStudyModule, studyModuleId, cpId);

  if (loading) {
    return (
      <Message loading>
        {t('studyModule.loading', { studyModuleId })}
      </Message>
    );
  }

  if (error) {
    const messageKey = error.status === 404 ? 'studyModule.notFound' : 'common.error';
    const errorData = error.status === 404 ? {
      studyModuleId
    } : {
      status: error.status,
      message: error.statusText
    };

    return (
      <Message error>
        {t(messageKey, errorData)}
      </Message>
    );
  }
  if (studyModule) {
    return (
      <StudyModule studyModule={studyModule} />
    );
  }
  return null;
};

StudyModuleWrapper.propTypes = {
  studyModuleId: PropTypes.string
};

export default StudyModuleWrapper;
