import React, {
  useState, useEffect, useContext, useCallback
} from 'react';
import { element } from 'prop-types';

import {
  deviceSizes, SMALL_DESKTOP_MEDIA, TABLET_MEDIA, MOBILE_MEDIA
} from '../constants';

import DeviceSizeContext from './DeviceSizeContext';

const RESIZE_LISTENER = 'resize';

const getDeviceSize = () => {
  if (window.matchMedia(MOBILE_MEDIA).matches) {
    return deviceSizes.MOBILE;
  }
  if (window.matchMedia(TABLET_MEDIA).matches) {
    return deviceSizes.TABLET;
  }
  if (window.matchMedia(SMALL_DESKTOP_MEDIA).matches) {
    return deviceSizes.SMALL_DESKTOP;
  }
  return deviceSizes.LARGE_DESKTOP;
};

const DeviceSizeContextProvider = ({ children }) => {
  const [deviceSize, setDeviceSize] = useState(getDeviceSize());

  const handleResize = useCallback(({ size }) => {
    const newSize = getDeviceSize();
    if (size !== newSize) {
      setDeviceSize(newSize);
    }
  }, [setDeviceSize]);

  useEffect(() => {
    window.addEventListener(RESIZE_LISTENER, handleResize);

    return () => {
      window.removeEventListener(RESIZE_LISTENER, handleResize);
    };
  }, [handleResize, deviceSize]);

  return (
    <DeviceSizeContext.Provider value={{ deviceSize }}>
      {children}
    </DeviceSizeContext.Provider>
  );
};

DeviceSizeContextProvider.propTypes = {
  children: element.isRequired
};

export default DeviceSizeContextProvider;

export const useDeviceSize = () => useContext(DeviceSizeContext);

// device sizes are represented as larger value is smaller screen
export const isSameSizeOrSmaller = (currentSize, toCompare) => currentSize >= toCompare;
export const isSameSizeOrLarger = (currentSize, toCompare) => currentSize <= toCompare;

export const isDesktop = (currentSize) => currentSize === deviceSizes.SMALL_DESKTOP || currentSize === deviceSizes.LARGE_DESKTOP;
export const isTabletOrSmaller = (currentSize) => isSameSizeOrSmaller(currentSize, deviceSizes.TABLET);
export const isMobile = (currentSize) => currentSize === deviceSizes.MOBILE;
