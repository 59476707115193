import React from 'react';
import { bool, node, string } from 'prop-types';
import { eventCategories } from '../../../services/analytics';
import { ArrowOffsite, ArrowRight } from '../../Icon/Arrow';

import styles from './exploreStudies.css';
import IconBox from '../../Icon/IconBox';
import Link from '../../Link';

const isAbsoluteUrl = (url) => /^https?:\/\//.test(url);

const LinkItem = ({
  url, text, tagLine, image, external, trackingLabel
}) => (
  <div className={`${styles.linkItem} grid-column desktop-size-4 tablet-size-8 mobile-size-4`}>
    <Link
      tracked
      eventCategory={eventCategories.courses.LINKS}
      trackingLabel={trackingLabel}
      href={url}
      useRouter={!external && !isAbsoluteUrl(url)}
      external={external}
      iconPosition="none"
    >
      <div className={styles.linkContent}>
        <div className={styles.image} aria-hidden="true">
          <img src={image} alt={text} />
        </div>
        <div className={styles.text}>
          <h3>{text}</h3>
          <p>{tagLine}</p>
        </div>
        <IconBox isLinkBoxIcon icon={external ? ArrowOffsite : ArrowRight} />
      </div>
    </Link>
  </div>
);

LinkItem.propTypes = {
  url: string.isRequired,
  text: string.isRequired,
  tagLine: string.isRequired,
  image: node.isRequired,
  external: bool,
  trackingLabel: string.isRequired
};

LinkItem.defaultProps = {
  external: false
};

export default LinkItem;
