import React, { useEffect, useState } from 'react';
import { element, string } from 'prop-types';

import LangContext from './LangContext';
import {
  AVAILABLE_LANGUAGES
} from '../constants';

const LangContextProvider = ({ children, testLang }) => {
  const [lang, setLang] = useState(AVAILABLE_LANGUAGES[0]);

  useEffect(() => {
    if (testLang) {
      setLang(testLang);
    }
  }, [testLang]);

  return (
    <LangContext.Provider value={{ lang, setLang }}>
      { children }
    </LangContext.Provider>
  );
};

LangContextProvider.propTypes = {
  children: element.isRequired,
  testLang: string
};

export default LangContextProvider;
