/* eslint-disable jsx-a11y/media-has-caption */

import React from 'react';
import { bool } from 'prop-types';

import { cmsMaterialFileType } from '../../../../../types';
import { COURSE_PAGE_API_PATH } from '../../../../../constants';
import { getLocale } from '../../../util';

import styles from '../cmsMaterials.css';
import { eventCategories } from '../../../../../services/analytics';
import Link from '../../../../Link';
import useTranslation from '../../../../../hooks/useTranslation';

const FILE_SIZE_UNITS = ['B', 'KB', 'MB', 'GB'];

const formatFileSize = (fileSize, lang = 'fi') => {
  const exponent = Math.min(Math.floor(Math.log10(fileSize) / 3), FILE_SIZE_UNITS.length - 1);
  const formattedSize = Number((fileSize / 1000 ** exponent).toPrecision(3));

  return `${formattedSize.toLocaleString(getLocale(lang))} ${FILE_SIZE_UNITS[exponent]}`;
};

const getFileExtension = (path) => (path && path.includes('.') ? path.split('?')[0].slice(path.lastIndexOf('.') + 1) : '');

const MaterialFile = ({ material, embedMedia }) => {
  const { lang } = useTranslation();
  const fileUrl = `${COURSE_PAGE_API_PATH}/cur/file${material.path}`;

  const getMediaContainer = () => {
    let media;
    if (material.mime?.startsWith('video')) {
      media = (
        <video id={`video-${material.id}`} autoPlay={false} controls>
          <source src={fileUrl} type={material.mime} />
        </video>
      );
    } else if (material.mime?.startsWith('audio')) {
      media = (
        <audio id={`audio-${material.id}`} autoPlay={false} controls>
          <source src={fileUrl} type={material.mime} />
        </audio>
      );
    } else {
      return null;
    }

    return (
      <div className={styles.mediaContainer}>
        {media}
      </div>
    );
  };

  const title = material.description || material.name;

  return (
    <div className={styles.materialContainer}>
      {embedMedia && getMediaContainer()}
      <div className={styles.infoContainer}>
        {material.path ? (
          <Link
            tracked
            eventCategory={eventCategories.coursePage.LINK}
            trackingLabel="materials"
            href={fileUrl}
            download
          >
            {title}
            {' '}
            ({getFileExtension(material.path).toUpperCase()}, {formatFileSize(material.size, lang)})
          </Link>
        ) : title}
        {material.instructions && <div>{material.instructions}</div>}
      </div>
    </div>
  );
};

MaterialFile.propTypes = {
  material: cmsMaterialFileType.isRequired,
  embedMedia: bool
};

MaterialFile.defaultProps = {
  embedMedia: true
};

export default MaterialFile;
