import { stringify } from 'query-string';
import Cookies from 'js-cookie';

import { parseStudyYearsAsRequestParams } from './utils';

import {
  API_PATH,
  COURSE_PAGE_API_PATH,
  AUTO_LOGIN_COOKIE_NAME,
  CSRF_PROTECTION_HEADER_NAME,
  UNICAFE_API_BASE_PATH,
  SODEXO_API_BASE_PATH
} from './constants';

const APPLICATION_JSON_TYPE = 'application/json';
export const optionTypes = {
  ORGANISATION: 'organisation',
  COURSE_TYPE: 'type',
  TEACHING_LANGUAGE: 'teachingLanguageUrn',
  STUDY_LEVEL: 'studyLevel',
  STUDY_YEAR: 'studyYear',
  PERIOD: 'period',
  TARGET_GROUP: 'customCodeUrns',
  DEGREE_PROGRAMME: 'degreeProgramme',
  STUDY_TRACK: 'studyTrack',
  FACULTY: 'faculty',
  SHOW_EXAMS: 'showExams',
  INTENSIVE_PERIOD: 'intensivePeriod'
};

const commonSearchParams = {
  SEARCH_TEXT: 'searchText',
  PAGE_INDEX: 'page'
};

export const coursesSearchParams = {
  ...optionTypes,
  ...commonSearchParams,
  SORT: 'sort'
};

export const multiSearchParams = {
  DEGREE_PROGRAMME: 'degreeProgramCode',
  TARGET_GROUP: 'targetGroup',
  TYPE: 'type',
  ...commonSearchParams
};

export const isJSONResponse = (response) => {
  const contentType = response.headers.get('content-type');
  return contentType?.toLowerCase?.()?.includes(APPLICATION_JSON_TYPE);
};

const withResponseHandler = async (request, promise) => {
  let response;

  try {
    response = await promise;
  } catch {
    // ignore network errors
    return {};
  }

  if (!response) {
    throw new Error(`no response for request: ${request?.method} - ${request?.url}`);
  }

  if (response.status === 200 && isJSONResponse(response)) {
    return response.json().catch(() => ({}));
  }

  if (response.ok) {
    return undefined;
  }

  throw new Error(`error response: ${response.status} - ${request.method} ${request.url}`);
};

export const getJson = (path, signal, extraHeaders = {}) => {
  const req = new Request(path, {
    headers: {
      Accept: APPLICATION_JSON_TYPE,
      ...extraHeaders
    },
    credentials: 'same-origin',
    signal
  });
  return withResponseHandler(req, fetch(req));
};

const postJson = (path, payload) => {
  const req = new Request(path, {
    method: 'POST',
    headers: {
      Accept: APPLICATION_JSON_TYPE,
      'Content-Type': APPLICATION_JSON_TYPE,
      [CSRF_PROTECTION_HEADER_NAME]: 1
    },
    body: payload ? JSON.stringify(payload) : undefined,
    credentials: 'same-origin'
  });
  return withResponseHandler(req, fetch(req));
};

const putJson = (path, payload) => {
  const req = new Request(path, {
    method: 'PUT',
    headers: {
      Accept: APPLICATION_JSON_TYPE,
      'Content-Type': APPLICATION_JSON_TYPE,
      [CSRF_PROTECTION_HEADER_NAME]: 1
    },
    body: payload ? JSON.stringify(payload) : undefined,
    credentials: 'same-origin'
  });
  return withResponseHandler(req, fetch(req));
};

const del = (path) => {
  const req = new Request(path, {
    method: 'DELETE',
    headers: {
      [CSRF_PROTECTION_HEADER_NAME]: 1
    },
    credentials: 'same-origin'
  });
  return withResponseHandler(req, fetch(req));
};

export const coursesSearch = (query) => getJson(`${API_PATH}/search/courses?${stringify(query)}`);

export const searchDegreeProgramCourses = (query, languageCode = 'fi') => coursesSearch({ ...query, includeStudyLevelAggregations: true, pageLanguage: languageCode });

export const multiSearchPath = '/search/multi';
export const multiSearch = (query) => getJson(`${API_PATH}${multiSearchPath}?${stringify(query)}`);

export const findFilterOptions = (query = { types: Object.values(optionTypes) }) =>
  getJson(`${API_PATH}/options?${stringify(query)}`);

export const findPeriodFilterOptions = (studyYear) =>
  findFilterOptions({ types: [optionTypes.PERIOD], studyYear });

export const findOrgFilterOptions = (studyYear) =>
  findFilterOptions({ types: [optionTypes.ORGANISATION], studyYear });

export const getCourseUnit = (id) => getJson(`${COURSE_PAGE_API_PATH}/cu/${id}`);

export const getCourseUnitRealisation = (id, languageCode = 'fi', fromSearchResult = false) =>
  getJson(`${COURSE_PAGE_API_PATH}/cur/${id}?languageCode=${languageCode}`, undefined, fromSearchResult ? { 'Studies-Search-Result': 1 } : {});

export const getClientConfig = () => getJson('/config');

export const login = (referer) => {
  let url = '/auth/login';
  if (referer) {
    url += `?referer=${referer}`;
  }
  window.location.assign(url);
};

export const getSession = () => getJson('/auth/session');

export const localLogin = (username, password, referer) => {
  let url = `/auth/login?username=${username}&password=${password}`;
  if (referer) {
    url += `&referer=${referer}`;
  }
  window.location.assign(url);
};

export const doPassiveLogin = () => {
  const inSixSeconds = new Date(new Date().getTime() + 6 * 1000);
  Cookies.set(AUTO_LOGIN_COOKIE_NAME, true, { secure: true, expires: inSixSeconds });

  window.location.replace(`/Shibboleth.sso/Login?isPassive=true&target=${encodeURIComponent(window.location)}`);
};

export const getLocalTestUsers = () => getJson('/auth/users');

export const getObarConfig = (lang) => getJson(`/obar-config?lang=${lang}`);

export const getDegreeProgrammeNews = (degreeProgramme, lang) => getJson(`/api/news?degreeProgramme=${degreeProgramme}&lang=${lang}`);

export const getCommonNews = (lang) => getJson(`/api/news?lang=${lang}`);

export const getNewsById = (id, lang) => getJson(`/api/news/${id}?lang=${lang}`);

export const getGuideDegreeProgrammes = (lang, hasNews = true) => getJson(`/api/news/degree-programmes?lang=${lang}${hasNews ? '&hasNews=true' : '&hasNews=false'}`);

export const getUnicafeRestaurants = () => {
  const req = new Request(`${UNICAFE_API_BASE_PATH}/restaurants`);
  return withResponseHandler(req, fetch(req));
};

export const getUnicafeRestaurantMenu = (id) => {
  const req = new Request(`${UNICAFE_API_BASE_PATH}/restaurant/${id}`);
  return withResponseHandler(req, fetch(req));
};

export const getSodexoRestaurantMenu = (id, date) => {
  const req = new Request(`${SODEXO_API_BASE_PATH}/${id}/${date}`);
  return withResponseHandler(req, fetch(req));
};

export const getEvents = (lang) => getJson(`/api/events?lang=${lang}`);

export const getFlammaNews = (lang, faculty) => getJson(`/api/flamma?lang=${lang}${faculty ? `&faculty=${faculty}` : ''}`);

export const getCalendarFeed = () => getJson('/api/calendars');

export const upsertCalendarFeed = () => postJson('/api/calendars', null);

export const getUserEvents = (lang) => getJson(`/api/enrollments?type=EVENT${lang ? `&lang=${lang}` : ''}`);

export const getUserEnrollments = (lang) => getJson(`/api/enrollments?type=COURSE${lang ? `&lang=${lang}` : ''}`);

export const getUserTodoItems = () => getJson('/api/todo-items');

export const addUserTodoItem = (item) => postJson('/api/todo-items', item);

export const updateUserTodoItem = (id, item) => putJson(`/api/todo-items/${id}`, item);

export const removeUserTodoItem = (id) => del(`/api/todo-items/${id}`);

export const updateUserPreferences = (preferences) => postJson('/api/preferences', preferences);

export const getBulletinLiftup = (lang) => getJson(`api/news/bulletin-liftup?lang=${lang}`);

export const getLatestCourseParticipantRequest = (id) => getJson(`/api/courses/participant-list-request/${id}`);

export const postCourseParticipantListRequest = (id, language) => postJson('/api/courses/participant-list-request', { id, language });

export const postEnrolment = (enrollment) => postJson('/api/enrollments', { enrollment });

export const getStudyModule = (id, cpId) => getJson(`/api/sm/${id}${cpId ? `?cpId=${cpId}` : ''}`);

export const getDegreeProgram = (id) => (id ? getJson(`/api/degree-programs/${id}`) : {});

/**
 * @param {string} studyYear Single study year or multiple study years separated by commas
 */
export const getDegreeProgrammes = (studyYear) => getJson(`/api/degree-programs${studyYear ? parseStudyYearsAsRequestParams(studyYear) : ''}`);
