import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { shape, string, bool } from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import classnames from 'classnames';
import styles from './newsPage.css';
import { eventCategories, eventActions } from '../../services/analytics';
import Breadcrumb from '../Breadcrumb';
import {
  AVAILABLE_LANGUAGES, breadcrumbs, ICON_POSITIONS, routes
} from '../../constants';

import {
  asRestrictedGuideBodyHtml,
  formatDate,
  guideAllNewsLinkForLanguage,
  withPlaceholderImage
} from '../../utils';
import { getNewsById } from '../../api';
import DocumentHead from '../DocumentHead';
import HyphenatedText from '../HyphenatedText';
import ErrorBoundary from '../ErrorBoundary';
import Link from '../Link';
import useTranslation from '../../hooks/useTranslation';

const NewsPageComponent = ({
  match: { params: { id: newsId } }, displayImage, archiveLinkTrackingCategory, isDegreeProgrammeNews
}) => {
  const { t, lang } = useTranslation();
  const [newsElement, setNewsElement] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const news = await getNewsById(newsId, lang);
        setNewsElement(displayImage ? withPlaceholderImage(news) : { ...news, image: '' });
      } catch (e) {
        setNewsElement(null);
      }
    };
    fetchData();
  }, [newsId, displayImage, lang]);

  if (newsElement === undefined) {
    return null; // not yet loaded
  }

  if (!newsElement) {
    return (
      <section className={styles.container}>
        <Breadcrumb crumb={breadcrumbs.NEWS_ARCHIVE} />
        <div className={styles.noNews}>{t('noNews')}</div>
      </section>
    );
  }

  const isTracked = Boolean(archiveLinkTrackingCategory);
  const ArchiveLink = () => (
    <Link
      href={guideAllNewsLinkForLanguage(lang)}
      className={classnames(styles.link, 'button--action')}
      iconPosition={ICON_POSITIONS.AFTER}
      tracked={isTracked}
      eventCategory={isTracked ? archiveLinkTrackingCategory : undefined}
      eventAction={isTracked ? eventActions.SHOW_ALL : undefined}
      noTrackingLabel
    >
      {t('bulletinPage.archive')}
    </Link>
  );

  const localizedUrls = AVAILABLE_LANGUAGES.map((l) => [l, routes.news(l, newsId, isDegreeProgrammeNews, newsElement.alias)]);
  const localizedUrl = routes.news(lang, newsId, isDegreeProgrammeNews, newsElement.alias);
  const editBar = newsElement.editLink ? (
    <div className={styles.editBar}>
      <div className={styles.innerEditBar}>
        <span aria-hidden="true" className={`icon-2x icon--edit-event ${styles.icon}`} />
        <span className={styles.actionLabel}>{t('bulletinPage.editing')}</span>
        <Link className="button--action" href={newsElement.editLink} iconPosition={ICON_POSITIONS.AFTER}>
          {t('bulletinPage.edit')}
        </Link>
      </div>
    </div>
  ) : null;

  const bulletinDate = (
    <div className={styles.date}>
      {t('bulletinPage.bulletin')} {newsElement.isoDate && `| ${formatDate(newsElement.isoDate, lang)}`}
    </div>
  );

  const aboutLine = (
    <div className={styles.aboutLineContainer}>
      <div className={styles.aboutLine}>
        {(newsElement.changeDate && newsElement.changeDate !== newsElement.isoDate) && (
          <span>{t('bulletinPage.updated')} {formatDate(newsElement.changeDate, lang)}</span>
        )}
        {(newsElement.responsibleUnit?.name || newsElement.responsibleUnit?.email) && (
          <div className={styles.responsibleUnit}>
            <span className={styles.responsibleUnitLabel}>{t('bulletinPage.responsibleUnit')}:</span>
            {newsElement.responsibleUnit.name && (
              <span className={styles.responsibleUnitName}>{newsElement.responsibleUnit.name}</span>
            )}
            {newsElement.responsibleUnit.email && (
              <a className={styles.responsibleUnitEmail} href={`mailto:${newsElement.responsibleUnit.email}`}>{newsElement.responsibleUnit.email}</a>
            )}
          </div>
        )}
      </div>
    </div>
  );

  const fixRelativeGuideLinksForLocal = (html) => {
    const localDomain = 'local.studies.helsinki.fi';
    if (window.location.host.startsWith(localDomain)) {
      const guideApiBaseWhenLocal = 'https://studies-qa.it.helsinki.fi';
      const relativeLinkRegEx = /src="\/sites\//gi;
      return html.replace(relativeLinkRegEx, `src="${guideApiBaseWhenLocal}/sites/`);
    }
    return html;
  };

  return (
    <section className={styles.container}>
      <Breadcrumb crumb={breadcrumbs.NEWS_ARCHIVE} />
      <DocumentHead urls={localizedUrls} title={newsElement.title} />
      <article className={styles.article}>
        <h1><HyphenatedText text={newsElement.title} lang={lang} /></h1>
        {editBar}
        <div className={styles.asideContainer}>
          <div className={styles.articleMain}>
            {newsElement.image && (
              <div className={styles.imageContainer}>
                <img src={newsElement.image} alt={newsElement.imageAltText} />
              </div>
            )}
            {bulletinDate}
            <div
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: fixRelativeGuideLinksForLocal(asRestrictedGuideBodyHtml(newsElement.body)) }}
              className={`${styles.newsContentBody} rich-text`}
            />
          </div>
          <aside>
            {bulletinDate}
            <CopyToClipboard text={`${window.location.protocol}//${window.location.host}${localizedUrl}`}>
              <button type="button" className={styles.copyURL}>
                <span>{t('bulletinPage.copyURL')}</span>
              </button>
            </CopyToClipboard>
            <div className={styles.archiveLinkContainer}>
              <span className={styles.actionLabel}>{t('bulletinPage.moreBulletins')}</span>
              <ArchiveLink />
            </div>
          </aside>
        </div>
        {aboutLine}
      </article>
    </section>
  );
};

NewsPageComponent.propTypes = {
  match: shape({
    params: shape({
      id: string
    }).isRequired
  }).isRequired,
  displayImage: bool,
  archiveLinkTrackingCategory: string,
  isDegreeProgrammeNews: bool
};

NewsPageComponent.defaultProps = {
  displayImage: true,
  archiveLinkTrackingCategory: eventCategories.frontpage.CURRENT_TOPICS,
  isDegreeProgrammeNews: false
};

/**
 * For degree programme news we will not display the placeholder image when image is not provided.
 */
const DegreeProgrammeNewsPageComponent = ({ match }) => (
  <NewsPageComponent
    match={match}
    displayImage={false}
    archiveLinkTrackingCategory={eventCategories.frontpage.CURRENT_TOPICS_DEGREE_PROGRAMME}
    isDegreeProgrammeNews
  />
);

DegreeProgrammeNewsPageComponent.propTypes = NewsPageComponent.propTypes;

export const NewsPageLocalized = withRouter(NewsPageComponent);
export const DegreeProgrammeNewsPageLocalized = withRouter(DegreeProgrammeNewsPageComponent);

export const NewsPage = () => (
  <ErrorBoundary>
    <NewsPageLocalized />
  </ErrorBoundary>
);

export const DegreeProgrammeNewsPage = () => (
  <ErrorBoundary>
    <DegreeProgrammeNewsPageLocalized />
  </ErrorBoundary>
);
